import React, { useState } from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Avatar,
    Typography,
} from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { setActiveSection } from "../HomePage/homeSectionSlice";
import { useNavigate } from "react-router";
import { SearchNormal } from "iconsax-react";

const AllMessagesMobile = () => {
    const [activeTab, setActiveTab] = useState("personal");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const messages = [
        {
            name: "Hugo Borg",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Raymond Hernandez",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Rajeev Lumari",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Khalid Rashid",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Alex John",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Hugo Borg",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Raymond Hernandez",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Rajeev Lumari",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Khalid Rashid",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
        {
            name: "Alex John",
            message: "Hi, How are you?",
            date: "21/09/23",
            number: 3,
        },
    ];

    const data = [
        {
            label: "Personal",
            value: "personal",
        },
        {
            label: "Groups",
            value: "groups",
        },
    ];

    return (
        <div className="">
            <Tabs value={activeTab} className="h-full p-3">
                <TabsHeader
                    className="rounded-none w-2/3 md:w-full border-0 bg-transparent p-0"
                    indicatorProps={{
                        className:
                            "bg-transparent border-b-2 border-primaryLight-500 dark:border-primaryDark-500 shadow-none rounded-none",
                    }}
                >
                    {data.map(({ label, value }) => (
                        <Tab
                            key={value}
                            value={value}
                            onClick={() => setActiveTab(value)}
                            className={`${activeTab === value ? "text-primaryLight-500 dark:text-primaryDark-500" : "text-textLight-300 dark:text-white"} font-semibold font-archivo`}
                        >
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <div className="relative rounded-xl mt-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                        <div className="p-1">
                            <SearchNormal size="18" />
                        </div>
                    </span>
                    <input
                        type="search"
                        name="q"
                        className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none
                         focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                        placeholder="Search"
                    />
                </div>
                <TabsBody animate={{
                    initial: { x: 350 },
                    mount: { x: 0 },
                    unmount: { x: 350 },
                }}>
                    {data.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            {messages?.map((item, index) => (
                                <div key={index} onClick={() => (
                                    dispatch(setActiveSection("messages")),
                                    navigate(`/home/chat/${index}`, { state: { type: value === "personal" ? "individual" : "group" } })
                                )}
                                    className="py-3 flex items-center justify-between cursor-pointer">
                                    <div className="flex gap-2 items-center">
                                        <Avatar
                                            src="https://docs.material-tailwind.com/img/face-2.jpg"
                                            alt="avatar"
                                            size="sm"
                                        />
                                        <div>
                                            <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white">
                                                {item?.name}
                                            </Typography>
                                            <Typography className="text-xs font-normal font-rubik text-textLight-400 dark:text-white">
                                                {item?.message}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <Typography className="text-sm font-archivo font-normal text-supportLight-500 dark:text-supportDark-100">
                                                {item?.date}
                                            </Typography>
                                            <div className=" flex items-center justify-center">
                                                <Typography className="text-sm font-rubik text-center text-white font-bold bg-supportLight-500 px-2 rounded-full">
                                                    {item?.number}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </div>
    );
}

export default AllMessagesMobile;