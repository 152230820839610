import React, { useEffect, useState } from "react";
import {
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import {
  Dislike,
  Save2,
  Like1,
  Message2,
  Trash,
  Edit2,
  PlayCircle,
  EyeSlash,
  Eye,
  ArchiveSlash,
} from "iconsax-react";
import PostPoll from "../PostPoll";
import PDFViewer from "../PdfViewer";
import ReportPostBox from "../ReportPostCard";
import { CgMoreVertical } from "react-icons/cg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setActiveScreen } from "../Sidebar/sidebarSlice";
import { GET_ALL_POSTS_OF_INDIVIDUAL, GET_POLL_VOTES, GET_SAVED_POSTS } from "../../../API/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { setActiveTab, setIsEditPost } from "../../../containers/Pages/CreatePost/activeTabSlice";
import { ADD_TO_SAVE, DELETE_POST, HIDE_MENTIONED_POSTS } from "../../../API/Mutations";
import toast from "react-hot-toast";
import moment from "moment/moment";

const PostCard = ({ data, isEdit, setIsEdit, setScrollView, mention, saved, isScheduled, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();;
  const [deletePostOpen, setdeletePostOpen] = useState(false);
  const [hide, setHide] = useState(null);
  const [savePost, setSavePost] = useState(false);
  const { userToken, userInfo } = useSelector((state) => state.authSlice);

  function titleCase(string) {
    return string?.length > 0 && string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  const {
    data: pollSubmitData
  } = useQuery(GET_POLL_VOTES, {
    variables: { pollId: data?.pollId },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      },
    },
    skip: type !== "poll",
  });

  const [deletePost, { data: deletePostData }] =
    useMutation(DELETE_POST, {
      onError: (error) => {
        // console.log(error.graphQLErrors[0].message)
        toast.error(error.graphQLErrors[0].message);
      },
      context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
      refetchQueries: [{
        query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
          headers: {
            Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
          },
        },
      }]
    });

  const [addToSave, { data: addtoSaveData }] = useMutation(ADD_TO_SAVE, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
    refetchQueries: [{
      query: GET_SAVED_POSTS, context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
    }]
  })

  const getFileNameFromUrl = (url) => {
    // Use the URL object to parse the URL and extract the pathname
    const urlObject = new URL(url);
    const pathname = urlObject?.pathname;
    // Split the pathname to get the filename
    const fileName = pathname.split("/").pop();
    const parts = fileName.split("-");
    parts.shift(); // Remove the first part
    // Join the remaining parts back together with dashes
    return parts.join("-");
  };

  const [archivePost, { data: archivedData }] = useMutation(HIDE_MENTIONED_POSTS, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    }
  });

  useEffect(() => {
    if (archivedData?.archivePost?.archived) {
      setHide(archivedData?.archivePost?.archived)
    } else {
      setHide(data?.archived)
    }
  }, [archivedData?.archivePost?.archived]);

  // console.log(data)

  return (
    <div className="relative flex flex-col w-[100%] md:w-[49%] md:min-w-[490px] min-w-[100%] rounded-xl shadow-xl py-8 px-4 md:px-8 dark:bg-textLight-700">
      {isScheduled && isEdit &&
        <div className="z-50 absolute top-0 left-0 h-full w-full bg-white/60 dark:bg-black/70 rounded-xl flex items-center justify-center">
          <div className="bg-primaryLight-50 dark:bg-textLight-500 border border-primaryLight-100 dark:border-transparent rounded-xl py-4 px-6 text-center">
            <Typography className="font-medium font-archivo text-textLight-500 dark:text-white text-2xl flex items-center gap-2">
              Post Scheduled <Edit2 size="20" className="cursor-pointer" onClick={() =>
              (navigate("/home/create-post", { state: { data: data } }),
                dispatch(setActiveScreen(0)),
                dispatch(setActiveTab(type)),
                dispatch(setIsEditPost(true))
              )} />
            </Typography>
            <Typography className="font-normal font-rubik text-textLight-300 dark:text-white/60 text-sm">
              {new Date(data?.scheduledTime).toLocaleTimeString()},{" "}{moment(data?.scheduledTime).utc().format("DD MMM, yyyy")}</Typography>
          </div>
        </div>
      }
      <div className={`w-full px-4 md:px-0 ${isScheduled && isEdit && "blur-[1px]"}`}>
        <div className="flex flex-wrap justify-between flex-row mb-4">
          <div
            className="flex flex-wrap justify-between flex-col cursor-pointer"
            onClick={() => setScrollView(true)}
          >
            {type == "blog" &&
              <Typography
                variant="h5"
                className="font-archivo font-semibold dark:text-white tracking-wide mb-1 !break-all"
              >
                {titleCase(data?.title)}
              </Typography>
            }
            <Typography className="font-archivo font-normal text-sm text-textLight-300 dark:text-white tracking-wide">
              23:00 23-04-2024
            </Typography>
          </div>
          {isEdit && !mention && !saved ? (
            <div className="flex flex-wrap justify-between flex-row gap-4">
              <Edit2 className="cursor-pointer text-[#292D32] dark:text-white" onClick={() =>
              (navigate("/home/create-post", { state: { data: data } }),
                dispatch(setActiveScreen(0)),
                dispatch(setActiveTab(type)),
                dispatch(setIsEditPost(true))
              )} />
              <Trash color="#FF2E00" className="cursor-pointer" onClick={() => setdeletePostOpen(true)} />
            </div>
          ) : (
            <div>
              {!mention && !saved &&
                <ReportPostBox type="post"
                />}
              {mention && (
                <Menu placement="left-start">
                  <MenuHandler>
                    <IconButton variant="text" className="dark:text-white">
                      <CgMoreVertical size="22" />
                    </IconButton>
                  </MenuHandler>
                  <MenuList className="flex flex-wrap max-w-[10px] justify-start gap-2 py-0">
                    <ReportPostBox type="post" postId={data?._id} reportPostType={type}
                      requestTargetId={type == "blog" ? data?.blogId :
                        type == "media" ? data?.mediaId :
                          type == "poll" ? data?.pollId :
                            type == "researchPaper" ? data?.researchPaperId : null}
                    />
                    <MenuItem className="px-0" onClick={() => archivePost({ variables: { postId: data?._id } })}>
                      <Typography className="flex flex-wrap justify-start items-center gap-2 font-archivo text-[14px] text-textLight-500 font-medium">
                        {!hide ? <EyeSlash size="20" /> : <Eye size="20" />}
                        {!hide ? "Hide Post" : "Unhide Post"}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
              {saved && (
                <Typography onClick={() => (
                  addToSave({
                    variables: {
                      postId: data?._id
                    }
                  })
                )}
                  className="flex flex-wrap justify-start items-center gap-2 font-archivo text-[14px] text-textLight-500 dark:text-white font-medium cursor-pointer"
                // onClick={() => setSavePost(!savePost)}
                >
                  {/* {savePost ? ( */}
                  <ArchiveSlash size="24" />
                  {/* // ) : (
                  //   <ArchiveTick size="24" />
                  // )} */}
                </Typography>
              )}
            </div>
          )}
        </div>
        <div className="overflow-hidden line-clamp-4">
          {type !== "poll" &&
            <Typography
              variant="paragraph"
              className="!break-all text-sm font-rubik text-textLight-500 font-normal mt-2 text-justify tracking-wide dark:text-white cursor-pointer"
              onClick={() => setScrollView(true)}
            >
              "{data?.description}"
            </Typography>
          }
        </div>
        {type == "poll" &&
          <Typography
            variant="paragraph"
            className="!break-all text-sm font-rubik text-textLight-500 font-normal mt-2 text-justify tracking-wide dark:text-white cursor-pointer"
            onClick={() => setScrollView(true)}
          >
            {data?.question}?
          </Typography>}
      </div>
      {type == 'blog' && (
        <div className={`flex flex-wrap justify-center px-4 md:px-0 w-full mt-4 ${isScheduled && isEdit && "blur-[1px]"}`}>
          <img
            src={data?.contentImage[0]?.url}
            alt="Post Image"
            className="rounded-xl w-full h-full max-h-[234px] object-cover object-center"
          />
        </div>
      )}
      {type == "blog" && data?.contentPdf?.length > 0 && (
        <div className="w-fit">
          <a href={data?.contentPdf[0]?.url}>
            <Typography className="mt-6 cursor-pointer w-fit flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
              {titleCase(decodeURIComponent(
                getFileNameFromUrl(data?.contentPdf[0]?.url)
              ))}
            </Typography>
          </a>
        </div>
      )}
      {type == "poll" && (
        <div className={`flex flex-wrap w-full ${isScheduled && isEdit && "blur-[1px]"}`}>
          <PostPoll getPollVotes={
            pollSubmitData?.getPollVotes?.length > 0 &&
            pollSubmitData?.getPollVotes[0]
          } />
        </div>
      )}

      {type == "media" && (
        <div
          className={`flex flex-wrap justify-center items-center px-4 md:px-0 h-[240px] w-full mt-4 cursor-pointer rounded-2xl ${isScheduled && isEdit && "blur-[1px]"}`}
          style={{
            backgroundImage: `url(${data?.contentImage[data?.contentImage.length - 1]?.url})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <PlayCircle
            variant="Linear"
            className="text-white bg-black/10 w-[100px] h-[100px] rounded-full backdrop-blur-[3px] p-2"
          />
        </div>
      )}
      {type == "researchPaper" && (
        <div className={`flex flex-wrap justify-center px-4 md:px-0 w-full mt-4 ${isScheduled && isEdit && "blur-[1px]"}`}>
          <PDFViewer uploadedFileUrl={data?.researchPaperPdf[0]?.url ||
            data?.contentPdf[0]?.url} />
        </div>
      )}

      <div className={`mt-5 px-4 block md:hidden ${isScheduled && isEdit && "blur-[1px]"} `}>
        <Typography
          variant="paragraph"
          className="text-xs font-rubik text-textLight-300 font-normal mt-2 text-justify tracking-wide dark:text-textLight-300"
        >
          250 Likes • 16 comments
        </Typography>
      </div>

      <div className={`mt-2 md:mt-6 flex items-center justify-between border-t md:border-0 md:pl-0 xl:px-0 py-2 md:py-0 gap-4 ${isScheduled && isEdit && "blur-[1px]"} `}>
        <div className="flex gap-4 w-full md:w-fit items-center justify-between px-4 md:pl-0 xl:px-0">
          <div className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
            <Like1 size="22" />
            <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
              Like <span className="hidden md:flex">(230)</span>
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
            <Dislike size="22" />
            <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
              Dislike <span className="hidden md:flex">(30)</span>
            </Typography>
          </div>
          <div onClick={() => (
            addToSave({
              variables: {
                postId: data?._id
              }
            })
          )}
            className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
            <Save2 size="22" />
            <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
              Save <span className="hidden md:flex">(12)</span>
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
            <Message2 size="22" />
            <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
              Comments <span className="hidden md:flex">(12)</span>
            </Typography>
          </div>
        </div>
      </div>
      <Dialog
        open={deletePostOpen}
        size="xs"
        handler={() => setdeletePostOpen(false)}
      >
        <DialogHeader>  <div className="flex flex-wrap flex-col items-center justify-center w-full">
          <Trash className="h-[81px] w-[81px] my-3 text-red-600" variant="Linear" />
          {/* <IoTrashBinSharp className="h-[81px] w-[81px] my-3 text-red-600" /> */}
          <Typography className="text-textLight-500 text-center font-archivo text-lg font-bold">
            Are you sure you want to delete this post?
          </Typography>{" "}
        </div></DialogHeader>
        <DialogFooter className="flex flex-wrap justify-center gap-3">
          <Button
            variant="text"
            // color="green"
            onClick={() => setdeletePostOpen(false)}
            className="normal-case font-archivo tracking-wide"
          >
            Cancel
          </Button>
          <Button
            variant="gradient"
            color="red"
            onClick={() => {
              setdeletePostOpen(false)
              deletePost({
                variables: {
                  body: {
                    blogId: type == "blog" ? data?.blogId : null,
                    mediaId: type == "media" ? data?.mediaId : null,
                    pollId: type == "poll" ? data?.pollId : null,
                    researchPaperId: type == "researchPaper" ? data?.researchPaperId : null
                  }
                }
              })
            }}
            className="normal-case font-archivo tracking-wide"
          >
            Delete
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default PostCard;
