import { useEffect, useState } from "react";
import banner from "../../../assets/images/profileBanner1.svg";
import avatar from "../../../assets/images/dummy_avatar.png";
import {
  Button,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Avatar,
} from "@material-tailwind/react";
import { SearchNormal1, Messages2, Instagram, AddCircle } from "iconsax-react";
import { FaHeartPulse, FaXTwitter } from "react-icons/fa6";
import { RiLinkedinFill } from "react-icons/ri";
import PostCard from "../../../common/components/PostCard";
import ScrollView from "./ScrollView";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setActiveScreen } from "../../../common/components/Sidebar/sidebarSlice";
import { useQuery } from "@apollo/client";
import { GET_ALL_POSTS_OF_INDIVIDUAL, GET_MENTIONED_POSTS, GET_SAVED_POSTS } from "../../../API/Queries";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";

const Profile = ({ isEdit, setIsEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.authSlice);
  // const [isEdit, setIsEdit] = useState(true);
  const [activeTab, setActiveTab] = useState("My Posts");
  const [followers, setFollowers] = useState(false);
  const [userFollowers, setUserFollowers] = useState(false);
  const [unFollow, setUnFollow] = useState(false);
  const [scrollView, setScrollView] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followArr, setFollowArr] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [toBeFollowArr, setToBeFollowArr] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const postArr = [1, 2, 3];

  const { data, loading, error } = useQuery(GET_ALL_POSTS_OF_INDIVIDUAL, {
    variables: { authorId: userInfo?._id },
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
  });

  const { data: mentionedPosts, loading: mentionedLoading, error: mentionedError } = useQuery(GET_MENTIONED_POSTS, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
  });

  const { data: savedPosts, loading: savedLoading, error: savedError } = useQuery(GET_SAVED_POSTS, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
  });

  // console.log("mentioned", mentionedPosts?.getMentionedPost)
  // console.log("saved", savedPosts?.getSavedPost)

  const tabLabel = [
    {
      label: "My Posts",
      value: "My Posts",
    },
    {
      label: "Mentions",
      value: "Mentions",
    },
    {
      label: "Saved",
      value: "Saved",
    },
  ];
  useEffect(() => {
    if (userInfo) {
      setFollowArr(userInfo?.followers)
      setToBeFollowArr(userInfo?.following)
    }
  }, [userInfo])

  useEffect(() => {
    setTimeout(() => {

      if (userFollowers) {
        setUserFollowers(false)
      }
    }, 3000);
  }, [userFollowers]);

  if (loading || mentionedLoading || savedLoading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div
        className="w-full h-56 bg-center bg-cover relative hidden lg:flex"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: "no-repeat",
        }}
      />
      <div className="relative flex flex-wrap items-center flex-col mt-12 lg:mt-0 lg:-top-[60px]">
        <img
          src={userInfo?.avatar || userInfo?.staticUrl || avatar}
          alt="avatar"
          className="h-[120px] w-[120px] rounded-2xl"
        />
        <Typography className="text-2xl font-bold mt-4 mb-6 dark:text-textLight-50">
          {userInfo?.firstName} {userInfo?.lastName}
        </Typography>
        <div className="flex flex-wrap w-full justify-center mb-6 px-1">
          <div
            className="flex flex-wrap flex-col items-center cursor-pointer"
            onClick={() => {
              setFollowers(true);
              setFollowing(false);
            }}
          >
            <Typography className="text-lg font-bold text-textLight-500 dark:text-textLight-50">
              {userInfo?.followers?.length}
            </Typography>
            <Typography className="text-base font-medium text-textLight-300 dark:text-textLight-50">
              Followers
            </Typography>
          </div>
          <div className="border-l-2 border-textLight-300 mx-6 dark:border-textLight-50" />

          <div
            className="flex flex-wrap flex-col items-center cursor-pointer"
            onClick={() => {
              setFollowing(true);
              setFollowers(false);
            }}
          >
            <Typography className="text-lg font-bold text-textLight-500 dark:text-textLight-50">
              {userInfo?.following?.length}
            </Typography>
            <Typography className="text-base font-medium text-textLight-300 dark:text-textLight-50">
              Following
            </Typography>
          </div>
          <div className="border-l-2 border-textLight-300 mx-6 dark:border-textLight-50" />

          <div
            className="flex flex-wrap flex-col items-center cursor-pointer"
            onClick={() => {
              setFollowers(false);
              setFollowing(false);
            }}
          >
            <Typography className="text-lg font-bold text-textLight-500 dark:text-textLight-50">
              20
            </Typography>
            <Typography className="text-base font-medium text-textLight-300 dark:text-textLight-50">
              Posts
            </Typography>
          </div>
        </div>

        <div className="flex flex-wrap mb-8 gap-6 px-1">
          <Button onClick={() => (isEdit && navigate("/home/settings"), isEdit && dispatch(setActiveScreen(3)))} variant="filled" color="green" className="normal-case">
            {isEdit ? "Edit Profile" : "Follow"}
          </Button>
          <Button variant="outlined" color="green" className="normal-case focus:ring-0">
            Share Profile
          </Button>
        </div>

        {following && (
          <div className="flex flex-wrap flex-col max-w-7xl w-full mb-8">
            <Typography className="text-xl font-archivo font-semibold text-textLight-500 dark:text-white mb-6">
              Total following{" "}
              (<span className="text-xl font-rubik text-textLight-300 dark:text-white">
                {toBeFollowArr ? toBeFollowArr?.length : 0}
              </span>)
            </Typography>
            <div className="w-full px-1">
              <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                <div className="grid place-items-center h-full w-12 text-gray-200">
                  <SearchNormal1 color="#999999" />
                </div>
                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                  type="text"
                  id="search"
                  placeholder="Search Following"
                />
              </div>
            </div>
          </div>
        )}
        {following && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 place-items-center w-full justify-center">
            {toBeFollowArr.map((item, index) => (
              <div key={index} className="flex items-center justify-between gap-4 mb-6">
                <div className="flex gap-2 items-center justify-center cursor-pointer">
                  <Avatar
                    src={item?.avatar ? item?.avatar : "https://docs.material-tailwind.com/img/face-2.jpg"}
                    alt="avatar"
                    size="sm"
                  />
                  <div>
                    <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                      {item?.firstName ? item?.firstName + " " + item?.lastName : "sumit Sachdeva"}
                    </Typography>
                    <Typography className="text-[16px] font-rubik text-textLight-300 dark:text-white">
                      {item?.username ? "@" + item?.username : "@sumidev"}
                    </Typography>
                  </div>
                </div>
                <div className="w-[106px]">
                  {userFollowers ? (
                    <Button variant="filled" color="green" size="sm" className="font-archivo normal-case text-sm w-full" onClick={() => { setUserFollowers(false) }}>
                      Follow
                    </Button>
                  ) : (
                    <Button variant="outlined" color="green" size="sm" className="ring-0 focus:ring-0 normal-case tracking-wide font-archivo text-sm w-full" onClick={() => { setUserFollowers(true) }}>
                      Following
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {followers && (
          <div className="flex flex-wrap flex-col max-w-7xl w-full mb-8">
            <Typography className="text-xl font-archivo font-semibold text-textLight-500 dark:text-white mb-6">
              Total followers{" "}
              (<span className="text-xl font-rubik text-textLight-300 dark:text-white">
                {followArr ? followArr?.length : 0}
              </span>)
            </Typography>
            <div className="w-full px-1">
              <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                <div className="grid place-items-center h-full w-12 text-gray-200">
                  <SearchNormal1 color="#999999" />
                </div>
                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                  type="text"
                  id="search"
                  placeholder="Search Followers"
                />
              </div>
            </div>
          </div>
        )}
        {followers && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 place-items-center w-full justify-center">
            {followArr.map((item, index) => (
              <div key={index} className="flex items-center justify-between gap-4 mb-6">
                <div className="flex gap-2 items-center justify-center cursor-pointer">
                  <Avatar
                    src={item?.avatar ? item?.avatar : "https://docs.material-tailwind.com/img/face-2.jpg"}
                    alt="avatar"
                    size="sm"
                  />
                  <div>
                    <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                      {item?.firstName ? item?.firstName + " " + item?.lastName : "sumit Sachdeva"}
                    </Typography>
                    <Typography className="text-[16px] font-rubik text-textLight-300 dark:text-white">
                      {item?.username ? "@" + item?.username : "@sumidev"}
                    </Typography>
                  </div>
                </div>
                <div className="w-[106px]">
                  {unFollow ? (
                    <Button variant="outlined" color="green" size="sm" className="ring-0 focus:ring-0 normal-case tracking-wide font-archivo text-sm w-full" onClick={() => setUnFollow(false)}>
                      Following
                    </Button>
                  ) : (
                    <Button variant="filled" color="green" size="sm" className="ring-0 focus:ring-0 normal-case tracking-wide font-archivo text-sm w-full" onClick={() => setUnFollow(true)}>
                      Follow
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {!followers && !following && (
          <div className="grid grid-cols-12 w-full justify-center items-center gap-6 mb-4">
            <div className="col-span-12 lg:col-span-6">
              <div className="w-full px-1">
                <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                  <div className="grid place-items-center h-full w-12 text-gray-200">
                    <SearchNormal1 color="#999999" />
                  </div>
                  <input
                    className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                    type="text"
                    id="search"
                    placeholder="Search Post"
                  />
                </div>
              </div>
            </div>

            {isEdit ? (
              <div onClick={() => (navigate("/home/create-post"), dispatch(setActiveScreen(0)))} className="col-span-12 lg:col-span-6 grid grid-cols-12 justify-center px-1">
                <div className="col-span-12 flex flex-wrap flex-row w-full h-full justify-center text-textDark-800 border-textDark-800 rounded-xl border-2 py-2 gap-2 border-dashed cursor-pointer">
                  <div>
                    <AddCircle size="24" />
                  </div>
                  <div>
                    <Typography className="w-full text-textDark-800 font-medium">
                      Add Post
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-span-12 lg:col-span-6 grid grid-cols-12 justify-center gap-6 md:gap-2 px-1">
                <div onClick={() => navigate("/home/chat/0", { state: { type: "individual" } })} className="col-span-12 md:col-span-8 lg:col-span-7 flex flex-wrap flex-row items-center gap-2 text-primaryLight-700 dark:text-primaryDark-500 bg-primaryLight-700/10 py-3 w-full rounded-xl shadow-xl justify-center cursor-pointer">
                  <Messages2 />
                  <Typography className="text-base font-medium">
                    Direct Message
                  </Typography>
                </div>
                <div className="col-span-12 md:col-span-4 lg:col-span-5 flex flex-wrap flex-row gap-2 justify-center px-1">
                  <div className="flex flex-wrap flex-row gap-2 text-primaryLight-700 dark:text-primaryDark-500 bg-primaryLight-700/10 p-3 rounded-xl shadow-xl items-center cursor-pointer">
                    <FaXTwitter size="20" />
                  </div>
                  <div className="flex flex-wrap flex-row gap-2 text-primaryLight-700 dark:text-primaryDark-500 bg-primaryLight-700/10 p-3 rounded-xl shadow-xl items-center cursor-pointer">
                    <RiLinkedinFill size="20" />
                  </div>
                  <div className="flex flex-wrap flex-row gap-2 text-primaryLight-700 dark:text-primaryDark-500 bg-primaryLight-700/10 p-3 rounded-xl shadow-xl items-center cursor-pointer">
                    <Instagram fontSize="18px" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!followers && !following && (
          <div className="w-full md:p-8 p-0">
            <Tabs
              value="My Posts"
              className="w-full flex flex-wrap justify-center md:justify-start"
            >
              <TabsHeader
                className="bg-transparent font-archivo text-lg"
                indicatorProps={{
                  className:
                    "bg-primaryLight-500/10 font-archivo shadow-none !text-primaryLight-700 dark:bg-transparent dark:border-2 dark:border-primaryLight-500",
                }}
              >
                <div className="flex flex-wrap flex-row justify-center gap-4 md:gap-8">
                  {tabLabel.map(({ label, value }) => (
                    <div key={value}>
                      <Tab
                        key={value}
                        value={value}
                        onClick={() => setActiveTab(value)}
                        className={
                          activeTab === value
                            ? "text-primaryLight-700 text-lg font-archivo w-[6rem]  dark:text-primaryLight-500"
                            : "text-gray-900 text-lg font-archivo w-[6rem] dark:text-textDark-600"
                        }
                      >
                        {label}
                      </Tab>
                    </div>
                  ))}
                </div>
              </TabsHeader>
              <TabsBody className="w-full">
                <TabPanel value="My Posts">
                  <div className="flex flex-wrap flex-row gap-5 justify-between mb-24 lg:mb-0">
                    {scrollView ? (
                      <ScrollView
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        setScrollView={setScrollView}
                        mention={false}
                        saved={false}
                        data={data?.getAllPostOfAnIndividual}
                      />
                    ) : (
                      <>
                        {data?.getAllPostOfAnIndividual?.map((post, i) => {
                          return (
                            <PostCard
                              data={post}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              setScrollView={setScrollView}
                              key={i}
                              mention={false}
                              saved={false}
                              isScheduled={post?.isScheduled}
                              type={post?.type}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value="Mentions" className="w-full">
                  {/* <Typography
                    variant="paragraph"
                    className="text-left font-archivo text-lg text-gray-900 dark:text-textDark-600 font-bold my-6"
                  >
                    Mentions
                  </Typography> */}
                  <div className="flex flex-wrap flex-row gap-5 justify-between mb-24 lg:mb-0">
                    {scrollView ? (
                      <ScrollView
                        isEdit={FaHeartPulse}
                        setIsEdit={setIsEdit}
                        setScrollView={setScrollView}
                        mention={true}
                        saved={false}
                        data={data?.getAllPostOfAnIndividual}
                      />
                    ) : (
                      <>
                        {mentionedPosts?.getMentionedPost?.length > 0 ? mentionedPosts?.getMentionedPost?.map((post, i) => {
                          return (
                            <PostCard
                              data={post}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              setScrollView={setScrollView}
                              key={i}
                              mention={true}
                              saved={false}
                              isScheduled={false}
                              type={post?.type}
                            />
                          );
                        }) :
                          <Typography className="text-textLight-400 dark:text-white/80 font-archivo font-medium text-lg tracking-wide">
                            You are not mentioned in any post.
                          </Typography>
                        }
                      </>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value="Saved">
                  {/* <Typography
                    variant="paragraph"
                    className="text-left font-archivo text-lg text-gray-900 dark:text-textDark-600 font-bold my-6"
                  >
                    Saved
                  </Typography> */}
                  <div className="flex flex-wrap flex-row gap-5 justify-between mb-24 lg:mb-0">
                    {scrollView ? (
                      <ScrollView
                        isEdit={false}
                        setIsEdit={setIsEdit}
                        setScrollView={setScrollView}
                        mention={false}
                        saved={true}
                        data={data?.getAllPostOfAnIndividual}
                      />
                    ) : (
                      <>
                        {savedPosts?.getSavedPost?.length > 0 ? savedPosts?.getSavedPost?.map((post, i) => {
                          return (
                            <PostCard
                              data={post}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              setScrollView={setScrollView}
                              key={i}
                              mention={false}
                              saved={true}
                              isScheduled={false}
                              type={post?.type}
                            />
                          );
                        }) :
                          <Typography className="text-textLight-400 dark:text-white/80 font-archivo font-medium text-lg tracking-wide">
                            You have not saved any post.
                          </Typography>
                        }
                      </>
                    )}
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
