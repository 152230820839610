import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import PeopleSearch from "../Search/PeopleSearch";
import GroupSearch from "../Search/GroupSearch";
import { useQuery } from "@apollo/client";
import { GET_SUGGESTED_GROUPS, GET_SUGGESTED_USERS } from "../../../API/Queries";
import { useSelector } from "react-redux";
import Loader from '../../../common/components/Loader';


const SuggestedAll = () => {

    const [activeTab, setActiveTab] = useState("people");
    const [peopleData, setPeopleData] = useState([]);
    const { userToken, userInfo } = useSelector((state) => state.authSlice);

    const { data: suggestedUsers, loading } = useQuery(GET_SUGGESTED_USERS, {
        context: {
            headers: {
                Authorization: `${userToken?.replace("Bearer ", "")}`,
            },
        }
    });

    const { data: suggestedGroups, loading: suggestedGroupsLoading } = useQuery(GET_SUGGESTED_GROUPS, {
        context: {
            headers: {
                Authorization: `${userToken?.replace("Bearer ", "")}`,
            },
        }
    });

    useEffect(() => {
        const temp = suggestedUsers?.getSuggestedUsers?.filter((x) => userInfo?.following?.some((y) => x?._id !== y?._id));
        setPeopleData(temp)
    }, [suggestedUsers?.getSuggestedUsers])

    //displaying tab content data
    const data = [
        {
            label: "People",
            value: "people",
            el: <PeopleSearch data={peopleData} />
        },
        {
            label: "Groups",
            value: "groups",
            el: <GroupSearch data={suggestedGroups?.getSuggestedGroups} />
        },
    ];

    if (loading || suggestedGroupsLoading) return <Loader minHeight="100vh" />;

    return (
        <>
            <div className="mt-6 lg:mt-0">
                <Tabs value="people" className="w-full">
                    <TabsHeader className=" w-1/3 bg-transparent flex justify-between gap-32" indicatorProps={{
                        className: "bg-primaryLight-500/10 shadow-none !text-primaryLight-700 dark:bg-transparent dark:border-2 dark:border-primaryDark-500",
                    }}
                    >
                        <div className="flex w-full ">
                            {data.map(({ label, value, icon }) => (
                                <Tab key={value} value={value} onClick={() => setActiveTab(value)}>
                                    <div className={`flex items-center gap-2 font-archivo font-medium text-lg
                                ${activeTab == value ? "!text-primaryLight-700 dark:!text-primaryDark-500" : "text-textLight-400 dark:text-white"}
                            `}>
                                        {label}
                                    </div>
                                </Tab>
                            ))}
                        </div>

                    </TabsHeader>
                    <TabsBody className="w-full">
                        {data.map(({ value, el }) => (
                            <TabPanel key={value} value={value} className="px-0">
                                {el}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    )
}

export default SuggestedAll;