import { gql } from '@apollo/client';


// sign up query
export const SIGNUP = gql`
mutation SignUp($body: userInput) {
  signUp(body: $body) {
    token
    data {
      _id
      avatar
      city
      country
      dateOfBirth
      email
      firstName
      followers {
        _id
        avatar
        username
        lastName
        firstName
      }
      following {
        _id
        avatar
        firstName
        lastName
        username
      }
      username
      gender
      intrestedDomains {
        name
      }
      lastName
      level
      rewardPoints
      role
      socialMedia {
        twitter
        linkedIn
        instagram
      }
      state
      staticUrl
      phoneNumber
    }
  }
}
`;

// login query
export const LOGIN = gql`
mutation Login($body: loginInput) {
Login(body: $body) {
    token,
    data {
     _id
     avatar
     city
     country
     dateOfBirth
     email
     firstName
     username
     gender
     intrestedDomains {
        name
     }
     lastName
     level
     rewardPoints
     role
     state
     staticUrl
     phoneNumber
       followers {
        _id
        avatar
        firstName
        lastName
        username
      }
      following {
        _id
        avatar
        firstName
        lastName
        username
      }
     socialMedia {
        instagram
        linkedIn
        twitter
     }
    }
}
}
`;

//forget password - otp sent to mail query
export const VERIFY_EMAIL_FOR_FORGET_PASSWORD = gql`
mutation Mutation($body: forgetPassword) {
  forgetPassword(body: $body) {
    message
    success
  }
}
`;

// submit otp for verification
export const SUBMIT_OTP_FOR_FORGET_PASSWORD = gql`
  mutation SubmitOtp($body: submitOtpInput) {
  submitOtp(body: $body) {
    message
    success
  }
}
`;

//change password query
export const CHANGE_PASSWORD = gql`
mutation ChangePassword($body: changePasswordInput) {
  changePassword(body: $body) {
    message
    success
  }
}
`;

//edit user profile
export const EDIT_USER_PROFILE = gql`
mutation UpdateUserProfile($body: UpdateUserProfileInput, $file: Upload) {
  updateUserProfile(body: $body, file: $file) {
    data {
     _id
      avatar
      city
      country
      dateOfBirth
      email
      firstName
      username
      gender
      intrestedDomains {
        name
      }
          followers {
        _id
        avatar
        firstName
        lastName
        username
      }
      following {
        _id
        avatar
        firstName
        lastName
        username
      }
      lastName
      level
      rewardPoints
      role
      socialMedia {
        twitter
        linkedIn
        instagram
      }
      state
      staticUrl
      phoneNumber
    }
  }
}`



// Set-up profile
export const SETUP_PROFILE = gql`
mutation SetupUserProfile($file: Upload!, $body: setuserprofileInput) {
  setupUserProfile(file: $file, body: $body) {
   data {
    firstName
    email
    country
    avatar
    _id
    dateOfBirth
    gender
    intrestedDomains {
        name
      }
    lastName
    level
    phoneNumber
    rewardPoints
    role
    state
      followers {
        _id
        avatar
        firstName
        lastName
        username
      }
      following {
        _id
        avatar
        firstName
        lastName
        username
      }
    socialMedia {
      instagram
      linkedIn
      twitter
    }
    username
  }
  }
}
`

//  add interested domains
export const ADD_INTERESTED_DOMAINS = gql`
mutation AddInterestedDomains($body: AddInterestedDomainsInput) {
  addInterestedDomains(body: $body) {
    data {
      _id
      avatar
      city
      country
      dateOfBirth
      email
      firstName
      username
      gender
      intrestedDomains {
        name
      }
      lastName
      level
      rewardPoints
      role
        followers {
        _id
        avatar
        firstName
        lastName
        username
      }
      following {
        _id
        avatar
        firstName
        lastName
        username
      }
      socialMedia {
        twitter
        linkedIn
        instagram
      }
      state
      staticUrl
      phoneNumber
    }
  }
}`

//create blog query
export const CREATE_BLOG = gql`
mutation Mutation($body: blogInput, $file: [Upload]) {
  createBlog(body: $body, file: $file) {
    message
    success
  }
}
`;

//like reels
export const LIKE_REEL = gql`
mutation Mutation($likeReelId: ID!) {
  likeReel(id: $likeReelId) {
    likes
  }
}
`;

//dislike reels
export const DISLIKE_REEL = gql`
mutation Mutation($dislikeReelId: ID!) {
  dislikeReel(id: $dislikeReelId) {
    dislikes
  }
}
`;
//  send follow request
export const SEND_FOLLOW_REQUEST = gql`
mutation SendFollowRequest($receiverId: ID!) {
  sendFollowRequest(receiverId: $receiverId) {
    receiver {
      _id
    }
    sender {
      _id
    }
    status
  }
}`


// respond to follow request
export const RESPOND_TO_FOLLOW_REQUEST = gql`mutation RespondToFollowRequest($status: String!, $receiverId: ID!, $senderId: ID!) {
  respondToFollowRequest(status: $status, receiverId: $receiverId, senderId: $senderId) {
    _id
    createdAt
    status
    sender {
      _id
      avatar
      city
      country
      dateOfBirth
      email
      followers {
        _id
        avatar
        firstName
        lastName
        username
      }
      firstName
      following {
        username
        lastName
        firstName
        avatar
        _id
      }
      gender
      lastName
      level
      phoneNumber
      socialMedia {
        twitter
        linkedIn
        instagram
      }
      state
      staticUrl
      username
    }
  }
}`

// unfollow user
export const UNFOLLOW_REQUEST = gql`
mutation UnfollowUser($userId: ID!) {
  unfollowUser(userId: $userId) {
    success
  }
}
`
//create media
export const CREATE_MEDIA = gql`
mutation Mutation($body: mediaInput, $file: [Upload]) {
  createMedia(body: $body, file: $file) {
    message
    success
  }
}
`;

//create polls
export const CREATE_POLLS = gql`
mutation CreatePoll($body: pollInput) {
  createPoll(body: $body) {
    message
    success
  }
}
`;

//create research paper
export const CREATE_RESEARCH_PAPER = gql`
mutation CreateResearchPaper($body: ResearchPaperInput, $file: Upload) {
  createResearchPaper(body: $body, file: $file) {
    message
    success
  }
}
`;

//submit polls 
export const SUBMIT_POLLS = gql`
mutation Mutation($body: VotesInput) {
  submitPoll(body: $body) {
    message
    success
  }
}
`;

//edit poll
export const EDIT_POLL = gql`
mutation Mutation($body: editPollInput) {
  editPoll(body: $body) {
    message
    success
  }
}
`;

//edit research paper
export const EDIT_RESEARCH_PAPER = gql`
mutation Mutation($body: editResearchPaperInput, $file: Upload) {
  editResearchPaper(body: $body, file: $file) {
    message
    success
  }
}
`;

//edit blog
export const EDIT_BLOG = gql`
mutation Mutation($body: editBlogInput, $file: [Upload]) {
  editBlog(body: $body, file: $file) {
    message
    success
  }
}
`;

//edit media
export const EDIT_MEDIA = gql`
mutation EditMedia($body: editMediaInput, $file: [Upload]) {
  editMedia(body: $body, file: $file) {
    message
    success
  }
}
`;

//delete image from media post 
export const DELETE_IMAGE_FROM_MEDIA = gql`
mutation Mutation($body: deleteImage) {
  deleteImageFromMedia(body: $body) {
    message
    success
  }
}
`;

//delete image from media post 
export const DELETE_VIDEO_FROM_MEDIA = gql`
mutation DeleteVideoFromMedia($body: deleteVideo) {
  deleteVideoFromMedia(body: $body) {
    message
    success
  }
}
`;

//post comment 
export const POST_COMMENTS = gql`
mutation Mutation($body: CommentInput) {
  postComment(body: $body) {
    _id
    createdAt
    postId
    postedBy {
      username
      lastName
      firstName
      avatar
      _id
    }
    replies {
      tagged {
        _id
        avatar
        firstName
        lastName
        username
      }
      text
      postedBy {
        _id
        avatar
        firstName
        lastName
      }
      _id
      createdAt
      parentComment
    }
    tagged {
      username
      lastName
      firstName
      avatar
      _id
    }
    text
}
    }`;

// send chat individual
export const SEND_CHAT_INDIVIDUAL = gql`
mutation SendMessage($input: CreateMessageInput!) {
  sendMessage(input: $input) {
    id
    message
    receiverId
    senderId
    updatedAt
    createdAt
  }
}
`

// send chat group
export const SEND_CHAT_GROUP = gql`
mutation SendGroupMessages($input: groupMessageInput) {
  sendGroupMessages(input: $input) {
    id
    senderId {
      id
      firstName
      lastName
      staticUrl
      avatar
    }
    message
  }
}
`;

//like post 
export const LIKE_POST = gql`
mutation Mutation($body: likeDislikePost, $likePostId: ID!) {
  likePost(body: $body, id: $likePostId) {
    count
  }
}
`;

//dislike post 
export const DISLIKE_POST = gql`
mutation DislikePost($dislikePostId: ID!, $body: likeDislikePost) {
  dislikePost(id: $dislikePostId, body: $body) {
    count
  }
}
`;
// Create group
export const CREATE_GROUP = gql`
mutation CreateGroup($input: groupInput, $file: Upload) {
  createGroup(input: $input, file: $file) {
    id
  }
}`;


// Remove user from group
export const REMOVE_MEMBERS_FROM_GROUP = gql`
mutation RemoveMember($input: memberInput) {
  removeMember(input: $input) {
    id
    members {
      firstName
      lastName
      avatar
      username
    }
  }
}`;


// add user from group
export const ADD_MEMBERS_FROM_GROUP = gql`
mutation AddMember($input: memberInput) {
  addMember(input: $input) {
    id
    members {
      firstName
      lastName
      avatar
      username
    }
  }
}`;

// Update group chat
export const UPDATE_GROUP = gql`
mutation UpdateGroup($input: groupUpdateInput, $file: Upload) {
  updateGroup(input: $input, file: $file) {
    id
  }
}`;

// Update read field for notification panel

export const READ_NOTIFICATION_PANEL = gql`
mutation ReadNotification($notificationIds: [ID]!) {
  readNotification(notificationIds: $notificationIds) {
    read
  }
}
`
//delete post
export const DELETE_POST = gql`
mutation DeletePost($body: deletePost) {
  deletePost(body: $body) {
    message
    success
  }
}
`;

// // hide mentioned posts
export const HIDE_MENTIONED_POSTS = gql`
mutation Mutation($postId: ID!) {
  archivePost(postId: $postId) {
    archived
  }
}
`;

//report post
export const REPORT_POST = gql`
mutation Mutation($body: reportPost) {
  reportPost(body: $body) {
    _id
  }
}
`;

//add to save
export const ADD_TO_SAVE = gql`
mutation Mutation($postId: ID!) {
  addToSave(postId: $postId) {
    type
  }
}
`;

//edit schedule time
export const EDIT_SCHEDULE_TIME = gql`
mutation EditScheduledTime($body: editScheduledTime) {
  editScheduledTime(body: $body) {
    message
    success
  }
}
`;