import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import forgetpasswordimg from "../../../assets/images/forgetpassword1.svg";
import { ArrowLeft, Sms } from "iconsax-react";
import { Typography, Input, Button } from "@material-tailwind/react";
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL_FOR_FORGET_PASSWORD } from '../../../API/Mutations';
import Loader from '../../../common/components/Loader';
import toast from 'react-hot-toast';

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [forgetPassword, { data, loading, error }] = useMutation(VERIFY_EMAIL_FOR_FORGET_PASSWORD, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    }
  });

  const handleSubmit = () => {
    const body = {
      email: email
    }
    forgetPassword({ variables: { body: body } })
  }

  useEffect(() => {
    if (data) {
      // console.log(data)
      navigate("/otp-verification", { state: { email: email } })
    }
  }, [data])

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-7 h-screen hidden lg:block">
          <img
            src={forgetpasswordimg}
            alt="forgetpasswordimg"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-span-12 lg:col-span-5 sm:col-span-12 md:col-span-12 md:justify-self-center lg:w-full">
          <div
            className="flex flex-wrap flex-col justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16  md:pr-10 sm:pr-4 lg:w-full md:w-full"
            style={{ height: "100%" }}
          >
            <div className="flex w-full float-right justify-end items-center">
              <Button onClick={() => navigate(-1)} variant="text" color="green" className="font-archivo text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"><ArrowLeft size="20" /> Back</Button>
            </div>
            <Typography className="font-archivo text-3xl font-bold mb-8">
              Forgot Password
            </Typography>
            {/* <Typography className="font-archivo text-xl font-medium">
            Forgot Password
          </Typography> */}
            <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
              Enter the registered email address to get the OTP.
            </Typography>
            <div className="mb-8 relative flex items-center gap-6 border-b-2 border-black">
              <Sms color="#4D4D4D" />
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="standard"
                label="Enter email address"
                placeholder="Enter email address"
                className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: "hidden",
                }}
              />
            </div>
            <Button
              variant="filled"
              size="lg"
              color="green"
              className="py-4 normal-case"
              onClick={() => (
                handleSubmit()
              )}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPasswordPage;