import { Button, IconButton, Typography } from "@material-tailwind/react";
import banner from "../../../assets/images/homepage_banner.png";
import { LuSearch } from "react-icons/lu";
import {
  IoIosAddCircleOutline,
  IoIosNotificationsOutline,
} from "react-icons/io";
import MessageList from "./MessageList";
import TrendingTopics from "./TrendingTopicsLIst";
import Products from "./Products";
import Discussions from "./Discussions";
import SuggestedUsers from "./SuggestedUsers";
import { useEffect, useRef, useState } from "react";
import IndividualTrendingTopics from "../../../common/components/TrendingTopics";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../Chat";
import Reels from "./Reels";
import IndividualReel from "../../../common/components/IndividualReel";
import AllTrendingTopics from "../TrendingTopicsAll";
import rangestorm from "../../../assets/images/Rangestorm.png";
import { Notification, SearchNormal } from "iconsax-react";
import MobileToggle from "../../../common/components/ThemeModeToggle/MobileToggle";
import MobileTopicsCard from "../../../common/components/MobileHome/TopicsCard";
import SuggestedUsersCard from "../../../common/components/MobileHome/SuggestedUsersCard";
import Loader from "../../../common/components/Loader";

const HomePage = () => {
  const { activeSection } = useSelector((state) => state.homeSectionSlice);
  const [isLoading, setIsLoading] = useState(null);

  return (
    <>
      {isLoading ? (
        <>
          <Loader minHeight="70vh" />
        </>
      ) : (
        <div className="flex flex-col md:flex-row gap-6">
          <div
            className={`${
              activeSection == "messages" ? "w-full" : "w-full lg:w-3/4"
            } h-[2500px] overflow-auto [&::-webkit-scrollbar]:hidden`}
          >
            {activeSection == "default" ? (
              <>
                <Discussions />
                <div className="block lg:hidden">
                  <Typography
                    variant="h4"
                    className="font-archivo dark:text-white mb-6"
                  >
                    Trending Topics
                  </Typography>
                  <MobileTopicsCard />
                </div>
                <div className="block lg:hidden mt-6">
                  <Typography
                    variant="h4"
                    className="font-archivo dark:text-white mb-6"
                  >
                    Suggested for you
                  </Typography>
                  <SuggestedUsersCard />
                </div>
              </>
            ) : activeSection == "topics" ? (
              <IndividualTrendingTopics />
            ) : activeSection == "reel" ? (
              <IndividualReel />
            ) : activeSection == "allTrendingTopics" ? (
              <AllTrendingTopics />
            ) : (
              <Chat />
            )}
          </div>
          {activeSection !== "messages" && (
            <div className="w-1/4 hidden lg:flex flex-col gap-6 lg:sticky lg:top-0 lg:z-50 lg:min-h-screen lg:h-fit">
              <div>
                <MessageList />
              </div>
              <div>
                <SuggestedUsers />
              </div>
              <div>
                <TrendingTopics />
              </div>
              <div>
                <Products />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HomePage;
