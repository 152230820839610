import { Avatar, Button, Card, Input, Textarea, Tooltip, Typography } from "@material-tailwind/react";
import chatBg from '../../../assets/images/chat-bg.jpg';
import { LuSearch } from "react-icons/lu";
import { FiVideo } from "react-icons/fi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { TbPhoto } from "react-icons/tb";
import { TiAttachment } from "react-icons/ti";
import smiley from '../../../assets/images/smileys.svg';
import darkSmiley from '../../../assets/images/dark-smileys.svg';
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { setEmojiArray } from "./frequentlyUsedEmojiSlice";
import MessageBubble from "./MessageBubble";
import './chat.css';
import useOnClickOutside from "./useOnClickOutside";
import { ArrowLeft, ArrowRight, SearchNormal, Video } from "iconsax-react";
import { useLocation, useNavigate } from "react-router";

const MobileChat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state;
    const { mode } = useSelector((state) => state.themeToggleSlice);
    const [emojiOpen, setEmojiOpen] = useState(false);
    const [frequentUsed, setFrequentUsed] = useState([]);
    const [image, setImage] = useState("");
    const [file, setFile] = useState("");
    const [isInputVisible, setIsInputVisible] = useState(false);
    const { emojiArray } = useSelector((state) => state.frequentlyUsedEmojiSlice);
    useEffect(() => { dispatch(setEmojiArray(frequentUsed)) }, [frequentUsed])
    const chatRef = useRef();
    const searchRef = useRef();
    useOnClickOutside(searchRef, () => setIsInputVisible(false));

    const messages = [
        { text: 'Hey There', type: 'received' },
        { text: 'How are you?', type: 'received' },
        { text: 'Hello', type: 'sent' },
        { text: 'I am fine what about you?', type: 'sent' },
        { text: 'I am doing well, can we meet tommorow?', type: 'received' },
    ]

    return (
        <>
            <Card className="w-full h-[calc(100vh-140px)] rounded-none !border-0 flex flex-col justify-between" style={{
                backgroundImage: `url(${chatBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="h-[calc(100vh-156px)] overflow-auto">
                    {/* --------------------chat header---------------- */}
                    <div className="sticky -top-2 bg-black !bg-opacity-40 shadow-md w-full h-[70px] p-4 flex gap-2
                     items-center justify-between border-0 border-textLight-100">
                        <div className="flex gap-2 items-center" >
                            <ArrowLeft size="17" className="text-white cursor-pointer" onClick={() => navigate(-1)} />
                            <Avatar
                                src="https://docs.material-tailwind.com/img/face-2.jpg"
                                alt="avatar"
                                size="sm"
                            />
                            <div>
                                <Typography className="text-sm font-archivo font-semibold text-white">
                                    Hugo borg
                                </Typography>
                                <Typography className="text-xs font-archivo mt-1 text-white">
                                    Online - Last seen, 2.02pm
                                </Typography>
                            </div>
                        </div>
                        <div className="flex gap-4 items-center" >
                            {isInputVisible &&
                                <div ref={searchRef} className="animate-slideInRight relative rounded-2xl w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                                        <div className="p-1">
                                            <LuSearch className="text-xl" />
                                        </div>
                                    </span>
                                    <input
                                        type="search"
                                        name="q"
                                        className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-textLight-300 placeholder:opacity-100"
                                        placeholder="Search message here"
                                    />
                                </div>
                            }
                            {!isInputVisible && <SearchNormal size="20" onClick={() => setIsInputVisible(true)} className="text-white text-lg cursor-pointer" />}
                            <Video size="22" className="text-white text-xl" />
                            <PiDotsThreeOutlineVerticalBold className="text-white text-xl" />
                        </div>
                    </div>

                    {/* -----------------messages div-------------------- */}
                    <div ref={chatRef} className="p-3">
                        <MessageBubble messages={messages} type={state?.type} />
                    </div>

                </div>
                {/* ---------------------chat input----------------- */}
                <div className="relative px-2 pb-2">
                    <Input
                        type="email"
                        placeholder="Type your message here..."
                        className="!border-0 pr-36 bg-white dark:bg-textLight-700 !font-archivo !font-medium text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 dark:text-white focus:!border-0 focus:ring-transparent"
                        labelProps={{
                            className: "hidden",
                        }}
                        containerProps={{ className: "min-w-[100px]" }}
                    />
                    <div className="absolute h-fit right-0 mr-2 top-0 flex gap-1 items-center">
                        <div className="flex gap-2 items-center">
                            <Tooltip content="Image" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <label className="cursor-pointer">
                                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => setImage(e.target.files[0])} />
                                    <TbPhoto className="text-xl text-textLight-400 dark:text-white" />
                                </label>
                            </Tooltip>
                            <Tooltip content="Document" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <label className="cursor-pointer">
                                    <input type="file" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={(e) => setFile(e.target.files[0])} />
                                    <TiAttachment className="text-2xl text-textLight-400 dark:text-white" />
                                </label>
                            </Tooltip>
                            <img
                                src={!mode ? smiley : darkSmiley}
                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                onClick={() => setEmojiOpen(!emojiOpen)}
                            />
                        </div>

                        <Button
                            size="sm"
                            color="green"
                            className="pt-3 pb-3.5 rounded rounded-tl-none rounded-bl-none normal-case"
                        >
                            <ArrowRight size="14" />
                        </Button>
                    </div>
                </div>

                {emojiOpen &&
                    <div className="px-2 pb-2">
                        <EmojiPicker
                            open={emojiOpen}
                            width="100%"
                            previewConfig={{ showPreview: false }}
                            height={225}
                            theme={!mode ? "light" : "dark"}
                            searchDisabled={true}
                            onEmojiClick={(data) => (
                                setFrequentUsed((prev) => [...prev, data.emoji])
                            )}
                        />
                    </div>
                }

            </Card>
        </>
    )
}

export default MobileChat;