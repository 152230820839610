
import {
    Avatar, Button, Typography, Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Card,
} from "@material-tailwind/react";
import { useState } from "react";
import { CgClose } from "react-icons/cg";


const GroupSearch = ({ data }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    return (
        <>
            {data?.length > 0 ?
                <div className="mx-10 my-6 grid grid-cols-1 gap-x-10 gap-y-4 sm:grid-cols-2 w-full min-h-28">
                    {data?.map((x, index) => (
                        <>
                            <div key={index} className="w-2/3 flex items-center justify-between gap-4">
                                <div className="flex gap-2 items-center justify-center cursor-pointer">
                                    <Avatar
                                        src={x?.profileImage}
                                        alt="avatar"
                                        size="sm"
                                    />
                                    <div>
                                        <Typography className="capitalize text-lg font-archivo font-bold text-textLight-500 dark:text-white">
                                            {x?.name}
                                        </Typography>
                                        <Typography className="text-base font-archivo font-medium text-textLight-300 dark:text-white">
                                            {x?.members?.length} Members
                                        </Typography>
                                    </div>
                                </div>
                                <div>
                                    <Button onClick={handleOpen} className="normal-case font-medium text-sm font-archivo border-2 !px-6 !py-2 focus:ring-0" variant="outlined" color="green" size="sm">
                                        Join
                                    </Button>


                                </div>
                            </div>
                            <Dialog size="md"
                                className="bg-white dark:bg-textLight-500 py-4"
                                open={open}
                                handler={handleOpen}
                                animate={{
                                    mount: { scale: 1, y: 0 },
                                    unmount: { scale: 0.9, y: -100 },
                                }}
                            >
                                <div className="w-fit float-end mt-6 mr-4 lg:mr-6 cursor-pointer">
                                    <CgClose onClick={() => setOpen(false)} className="text-textLight-500 dark:text-white hover:text-error-500 dark:hover:text-error-500" />
                                </div>
                                <DialogBody className="lg:!px-6 flex gap-6 items-center">
                                    <Avatar className="!w-40 !h-40"
                                        src={x?.profileImage}
                                        alt="avatar"
                                        size="xxl"
                                    />
                                    <div className="flex flex-col gap-1">
                                        <Typography className="font-archivo font-bold text-textLight-500 dark:text-white text-2xl">{x?.name}</Typography>
                                        <Typography className="font-archivo font-medium text-textLight-300 dark:text-white/80 text-sm">{x?.description}</Typography>
                                        <Typography className="font-archivo font-semibold text-textLight-400 dark:text-white/70 text-sm">Active users : {x?.members?.length}</Typography>
                                        <Typography className="font-archivo font-bold text-xl text-textLight-500 dark:text-white">Total Members : <span className="text-textLight-200">{x?.members?.length}</span></Typography>
                                        <Button onClick={() => setOpen(false)} className="bg-primaryLight-500 dark:bg-primaryDark-600 mt-2 w-fit normal-case font-medium text-sm font-archivo border-0 !px-6 !py-2 focus:ring-0" variant="filled" color="green" size="sm">
                                            Join Group
                                        </Button>
                                    </div>
                                </DialogBody>
                            </Dialog>
                        </>
                    ))}

                </div>
                :
                <Typography className="mx-10 my-6 font-archivo font-bold text-base min-h-28 text-textLight-400 dark:text-white">
                    No groups found
                </Typography>
            }
        </>
    )
}

export default GroupSearch;