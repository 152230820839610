import { Typography } from "@material-tailwind/react";
import RecommendedTopicsCard from "../../../common/components/TrendingTopics/RecommenedTopicsCard";
import Reels from "../HomePage/Reels";
import ExploreGrid from "./ExploreGrid";
import MobileTopicsCard from "../../../common/components/MobileHome/TopicsCard";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/client";
import { GET_REELS } from "../../../API/Queries";
import { useDispatch, useSelector } from "react-redux";

const AllTrendingTopics = () => {

    const { userToken } = useSelector((state) => state.authSlice);

    const { data, loading, error } = useQuery(GET_REELS, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message);
        },
        context: {
            headers: {
              Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
            }
          }
    });

    if (loading) return <Loader minHeight="100vh" />;

    return (
        <>
            <div className="pr-0 lg:pr-12 w-full lg:w-[60vw]">
                <Typography variant="h4" className="font-archivo text-xl dark:text-white">Trending Topics</Typography>
                <div className="hidden lg:block">
                    <RecommendedTopicsCard />
                </div>
                <div className="mt-6 block lg:hidden">
                    <MobileTopicsCard />
                </div>
            </div>

            <div className="mt-10 w-full lg:w-[60vw]">
                <Typography variant="h4" className="font-archivo text-xl dark:text-white">Explore More</Typography>
                <ExploreGrid />
            </div>

            <div className="py-8 pr-0 w-full lg:w-[60vw] xl:pr-12">
                <Typography
                    variant="h4"
                    className="font-archivo text-xl mb-5 dark:text-white"
                >
                    60 Second Learning
                </Typography>
                <div
                    className="hidden md:flex items-center gap-8 w-full md:overflow-auto pb-2 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full
             [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121]
             dark:[&::-webkit-scrollbar-thumb]:bg-white"
                >
                    {data?.fetchReels?.map((reel, index) => (
                        <Reels key={index} reel={reel} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default AllTrendingTopics;