import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "./homeSectionSlice";
import { useQuery } from "@apollo/client";
import { GET_TRENDING_TOPICS } from "../../../API/Queries";

const TrendingTopics = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.authSlice);

  const { data, loading, error } = useQuery(GET_TRENDING_TOPICS, {
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
  });

  // console.log(data?.getTrendingTopics);

  return (
    <>
      <Card className="h-fit p-6 dark:bg-textLight-700">
        <Typography
          variant="h4"
          className="font-archivo text-xl text-textLight-500 dark:text-white font-bold mb-4"
        >
          Trending Topics
        </Typography>
        <div className="flex flex-col gap-5">
          {data?.getTrendingTopics?.slice(0, 3)?.map((item, index) => (
            <div key={index} className="flex gap-2 items-center">
              <div className="w-[45%] h-32">
                <Avatar
                  className="!rounded-md w-full h-full object-cover"
                  src={item?.image}
                  alt="avatar"
                  size="sm"
                />
              </div>
              <div className="h-full w-2/3">
                <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white">
                  {item?.title}
                </Typography>
                <div className="overflow-hidden line-clamp-3 w-full mt-2">
                  <Typography className="text-xs font-rubik text-textLight-400 dark:text-textDark-700">
                    {item?.description}
                  </Typography>
                </div>
                <Typography
                  className="cursor-pointer hover:underline underline-offset-2 normal-case text-md font-archivo text-primaryLight-500 dark:text-primaryDark-500 mt-2"
                  onClick={() => dispatch(setActiveSection("topics"))}
                >
                  Read More
                </Typography>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center">
          <Button
            variant="text"
            color="green"
            size="sm"
            className="w-1/2 mt-4 normal-case text-md font-archivo"
            onClick={() => dispatch(setActiveSection("allTrendingTopics"))}
          >
            See All
          </Button>
        </div>
      </Card>
    </>
  );
};

export default TrendingTopics;
