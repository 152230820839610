import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import rangestorm from "../../../assets/images/Rangestorm.png";
import rangestorm_dark from "../../../assets/images/Rangestorm_dark.png";
import ulearnix from "../../../assets/images/Ulearnix.png";
import ulearnix_dark from "../../../assets/images/Ulearnix_dark.png";
import { useSelector } from "react-redux";

const Products = () => {
  const { mode } = useSelector((state) => state.themeToggleSlice);

  const products = [
    {
      img: !mode ? rangestorm : rangestorm_dark,
      title: "Rangestorm",
      text: "Rangestorm offers a customizable cyber-training platform, emphasizing real-world scenarios and diverse team formations.",
      link: "https://cyberrange.bhumiitech.com/",
    },
    {
      img: !mode ? ulearnix : ulearnix_dark,
      title: "Ulearnix",
      text: "Ulearnix provides a valuable medium for learners to access learning materials, videos, attempt online quizzes, and much more at any time and place convenient to them.",
      link: "https://ulearnix.com",
    },
  ];

  return (
    <>
      <Card className="h-fit p-6 dark:bg-textLight-700">
        <Typography
          variant="h4"
          className="font-archivo text-xl text-textLight-500 dark:text-white font-bold mb-4"
        >
          Our Products
        </Typography>
        <div className="flex flex-col gap-5">
          {products?.map((item, index) => (
            <div key={index} className="flex flex-col gap-2 items-center">
              <img src={item.img} />
              <div className="flex items-center justify-between w-full">
                <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white">
                  {item?.title}
                </Typography>
                <a href={item?.link} target="_blank">
                  <Typography className="text-sm font-archivo font-normal text-primaryLight-500 dark:text-primaryDark-500">
                    Visit Product
                  </Typography>
                </a>
              </div>

              <Typography className="text-xs font-rubik text-textLight-300 dark:text-textDark-700">
                {item?.text}
              </Typography>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Products;
