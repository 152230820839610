

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSection: "default"
};

const homeSectionSlice = createSlice({
    name: 'homeSectionSlice',
    initialState,
    reducers: {
        setActiveSection: (state, { payload }) => {
            state.activeSection = payload;
        }
    }
});

export const { setActiveSection } = homeSectionSlice.actions;
export default homeSectionSlice.reducer;
