import { Button, Chip, Tooltip, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import TextEditor from "../../../common/components/Editor";
import { AddCircle, Clock, SearchNormal1, TagUser, Trash } from "iconsax-react";
import ScheduleCard from "../../../common/components/ScheduleCard";
import { useLocation, useNavigate } from "react-router";
import { FaCircleCheck } from "react-icons/fa6";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToMarkdown from 'draftjs-to-markdown';
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { DELETE_IMAGE_FROM_MEDIA, DELETE_VIDEO_FROM_MEDIA } from "../../../API/Mutations";
import toast from "react-hot-toast";

const Media = () => {
    const location = useLocation();
    const data = location?.state?.data;
    const { isEdit } = useSelector((state) => state.activeTabSlice);
    const { userToken } = useSelector((state) => state.authSlice);
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const [imageArr, setImageArr] = useState([]);
    const [fileArr, setFileArr] = useState([]);
    const [open, setOpen] = useState(false);
    const [showTagOption, setShowTagOption] = useState(false);
    const [selectedForHero, setSelectedForHero] = useState(null);

    const [editorState, setEditorState] = useState(() => isEdit && data?.type == "media" ?
        EditorState.createWithContent(ContentState.createFromText(data?.description))
        : EditorState.createEmpty());

    const [length, setLength] = useState(editorState?.getCurrentContent().getPlainText('').length)
    // console.log((draftToMarkdown(convertToRaw(editorState.getCurrentContent()))))
    useEffect(() => {
        setLength(editorState.getCurrentContent().getPlainText('').length)
    }, [editorState]);

    const [deleteImageFromMedia, { data: deleteImageData, loading, error }] = useMutation(DELETE_IMAGE_FROM_MEDIA, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
    });

    const [deleteVideoFromMedia, { data: deleteVideoData, loading: deleteVideoLoading, error: deleteVideoError }] = useMutation(DELETE_VIDEO_FROM_MEDIA, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
    })

    useEffect(() => {
        if (isEdit && data?.type == "media") {
            let mediaArr = [];
            mediaArr = data?.contentImage?.concat(data?.contentVideo); //merging video and image array for media content
            setImageArr(mediaArr)
        }
    }, [isEdit])

    // console.log("edit data", data, isEdit, imageArr, image)

    const handleOpen = () => setOpen(!open);

    //onchange function to upload image
    const handleChange = (e) => {
        setImage(e.target.files[0]);
        setFileArr([...fileArr, e.target.files[0]])
        setImageArr([...imageArr, {
            url: URL.createObjectURL(e.target.files[0]),
            type: e.target.files[0].type
        }])
    }

    // delete the image from array according to index on click
    const handleDelete = (index, obj, imageId) => {
        const temp = [...imageArr];
        temp.splice(index, 1);
        setImageArr(temp);

        if (isEdit && obj?.__typename === "imageContent") {
            deleteImageFromMedia({
                variables: {
                    body: {
                        imageId: imageId,
                        mediaId: data?.mediaId,
                        postId: data?._id
                    }
                }
            })
        } else if (isEdit && obj?.__typename === "videoContent") {
            deleteVideoFromMedia({
                variables: {
                    body: {
                        videoId: imageId,
                        mediaId: data?.mediaId,
                        postId: data?._id
                    }
                }
            })
        }
    }

    //set hero image as the index of the array when clicked
    const handleSelectedForHero = (index) => {
        setSelectedForHero(index)
    }

    useEffect(() => {
        if (imageArr.length > 1) { // hero image to be selected when more than 1 media is uploaded
            const temp = [...imageArr];
            // show the selected hero element at the first position
            temp.splice(selectedForHero, 1);
            temp.unshift(imageArr[selectedForHero]);
            setImageArr(temp);
            setSelectedForHero(0);
        }
    }, [selectedForHero]);

    let state = {
        fileArr, fileArr,
        imageArr: imageArr,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        type: "media"
    }

    let editState = {
        fileArr, fileArr,
        imageArr: imageArr,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        type: "media",
        mediaId: data?.mediaId,
        postId: data?._id
    }

    return (
        <>
            <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload Media</Typography>
            <div>
                <div className="flex items-center gap-4 my-6">
                    <label className="cursor-pointer">
                        <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/ogg, video/mkv" onChange={(e) => handleChange(e)} />
                        <div className="bg-textLight-50 dark:bg-textLight-700 w-36 h-36 rounded-xl flex items-center justify-center">
                            <AddCircle size="40" className="text-primaryLight-700 dark:text-primaryDark-700 bg-primaryLight-50 dark:bg-primaryDark-100 p-2 rounded-full drop-shadow-lg" />
                        </div>
                    </label>

                    <div className="flex gap-4 flex-wrap">
                        {imageArr?.length > 0 && imageArr?.map((obj, index) => (
                            <div key={index} className="group w-36 h-36 rounded-xl relative">
                                {obj?.type && (obj?.type.split("/")[0] == "image" ?
                                    <img src={obj?.url} className={`object-cover w-full h-full rounded-xl ${selectedForHero == index && "border-4 border-primaryLight-500"}`} /> :
                                    <video src={obj?.url} muted autoPlay loop className={`object-cover w-full h-full rounded-xl ${selectedForHero == index && "border-4 border-primaryLight-500"}`} />
                                )}
                                {isEdit && obj?.__typename && (obj?.__typename == "imageContent" ?
                                    <img src={obj?.url} className={`object-cover w-full h-full rounded-xl ${selectedForHero == index && "border-4 border-primaryLight-500"}`} /> :
                                    <video src={obj?.url} muted autoPlay loop className={`object-cover w-full h-full rounded-xl ${selectedForHero == index && "border-4 border-primaryLight-500"}`} />
                                )}
                                <div onClick={() => (handleDelete(index, obj, obj?._id), setSelectedForHero(null))}
                                    className="cursor-pointer absolute top-0 right-0 p-1 bg-gradient-to-r from-white to-[rgba(238,238,238,0.88)] rounded-tr-[11px] rounded-bl-sm">
                                    <Trash size="20" className="text-error-500" />
                                </div>
                                {selectedForHero !== index ? //show button to select for hero image if index is not equal to selected hero image
                                    <div onClick={() => handleSelectedForHero(index)} className="animate-slideInUp hidden group-hover:block cursor-pointer absolute bottom-0 bg-primaryLight-500 dark:bg-primaryDark-500 w-full rounded-bl-xl rounded-br-xl text-center p-3">
                                        <Typography className="text-xs font-bold text-white font-archivo">Set as hero image</Typography>
                                    </div>
                                    :
                                    // show check on the image selected for hero
                                    <div className="absolute top-14 left-14">
                                        <FaCircleCheck size="30" variant="Bold" className="text-primaryLight-500 rounded-full bg-white" />
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>

                {/* -----------------editor for description-------------------- */}
                <TextEditor editorState={editorState} setEditorState={setEditorState} length={length} />

                <div className="flex justify-between items-end my-6">
                    {/* ---------------------tag user button------------------ */}
                    <div >
                        <div onClick={() => setShowTagOption(!showTagOption)}
                            className="w-fit cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white dark:hover:border-primaryDark-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Tooltip content="Tag User" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <TagUser className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                            </Tooltip>
                        </div>

                        {/* --------------------show tag option------------- */}
                        {showTagOption && (
                            <>
                                <div className="mt-6 w-96">
                                    <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Tag people</Typography>
                                    <div className="mt-4 flex gap-4 items-center w-full">
                                        <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <SearchNormal1 size="18" />
                                            </span>
                                            <input
                                                type="search"
                                                name="q"
                                                className="py-2.5 w-full text-sm font-archivo dark:bg-textLight-700 dark:text-white text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </div>
                                    <Chip size="sm" value="@vivek09_76" className="w-fit normal-case rounded-xl bg-primaryLight-50 text-textLight-400 dark:text-white dark:bg-primaryDark-800 font-archivo" />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="flex gap-5">
                        <div onClick={handleOpen} className="cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                        </div>
                        <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} data={isEdit ? data : state} />
                        <Button color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                        <Button color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2  normal-case" onClick={() =>
                            navigate("/home/preview", {
                                state: !isEdit ? state : editState
                            })}
                        >Preview</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media;