import React, { useState } from 'react'
import Profile from '../Profile'

const MyProfile = () => {
    const [isEdit, setIsEdit] = useState(true);
  return (
    <>
    <Profile isEdit={isEdit} setIsEdit={setIsEdit} />
    </>
  )
}

export default MyProfile