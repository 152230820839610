import { Avatar, Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router";


const PeopleSearch = ({ data }) => {
    const navigate = useNavigate()

    return (
        <>
            {data?.length > 0 ?
                <div className="mx-10 my-6 grid grid-cols-1 gap-x-10 gap-y-4 sm:grid-cols-2 w-full min-h-28">
                    {data?.map((x, index) => (
                        <div key={index} onClick={() => navigate("/home/user-profile")} className="w-2/3 flex items-center justify-between gap-4">
                            <div className="flex gap-2 items-center justify-center cursor-pointer">
                                <Avatar
                                    src={x?.avatar}
                                    alt="avatar"
                                    size="sm"
                                />
                                <div>
                                    <Typography className="capitalize text-lg font-archivo font-bold text-textLight-500 dark:text-white">
                                        {x?.firstName} {x?.lastName}
                                    </Typography>
                                    <Typography className="capitalize text-base font-archivo font-medium text-textLight-300 dark:text-white">
                                        @{x?.username}
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <Button className="normal-case font-medium text-sm font-archivo border-2 !px-6 !py-2 focus:ring-0" variant="outlined" color="green" size="sm">
                                    Follow
                                </Button>
                            </div>
                        </div>
                    ))}
                </div> :
                <Typography className="font-archivo font-bold text-base min-h-28 text-textLight-400 dark:text-white">
                    No persons found
                </Typography>
            }
        </>
    )
}

export default PeopleSearch;