import { Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineCopyright } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";
import { setActiveScreen } from "../Sidebar/sidebarSlice";

const Footer = () => {
  const { loginSuccess, registerSuccess } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  const footerLink = [
    {
      tab: "Community",
      communityData: [
        { name: "Home", link: (loginSuccess || registerSuccess) ? "/home" : "/" },
        { name: "About Us", link: "/about-us" },
        { name: "Trending Topics", link: (loginSuccess || registerSuccess) ? "/home" : "/login" },
        { name: "Contact us", link: "/contact-us" },
      ],
    },
    {
      tab: "Our Terms",
      communityData: [
        { name: "Refund Policy", link: "/refund-policy" },
        { name: "Privacy Policy", link: "/privacy-policy" },
        { name: "Cookie Policy", link: "/cookie-policy" },
        { name: "Terms & Conditions", link: "/terms-and-conditions" },
      ],
    },
    {
      tab: "Features",
      communityData: [
        { name: "Ulearnix", link: "https://ulearnix.com" },
        { name: "Rangestorm", link: "https://cyberrange.bhumiitech.com/" },
        { name: "Settings", link: (loginSuccess || registerSuccess) ? "/home/settings" : "/login" },
        { name: "Newsletter", link: "/newsletter" },
      ],
    },
  ];
  return (
    <>
      <div className={`flex flex-wrap w-full dark:bg-white bg-textLight-500 justify-evenly gap-2 ${(loginSuccess || registerSuccess) && "mt-4"}`}>
        {footerLink?.map((item, index) => {
          return (
            <div
              className="flex flex-wrap justify-start md:justify-center items-center flex-row md:max-w-[32%] lg:max-w-[253px] w-full py-4 md:py-8 gap-8"
              key={index}
            >
              <Typography className="text-textLight-50 dark:text-textLight-600 [writing-mode:vertical-lr] font-semibold rotate-180 text-3xl font-archivo pr-5 md:pr-0">
                {item?.tab}
              </Typography>
              <div className="flex flex-wrap flex-col gap-2 md:gap-4">
                {item?.communityData?.map((link, index) => {
                  return (
                    <div className="p-1" key={index} onClick={() => (
                      link?.name == "Trending Topics" && (dispatch(setActiveSection("allTrendingTopics"), dispatch(setActiveScreen(0))))
                    )}>
                      <Link
                        to={link?.link} target={link.name === "Rangestorm" || link.name === "Ulearnix" ? "_blank" : "_self"}
                        className="text-textLight-50 dark:text-textLight-600 text-base font-archivo font-semibold"
                      >
                        {link?.name}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <>
        <div className="flex flex-wrap w-full gap-6 py-8 flex-col dark:bg-textLight-50 bg-textLight-700 items-center">
          <Typography className="flex flex-wrap gap-6 text-textLight-50 dark:text-textLight-500 font-archivo">
            <a href="https://www.facebook.com/BhumiCyberWorld" target="#">
              <FaFacebook className="cursor-pointer text-2xl" />
            </a>
            <a href="https://www.linkedin.com/showcase/rangestorm/" target="#">
              <FaLinkedin className="cursor-pointer text-2xl" />
            </a>
            <a
              href="https://www.instagram.com/rangestorm_by_bhumi_itech/"
              target="#"
            >
              <FaInstagram className="cursor-pointer text-2xl" />
            </a>
            <a href="https://twitter.com/rangestorm1" target="#">
              <FaXTwitter className="cursor-pointer text-2xl" />
            </a>
          </Typography>
          <Typography className="flex flex-wrap justify-center items-center dark:text-textLight-500 text-textLight-50 text-xs font-archivo">
            <AiOutlineCopyright className="text-2xl mr-4" />
            Copyrights reserved by Bhumi iTech
          </Typography>
        </div>
      </>
    </>
  );
};

export default Footer;
