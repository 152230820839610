import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {},
    userToken: "",
    registerSuccess: null,
    loginSuccess: null
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setUserInfo: (state, { payload }) => {
            state.userInfo = payload;
        },
        setUserToken: (state, { payload }) => {
            state.userToken = payload;
        },
        setRegisterSuccess: (state, { payload }) => {
            state.registerSuccess = payload;
        },
        setLoginSuccess: (state, { payload }) => {
            state.loginSuccess = payload;
        }
    }
});

export const { setUserInfo, setRegisterSuccess, setLoginSuccess, setUserToken } = authSlice.actions;
export default authSlice.reducer;
