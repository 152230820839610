import { Outlet, useLocation, useNavigate } from "react-router";
import SidebarWithLogo from "../components/Sidebar/Sidebar";
import BottomNav from "../components/BottomNav";
import TabletSidebar from "../components/Sidebar/TabletSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { LuSearch } from "react-icons/lu";
import {
  IoIosAddCircleOutline,
  IoIosNotificationsOutline,
} from "react-icons/io";
import {
  Avatar,
  Button,
  Chip,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Typography,
  navbar,
} from "@material-tailwind/react";
import banner from "../../assets/images/homepage_banner.png";
import rangestorm from "../../assets/images/Rangestorm.png";
import {
  Cup,
  Notification,
  SearchNormal,
  Profile2User,
  TickCircle,
  CloseCircle,
  Messages,
} from "iconsax-react";
import MobileToggle from "../components/ThemeModeToggle/MobileToggle";
import useOnClickOutside from "../../containers/Pages/Chat/useOnClickOutside";
import Footer from "../components/Footer";
import { setActiveScreen } from "../components/Sidebar/sidebarSlice";
import { setMobileActiveScreen } from "../components/BottomNav/mobileNavSlice";
import { Toaster } from "react-hot-toast";
import {  GET_ALL_NOTIFICATION_FOR_MENTIONS, PLATFORM_SEARCH } from "../../API/Queries";
import { GET_ALL_NOTIFICATION, GET_FOLLOW_REQUEST } from "../../API/Queries";
import toast from "react-hot-toast";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "../components/Loader";
import { READ_NOTIFICATION_PANEL, RESPOND_TO_FOLLOW_REQUEST } from "../../API/Mutations";
import { setUserInfo } from "../../containers/Auth/authSlice";
import { setActiveTab } from "../../containers/Pages/CreatePost/activeTabSlice";
import { debounce } from "lodash";
import { useSocketContext } from "../../containers/ChatContextBox/ChatContext";

const HomeLayout = () => {
  const { activeSection } = useSelector((state) => state.homeSectionSlice);
  const { userToken, userInfo } = useSelector((state) => state.authSlice);
  const location = useLocation();
  const socket = useSocketContext();
  const homeRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isHoveredCloseCircle, setIsHoveredCloseCircle] = useState(false);
  const [isHoveredTickCircle, setIsHoveredTickCircle] = useState(false);
  const [userNotification, setUserNotification] = useState([]);
  const [userNotificationMention, setUserNotificationMention] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const openDrawer = () => setOpen(true);
  const openDrawer2 = () => setOpen2(true);
  const closeDrawer = () => setOpen(false);
  const closeDrawer2 = () => setOpen2(false);
  const [followTab, setFollowTab] = useState(false);
  const searchRef = useRef();
  const pathname = location?.pathname.split("/")[2];
  useOnClickOutside(searchRef, () => setIsInputVisible(false));
  
  // get all notification for follow request data for user
  const {
    data: followRequestData,
    loading: followRequestLoading,
    error: followRequestError,
    refetch: refetchFollowRequests, // Added refetch function
  } = useQuery(GET_FOLLOW_REQUEST, {
    variables: { receiverId: userInfo?._id },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      console.log(error?.graphQLErrors[0]?.message || "An error occurred");
    },
  });
  
  // get all notification for user
  const {
    data: notificationForUser,
    loading: notificationForUserLoading,
    error: notificationForUserError,
    refetch: refetchNotificationForUser, // Added refetch function
  } = useQuery(GET_ALL_NOTIFICATION, {
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
    onError:(error)=> {
      console.log(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  // get all mention notification for user
  const {
    data: notificationForMentionUser,
    loading: notificationForMentionUserLoading,
    error: notificationForMentionUserError,
    refetch: refetchNotificationForMentionUser, // Added refetch function
  } = useQuery(GET_ALL_NOTIFICATION_FOR_MENTIONS, {
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
    onError:(error)=> {
      console.log(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  useEffect(()=>{
    if (notificationForMentionUser) {
      setUserNotificationMention(notificationForMentionUser?.getMentionsNotification)
    }
  }, [notificationForMentionUser])

  useEffect(()=>{
    if(notificationForUser) {
      setUserNotification(notificationForUser?.getNotificationForUser)
    }
  }, [notificationForUser])

  // read notification
  const [ReadNotification, {
    data: readNotificationData,
    loading: readNotificationLoading,
    error: readNotificationError,
  }] = useMutation(READ_NOTIFICATION_PANEL, {
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
  })

  useEffect(()=>{
    if(readNotificationData) {
      refetchNotificationForUser();
      refetchNotificationForMentionUser();
      navigate("/home/all-messages-default");
    }
  }, [readNotificationData])



  // respond to follow request
  const [
    respondToFollowRequest,
    {
      data: followResponseData,
      loading: followResponseLoading,
      error: followResponseError,
    },
  ] = useMutation(RESPOND_TO_FOLLOW_REQUEST, {
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
  });

  useEffect(() => {
    homeRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [location.pathname, activeSection]);

  const [platformSearch, { data: searchData, loading: searchLoading, error: searchError }] = useLazyQuery(PLATFORM_SEARCH, {
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      toast.error(error.graphQLErrors[0]?.message || "An error occurred");
    },
    skip: searchValue == ""
  });

  const debouncer = useCallback(debounce(platformSearch, 1000), []);

  useEffect(() => {
    if (searchData && searchValue !== "") {
      navigate(`/home/search`, { state: { searchData: searchData?.platformSearch } });
    }
  }, [searchData, searchValue])

  const handleFollowRequest = (status, senderId) => {
    respondToFollowRequest({
      variables: {
        status,
        senderId,
        receiverId: userInfo._id,
      },
    }).then(() => {
      refetchFollowRequests(); // Refetch follow requests after responding
    });
  };

  useEffect(() => {
    if (
      followResponseData &&
      followResponseData?.respondToFollowRequest?.sender
    ) {
      dispatch(setUserInfo(followResponseData?.respondToFollowRequest?.sender));
    }
  }, [followResponseData, dispatch]);

  
  useEffect(() => {
    if (socket) {
      socket.on("receiveNotification", (newNotification) => {
        const tempNotification = {
          notificationData: newNotification?.notificationData,
          read:newNotification?.read,
          type:newNotification?.type,
          notificationIds:[newNotification?._id],

        }
        // console.log("userNotification1",newNotification, tempNotification)
        setUserNotification((prevNotifications) => [...prevNotifications, tempNotification]);
      });
      return () => {
        socket.off("receiveNotification");
      };
    }
  }, [socket, setUserNotification]);

  if (followRequestLoading || searchLoading || notificationForMentionUserLoading) return <Loader minHeight="100vh" />;

  return (
    <div className="flex relative">
      <div className="hidden xl:flex">
        <SidebarWithLogo />
      </div>
      <div className="hidden lg:flex xl:hidden">
        <TabletSidebar />
      </div>
      <div className="flex lg:hidden absolute top-[100vh]">
        <BottomNav />
      </div>
      <div className="p-6 w-full h-full max-h-max pb-20 lg:pb-0 dark:bg-[#212121]">
        {location?.pathname === "/home/profile" ||
          location?.pathname === "/home/user-profile" ? (
          <div ref={homeRef} />
        ) : (
          <>
            <div
              ref={homeRef}
              className="mb-6 w-full h-44 bg-center bg-cover relative hidden lg:flex"
              style={{
                backgroundImage: `url(${banner})`,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="bg-black bg-opacity-40 absolute w-full top-[60px] px-8 py-2 flex gap-6 backdrop-blur-md">
                <div className="relative w-2/3 text-gray-600 focus-within:text-gray-400">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                    <div className="p-1">
                      <LuSearch className="text-xl" />
                    </div>
                  </span>
                  <input
                    value={searchValue}
                    type="search"
                    name="q"
                    className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchValue(e.target.value.replace(/^\s+|[^A-Za-z0-9\s]/g, ""))
                      if (e.target.value !== "") {
                        debouncer({ variables: { key: e.target.value.replace(/^\s+|[^A-Za-z0-9\s]/g, "") } })
                      }
                      e.target.value == "" && navigate("/home")
                    }
                    }
                  />
                </div>

                <div className="flex justify-between w-1/3">
                  <Button
                    onClick={() => (
                      navigate("/home/create-post"),
                      dispatch(setActiveScreen(0)),
                      dispatch(setActiveTab("blog"))
                    )}
                    color="green"
                    variant="filled"
                    size="sm"
                    className="py-1 px-6 focus:bg-primaryLight-500 focus:opacity-100 flex items-center gap-2 normal-case text-sm"
                  >
                    <IoIosAddCircleOutline className="text-lg" />
                    Add Post
                  </Button>

                  <div className="flex items-center gap-4">
                    <IconButton
                      color="white"
                      className="p-0 rounded-full"
                      onClick={openDrawer}
                    >
                      <IoIosNotificationsOutline className="text-2xl" />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="sticky z-50 -top-6 h-[70px] bg-white dark:bg-[#212121] flex lg:hidden shadow-md p-4 items-center justify-between">
              <img src={rangestorm} className="w-36" />
              <div className="flex gap-3">
                {isInputVisible && (
                  <div
                    ref={searchRef}
                    className="animate-slideInRight relative rounded-2xl w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                      <div className="p-1">
                        <SearchNormal size="16" />
                      </div>
                    </span>
                    <input
                      value={searchValue}
                      onChange={(e) =>
                        setSearchValue(
                          e.target.value.replace(/^\s+|[^A-Za-z0-9\s]/g, "")
                        )
                      }
                      type="search"
                      name="q"
                      className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-textLight-300 placeholder:opacity-100"
                      placeholder="Search..."
                    />
                    2
                  </div>
                )}
                {!isInputVisible && (
                  <div
                    onClick={() => setIsInputVisible(true)}
                    className="cursor-pointer shadow-lg  p-2 rounded-md text-textLight-300 dark:bg-textLight-700"
                  >
                    <SearchNormal size="16" />
                  </div>
                )}
                <div
                  className="cursor-pointer shadow-lg  p-2 rounded-md text-textLight-300 dark:bg-textLight-700"
                  onClick={openDrawer}
                >
                  <Notification size="16" />
                </div>
                <div
                  onClick={() => (
                    navigate("/home/rewards"),
                    dispatch(setMobileActiveScreen(0))
                  )}
                  className="cursor-pointer shadow-lg  p-2 rounded-md text-textLight-300 dark:bg-textLight-700"
                >
                  <Cup size="16" />
                </div>
              </div>
            </div>
          </>
        )}
        <Outlet />
        <Footer />
        {/* {(location?.pathname === "/home/all-messages-default" || pathname === "chat") ? null :
          <Footer />} */}
      </div>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              borderBottom: "4px solid green",
              color: "green",
              borderRadius: "5px",
            },
          },
          error: {
            style: {
              borderBottom: "4px solid red",
              color: "red",
              borderRadius: "5px",
            },
            icon: "❌",
            iconTheme: {
              primary: "red",
              secondary: "red",
            },
          },
        }}
      />
      <Drawer
        placement="right"
        size={450}
        open={open}
        onClose={closeDrawer}
        className="dark:bg-[#212121]"
      >
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" className="dark:text-white text-[#212121]">
            Notifications
          </Typography>
          <IconButton
            variant="text"
            className="dark:text-white text-[#212121]"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        {followTab ? (
          <Drawer
            placement="right"
            size={450}
            open={open2}
            onClose={closeDrawer2}
            className="dark:bg-[#212121]"
          >
            <div className="mb-2 flex items-center justify-between p-4">
              <Typography
                variant="h5"
                className="dark:text-white text-[#212121]"
              >
                Follow Requests
              </Typography>
              <IconButton
                variant="text"
                className="dark:text-white text-[#212121]"
                onClick={() => {
                  closeDrawer2();
                  setFollowTab(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
            <div className="dark:text-white text-[#212121] w-full p-2 rounded">
              {followRequestData?.getFollowRequests && followRequestData?.getFollowRequests?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between w-full gap-4"
                  >
                    <div className="flex gap-2 items-center justify-center cursor-pointer">
                      <Avatar
                        src={
                          item?.sender?.avatar
                            ? item?.sender?.avatar
                            : "https://docs.material-tailwind.com/img/face-2.jpg"
                        }
                        alt="avatar"
                        size="sm"
                      />
                      <div>
                        <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                          {item?.sender?.firstName
                            ? item?.sender?.firstName +
                            " " +
                            item?.sender?.lastName
                            : "sumit Sachdeva"}
                        </Typography>
                        <Typography className="text-[16px] font-rubik text-textLight-300 dark:text-white">
                          {item?.sender?.username
                            ? item?.sender?.username
                            : "@sumidev"}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-row gap-3 mr-4">
                      <CloseCircle size="40" variant={isHoveredCloseCircle ? "Bold" : "Linear"} onMouseEnter={() => setIsHoveredCloseCircle(true)} onMouseLeave={() => setIsHoveredCloseCircle(false)} className="text-[#ea4335] cursor-pointer" onClick={() => handleFollowRequest("rejected", item.sender._id)} />
                      <TickCircle size="40" variant={isHoveredTickCircle ? "Bold" : "Linear"} onMouseEnter={() => setIsHoveredTickCircle(true)} onMouseLeave={() => setIsHoveredTickCircle(false)} className="text-[#83EC8E] cursor-pointer" onClick={() => handleFollowRequest("accepted", item.sender._id)} />
                    </div>
                  </div>
                );
              })}
              {followRequestData?.getFollowRequests &&
                followRequestData?.getFollowRequests?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between w-full gap-4"
                    >
                      <div className="flex gap-2 items-center justify-center cursor-pointer">
                        <Avatar
                          src={
                            item?.sender?.avatar
                              ? item?.sender?.avatar
                              : "https://docs.material-tailwind.com/img/face-2.jpg"
                          }
                          alt="avatar"
                          size="sm"
                        />
                        <div>
                          <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                            {item?.sender?.firstName
                              ? item?.sender?.firstName +
                                " " +
                                item?.sender?.lastName
                              : "sumit Sachdeva"}
                          </Typography>
                          <Typography className="text-[16px] font-rubik text-textLight-300 dark:text-white">
                            {item?.sender?.username
                              ? "@ " + item?.sender?.username
                              : "@sumidev"}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex flex-row gap-3 mr-4">
                        <CloseCircle
                          size="40"
                          variant={isHoveredCloseCircle ? "Bold" : "Linear"}
                          onMouseEnter={() => setIsHoveredCloseCircle(true)}
                          onMouseLeave={() => setIsHoveredCloseCircle(false)}
                          className="text-[#ea4335] cursor-pointer"
                          onClick={() =>
                            handleFollowRequest("rejected", item.sender._id)
                          }
                        />
                        <TickCircle
                          size="40"
                          variant={isHoveredTickCircle ? "Bold" : "Linear"}
                          onMouseEnter={() => setIsHoveredTickCircle(true)}
                          onMouseLeave={() => setIsHoveredTickCircle(false)}
                          className="text-[#83EC8E] cursor-pointer"
                          onClick={() =>
                            handleFollowRequest("accepted", item.sender._id)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Drawer>
        ) : (
          <List className="dark:text-white text-[#212121]">
            <ListItem
              onClick={() => {
                setFollowTab(true);
                openDrawer2();
              }}
            >
              <ListItemPrefix>
                <Profile2User className="h-5 w-5" />
              </ListItemPrefix>
              Follow Requests
              <ListItemSuffix>
                <Chip
                  value={
                    followRequestData?.getFollowRequests
                      ? followRequestData?.getFollowRequests?.length
                      : 0
                  }
                  size="sm"
                  color="green"
                  className="rounded-full"
                />
              </ListItemSuffix>
            </ListItem>
            {userNotification?.filter((item)=> item?.read == false)?.reverse()?.map((item, index) => {
              return(
            <ListItem
            key={index}
              onClick={() => {
                closeDrawer();
                const notificationIdArry = item?.notificationIds;
                ReadNotification({ variables: { notificationIds: notificationIdArry}})
              }}
            >
              <ListItemPrefix>
                <Messages className="h-5 w-5" />
              </ListItemPrefix>
              {item?.notificationData}.
            </ListItem>
              )
            })}
            {userNotificationMention?.filter((item)=> item?.read == false)?.reverse()?.map((item, index) => {
              return(
            <ListItem
            key={index}
              onClick={() => {
                closeDrawer();
                const notificationIdArry = item?.id;
                ReadNotification({ variables: { notificationIds: notificationIdArry}})
              }}
            >
              <ListItemPrefix>
                <Messages className="h-5 w-5" />
              </ListItemPrefix>
              {item?.notificationData}.
            </ListItem>
              )
            })}
          </List>
        )}
      </Drawer>
    </div>
  );
};

export default HomeLayout;
