import { Typography } from "@material-tailwind/react";
import { BiDislike, BiLike } from "react-icons/bi";
import { RiShareBoxLine } from "react-icons/ri";
import RecommendedTopicsCard from "./RecommenedTopicsCard";
import MobileTopicsCard from "../MobileHome/TopicsCard";

const IndividualTrendingTopics = () => {
    return (
        <>
            <div className="mt-6 lg:mt-0" >
                <Typography variant="h4" className="font-archivo dark:text-white">Trending Topics</Typography>
                <div className="px-0 py-6 lg:p-6">
                    <Typography variant="h4" className="font-archivo text-textLight-400 dark:text-white">Rise of Automotive Hacking</Typography>
                    <img
                        className="h-96 w-full object-cover object-center rounded-lg mt-6"
                        src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                        alt="nature image"
                    />
                    <Typography variant="paragraph" className="font-rubik font-normal mt-4 text-justify dark:text-white">
                        In an era where vehicles are more connected than ever, the rise of automotive hacking poses a significant threat to the safety, privacy, and security of motorists. As cars become rolling computers on wheels, cybercriminals are finding new and sophisticated ways to exploit vulnerabilities. This article explores the alarming trends in automotive hacking, the potential risks involved, and the urgent need for robust cybersecurity measures within the automotive industry.<br />
                        The Acceleration of Automotive Hacking: <br />
                        1. Connected Cars as Targets: Modern vehicles boast advanced features like infotainment systems, autonomous capabilities, and wireless connectivity. However, these very innovations make cars appealing targets for hackers seeking to exploit weaknesses in software and network security. <br />
                        2. Remote Exploitation: With the rise of telematics and remote vehicle access features, hackers can potentially gain control of a car's systems from a distance. This raises concerns about unauthorized access, tracking, and even remote manipulation of critical functions.<br />
                        3. Data Harvesting: Vehicles generate and store vast amounts of data, including GPS locations, driving patterns, and personal preferences. This trove of information becomes a valuable target for cybercriminals engaged in identity theft or other malicious activities.<br />
                        4. Ransomware on Wheels: The prospect of automotive ransomware is becoming a reality, with hackers capable of locking down vehicle systems and demanding payment for their release. This not only affects the vehicle's functionality but also puts drivers in precarious situations.
                    </Typography>

                    <div className="flex gap-4 mt-6">
                        <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <BiLike />
                            <Typography className="text-sm font-archivo font-medium">
                                Like (230)
                            </Typography>
                        </div>
                        <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <BiDislike />
                            <Typography className="text-sm font-archivo font-medium">
                                Dislike (30)
                            </Typography>
                        </div>
                        <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <RiShareBoxLine />
                            <Typography className="text-sm font-archivo font-medium">
                                Share
                            </Typography>
                        </div>
                    </div>
                </div>


                <Typography variant="h4" className="font-archivo dark:text-white">Recommended Topics</Typography>
                <div className="mt-6 w-[60vw] hidden xl:flex gap-4 pr-12">
                    <RecommendedTopicsCard />
                </div>
                <div className="mt-6 w-[90vw] flex xl:hidden gap-4 pr-3">
                    <MobileTopicsCard />
                </div>
            </div>
        </>
    )
}

export default IndividualTrendingTopics;