import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import forgetpasswordimg from "../../../assets/images/forgetpassword1.svg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Sms, Key, Eye, ArrowLeft, EyeSlash } from "iconsax-react";
import { Typography, Input, Button } from "@material-tailwind/react";
import { useMutation } from "@apollo/client";
import Loader from "../../../common/components/Loader";
import toast from "react-hot-toast";
import { CHANGE_PASSWORD } from "../../../API/Mutations";

const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [rePasswordVisible, setRePasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const { email, otp } = location?.state;

  const [changePassword, { data, loading, error }] = useMutation(CHANGE_PASSWORD, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    }
  });

  const initialValues = {
    reEnterPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    reEnterPassword: Yup.string()
      .required("Required")
      .min(8, "Password should be of minimum 8 characters length")
      .max(16, "Password should be of maximum 16 characters length"),
    confirmPassword: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .oneOf([Yup.ref("reEnterPassword"), null], "Password do not match")
      .max(16, "Password should be of maximum 16 characters length"),
  });
  const toggleRePasswordVisibility = () => {
    setRePasswordVisible(!rePasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = (values) => {
    const body = {
      email: email,
      otp: otp,
      newPassword: values?.reEnterPassword,
      confirmPassword: values?.confirmPassword
    }
    changePassword({ variables: { body: body } })
  }

  useEffect(() => {
    if (data) {
      // console.log(data);
      toast.success("Password changed successfully");
      navigate("/login")
    }
  }, [data])

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-7 h-screen hidden lg:block">
          <img
            src={forgetpasswordimg}
            alt="forgetpasswordimg"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-span-12 lg:col-span-5 sm:col-span-12 md:col-span-12 md:justify-self-center lg:w-full">
          <div
            className="flex flex-wrap flex-col justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16  md:pr-10 sm:pr-4 lg:w-full md:w-full"
            style={{ height: "100%" }}
          >
            <div className="flex w-full float-right justify-end items-center">
              <Button
                onClick={() => navigate(-1)}
                variant="text"
                color="green"
                className="font-archivo text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
              >
                <ArrowLeft size="20" /> Back
              </Button>
            </div>
            <Typography className="font-archivo text-3xl font-bold mb-8">
              Enter new password
            </Typography>
            <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
              Use capital letter & Numeric
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-full h-full"
            >
              {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Form className="w-full">
                  <div
                    className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.reEnterPassword && touched.reEnterPassword
                      ? "border-error-500 animate-shake"
                      : "border-black"
                      }`}
                  >
                    <Key
                      className={`${errors.reEnterPassword && touched.reEnterPassword
                        ? "text-error-500"
                        : "text-textLight-400"
                        }`}
                    />
                    <Field
                      as={Input}
                      variant="standard"
                      type={rePasswordVisible ? "text" : "password"}
                      label="Enter the password"
                      placeholder="Enter the password"
                      name="reEnterPassword"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                      icon={
                        rePasswordVisible ? (
                          <EyeSlash
                            size="20"
                            className="text-black cursor-pointer"
                            onClick={toggleRePasswordVisibility}
                          />
                        ) : (
                          <Eye
                            size="20"
                            className="text-black cursor-pointer"
                            onClick={toggleRePasswordVisibility}
                          />
                        )
                      }
                    />
                  </div>

                  <div
                    className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.confirmPassword && touched.confirmPassword
                      ? "border-error-500 animate-shake"
                      : "border-black"
                      }`}
                  >
                    <Key
                      className={`${errors.confirmPassword && touched.confirmPassword
                        ? "text-error-500"
                        : "text-textLight-400"
                        }`}
                    />
                    <Field
                      as={Input}
                      variant="standard"
                      type={confirmPasswordVisible ? "text" : "password"}
                      label="Enter the password"
                      placeholder="Enter the password"
                      name="confirmPassword"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                      icon={
                        confirmPasswordVisible ? (
                          <EyeSlash
                            size="20"
                            className="text-black cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        ) : (
                          <Eye
                            size="20"
                            className="text-black cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        )
                      }
                    />
                  </div>

                  <Button
                    type="submit"
                    variant="filled"
                    size="lg"
                    color="green"
                    className="py-4 normal-case w-full"
                  >
                    Proceed
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
