import {
  Avatar,
  Card,
  Carousel,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { Dislike, Like1, Message2, Profile2User, Save2 } from "iconsax-react";
import { BiReply } from "react-icons/bi";
import SharePostBox from "../../../common/SharePost";
import ReportPostBox from "../../../common/components/ReportPostCard";
import { useNavigate } from "react-router";
import { format as timeagoFormat } from "timeago.js";
import PDFViewer from "../../../common/components/PdfViewer";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TO_SAVE,
  DISLIKE_POST,
  LIKE_POST,
  POST_COMMENTS,
  SUBMIT_POLLS,
} from "../../../API/Mutations";
import { GET_FEED, GET_REELS, GET_SAVED_POSTS } from "../../../API/Queries";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { GET_POLL_VOTES } from "../../../API/Queries";
import Loader from "../../../common/components/Loader";
import "./send.css";
import PostPoll from "../../../common/components/PostPoll";
import Reels from "./Reels";

const Discussions = () => {
  const navigate = useNavigate();
  const { userToken, userInfo } = useSelector((state) => state.authSlice);
  const [pollId, setPollId] = useState("");
  const [commentText, setCommentText] = useState({});
  const [replyText, setReplyText] = useState({});
  const [showAllReplies, setshowAllReplies] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);

  const getFileNameFromUrl = (url) => {
    // Use the URL object to parse the URL and extract the pathname
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    // Split the pathname to get the filename
    const fileName = pathname.split("/").pop();
    const parts = fileName.split("-");
    parts.shift(); // Remove the first part
    // Join the remaining parts back together with dashes
    return parts.join("-");
  };

  const { data: posts, loading: feedLoading } = useQuery(GET_FEED, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
  });

  const {
    data: reelsData,
    loading: reelsLoading,
    error: reelsError,
  } = useQuery(GET_REELS, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      },
    },
  });

  const [submitPoll, { data, loading }] = useMutation(SUBMIT_POLLS, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
      },
    },
  });

  const { data: pollSubmitData } = useQuery(GET_POLL_VOTES, {
    variables: { pollId: pollId },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      },
    },
    skip: !data,
  });

  const [postComment, { data: postCommentData, loading: postCommentLoading }] =
    useMutation(POST_COMMENTS, {
      onError: (error) => {
        // console.log(error.graphQLErrors[0].message)
        toast.error(error.graphQLErrors[0].message);
      },
      context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
      onCompleted: () => {
        setCommentText({});
        setReplyText({});
      },
      refetchQueries: [{ query: GET_FEED }],
    });

  const [likePost, { data: likePostData, loading: likePostLoading }] =
    useMutation(LIKE_POST, {
      onError: (error) => {
        // console.log(error.graphQLErrors[0].message)
        toast.error(error.graphQLErrors[0].message);
      },
      context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
      refetchQueries: [{ query: GET_FEED }],
    });

  const [dislikePost, { data: dislikePostData, loading: dislikePostLoading }] =
    useMutation(DISLIKE_POST, {
      onError: (error) => {
        // console.log(error.graphQLErrors[0].message)
        toast.error(error.graphQLErrors[0].message);
      },
      context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
      refetchQueries: [{ query: GET_FEED }],
    });

  const handleCommentChange = (e, index) => {
    setCommentText((prevCommentText) => ({
      ...prevCommentText,
      [index]: e.target.value,
    }));
  };

  const handleReplyChange = (e, index, commentUserName) => {
    setReplyText((prevReplyText) => ({
      ...prevReplyText,
      [index]: e.target.value,
    }));
  };

  const handleShowReply = (e) => {
    if (showReplyInput == e) {
      setShowReplyInput(false);
    } else {
      setShowReplyInput(e);
    }
  };

  // console.log(posts?.getAllPost);

  const [addToSave, { data: addtoSaveData, loading: saveLoading }] =
    useMutation(ADD_TO_SAVE, {
      onError: (error) => {
        // console.log(error.graphQLErrors[0].message)
        toast.error(error.graphQLErrors[0].message);
      },
      context: {
        headers: {
          Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
        },
      },
      refetchQueries: [
        {
          query: GET_SAVED_POSTS,
          context: {
            headers: {
              Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
            },
          },
        },
      ],
    });

  if (loading || postCommentLoading || feedLoading || saveLoading)
    return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="flex gap-6 flex-col">
        {posts?.getAllPost?.map((post, index) => {
          let mediaArr = [];
          if (post?.type === "media") {
            mediaArr = post?.contentImage?.concat(post?.contentVideo); //merging video and image array for media content
          }
          if (index % 4 === 0 && index > 0) {
            return (
              <div className="py-8 pr-0 w-full md:w-[60vw] xl:pr-12">
                <Typography
                  variant="h4"
                  className="font-archivo text-xl mb-5 dark:text-white"
                >
                  60 Second Learning
                </Typography>
                <div
                  className="hidden md:flex items-center gap-8 w-full md:overflow-auto pb-2 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full
             [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121]
             dark:[&::-webkit-scrollbar-thumb]:bg-white"
                >
                  {reelsData?.fetchReels?.map((reel, index) => (
                    <Reels key={index} reel={reel} />
                  ))}
                </div>
                <div className="z-10 md:hidden flex flex-wrap items-center justify-between">
                  {reelsData?.fetchReels?.map((reel, index) => (
                    <Reels key={index} reel={reel} />
                  ))}
                </div>
              </div>
            );
          }

          return (
            <div
              key={index}
              className="flex gap-7 pt-1 xl:pr-12 shadow-md md:shadow-none rounded-lg xl:rounded-none bg-transparent dark:bg-textLight-700 dark:md:bg-transparent"
            >
              <Avatar
                className="hidden xl:block cursor-pointer"
                src={
                  post?.author?.avatar ||
                  post?.author?.staticUrl ||
                  "https://docs.material-tailwind.com/img/face-2.jpg"
                }
                alt="avatar"
                size="md"
                onClick={() =>
                  post?.author?._id !== userInfo?._id
                    ? navigate("/home/user-profile")
                    : navigate("/home/profile")
                }
              />
              <div className="w-full">
                <div className="flex flex-row gap-2 xl:flex-col px-4 md:px-0 pt-2 xl:pt-0">
                  <Avatar
                    className="block xl:hidden cursor-pointer"
                    src={
                      post?.author?.avatar ||
                      post?.author?.staticUrl ||
                      "https://docs.material-tailwind.com/img/face-2.jpg"
                    }
                    alt="avatar"
                    size="md"
                    onClick={() =>
                      post?.author?._id !== userInfo?._id
                        ? navigate("/home/user-profile")
                        : navigate("/home/profile")
                    }
                  />
                  <div
                    className="cursor-pointer w-fit"
                    onClick={() =>
                      post?.author?._id !== userInfo?._id
                        ? navigate("/home/user-profile")
                        : navigate("/home/profile")
                    }
                  >
                    <Typography className="text-textLight-400 font-bold font-archivo dark:text-white tracking-wide capitalize">
                      {post?.author?.firstName} {post?.author?.lastName}
                    </Typography>
                    <Typography className="text-sm font-bold font-archivo dark:text-white tracking-wide">
                      {timeagoFormat(post.createdAt)}
                    </Typography>
                  </div>
                </div>

                <div className="mt-4 px-4 md:px-0">
                  {post?.type === "blog" && (
                    <Typography
                      variant="h5"
                      className="!break-all font-archivo font-semibold dark:text-white tracking-wide"
                    >
                      {post?.title}{" "}
                    </Typography>
                  )}
                  <div className="overflow-hidden line-clamp-4">
                    {post?.type !== "poll" && (
                      <Typography
                        variant="paragraph"
                        className="!break-all text-sm font-rubik text-textLight-500 font-normal mt-2 text-justify tracking-wide dark:text-white"
                      >
                        "{post?.description}"
                      </Typography>
                    )}
                  </div>
                </div>

                {/* -----------------blog content------------------- */}
                {post?.type === "blog" && post?.contentImage?.length > 0 && (
                  <div className="mt-6 h-72 w-full lg:w-[80%]">
                    <img
                      alt="blog"
                      src={post?.contentImage[0]?.url}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                {post?.type === "blog" && post?.contentPdf?.length > 0 && (
                  <div className="w-fit">
                    <a href={post?.contentPdf[0]?.url}>
                      <Typography className="mt-6 cursor-pointer w-fit flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                        {decodeURIComponent(
                          getFileNameFromUrl(post?.contentPdf[0]?.url)
                        )}
                      </Typography>
                    </a>
                  </div>
                )}

                {/* ---------------------poll content------------ */}
                {post?.type === "poll" && (
                  <div className="mt-4 flex flex-col gap-4">
                    <Typography className="!break-all font-medium font-archivo text-lg text-textLight-400 dark:text-white">
                      {post?.question}?
                    </Typography>

                    {!data ? (
                      Object?.keys(post?.options)
                        ?.filter(
                          (x) => x !== "__typename" && post?.options[x] !== null
                        )
                        ?.map(
                          (
                            option,
                            index //mapping the option[0] object to show the option input field
                          ) => {
                            return (
                              <div
                                onClick={() => {
                                  setPollId(post?.pollId);
                                  submitPoll({
                                    variables: {
                                      body: {
                                        answer: option,
                                        pollId: post?.pollId,
                                        user: userInfo?._id,
                                      },
                                    },
                                  });
                                }}
                                key={index}
                                className="flex gap-2 w-full items-center justify-between"
                              >
                                <Card className="cursor-pointer w-[70%] dark:bg-textLight-700 drop-shadow-[0_0px_3px_rgba(0,0,0,0.25)] dark:drop-shadow-none px-4 py-3 mx-1">
                                  <Typography className="!break-all text-textLight-200 dark:text-white font-archivo font-semibold text-base">
                                    {post?.options[option]}
                                  </Typography>
                                </Card>
                              </div>
                            );
                          }
                        )
                    ) : (
                      <PostPoll
                        getPollVotes={
                          pollSubmitData?.getPollVotes?.length > 0 &&
                          pollSubmitData?.getPollVotes[0]
                        }
                      />
                    )}
                  </div>
                )}

                {/* -----------------research paper content---------------- */}
                {post?.type === "researchPaper" && (
                  <div className="flex flex-wrap justify-center px-4 md:px-0 w-full mt-4">
                    <PDFViewer
                      uploadedFileUrl={
                        post?.researchPaperPdf[0]?.url ||
                        post?.contentPdf[0]?.url
                      }
                    />
                  </div>
                )}

                {/* ---------------media content carousel----------------- */}
                {post?.type === "media" && (
                  <div className="mt-6 w-full">
                    {mediaArr?.length > 1 ? (
                      <Carousel
                        transition={{ duration: 1 }}
                        className="rounded-xl !h-96"
                        prevArrow={({ handlePrev }) => (
                          <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handlePrev}
                            className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="currentColor"
                              className="h-6 w-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                              />
                            </svg>
                          </IconButton>
                        )}
                        nextArrow={({ handleNext }) => (
                          <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handleNext}
                            className="!absolute top-2/4 !right-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="currentColor"
                              className="h-6 w-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                              />
                            </svg>
                          </IconButton>
                        )}
                        navigation={({
                          setActiveIndex,
                          activeIndex,
                          length,
                        }) => (
                          <div className="absolute bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                              <span
                                key={i}
                                className={`block h-1.5 cursor-pointer rounded-2xl transition-all content-[''] ${
                                  activeIndex === i
                                    ? "w-8 bg-primaryLight-500"
                                    : "w-1.5 bg-primaryLight-100"
                                }`}
                                onClick={() => setActiveIndex(i)}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {mediaArr?.map((media, i) => (
                          <>
                            {media?.__typename === "imageContent" ? (
                              <img
                                alt="media"
                                src={media?.url}
                                className={`object-contain w-full h-[95%] rounded-xl`}
                              />
                            ) : (
                              <video
                                src={media?.url}
                                muted
                                autoPlay
                                loop
                                className={` object-cover w-full h-[95%] rounded-xl`}
                              />
                            )}
                            <div className="absolute bottom-4 m-2 bg-primaryLight-50 p-1 rounded-full">
                              <Profile2User size="20" color="#292D32" />
                            </div>
                          </>
                        ))}
                      </Carousel>
                    ) : (
                      <div className="rounded-xl !h-96">
                        {/* {post?.contentImage[0].type?.split("/")[0] == "image" ? ( */}
                        <img
                          alt="media"
                          src={post?.contentImage[0]?.url}
                          className={` object-cover w-full h-[95%] rounded-xl`}
                        />
                        {/* ) : (
                        <video
                          src={state?.imageArr[0]?.url}
                          muted
                          autoPlay
                          loop
                          className={` object-cover w-full h-[95%] rounded-xl`}
                        />
                      )} */}
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-5 px-4 block md:hidden">
                  <Typography
                    variant="paragraph"
                    className="text-xs font-rubik text-textLight-300 font-normal mt-2 text-justify tracking-wide dark:text-textLight-300"
                  >
                    250 Likes • 16 comments
                  </Typography>
                </div>

                <div className="mt-2 md:mt-6 flex items-center justify-between border-t md:border-0 px-4 md:pl-0 xl:px-0 py-2 md:py-0">
                  <div className="flex gap-4 w-full md:w-fit items-center justify-between px-4 md:pl-0 xl:px-0">
                    <div
                      className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer"
                      onClick={() => {
                        likePost({
                          variables: {
                            likePostId:
                              post?.type === "poll"
                                ? post?.pollId
                                : post?.type === "media"
                                ? post?.mediaId
                                : post?.type === "researchPaper"
                                ? post?.researchPaperId
                                : post?.blogId,
                            body: {
                              postId: post?._id,
                              postType: post?.type,
                            },
                          },
                        });
                      }}
                    >
                      <Like1 size="22" />
                      <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                        Like <span className="hidden md:flex">(230)</span>
                      </Typography>
                    </div>
                    <div
                      className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer"
                      onClick={() => {
                        dislikePost({
                          variables: {
                            dislikePostId:
                              post?.type === "poll"
                                ? post?.pollId
                                : post?.type === "media"
                                ? post?.mediaId
                                : post?.type === "researchPaper"
                                ? post?.researchPaperId
                                : post?.blogId,
                            body: {
                              postId: post?._id,
                              postType: post?.type,
                            },
                          },
                        });
                      }}
                    >
                      <Dislike size="22" />
                      <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                        Dislike <span className="hidden md:flex">(30)</span>
                      </Typography>
                    </div>
                    <div
                      onClick={() =>
                        addToSave({
                          variables: {
                            postId: post?._id,
                          },
                        })
                      }
                      className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer"
                    >
                      <Save2 size="22" />
                      <Typography className="text-sm font-rubik md:font-archivo font-medium">
                        Save
                      </Typography>
                    </div>
                    {/* <div className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                    <ExportSquare size="20" />
                    <Typography className="text-sm font-rubik md:font-archivo font-medium">
                      Share
                    </Typography>
                  </div> */}
                    <SharePostBox />
                  </div>

                  {/* <div className="hidden xl:flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                  <TbExclamationCircle />
                  <Typography className="text-sm font-archivo font-medium">
                    Report Post
                  </Typography>
                </div> */}
                  <ReportPostBox
                    type="post"
                    postId={post?._id}
                    reportPostType={post?.type}
                    requestTargetId={
                      post?.type == "blog"
                        ? post?.blogId
                        : post?.type == "media"
                        ? post?.mediaId
                        : post?.type == "poll"
                        ? post?.pollId
                        : post?.type == "researchPaper"
                        ? post?.researchPaperId
                        : null
                    }
                  />
                </div>

                {/* --------------------comment input box------------------- */}
                <div className="relative bg-[#F3F3F3] dark:bg-textLight-700 h-fit rounded-md">
                  <input
                    name="commentText"
                    value={commentText[index] || ""}
                    onChange={(e) => handleCommentChange(e, index)}
                    placeholder="Write your comment"
                    className=" hidden md:block mt-5 py-2.5 tracking-wide w-full text-sm font-archivo bg-[#F3F3F3] dark:bg-textLight-700 text-black dark:text-white rounded-md pl-2 focus:outline-none
               focus:text-gray-900  placeholder:text-textLight-400 placeholder:opacity-100 dark:placeholder:text-white"
                  />
                  <button
                    className="send-btn absolute right-2 top-1.5"
                    onClick={() => {
                      postComment({
                        variables: {
                          body: {
                            parentComment: null,
                            postType: post?.type,
                            postedBy: userInfo?._id,
                            tagged: null,
                            text: commentText[index],
                            postId:
                              post?.type === "poll"
                                ? post?.pollId
                                : post?.type === "media"
                                ? post?.mediaId
                                : post?.type === "researchPaper"
                                ? post?.researchPaperId
                                : post?.blogId,
                          },
                        },
                      });
                    }}
                  >
                    <div className="svg-wrapper-1">
                      <div className="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </button>
                </div>

                <div className="pb-3 hidden md:block bg-[#F3F3F3] dark:bg-textLight-700 rounded-md">
                  {/* ----------------comment--------------- */}
                  {post?.comments?.length > 0 &&
                    post?.comments?.map((comment, commentIndex) => (
                      <div key={commentIndex}>
                        <div className="mt-3 p-4 pb-0 flex gap-2">
                          <Avatar
                            src={
                              comment?.postedBy?.avatar ||
                              "https://docs.material-tailwind.com/img/face-2.jpg"
                            }
                            alt="avatar"
                            size="xs"
                          />

                          <div className="w-full">
                            <Typography
                              variant="h6"
                              className="font-archivo text-textLight-400 dark:text-white tracking-wide capitalize"
                            >
                              {comment?.postedBy?.firstName || "Firstname"}{" "}
                              {comment?.postedBy?.lastName || "Lastname"}
                            </Typography>
                            <Typography
                              variant="paragraph"
                              className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-sm mt-2 text-justify"
                            >
                              {" "}
                              {comment?.text}
                            </Typography>
                            <div className="flex items-center justify-between">
                              <div className="flex gap-4 mt-2">
                                <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                  <Like1 size="18" />
                                  <Typography className="text-sm font-rubik font-medium">
                                    Like
                                  </Typography>
                                </div>
                                <div
                                  onClick={() => handleShowReply(comment)}
                                  className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer"
                                >
                                  <BiReply />
                                  <Typography className="text-sm font-rubik font-medium">
                                    Reply
                                  </Typography>
                                </div>
                              </div>

                              <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                                <Typography className="text-sm font-rubik font-medium">
                                  {timeagoFormat(comment?.createdAt)}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* --------------------reply input box------------------- */}
                        {showReplyInput == comment && (
                          <div className="md:pl-10 xl:pl-20 relative bg-[#F3F3F3] dark:bg-textLight-700 h-fit rounded-md">
                            <input
                              name="commentText"
                              value={replyText[commentIndex] || ""}
                              onChange={(e) =>
                                handleReplyChange(
                                  e,
                                  commentIndex,
                                  comment?.postedBy?.username
                                )
                              }
                              // onFocus={(e) => {
                              //   setReplyText((prevReplyText) => ({
                              //     ...prevReplyText,
                              //     [index]: `@${comment?.postedBy?.username}`,
                              //   }));
                              // }}
                              placeholder="Write your reply"
                              className=" hidden md:block mt-5 py-2.5 tracking-wide w-full text-sm font-archivo bg-[#F3F3F3] dark:bg-textLight-700 text-black dark:text-white rounded-md pl-2 focus:outline-none
               focus:text-gray-900  placeholder:text-textLight-400 placeholder:opacity-100 dark:placeholder:text-white"
                            />
                            <button
                              className="send-btn absolute right-2 top-1.5"
                              onClick={() => {
                                postComment({
                                  variables: {
                                    body: {
                                      parentComment: comment?._id,
                                      postType: post?.type,
                                      postedBy: userInfo?._id,
                                      tagged: comment?.postedBy?._id,
                                      text: replyText[index],
                                      postId:
                                        post?.type === "poll"
                                          ? post?.pollId
                                          : post?.type === "media"
                                          ? post?.mediaId
                                          : post?.type === "researchPaper"
                                          ? post?.researchPaperId
                                          : post?.blogId,
                                    },
                                  },
                                });
                              }}
                            >
                              <div className="svg-wrapper-1">
                                <div className="svg-wrapper">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path
                                      fill="currentColor"
                                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}

                        {/* ---------------reply on comment---------------- */}
                        {showAllReplies &&
                          comment?.replies?.slice(1)?.map((reply) => (
                            <div className="md:mt-1 xl:mt-1 p-4 md:pl-10 xl:pl-20 rounded-md flex gap-2">
                              <Avatar
                                src={
                                  reply?.postedBy?.avatar ||
                                  "https://docs.material-tailwind.com/img/face-2.jpg"
                                }
                                alt="avatar"
                                size="xs"
                              />

                              <div className="w-full">
                                <Typography
                                  variant="h6"
                                  className="font-archivo text-textLight-400 dark:text-white tracking-wide"
                                >
                                  {reply?.postedBy?.firstName || "Firstname"}{" "}
                                  {reply?.postedBy?.lastName || "Lastname"}
                                </Typography>
                                <Typography
                                  variant="paragraph"
                                  className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-sm mt-2 text-justify"
                                >
                                  {" "}
                                  {reply?.text}
                                </Typography>
                                <div className="flex items-center justify-between">
                                  <div className="flex gap-4 mt-2">
                                    <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                      <Like1 size="18" />
                                      <Typography className="text-sm font-rubik font-medium">
                                        Like
                                      </Typography>
                                    </div>
                                    <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                      <BiReply />
                                      <Typography className="text-sm font-rubik font-medium">
                                        Reply
                                      </Typography>
                                    </div>
                                  </div>

                                  <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                                    <Typography className="text-sm font-rubik font-medium">
                                      {timeagoFormat(reply?.createdAt)}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        {comment?.replies?.slice(0, 1)?.map((reply) => (
                          <div className="md:mt-1 xl:mt-1 p-4 md:pl-10 xl:pl-20 rounded-md flex gap-2">
                            <Avatar
                              src={
                                reply?.postedBy?.avatar ||
                                "https://docs.material-tailwind.com/img/face-2.jpg"
                              }
                              alt="avatar"
                              size="xs"
                            />

                            <div className="w-full">
                              <Typography
                                variant="h6"
                                className="font-archivo text-textLight-400 dark:text-white tracking-wide"
                              >
                                {reply?.postedBy?.firstName || "Firstname"}{" "}
                                {reply?.postedBy?.lastName || "Lastname"}
                              </Typography>
                              <Typography
                                variant="paragraph"
                                className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-sm mt-2 text-justify"
                              >
                                {" "}
                                {reply?.text}
                              </Typography>
                              <div className="flex items-center justify-between">
                                <div className="flex gap-4 mt-2">
                                  <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                    <Like1 size="18" />
                                    <Typography className="text-sm font-rubik font-medium">
                                      Like
                                    </Typography>
                                  </div>
                                  <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                    <BiReply />
                                    <Typography className="text-sm font-rubik font-medium">
                                      Reply
                                    </Typography>
                                  </div>
                                </div>

                                <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                                  <Typography className="text-sm font-rubik font-medium">
                                    {timeagoFormat(reply?.createdAt)}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        {comment?.replies?.length > 1 && (
                          <div className="flex justify-start ml-28">
                            <button
                              className="text-textLight-400 font-medium dark:text-primaryDark-500 cursor-pointer text-xs font-rubik hover:underline"
                              onClick={() => setshowAllReplies(!showAllReplies)}
                            >
                              {!showAllReplies ? "Show" : "Hide"} more replies
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Discussions;
