import { Avatar, Button, Card, Carousel, IconButton, Typography, useSelect } from "@material-tailwind/react";
import {
  Dislike,
  ExportSquare,
  Like1,
  Message2,
  Trash,
  Edit2,
  ArrowLeft2,
  PlayCircle,
  Profile2User,
} from "iconsax-react";
import { BiReply } from "react-icons/bi";
import { TbExclamationCircle } from "react-icons/tb";
import SharePostBox from "../../../common/SharePost";
import ReportPostBox from "../../../common/components/ReportPostCard";
import { useState } from "react";
import PostPoll from "../../../common/components/PostPoll";
import PDFViewer from "../../../common/components/PdfViewer";
import { useNavigate } from "react-router";
import { format as timeagoFormat } from "timeago.js";
import { useQuery } from "@apollo/client";
import { GET_POLL_VOTES } from "../../../API/Queries";
import { useSelector } from "react-redux";

const ScrollView = ({ data, isEdit, setIsEdit, setScrollView, mention, saved }) => {

  const navigate = useNavigate();
  const [pollId, setPollId] = useState(null);
  const { userToken } = useSelector((state) => state.authSlice);

  const pollOptions = [
    { label: "CTF Time", percentage: 22 },
    { label: "Another Option", percentage: 90 },
    { label: "Another1", percentage: 78 },
    { label: "Another2", percentage: 52 },
    // Add more options as needed
  ];

  const getFileNameFromUrl = (url) => {
    // Use the URL object to parse the URL and extract the pathname
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    // Split the pathname to get the filename
    const fileName = pathname.split("/").pop();
    const parts = fileName.split("-");
    parts.shift(); // Remove the first part
    // Join the remaining parts back together with dashes
    return parts.join("-");
  };

  // console.log(pollId)

  const {
    data: pollSubmitData,
    loading: pollSubmitLoading,
    error: pollSubmitError,
  } = useQuery(GET_POLL_VOTES, {
    variables: { pollId: pollId },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      },
    },
    skip: !pollId,
  });


  return (
    <>
      <div className="flex gap-6 flex-col flex-wrap">
        <div className="flex flex-wrap w-full justify-end">
          <Button
            variant="text"
            color="green"
            className="flex items-center gap-3 normal-case"
            onClick={() => setScrollView(false)}
          >
            <ArrowLeft2 size="20" />
            Back
          </Button>
        </div>
        {data?.map((post, index) => {
          let mediaArr = [];
          if (post?.type == "media") {
            mediaArr = post?.contentImage?.concat(post?.contentVideo); //merging video and image array for media content
          }
          return (
            <div
              key={index}
              className="flex flex-wrap gap-7 pt-1 xl:pr-12 shadow-none rounded-lg xl:rounded-none bg-transparent mb-4"
            >
              <div>
                <div className="flex flex-wrap justify-between flex-row mb-4">
                  <div className="cursor-pointer flex gap-4 items-center"
                    //  onClick={() => navigate("/home/profile")}
                    onClick={() => setScrollView(false)}
                  >
                    <Avatar
                      className="block cursor-pointer"
                      src={post?.author?.avatar || post?.author?.staticUrl}
                      alt="avatar"
                      size="md"
                      // onClick={() => navigate("/home/profile")}
                      onClick={() => setScrollView(false)}
                    />
                    <div className="">
                      <Typography className="text-textLight-400 font-bold font-archivo dark:text-white tracking-wide">
                        {post?.author?.firstName} {post?.author?.lastName}
                      </Typography>
                      <Typography className="text-sm font-bold font-archivo dark:text-white tracking-wide">
                        {timeagoFormat(post?.createdAt)}
                      </Typography>
                    </div>
                  </div>

                  {isEdit ? (
                    <div className="flex flex-wrap justify-between flex-row gap-4">
                      <Edit2 className="cursor-pointer text-[#292D32] dark:text-white" />
                      <Trash color="#FF2E00" className="cursor-pointer" />
                    </div>
                  ) : (
                    <div>
                      <ReportPostBox />
                    </div>
                  )}
                </div>

                <div className="mt-4 px-4 md:px-0 max-w-[69vw]">
                  {post?.type == "blog" &&
                    <Typography
                      variant="h5"
                      className="font-archivo font-semibold dark:text-white tracking-wide"
                    >
                      {post?.title}{" "}
                    </Typography>
                  }
                  {post?.type !== "poll" &&
                    <Typography
                      variant="paragraph"
                      className="break-all text-sm font-rubik text-textLight-500 font-normal mt-2 text-justify tracking-wide dark:text-white"
                    >
                      "{post?.description}"
                    </Typography>
                  }
                  {post?.type == "poll" &&
                    <Typography
                      variant="paragraph"
                      className="text-sm font-rubik text-textLight-500 font-normal mt-2 text-justify tracking-wide dark:text-white"
                    >
                      {post?.question}?
                    </Typography>
                  }
                </div>
                {post?.type == "blog" && post?.contentImage?.length > 0 && (
                  <div className="mt-6 h-72 w-full lg:w-[80%]">
                    <img
                      src={post?.contentImage[0]?.url}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                {post?.type == "blog" && post?.contentPdf?.length > 0 && (
                  <div className="w-fit">
                    <a href={post?.contentPdf[0]?.url}>
                      <Typography className="mt-6 cursor-pointer w-fit flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                        {decodeURIComponent(
                          getFileNameFromUrl(post?.contentPdf[0]?.url)
                        )}
                      </Typography>
                    </a>
                  </div>
                )}
                {post?.type == "poll" && (
                  <div className="mt-4 flex flex-col gap-4">

                    {!pollId ? (
                      Object?.keys(post?.options)
                        ?.filter(
                          (x) => x !== "__typename" && post?.options[x] !== null
                        )
                        ?.map(
                          (
                            option,
                            index //mapping the option[0] object to show the option input field
                          ) => {
                            return (
                              <div
                                onClick={() => (
                                  setPollId(post?.pollId)
                                )}
                                key={index}
                                className="flex gap-2 w-full items-center justify-between"
                              >
                                <Card className="cursor-pointer w-full dark:bg-textLight-700 drop-shadow-[0_0px_3px_rgba(0,0,0,0.25)] dark:drop-shadow-none px-4 py-3 mx-1">
                                  <Typography className="text-textLight-200 dark:text-white font-archivo font-semibold text-base">
                                    {post?.options[option]}
                                  </Typography>
                                </Card>
                              </div>
                            );
                          }
                        )
                    ) : (
                      <PostPoll
                        getPollVotes={
                          pollSubmitData?.getPollVotes?.length > 0 &&
                          pollSubmitData?.getPollVotes[0]
                        }
                      />
                    )}
                  </div>
                )}

                {post?.type == "media" && (
                  <div className="mt-6 w-full">
                    {mediaArr?.length > 1 ? (
                      <Carousel
                        transition={{ duration: 1 }}
                        className="rounded-xl !h-96"
                        prevArrow={({ handlePrev }) => (
                          <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handlePrev}
                            className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="currentColor"
                              className="h-6 w-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                              />
                            </svg>
                          </IconButton>
                        )}
                        nextArrow={({ handleNext }) => (
                          <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handleNext}
                            className="!absolute top-2/4 !right-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="currentColor"
                              className="h-6 w-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                              />
                            </svg>
                          </IconButton>
                        )}
                        navigation={({
                          setActiveIndex,
                          activeIndex,
                          length,
                        }) => (
                          <div className="absolute bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                              <span
                                key={i}
                                className={`block h-1.5 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i
                                  ? "w-8 bg-primaryLight-500"
                                  : "w-1.5 bg-primaryLight-100"
                                  }`}
                                onClick={() => setActiveIndex(i)}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {mediaArr?.map((media, i) => (
                          <>
                            {media?.__typename == "imageContent" ? (
                              <img
                                src={media?.url}
                                className={`object-contain w-full h-[95%] rounded-xl`}
                              />
                            ) : (
                              <video
                                src={media?.url}
                                muted
                                autoPlay
                                loop
                                className={` object-cover w-full h-[95%] rounded-xl`}
                              />
                            )}
                            <div className="absolute bottom-4 m-2 bg-primaryLight-50 p-1 rounded-full">
                              <Profile2User size="20" color="#292D32" />
                            </div>
                          </>
                        ))}
                      </Carousel>
                    ) : (
                      <div className="rounded-xl !h-96">
                        {/* {post?.contentImage[0].type?.split("/")[0] == "image" ? ( */}
                        <img
                          src={post?.contentImage[0]?.url}
                          className={` object-cover w-full h-[95%] rounded-xl`}
                        />
                        {/* ) : (
                        <video
                          src={state?.imageArr[0]?.url}
                          muted
                          autoPlay
                          loop
                          className={` object-cover w-full h-[95%] rounded-xl`}
                        />
                      )} */}
                      </div>
                    )}
                  </div>
                )}

                {post?.type == "researchPaper" && (
                  <div className="flex flex-wrap justify-center px-4 md:px-0 w-full mt-4">
                    <PDFViewer uploadedFileUrl={
                      post?.researchPaperPdf[0]?.url ||
                      post?.contentPdf[0]?.url
                    } />
                  </div>
                )}

                <div className="mt-5 px-4 block md:hidden">
                  <Typography
                    variant="paragraph"
                    className="text-xs font-rubik text-textLight-300 font-normal mt-2 text-justify tracking-wide dark:text-textLight-300"
                  >
                    250 Likes • 16 comments
                  </Typography>
                </div>

                <div className="mt-2 md:mt-6 flex items-center justify-between border-t md:border-0 px-4 md:pl-0 xl:px-0 py-2 md:py-0">
                  <div className="flex gap-4 w-full md:w-fit items-center justify-between px-4 md:pl-0 xl:px-0">
                    <div className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                      <Like1 size="22" />
                      <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                        Like <span className="hidden md:flex">(230)</span>
                      </Typography>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                      <Dislike size="22" />
                      <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                        Dislike <span className="hidden md:flex">(30)</span>
                      </Typography>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                      <Message2 size="22" />
                      <Typography className="text-sm font-rubik md:font-archivo font-medium">
                        Comment
                      </Typography>
                    </div>
                    <SharePostBox />
                  </div>
                  {/* <ReportPostBox /> */}
                </div>

                <input
                  placeholder="Write your comment"
                  className=" mt-5 py-2.5 tracking-wide w-full text-sm font-archivo bg-[#F3F3F3] dark:bg-textLight-700 text-black dark:text-white rounded-md pl-2 focus:outline-none
               focus:text-gray-900  placeholder:text-textLight-400 placeholder:opacity-100 dark:placeholder:text-white"
                />

                <div className=" mt-3 bg-[#F3F3F3] dark:bg-textLight-700 rounded-md">
                  {/* ----------------comment--------------- */}
                  <div className="mt-3 p-4 pb-0 flex gap-2">
                    <Avatar
                      src="https://docs.material-tailwind.com/img/face-2.jpg"
                      alt="avatar"
                      size="xs"
                    />

                    <div className="">
                      <Typography
                        variant="h6"
                        className="font-archivo text-textLight-400 dark:text-white tracking-wide"
                      >
                        Mathews
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-sm mt-2 text-justify"
                      >
                        {" "}
                        Try platforms like Hack The Box, TryHackMe, and
                        OverTheWire to practice and learn. read write-ups of CTF
                        challenges to understand different approaches and
                        techniques
                      </Typography>
                      <div className="flex items-center justify-between">
                        <div className="flex gap-4 mt-2">
                          <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <Like1 size="18" />
                            <Typography className="text-sm font-rubik font-medium">
                              Like
                            </Typography>
                          </div>
                          <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <BiReply />
                            <Typography className="text-sm font-rubik font-medium">
                              Reply
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                          <Typography className="text-sm font-rubik font-medium">
                            (3 min ago)
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ---------------reply on comment---------------- */}
                  <div className="md:mt-1 xl:mt-3 p-4 md:pl-10 xl:pl-20 rounded-md flex gap-2">
                    <Avatar
                      src="https://docs.material-tailwind.com/img/face-2.jpg"
                      alt="avatar"
                      size="xs"
                    />

                    <div className="">
                      <Typography
                        variant="h6"
                        className="font-archivo text-textLight-400 dark:text-white tracking-wide"
                      >
                        Mathews
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-sm mt-2 text-justify"
                      >
                        {" "}
                        Try platforms like Hack The Box, TryHackMe, and
                        OverTheWire to practice and learn. read write-ups of CTF
                        challenges to understand different approaches and
                        techniques
                      </Typography>
                      <div className="flex items-center justify-between">
                        <div className="flex gap-4 mt-2">
                          <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <Like1 size="18" />
                            <Typography className="text-sm font-rubik font-medium">
                              Like
                            </Typography>
                          </div>
                          <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                            <BiReply />
                            <Typography className="text-sm font-rubik font-medium">
                              Reply
                            </Typography>
                          </div>
                        </div>

                        <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                          <Typography className="text-sm font-rubik font-medium">
                            (3 min ago)
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
};

export default ScrollView;
