import { Button, Typography } from "@material-tailwind/react";
import Footer from "../Footer";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

const RefundPolicy = () => {
    const navigate = useNavigate();
    const divRef = useRef();

    useEffect(() => {
        divRef?.current.scrollIntoView({ behavior: "smooth" });
    }, []);
    return (
        <>
            <div ref={divRef} className="max-w-full min-h-screen py-3 md:py-10 bg-[#080808] flex items-center justify-center flex-col">
                <div className="w-full md:w-2/3 md:text-justify px-5 md:px-0">
                    <div className="my-6 flex flex-col-reverse md:flex-row items-start md:items-center justify-between">
                        <Typography className="font-archivo text-white text-2xl md:text-3xl font-bold">Cancellation & Refund Policy</Typography>
                        <div className="flex w-full md:w-fit justify-end">
                            <Button
                                onClick={() => navigate(-1)}
                                variant="text"
                                color="green"
                                className="font-archivo text-primaryDark-500 text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
                            >
                                <ArrowLeft size="20" /> Back
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Effective Date: 01 Jan 2024</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">This Privacy Policy governs the manner in which Bhumi Itech Private Limited collects, uses, maintains, and discloses information collected from users
                            (hereinafter referred to as 'Users') of the www.bhumiitech.com website
                            (hereinafter referred to as the 'Website').
                            This Privacy Policy applies to the Website and all products and services offered by Bhumi Itech Private Limited.
                            This Cancellation & Refund Policy ('Policy') applies to all users ('Users,' 'You,' or 'Your') of the online educational B2C platform ('Platform') operated by
                            Bhumi iTech Private Limited ('Bhumi iTech'), registered in India.
                            By using the Platform, you agree to abide by the terms of this Policy.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">1. Cancellation of Courses</Typography>
                        <div className="ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">1.1 User Initiated Cancellations:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">Users have the right to cancel their enrollment in a course on the Platform, subject to the following conditions:<br />
                                <li className="ml-6">Cancellations must be requested within 2 days from the date of enrollment.</li>
                                <li className="ml-6">The cancellation request should be submitted through the Platform's designated cancellation process.</li>
                                <li className="ml-6">A processing fee of 3.5% will be deducted from the refund amount.</li>
                            </Typography>
                        </div>
                        <div className="mt-2 ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">1.2 Bhumi iTech Initiated Cancellations:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">In certain cases, Bhumi iTech may need to cancel a course due to unforeseen
                                circumstances, such as insufficient enrollments, technical issues, or instructor unavailability. In such cases, the following steps will be taken:<br />
                                <li className="ml-6">Bhumi iTech will notify enrolled users of the cancellation and provide reasons for the cancellation.</li>
                                <li className="ml-6">Users will be offered the option to transfer to an alternative course or receive a full refund.</li>
                            </Typography>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">2. Refund Policy</Typography>
                        <div className="ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">2.1 Refund Eligibility:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">Refunds will be processed in accordance with the following conditions:<br />
                                <li className="ml-6">Refund requests must be made within 2 days from the date of course cancellation or enrollment, as applicable.</li>
                                <li className="ml-6">Refund requests should be submitted through the Platform's designated refund request process.</li>
                            </Typography>
                        </div>
                        <div className="mt-2 ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">2.2 Refund Amount:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">
                                <li className="ml-6">If a user cancels their enrollment within the stipulated cancellation period, a refund will be processed after deducting the processing fee mentioned in section 1.1.</li>
                                <li className="ml-6">If Bhumi iTech cancels a course, users will receive a full refund.</li>
                                <li className="ml-6">Refunds will be processed in the same form of payment used for the original transaction.</li>
                            </Typography>
                        </div>
                        <div className="mt-2 ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">2.3 Refund Processing:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">
                                <li className="ml-6">Refunds will be processed within 15 days from the date of receiving a valid refund request.</li>
                                <li className="ml-6">Users will be notified via email once the refund is initiated, and the refund process is underway.</li>
                            </Typography>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">3. Dispute Resolution</Typography>
                        <div className="ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">3.1 User Concerns:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">If you have any concerns or disputes regarding cancellations or refunds, please contact our customer support.
                                We are committed to resolving any issues promptly and fairly.
                            </Typography>
                        </div>
                        <div className="mt-2 ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">3.2 Legal Recourse:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">In case of unresolved disputes, you may consider seeking legal remedies in accordance with applicable laws in India.
                            </Typography>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">4. Changes to Policy</Typography>
                        <div className="ml-6">
                            <Typography className="text-white font-medium font-archivo text-lg mt-1">4.1 Policy Updates:</Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">Bhumi iTech reserves the right to update or modify this Cancellation & Refund Policy at any time. Any changes to
                                the Policy will be posted on the Platform, and the revised Policy will be effective immediately upon posting.
                            </Typography>
                        </div>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">5. Contact Us</Typography>
                        <div className="ml-6">
                            <Typography className="text-textLight-50 font-rubik text-base font-normal">
                                If you have any questions or require further information about our Cancellation & Refund Policy, please contact us at:
                            </Typography>
                            <Typography className="text-textLight-50 font-rubik text-base font-normal mt-2">
                                Bhumi Itech Private Limited<br />
                                Address: #521, 5th Floor, City Centre, Sector-12, Dwarka, New Delhi-110075, India<br />
                                Email: info@bhumiitech.com<br />
                                This Policy was last updated on 01 January 2024.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default RefundPolicy;