import React, { useState, useEffect } from "react";
import { Button, Typography } from "@material-tailwind/react";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { SearchNormal1 } from "iconsax-react";
import { ADD_INTERESTED_DOMAINS } from "../../../API/Mutations";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "../../../common/components/Loader";
import { GET_DOMAINS } from "../../../API/Queries";
import { setLoginSuccess, setRegisterSuccess, setUserInfo } from "../authSlice";

// ------------------ domain listing component ----------------------- //
const InterestedDomains = ({ setStarted }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, userInfo } = useSelector((state) => state.authSlice);
  const [domainList, setDomainList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { data: domainData, loading: domainLoading, error: domainError } = useQuery(GET_DOMAINS, {
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    if (domainData && domainData?.getDomains) {
      setDomainList(domainData?.getDomains);
    }
  }, [domainData]);

  const [AddInterestedDomains, { data, loading, error }] = useMutation(
    ADD_INTERESTED_DOMAINS,
    {
      context: {
        headers: {
          Authorization: `${userToken.replace("Bearer ", "")}`,
        },
      },
      onError: (error) => {
        toast.error(error.graphQLErrors[0].message);
      },
    }
  );
  useEffect(()=>{
if (data && data?.addInterestedDomains && data?.addInterestedDomains?.data) {
  dispatch(setUserInfo(data?.addInterestedDomains?.data));
  dispatch(setRegisterSuccess(true));
  dispatch(setLoginSuccess(true));
  setStarted(true);
  navigate('/');
}
  },[data])

  // ------------------ option click handler component ----------------------- //
  const handleOptionClick = (data) => {
    const isAlreadySelected = selectedOptions.some(
      (option) => option?._id === data?._id
    );

    if (!isAlreadySelected) {
      setSelectedOptions([...selectedOptions, data]);
      const updatedOptions = domainList.filter(
        (option) => option?._id !== data?._id
      );
      setDomainList(updatedOptions);
    } else {
      setSelectedOptions((prev) => prev.filter((f) => f?._id !== data?._id));
      setDomainList([...domainList, data]);
    }
  };

  // ------------------ option select handler component ----------------------- //
  const handleSelected = (data) => {
    setSelectedOptions((prev) => prev.filter((f) => f?._id !== data?._id));
    setDomainList([...domainList, data]);
  };

  // ------------------ continue handler component ----------------------- //
  const handleContinueClick = () => {
    const postData = {
      domains: selectedOptions.map((option) => ({
        _id: option._id,
        name: option.name,
      })),
    };
    AddInterestedDomains({ variables: { body: postData } });
  };

  // ------------------ filter domain list handler component ----------------------- //
  const filterDomainList = () => {
    return domainList.filter((data) =>
      data?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };


  if (domainLoading || loading) return <Loader minHeight="100vh" />;

  return (
    <div className="max-w-screen-lg mx-auto py-10 min-h-screen">
      <div className="text-center">
        <Typography className="text-2xl mb-8">Company Logo</Typography>
        <Typography className="text-3xl font-bold mb-2">
          Choose upto five Interests
        </Typography>
        <Typography className="text-sm text-textLight-300 mb-4">
          Choosing of interested topics will help us to deliver you the best
          experience.
        </Typography>
      </div>
      <div className="text-center w-full min-h-[40vh]">
        {loading ? (
          <div className="flex items-center justify-center h-48">
            <Typography className="text-4xl">No! Data found.</Typography>
          </div>
        ) : (
          <div className="w-full h-full p-2">
            <div className="max-w-xl mx-auto mb-8">
              <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                <div className="grid place-items-center h-full w-12 text-gray-200">
                  <SearchNormal1 color="#999999" />
                </div>
                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                  type="text"
                  id="search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) =>
                    setSearchValue(
                      e.target.value
                        .replace(/^\s+|[^A-Za-z0-9\s]/g, '')
                        .slice(0, 30)
                    )
                  }
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between flex-col">
              <div className="flex flex-wrap justify-center mb-6 min-h-[45px]">
                {filterDomainList()?.map((data, index) => (
                  <Button
                    variant="outlined"
                    key={index}
                    onClick={() => handleOptionClick(data)}
                    className={`font-semibold py-2 px-4 rounded-full mx-2 mb-2 text-textLight-300 normal-case`}
                  >
                    {data?.name}
                  </Button>
                ))}
              </div>
              <div className="flex flex-wrap justify-center mb-6">
                <Typography className="font-archivo font-semibold text-sm">
                  {selectedOptions?.length}{" "}
                  <span className="font-medium text-textLight-300">of</span>{" "}
                  {domainData?.getDomains?.length} Selected
                </Typography>
              </div>
              <div className="flex flex-wrap justify-center">
                {selectedOptions?.map((data, index) => (
                  <Button
                    variant="outlined"
                    color="green"
                    key={index}
                    onClick={() => handleSelected(data)}
                    className="bg-primaryLight-600 bg-opacity-10 font-semibold py-2 px-4 rounded-full shadow-md mx-2 mb-2 normal-case"
                  >
                    <div className="flex flex-row gap-1 items-center">{data?.name}<IoCloseSharp size={17} /></div>
                  </Button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <div className="text-center mt-8">
          <Button
            variant="filled"
            size="lg"
            onClick={handleContinueClick}
            className={`${
              selectedOptions.length < 5
                ? "bg-primaryLight-600 cursor-not-allowed"
                : "bg-primaryLight-600"
            } text-white font-semibold py-2 px-8 rounded-lg normal-case text-lg`}
            disabled={selectedOptions.length < 5}
            // onClickCapture={()=>{
                // setStarted(true);
                // navigate("/");
            // }}
          >
            Let’s get started
          </Button>
        </div>
      )}
    </div>
  );
};

export default InterestedDomains;
