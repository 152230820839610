import { Avatar, Card, Typography } from "@material-tailwind/react";
import bg from "../../../assets/images/dummy-bg.jpg";
import { RiShareBoxLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";

const RecommendedTopicsCard = () => {
  const dispatch = useDispatch();
  const topics = [
    { title: "Cloud is Also Potentially Vulnerable" },
    { title: "Artificial Intelligence (AI) Integration" },
    { title: "Serverless Architectures" },
    { title: "Cloud is Also Potentially Vulnerable" },
    { title: "Artificial Intelligence (AI) Integration" },
    { title: "Serverless Architectures" },
  ];

  return (
    <>
      <div
        className="mt-6 flex items-center whitespace-nowrap gap-6 w-full overflow-x-auto pb-2 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full
             [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121]
             dark:[&::-webkit-scrollbar-thumb]:bg-white"
      >
        {topics?.map((item, index) => (
          <div
            onClick={() => dispatch(setActiveSection("topics"))}
            key={index}
            className="border-2 cursor-pointer min-w-80 h-56 rounded-none !rounded-tr-xl !rounded-bl-md p-2 flex flex-col justify-between hover:underline underline-offset-2 text-white"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="flex flex-col items-end w-full">
              <RiShareBoxLine className="text-sm text-white" />
            </div>
            <div className="flex w-full items-center justify-between">
              <div className="w-2/3">
                <Typography className="text-sm font-archivo text-white font-bold">
                  {item?.title}
                </Typography>
              </div>

              <div className="flex items-center -space-x-2">
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 1"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                />
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 2"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
                />
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 3"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RecommendedTopicsCard;
