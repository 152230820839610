import { Button, Chip, Tooltip, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import TextEditor from "../../../common/components/Editor";
import { Clock, DocumentText1, DocumentUpload, SearchNormal1, TagUser, Trash } from "iconsax-react";
import ScheduleCard from "../../../common/components/ScheduleCard";
import { useLocation, useNavigate } from "react-router";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToMarkdown from 'draftjs-to-markdown';
import { useSelector } from "react-redux";

const ResearchPaper = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const { isEdit } = useSelector((state) => state.activeTabSlice);

    const getFileNameFromUrl = (url) => {
        // Use the URL object to parse the URL and extract the pathname
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        // Split the pathname to get the filename
        const fileName = pathname.split("/").pop();
        const parts = fileName.split("-");
        parts.shift(); // Remove the first part
        // Join the remaining parts back together with dashes
        return parts.join("-");
    };

    const [file, setFile] = useState();
    const [fileURL, setFileURL] = useState();
    const [open, setOpen] = useState(false);
    const [showTagOption, setShowTagOption] = useState(false);

    const [editorState, setEditorState] = useState(() => isEdit && data?.type == "researchPaper" ?
        EditorState.createWithContent(ContentState.createFromText(data?.description))
        : EditorState.createEmpty());

    const [length, setLength] = useState(editorState?.getCurrentContent().getPlainText('').length)
    // console.log((draftToMarkdown(convertToRaw(editorState.getCurrentContent()))))
    useEffect(() => {
        setLength(editorState.getCurrentContent().getPlainText('').length)
    }, [editorState]);

    const handleOpen = () => setOpen(!open);

    const state = {
        file: !isEdit && file ? fileURL : data?.researchPaperPdf[0]?.url,
        pdf: file,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        type: "research_paper"
    }

    const editState = {
        file: !isEdit && file ? fileURL : data?.researchPaperPdf[0]?.url,
        pdf: file,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        paperId: data?.researchPaperId,
        postId: data?._id,
        type: "research_paper"
    }

    return (
        <>

            <div className="flex lg:hidden justify-between items-center gap-3">
                <div className="">
                    <Typography className="text-textLight-500 font-bold text-base font-archivo dark:text-white">Add new research paper</Typography>
                    <Typography variant="paragraph" className="text-textLight-200 dark:text-textLight-50 font-normal text-xs font-rubik">Import your research paper pdf file to share it on the community.</Typography>
                </div>
                <div onClick={handleOpen} className="w-fit cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                    <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                </div>
                <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} />
            </div>

            <Typography className="hidden lg:block font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload Research paper</Typography>
            <div className="mt-6">
                <TextEditor editorState={editorState} setEditorState={setEditorState} length={length} />
                <div className="w-full flex items-center my-6">
                    <label className="cursor-pointer w-full ">
                        <input type="file" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={(e) => (setFile(e.target.files[0]), setFileURL(URL.createObjectURL(e.target.files[0], { type: "application/pdf" })))} />
                        <div className="bg-textLight-50 dark:bg-textLight-700 w-full h-40 rounded-xl flex flex-col items-center justify-center">
                            <DocumentUpload size="37" className="text-textLight-400 bg-gradient-to-r from-white to-[rgba(238,238,238,0.88)] dark:from-black dark:border dark:border-white dark:text-white p-2 rounded-full drop-shadow-lg" />
                            <Typography className="font-archivo font-medium text-textLight-200 text-sm mt-2">Upload or Drop PDF file here</Typography>
                        </div>
                    </label>
                </div>

                {/* --------------------preview file name if available------------- */}
                {file && fileURL && (
                    <div className="mt-6">
                        <div className="mt-4 flex gap-4 items-center w-fit">
                            <div className="flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                                <DocumentText1 size="18" /> {file.name}
                            </div>
                            <Trash size="20" className="text-error-500 cursor-pointer" />
                        </div>
                    </div>
                )}
                {isEdit && data?.type == "researchPaper" && !file && !fileURL && (
                    <div className="mt-6">
                        <div className="mt-4 flex gap-4 items-center w-fit">
                            <div className="flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                                <DocumentText1 size="18" /> {decodeURIComponent(getFileNameFromUrl(data?.researchPaperPdf[0]?.url))}
                            </div>
                            <Trash size="20" className="text-error-500 cursor-pointer" />
                        </div>
                    </div>
                )}


                <div className="flex justify-between items-end my-6">
                    {/* ---------------------tag user button------------------ */}
                    <div >
                        <div onClick={() => setShowTagOption(!showTagOption)}
                            className="w-fit cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white dark:hover:border-primaryDark-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Tooltip content="Tag User" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <TagUser className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                            </Tooltip>
                        </div>

                        {/* --------------------show tag option------------- */}
                        {showTagOption && (
                            <>
                                <div className="mt-6 w-96">
                                    <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Tag people</Typography>
                                    <div className="mt-4 flex gap-4 items-center w-full">
                                        <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <SearchNormal1 size="18" />
                                            </span>
                                            <input
                                                type="search"
                                                name="q"
                                                className="py-2.5 w-full text-sm font-archivo dark:bg-textLight-700 dark:text-white text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </div>
                                    <Chip size="sm" value="@vivek09_76" className="w-fit normal-case rounded-xl bg-primaryLight-50 text-textLight-400 dark:text-white dark:bg-primaryDark-800 font-archivo" />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="gap-5 hidden lg:flex">
                        <div onClick={handleOpen} className="cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                        </div>
                        <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} />
                        <Button color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                        <Button color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2  normal-case" onClick={() =>
                            navigate("/home/preview", {
                                state: !isEdit ? state : editState
                            })}
                        >Preview</Button>
                    </div>
                </div>

                <div className="gap-5 flex items-end justify-end lg:hidden mb-10">
                    <Button color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                    <Button color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2  normal-case" onClick={() =>
                        navigate("/home/preview", {
                            state: !isEdit ? state : editState
                        })}
                    >Preview</Button>
                </div>
            </div>
        </>
    )
}

export default ResearchPaper;