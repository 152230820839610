import { Input, Typography, Card, Button } from '@material-tailwind/react'
import { Edit } from "iconsax-react";
import cap from '../../../assets/images/cap.png';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const RewardsCheckout = () => {
  const [checkout, setCheckout] = useState(true);
  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState('');

  //dummy product list
  const productList = [
    { name: "Merch Cap", ogPoints: 1500, points: 1000, description: "Get a Merchandize cap with your earned points now!", img: cap }]

  return (<div className='flex flex-wrap w-full h-full items-center justify-center'>
    <div className='flex flex-wrap justify-center mt-6 lg:mt-0 lg:my-10'>
      <Typography className="text-2xl font-archivo font-bold text-textLight-500 dark:text-white">Enter Shipping Address</Typography>
    </div>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 w-[90%] mt-6">
      <div className='grid item w-full'>
        {checkout ?
          <>
            <div className="mt-6 lg:mt-0 mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter First Name
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter First Name"
                  placeholder="Enter First Name"
                  readOnly
                  value="Asd"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter Last Name
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter Last Name"
                  placeholder="Enter Last Name"
                  readOnly
                  value="Fgh"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
                <div className="w-full">
                  <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                    Select Size
                  </Typography>
                  <div className="flex flex-row gap-4 justify-evenly mt-2">
                    {["XS", "S", "M", "L", "XL", "XXL"].map(size => (
                      <Typography
                        key={size}
                        onClick={() => setSelectedSize(size)}
                        className={`cursor-pointer border p-2 rounded transition-colors duration-200 w-full text-center ${
                          selectedSize === size
                            ? 'border-primaryLight-300 text-primaryLight-300'
                            : 'border-[#262525] dark:border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100'
                        } hover:border-primaryLight-300 hover:text-primaryLight-300`}
                      >
                        {size}
                      </Typography>
                    ))}
                  </div>
                </div>
              </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter Address Line 1
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter Address Line 1"
                  placeholder="Enter Address Line 1"
                  readOnly
                  value="Fgh"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter Address Line 2
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter Address Line 2"
                  placeholder="Enter Address Line 2"
                  readOnly
                  value="Fgh"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter Pincode
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter Pincode"
                  placeholder="Enter Pincode"
                  readOnly
                  value="Fgh"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter Country
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter Country"
                  placeholder="Enter Country"
                  readOnly
                  value="India"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="mb-8 flex items-right w-full">
              <div className="w-full">
                <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                  Enter City
                </Typography>
                <Input
                  variant="outlined"
                  label="Enter City"
                  placeholder="Enter City"
                  readOnly
                  value="Dwarka"
                  className="!border !border-[#262525] dark:!border-textDark-100 bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                  labelProps={{
                    className: "hidden",
                  }}
                  icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2 mb-8">
              <Button
                variant="outlined"
                color="green"
                size="md"
                className="flex flex-wrap gap-2 items-center normal-case"
                onClick={()=>navigate('/home/rewards')}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color="green"
                size="md"
                className="flex flex-wrap gap-2 items-center normal-case"
                onClick={() => setCheckout(false)}
              >
                Next
              </Button>
            </div>
          </>

          :
          (<div className='grid item w-full'>
            <div className="mb-8 flex items-right w-full">
              <div className='w-full'>
                <div className="w-full bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 p-6 mb-4 rounded-xl">
                  <Typography className="font-archivo font-bold text-base text-textLight-500 dark:text-textDark-100">Address:</Typography>
                  <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100">Rahul Gupta</Typography>
                  <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100">456 Green Park</Typography>
                  <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100">Sector-7, Gurugram, Haryana, India</Typography>
                  <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100 mb-6">110037</Typography>
                  <div className="flex flex-wrap justify-between w-full bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 py-6 border-0 dark:border-t border-textLight-100">
                    <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100">Amount Payable:</Typography>
                    <Typography className="font-archivo font-bold text-base text-textLight-500 dark:text-textDark-100">1000Points</Typography>
                  </div>
                  <div className="flex justify-start gap-4">
                    <Button
                      variant="outlined"
                      color="green"
                      // size="md"
                      className="flex flex-wrap gap-2 font-archivo items-center normal-case text-lg font-medium"
                      onClick={() => setCheckout(true)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="filled"
                      color="green"
                      // size="md"
                      className="flex flex-wrap gap-2 font-archivo items-center normal-case text-lg font-medium"
                      onClick={() => { setCheckout(true); navigate('/home/rewards') }}
                    >
                      Confirm Order
                    </Button>
                  </div>
                </div>
                <Typography className="font-archivo font-normal text-base text-textLight-500 dark:text-textDark-100">*The order might take 10-15 days to reach you.</Typography>
              </div>
            </div>
          </div>)}
      </div>
      <div className='grid item w-full justify-center -order-1 md:order-1'>
        <div className='place-self-start lg:mb-6'>
          {productList?.map((product, index) => (
            <Card key={index} className="p-3 drop-shadow-xl bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 w-[75vw] md:w-[30vw] sm:w-[30vw]">
              <img src={product.img} className='rounded-3xl' />
              <div className="flex justify-between items-center mt-1">
                <Typography className="font-archivo font-medium text-base text-textLight-500 dark:text-textDark-100">{product.name}</Typography>
                <Typography className="font-archivo font-medium text-base text-textLight-500 flex gap-1 items-center dark:text-textDark-100">
                  <span className="font-rubik text-[10px] font-normal text-textLight-200 line-through">{product.ogPoints} Pts</span>
                  {product.points} Pts
                </Typography>
              </div>
              <Typography className="font-rubik text-xs font-normal text-textLight-300 mt-2 dark:text-textDark-100">{product.description}</Typography>
            </Card>
          ))}
        </div>
      </div>
    </div>
  </div>
  )
}

export default RewardsCheckout
