import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from "@material-tailwind/react";
import { useState } from "react";
import PostSearch from "./PostsSearch";
import PeopleSearch from "./PeopleSearch";
import GroupSearch from "./GroupSearch";
import { useLocation } from "react-router";


const Search = () => {
    const location = useLocation();
    const searchData = location?.state?.searchData;
    const [activeTab, setActiveTab] = useState("posts");

    //displaying tab content data
    const data = [
        {
            label: "Posts",
            value: "posts",
            el: <PostSearch data={searchData?.post} />
        },
        {
            label: "People",
            value: "people",
            el: <PeopleSearch data={searchData?.people}/>
        },
        {
            label: "Groups",
            value: "groups",
            el: <GroupSearch data={searchData?.group}/>
        },
    ];

    return (
        <>
            <div className="mt-6 lg:mt-0">
                <Tabs value="posts" className="w-full">
                    <TabsHeader className=" w-1/3 bg-transparent flex justify-between gap-32" indicatorProps={{
                        className: "bg-primaryLight-500/10 shadow-none !text-primaryLight-700 dark:bg-transparent dark:border-2 dark:border-primaryDark-500",
                    }}
                    >
                        <div className="flex w-full ">
                            {data.map(({ label, value, icon }) => (
                                <Tab key={value} value={value} onClick={() => setActiveTab(value)}>
                                    <div className={`flex items-center gap-2 font-archivo font-medium text-lg
                                ${activeTab == value ? "!text-primaryLight-700 dark:!text-primaryDark-500" : "text-textLight-400 dark:text-white"}
                            `}>
                                        {label}
                                    </div>
                                </Tab>
                            ))}
                        </div>

                    </TabsHeader>
                    <TabsBody className="w-full">
                        {data.map(({ value, el }) => (
                            <TabPanel key={value} value={value} className="px-0">
                                {el}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    )
}

export default Search;