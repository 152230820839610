import React from "react";
import chart1 from "../../assets/images/chart1.png";
import chart2 from "../../assets/images/chart2.png";
import separator1 from "../../assets/images/separator1.png";
import separator2 from "../../assets/images/separator2.png";
import separator3 from "../../assets/images/separator3.png";
import separator4 from "../../assets/images/separator4.png";
import separator5 from "../../assets/images/separator5.png";
import { Typography } from "@material-tailwind/react";

const CommunityCard = () => {
  const dummyCardComponent = [
    {
      separateStatus: true,
      separatist: separator1,
    },
    {
      logo: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80",
      description: "100+ Community",
      pic: chart1,
      separateStatus: false,
    },
    {
      separateStatus: true,
      separatist: separator2,
    },
    {
      logo: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80",
      description: "2000+ Members",
      pic: chart2,
      separateStatus: false,
    },
    {
      separateStatus: true,
      separatist: separator3,
    },
    {
      separateStatus: true,
      separatist: separator4,
      logo: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80",
      description: "120+ Courses",
      pic: chart1
    },
    {
      separateStatus: true,
      separatist: separator5,
    },
  ];
  return (
    <div className="flex flex-wrap justify-center gap-6 md:w-5/6 lg:w-2/3 sm:max-w-full">
      {dummyCardComponent.map((item, index) => {
        return (
          <div key={index} className="flex flex-wrap">
            {item.separateStatus ? (
              <div className="flex  flex-wrap gap-6 flex-col sm:flex-row items-center">
                <div className="flex items-center">
                  <img
                    src={item.separatist}
                    alt="separatist"
                    className="h-[48px] w-[48px] object-cover object-center"
                  />
                </div>
                {item?.logo && item?.logo && (
                  <div
                    key={index}
                    className="relative flex w-[20rem] flex-col rounded-xl bg-textLight-700 border-2 border-textLight-300 bg-clip-border shadow-none px-4 py-3"
                  >
                    <div className="relative flex items-center gap-4 overflow-hidden bg-transparent shadow-none rounded-xl bg-clip-border">
                      <img
                        src={item.logo}
                        alt="avatar"
                        className="h-[50px] w-[50px] !rounded-full  object-cover object-center"
                      />
                      <div className="flex w-full flex-col gap-0.5">
                        <div className="flex items-center justify-between gap-4">
                          <Typography className="font-archivo text-sm text-textDark-50">
                            {item?.description}
                          </Typography>
                          <div className="flex items-center gap-0">
                            <img
                              src={item.pic}
                              alt="chart"
                              className="h-[50px] w-[75px] object-cover object-center"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                key={index}
                className="relative flex w-[20rem] flex-col rounded-xl bg-textLight-700 border-2 border-textLight-300 bg-clip-border shadow-none px-4 py-3"
              >
                <div className="relative flex items-center gap-4 overflow-hidden bg-transparent shadow-none rounded-xl bg-clip-border">
                  <img
                    src={item.logo}
                    alt="avatar"
                    className="h-[50px] w-[50px] !rounded-full  object-cover object-center"
                  />
                  <div className="flex w-full flex-col gap-0.5">
                    <div className="flex items-center justify-between gap-4">
                      <Typography className="font-archivo text-sm text-textDark-50">
                        {item?.description}
                      </Typography>
                      <div className="flex items-center gap-0">
                        <img
                          src={item.pic}
                          alt="chart"
                          className="h-[50px] w-[75px] object-cover object-center"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CommunityCard;
