import { Avatar, Card, Chip, Typography, avatar } from "@material-tailwind/react";
import { ArrowLeft, Edit, Trash } from "iconsax-react";
import { useNavigate } from "react-router";

const TABLE_ROWS = [
    {
        avatar: "https://docs.material-tailwind.com/img/face-2.jpg",
        name: "Cybersecurity",
        lastEdited: "07.05.24",
        job: "Blog",
    },
    {
        avatar: "https://docs.material-tailwind.com/img/face-2.jpg",
        name: "UI/ UX Research",
        lastEdited: "07.05.24",
        job: "Research Paper",
    },
    {
        avatar: "https://docs.material-tailwind.com/img/face-2.jpg",
        name: "Blockchain",
        lastEdited: "07.05.24",
        job: "Poll",
    },
    {
        avatar: "https://docs.material-tailwind.com/img/face-2.jpg",
        name: "UI/ UX Research Paper",
        lastEdited: "07.05.24",
        job: "Research Paper",
    },
];

const Drafts = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="my-6 flex lg:hidden justify-between items-center gap-3">
                <div className="">
                    <Typography className="text-textLight-500 font-bold text-base font-archivo dark:text-white">Draft</Typography>
                    <Typography variant="paragraph" className="text-textLight-200 dark:text-textLight-50 font-normal text-xs font-rubik">Some post want your attention!</Typography>
                </div>
                <ArrowLeft onClick={() => navigate(-1)} className="cursor-pointer" />
            </div>
            <Card className="w-[85vw] overflow-auto shadow-none lg:w-2/3 dark:bg-textLight-500">

                <Typography className="hidden lg:block text-textLight-400 dark:text-white font-archivo font-bold text-xl mb-6">Drafts</Typography>

                <table className=" min-w-max text-center">
                    <tbody>
                        {TABLE_ROWS.map(({ avatar, name, job, lastEdited }, index) => {
                            // const isLast = index === TABLE_ROWS.length - 1;
                            // const classes = isLast ? "p-4" : "p-4";

                            return (
                                <tr key={name}>
                                    <td className="p-2">
                                        <div
                                            variant="small"
                                            className="flex gap-4 items-center font-bold font-archivo text-textLight-400 dark:text-white"
                                        >
                                            <Avatar src={avatar} className="w-12 h-12 !rounded-lg" />
                                            <Typography className="font-archivo font-semibold flex flex-col items-start">
                                                {name} <span className="font-medium text-xs">Last Edited {lastEdited}</span>
                                            </Typography>
                                        </div>
                                    </td>
                                    <td className="p-2 py-4 h-full flex items-center justify-center">
                                        <Chip value={job} size="sm" className="w-fit rounded-full normal-case font-medium text-sm font-rubik bg-supportLight-50  text-supportLight-300 px-4 py-1" />
                                    </td>
                                    <td className="p-2 cursor-pointer">
                                        <Typography
                                            variant="small"
                                            className="font-normal"
                                        >
                                            <Trash className="text-error-500" />
                                        </Typography>
                                    </td>
                                    <td className="p-2 cursor-pointer">
                                        <Typography
                                            variant="small"
                                            className="font-medium"
                                        >
                                            <Edit size="20" className="text-textLight-300 dark:text-white" />
                                        </Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Card>
        </>
    );
}

export default Drafts;