import { Card, Typography } from "@material-tailwind/react";
import GroupCard from "./GroupsCard";
import MessageCard from "./MessagesCard";
import chatBg from '../../../assets/images/chat-bg.jpg';
import chat_default from '../../../assets/images/chat_default.png';
import { useEffect, useRef } from "react";

const DesktopDefault = () => {
    const chatRef = useRef();
    useEffect(() => {
        chatRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <>
            <div className="flex gap-6 h-[calc(100vh-167px)] lg:h-screen p-0 mb-6">
                <div ref={chatRef} className="hidden lg:block w-full xl:w-3/4">
                    <Card className="w-full h-full rounded-2xl !border-0 flex flex-col items-center justify-center" style={{
                        backgroundImage: `url(${chatBg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <img src={chat_default} className="" />
                        <Typography className="text-textLight-50 font-bold font-archivo text-3xl tracking-wide">Feeling Chatty?</Typography>
                        <Typography className="text-textLight-50 font-medium font-archivo text-lg tracking-wide">Pick a friend to chat with!</Typography>


                    </Card>
                </div>
                <div className="sticky top-0 h-full w-1/4 hidden xl:flex flex-col gap-6 ">
                    <GroupCard />
                    <MessageCard />
                </div>
            </div>
        </>
    )
}

export default DesktopDefault;