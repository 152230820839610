import { Avatar, Typography } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { setActiveSection } from "./homeSectionSlice";

const Reels = ({ reel }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        onClick={() => dispatch(setActiveSection("reel"))}
        className="z-10 hidden md:flex flex-col items-center gap-7 cursor-pointer"
      >
        <div className="z-10 w-52 h-72 rounded-2xl flex items-end relative">
          <video
            muted
            autoPlay
            loop
            src={reel?.videoUrl?.url}
            className="w-full h-full object-cover rounded-2xl"
          />
          <Avatar
            className="absolute left-20 -bottom-6"
            src={reel?.author?.avatar}
            alt="avatar"
            size="lg"
          />
        </div>
        <Typography className="text-textLight-200 font-archivo text-base font-semibold">
          {reel?.author?.firstName} {reel?.author?.lastName}
        </Typography>
      </div>

      {/* ----------mobile screen--------------- */}
      <div
        onClick={() => dispatch(setActiveSection("reel"))}
        className="z-10 w-36 h-72 md:hidden flex flex-col items-center gap-7 cursor-pointer"
      >
        <div className="z-10 w-full h-full rounded-2xl flex items-end relative">
          <video
            muted
            autoPlay
            loop
            src={reel?.videoUrl?.url}
            className="w-full h-full object-cover rounded-2xl"
          />
          <Avatar
            className="absolute left-12 -bottom-6"
            src="https://docs.material-tailwind.com/img/face-2.jpg"
            alt="avatar"
            size="md"
          />
        </div>
        <Typography className="text-textLight-200 font-archivo text-base font-semibold">
          {reel?.name || ""}
        </Typography>
      </div>
    </>
  );
};

export default Reels;
