import { useLocation } from "react-router";
import ChatBox from "./ChatBox";
import GroupCard from "./GroupsCard";
import MessageCard from "./MessagesCard";
import MobileChat from "./MobileChat";

const Chat = () => {
  const location = useLocation();
  const state = location?.state;

  return (
    <>
      <div className="flex gap-6 h-[calc(100vh-167px)] lg:h-screen p-0 mb-6">
        <div className="hidden lg:block w-full xl:w-3/4">
          <ChatBox type={state?.type} userData={state?.userData} />
        </div>
        <div className="block lg:hidden w-full xl:w-3/4">
          <MobileChat />
        </div>
        <div className="sticky top-0 h-full w-1/4 hidden xl:flex flex-col gap-6 ">
          <GroupCard />
          <MessageCard />
        </div>
      </div>
    </>
  );
};

export default Chat;
