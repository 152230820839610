import { Button, Typography } from "@material-tailwind/react";
import frame1 from '../../../assets/images/onboardingframe1.svg';
import frame2 from '../../../assets/images/onboardingframe2.svg';
import React, { useState } from "react";
import InterestedDomains from "./InterestedDomains";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const OnboardingPage = () => {
    const [started, setStarted] = useState(true);
    const  { userInfo } = useSelector((state)=>state.authSlice);
    const navigate = useNavigate();

  return (
    <>
    {started ?
        <div className="flex flex-wrap flex-col justify-center items-center h-screen border-2 border-green-900"
     style={{ background: `url(${frame1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "auto",
    backgroundPosition:"center"
   }}
    >
      <div className="flex flex-wrap flex-col max-w-[90%] lg:max-w-[45%] md:max-w-[75%] sm:max-w-[90%] justify-between items-center my-16">
        <Typography className="text-2xl">Company Logo</Typography>
        <div className="flex flex-wrap flex-col justify-center items-end max-h-[50vh] min-h-[40vh] min-w-[330px] w-1/2 mb-14 mt-7" style={{background: `url(${frame2})`,backgroundRepeat: 'no-repeat', backgroundSize: "contain", backgroundPosition:"center"}}>
        <Typography className="text-5xl mr-[65px] mt-[90px] font-BonheurRoyale origin-center -rotate-12">{userInfo && userInfo.firstName} {userInfo && userInfo.lastName}</Typography>
        </div>
        <Typography className="text-sm text-center">
          "We're absolutely thrilled to have you join our vibrant community! 🚀
          Whether you're here to share your expertise, learn something new, or
          connect with like-minded individuals, you've just become a valued
          member of our growing family."
        </Typography>
      <div className="flex flex-wrap w-full justify-center">
            <Button
              variant="filled"
              size="lg"
              color="green"
              className="py-2 normal-case text-lg w-full max-w-[200px] mt-12"
              onClick={()=>(setStarted(false)
                // ,
                //  navigate('/')
                )}
            >
              Let’s get started
            </Button>
            </div>
      </div>
    </div> :
    <InterestedDomains setStarted={setStarted} />
    }
    </>
  );
};

export default OnboardingPage;
