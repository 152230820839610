import { Button, Typography } from "@material-tailwind/react";
import Footer from "../Footer";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

const CookiePolicy = () => {
    const navigate = useNavigate();
    const divRef = useRef();

    useEffect(() => {
        divRef?.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <>
            <div ref={divRef} className="max-w-full min-h-screen py-3 md:py-10 bg-[#080808] flex items-center justify-center flex-col">
                <div className="w-full md:w-2/3 md:text-justify px-5 md:px-0">
                    <div className="my-6 flex flex-col-reverse md:flex-row items-start md:items-center justify-between">
                        <Typography className="font-archivo text-white text-2xl md:text-3xl font-bold">Cookie Policy</Typography>
                        <div className="flex w-full md:w-fit justify-end">
                            <Button
                                onClick={() => navigate(-1)}
                                variant="text"
                                color="green"
                                className="font-archivo text-primaryDark-500 text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
                            >
                                <ArrowLeft size="20" /> Back
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">This policy explains the use of cookies on Community.com. Cookies are small text files stored on your
                            device to enhance website functionality and user experience.</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal mt-2">We use cookies to store or retrieve information on your browser.
                            This information may relate to you, your preferences, or your device and is mostly used to make the site work as you expect it to.
                            Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change your settings.
                            However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</Typography>
                    </div>

                    <Typography className="text-white font-semibold font-archivo text-xl mb-4">Types of Cookies</Typography>

                    <div className="flex flex-col gap-1 mb-4 ml-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">1. Essential Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">These cookies are crucial for the basic functioning of Community.com.
                            They activate in response to your actions like setting privacy preferences or logging in.
                            While you can block them through your browser, doing so may impact the functionality of the site.
                            These cookies are privacy-friendly as they don't store personal information.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-4 ml-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">2. Performance Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">These cookies help us understand and improve our website's performance.
                            They collect data about your visits and how you interact with the site, letting us know which pages are popular and how users navigate our site.
                            Opting out of these cookies means we won't be able to track these performance metrics.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-4 ml-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">3. Functional Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Functional cookies enhance your experience on Community.com by enabling advanced features and personalization. They might be set by us or third-party providers whose services we've integrated.
                            If you choose not to allow these cookies, it might affect the performance of certain functionalities on the site.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6 ml-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">4. Advertising Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Set by our advertising partners, these cookies are used to build a profile of your interests and show you relevant advertisements on other sites. They work by uniquely identifying your browser and internet device, without storing personal information.
                            Disabling these cookies will result in less personalized advertising experiences. </Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Consent and Withdrawal</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">When you visit Community.com, a cookie banner requests your consent for using cookies.
                            This policy includes guidance on how you can withdraw this consent at any time, ensuring control over your privacy preferences.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Managing Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">You have the ability to manage the cookies we use. This policy provides detailed instructions
                            on how you can adjust your browser settings to control, block, or delete cookies as per your preference.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Third-Party Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Our site uses third-party cookies primarily for analytics and enhancing site functionality.
                            This policy outlines the types of third-party cookies we use and their purpose.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Link to Privacy Policy</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">For more comprehensive information on how we
                            handle your data, including the use of
                            cookies, you can refer to our{' '}
                            <a
                                href="/privacy-policy"
                                style={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '5px'
                                }}
                            >
                                <span>Privacy Policy</span>
                            </a>{' '}
                            linked in this document.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Policy Updates</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">We periodically update our Cookies Policy. This section explains how we will notify you about these updates,
                             ensuring you are always informed about how we use cookies.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Contact Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">
                            If you have any questions or require further information about our Cancellation & Refund Policy, please contact us at:
                        </Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal mt-2">
                            Bhumi Itech Private Limited Address: #521, 5th Floor, City Centre,<br />
                            Sector-12, Dwarka, New Delhi-110075, India<br />
                            Email: info@bhumiitech.com<br />
                            This Policy was last updated on 01 January 2024.
                        </Typography>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default CookiePolicy;