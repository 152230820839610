import { Avatar, Button, Card, Chip, Input, Tooltip, Typography } from "@material-tailwind/react";
import { AddCircle, Clock, Gallery, SearchNormal1, TagUser, Trash } from "iconsax-react";
import ScheduleCard from "../../../common/components/ScheduleCard";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useSelector } from "react-redux";

const Polls = () => {
    const location = useLocation();
    const data = location?.state?.data;
    const { isEdit } = useSelector((state) => state.activeTabSlice);
    const navigate = useNavigate();
    const [question, setQuestion] = useState(isEdit ? data?.question : "");
    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState();
    const [showTagOption, setShowTagOption] = useState(false);
    const [open, setOpen] = useState(false);
    const withoutTypenameObj = data && Object.fromEntries(Object.entries(data?.options || {}).filter(([key]) => key !== '__typename'));
    const [options, setOptions] = useState(isEdit && data?.type == "poll" ? [withoutTypenameObj] : [{ option1: "", option2: "" }]);
    const [indexCount, setIndexCount] = useState(isEdit && data?.type == "poll" ? Object?.keys(data?.options || {})?.length : 3);
    // console.log("edit data", data, isEdit)

    const handleOpen = () => setOpen(!open);

    // renumbering the option index
    const renumberKeys = (optionsObject) => {
        let index = 1;
        const newOptions = {};
        for (const key in optionsObject) {
            newOptions[`option${index}`] = optionsObject[key];
            index++;
        }
        return newOptions;
    };

    //add option function
    const handleAddOption = () => {
        if (indexCount <= 4) {
            const newOptionName = `option${indexCount}`;
            const newOption = { [newOptionName]: '' };
            setOptions((prevOptions) => { //adding new option to the options[0] object 
                const updatedOptions = [{ ...prevOptions[0], ...newOption }];
                return updatedOptions;
            });
            setIndexCount((prevCounter) => prevCounter + 1); //increasing the index count when option is added
        }
    };

    //delete option function 
    const deleteOption = (index) => {
        setOptions((prevOptions) => {
            const { [index]: _, ...rest } = prevOptions[0]; // Destructure to remove the key
            return [renumberKeys(rest)]; // Return the updated object inside an array
        });
        setIndexCount((prevCounter) => prevCounter - 1); //decreasing the index count when option is removed
    };

    const state = {
        question: question,
        options: options,
        type: "poll"
    }

    const editState = {
        question: question,
        options: options,
        type: "poll",
        pollId: data?.pollId,
        postId: data?._id
    }

    return (
        <>
            <div className="flex lg:hidden justify-between items-center gap-3">
                <div className="">
                    <Typography className="text-textLight-500 font-bold text-base font-archivo dark:text-white">Add new poll</Typography>
                    <Typography variant="paragraph" className="text-textLight-200 dark:text-textLight-50 font-normal text-xs font-rubik">Add question and option to share your poll on the community.</Typography>
                </div>
                <div onClick={handleOpen} className="w-fit cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                    <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                </div>
                <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} />
            </div>

            <Typography className="hidden lg:block font-archivo font-bold text-textLight-400 dark:text-white text-lg">Create new poll</Typography>

            <div className="w-full lg:w-3/4 flex flex-col gap-4">
                <Input
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    variant="standard"
                    label="Add Question"
                    placeholder="Add Question"
                    className="border-b-0 text-textLight-500 dark:text-white !text-lg ring-4 !font-archivo ring-transparent !font-medium placeholder:!font-medium placeholder:text-textLight-200 placeholder:opacity-100"
                    labelProps={{
                        className: "hidden",
                    }}
                />

                {Object?.keys(options[0] || {})?.map((option, index) => ( //mapping the option[0] object to show the option input field
                    <div key={index} className="flex flex-col lg:flex-row gap-2 w-full items-center justify-between">
                        <Card className={`${Object?.keys(options[0]).length == 3 ? "w-[99%] lg:w-[83%]" : "w-[99%] lg:w-[90%]"} flex flex-row items-center justify-between dark:bg-textLight-700 drop-shadow-[0_0px_3px_rgba(0,0,0,0.25)] dark:drop-shadow-none px-4 pb-2 mx-1`}>
                            <Input
                                value={options[0][`option${index + 1}`] || ""}
                                variant="standard"
                                onChange={(e) => {
                                    const newVal = e.target.value;
                                    const newOption = `option${index + 1}`
                                    setOptions((prev) => { //value of option based on index and updating the option object 
                                        const updated = {
                                            ...prev[indexCount], [newOption]: newVal
                                        }
                                        const updatedOptions = [{ ...prev[0], ...updated }]
                                        return updatedOptions;
                                    })
                                }}
                                label={`Add Option ${index + 1}`}
                                placeholder={`Add Option ${index + 1}`}
                                className="pr-2 lg:pr-0 border-b-0 text-textLight-500 dark:text-white !text-lg ring-4 !font-archivo ring-transparent !font-medium placeholder:!font-medium placeholder:text-textLight-200 placeholder:opacity-100"
                                labelProps={{
                                    className: "hidden",
                                }}
                            />

                            {Object?.keys(options[0] || {}).length > 2 && //show delete option button if the available options are more than 2
                                <>
                                    <Tooltip placement="bottom" content="Delete Option" className="flex lg:hidden bg-gray-600 px-2 py-1 text-xs font-rubik">
                                        <Trash onClick={() => deleteOption(`option${index + 1}`)} size="40" className={`flex lg:hidden cursor-pointer text-error-700 dark:text-error-700 bg-error-50 dark:bg-error-100 p-2 rounded-full drop-shadow-lg`} />
                                    </Tooltip>
                                </>
                            }
                        </Card>
                        {Object?.keys(options[0] || {}).length < 4 && //show add option if number of option is less than 4 and only on the last option available
                            <>
                                <Tooltip placement="bottom" content="Add Option" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                    <AddCircle onClick={handleAddOption} size="40" className={`${index + 1 == Object?.keys(options[0]).length ? "block" : "hidden"} cursor-pointer text-primaryLight-700 dark:text-primaryDark-700 bg-primaryLight-50 dark:bg-primaryDark-100 p-2 rounded-full drop-shadow-lg`} />
                                </Tooltip>
                            </>
                        }
                        {Object?.keys(options[0] || {}).length > 2 && //show delete option button if the available options are more than 2
                            <>
                                <Tooltip placement="bottom" content="Delete Option" className="hidden lg:flex bg-gray-600 px-2 py-1 text-xs font-rubik">
                                    <Trash onClick={() => deleteOption(`option${index + 1}`)} size="40" className={`hidden lg:flex cursor-pointer text-error-700 dark:text-error-700 bg-error-50 dark:bg-error-100 p-2 rounded-full drop-shadow-lg`} />
                                </Tooltip>
                            </>
                        }

                    </div>
                ))}

            </div >

            <div className="my-6 flex gap-5 w-full items-end justify-between">
                <div className="">
                    <div className="flex gap-5">

                        {/* ---------------------tag user button------------------ */}
                        <div onClick={() => setShowTagOption(!showTagOption)}
                            className="w-fit cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white hover:bg-primaryLight-50 dark:hover:border-primaryDark-500 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Tooltip content="Tag User" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <TagUser className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                            </Tooltip>
                        </div>
                        {/* --------------upload image button--------------- */}
                        <div className="w-fit cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white hover:bg-primaryLight-50 dark:hover:border-primaryDark-500 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Tooltip content="Upload Image" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                <label className="cursor-pointer">
                                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => (
                                        setImage(e.target.files[0]), setImageURL(URL.createObjectURL(e.target.files[0])))}
                                    />
                                    <Gallery className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                                </label>
                            </Tooltip>
                        </div>
                    </div>

                    {/* ----------------preview image if available--------------- */}
                    {image && imageURL && (
                        <div className="mt-6">
                            <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload media</Typography>
                            <div className=" mt-4 !relative w-fit">
                                <Avatar
                                    src={imageURL}
                                    className="w-40 h-40 !rounded-xl"
                                />
                                <div className="absolute top-0 right-0 p-1 bg-gradient-to-r from-white to-[rgba(238,238,238,0.88)] rounded-tr-[11px] rounded-bl-sm">
                                    <Trash size="20" className="text-error-500" />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* --------------------show tag option------------- */}
                    {showTagOption && (
                        <>
                            <div className="mt-6 w-96">
                                <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Tag people</Typography>
                                <div className="mt-4 flex gap-4 items-center w-full">
                                    <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                            <SearchNormal1 size="18" />
                                        </span>
                                        <input
                                            type="search"
                                            name="q"
                                            className="py-2.5 w-full text-sm font-archivo dark:bg-textLight-700 dark:text-white text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                                <Chip size="sm" value="@vivek09_76" className="w-fit normal-case rounded-xl bg-primaryLight-50 text-textLight-400 dark:text-white dark:bg-primaryDark-800 font-archivo" />
                            </div>
                        </>
                    )}
                </div>

                {/* ----------------section action buttons-------------- */}
                <div className="hidden lg:flex gap-5">
                    <div onClick={handleOpen} className="cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                        <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                    </div>
                    <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} />
                    <Button disabled={question == "" || Object.values(options[0] || {}).every((val) => val == '')} color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                    <Button
                        disabled={question == "" || Object.values(options[0] || {}).every((val) => val == '')}
                        color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2  normal-case" onClick={() =>
                            navigate("/home/preview", {
                                state: !isEdit ? state : editState
                            })}
                    >Preview</Button>
                </div>
            </div>

            <div className="gap-5 flex items-end justify-end lg:hidden mb-10">
                <Button disabled={question == "" || Object.values(options[0] || {}).every((val) => val == '')} color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                <Button
                    disabled={question == "" || Object.values(options[0] || {}).every((val) => val == '')}
                    color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2  normal-case" onClick={() =>
                        navigate("/home/preview", {
                            state: !isEdit ? state : editState
                        })}
                >Preview</Button>
            </div>
        </>
    )
}

export default Polls;