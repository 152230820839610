import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import signupimg from "../../../assets/images/signup1.svg";
import gIcon from "../../../assets/images/googleIcon.png";
import emo9 from "../../../assets/images/animoji (8).png";
import { Edit } from "iconsax-react";
import { Typography, Input, Button, Avatar, Select, Option } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Field, Form, Formik, useFormik } from "formik";
import { Country, State } from "country-state-city";
import { EDIT_USER_PROFILE } from "../../../API/Mutations";
import toast from "react-hot-toast";
import Loader from "../../../common/components/Loader";
import { useMutation } from "@apollo/client";
import { setUserInfo } from "../../Auth/authSlice";
import './profile.css';

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.authSlice);
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState();
  const [fake, setFake] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dob, setDob] = useState("")
  const [states, setStates] = useState([]);
  const [updateUserProfile, { data, loading, error }] = useMutation(EDIT_USER_PROFILE, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      }
    }
  });

  const countries = Country.getAllCountries(); //get all countries

  useEffect(() => {
    setSelectedState(userInfo?.state);
      setDob(userInfo?.dateOfBirth)
  }, []) //set default state for states

  useEffect(() => {
    if (data) {
      dispatch(setUserInfo(data?.updateUserProfile?.data)) //dispatch user info
    }
  }, [data, userInfo]);

  //URL validaton regex
  const isValidInstagramUrl = /(?:http:\/\/)?(?:www\.)?instagram\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)?/;
  const isValidLinkedInUrl = /^(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  const isValidTwitterUrl = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)?/;

  //initial formik values
  const initialValues = {
    firstName: userInfo?.firstName ? userInfo?.firstName : "",
    lastName: userInfo?.lastName ? userInfo?.lastName : "",
    email: userInfo?.email ? userInfo?.email : "",
    username: userInfo?.username ? userInfo?.username : "",
    phone: userInfo?.phoneNumber ? userInfo?.phoneNumber : "",
    country: userInfo?.country ? userInfo?.country : "",
    state: selectedState,
    dateOfBirth: dob,
    gender: userInfo?.gender ? userInfo?.gender : "",
    twitter: userInfo?.socialMedia?.twitter ? userInfo?.socialMedia?.twitter : "",
    instagram: userInfo?.socialMedia?.instagram ? userInfo?.socialMedia?.instagram : "",
    linkedIn: userInfo?.socialMedia?.linkedIn ? userInfo?.socialMedia?.linkedIn : ""
  };

  //validation schema for formik
  const validationSchema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email("Invalid email address"),
    username: Yup.string(),
    phone: Yup.string(),
    country: Yup.string(),
    state: Yup.string(),
    dateOfBirth: Yup.string(),
    gender: Yup.string(),
    twitter: Yup.string().matches(isValidTwitterUrl, "Invalid URL"),
    instagram: Yup.string().matches(isValidInstagramUrl, "Invalid URL"),
    linkedIn: Yup.string().matches(isValidLinkedInUrl, "Invalid URL"),
  });

  //formik submit 
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //body to be sent in API
      let body = {
        firstName: values.firstName,
        lastName: values.lastName,
        country: values.country,
        dateOfBirth: dob,
        gender: values.gender,
        phoneNumber: values.phone,
        state: selectedState,
        username: values.username,
        socialMedia: {
          twitter: values.twitter,
          linkedIn: values.linkedIn,
          instagram: values.instagram
        }
      }
      updateUserProfile({ variables: { body: body, file: image ? image : null } }); //update profile query
    }
  });

  //selected states based on country code
  useEffect(() => {
    if (selectedCountry || userInfo?.country) {
      let countryCode = countries.find(
        (country) => country.name === userInfo?.country
      );
      const countryStates = State.getStatesOfCountry(selectedCountry ? selectedCountry : countryCode?.isoCode);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry, formik.values.country]);

  const { values, handleChange, handleSubmit, touched, errors } = formik;

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <form onSubmit={handleSubmit}>

        {/* ---------------------avatar aand upload input----------------------- */}
        <div className="flex flex-wrap flex-col justify-center mt-10">
          <Typography className="font-archivo text-3xl font-bold dark:text-textDark-100">
            Upload Your Image
          </Typography>
          <div className='flex flex-col gap-4 relative w-fit mt-4'>
            <Avatar className='w-[138px] h-[138px] lg:w-[200px] lg:h-[200px]'
              src={imageURL || userInfo?.avatar || userInfo?.staticUrl}
              alt="avatar"
              size="xxl"
            />
            <label className="cursor-pointer">
              <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => (
                setImage(e.target.files[0]), setImageURL(URL.createObjectURL(e.target.files[0])))} />
              <Edit size="24" className='absolute top-3 right-5 bg-textDark-100/75 dark:text-textDark-100 dark:bg-gray-900/75 rounded-lg shadow-black/30 shadow-md' />
            </label>
          </div>


          {/* ------------------------first name and last name input----------------- */}
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                First Name
              </Typography>
              <Input
                variant="outlined"
                label="Enter First Name"
                placeholder="Enter First Name"
                name="firstName"
                value={values.firstName}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
            </div>
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Last Name
              </Typography>
              <Input
                variant="outlined"
                label="Enter Last Name"
                placeholder="Enter Last Name"
                name="lastName"
                value={values.lastName}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
            </div>
          </div>

          {/* --------------------email and username input-------------------- */}
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Email
              </Typography>
              <Input
                variant="outlined"
                label="Enter Email"
                placeholder="Enter Email"
                readOnly
                name="email"
                value={values.email}
                onChange={(e) => handleChange(e)}
                className="!cursor-not-allowed !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
            </div>
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Username
              </Typography>
              <Input
                variant="outlined"
                label="Enter Username"
                placeholder="Enter Username"
                name="username"
                value={values.username}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
            </div>
          </div>

          {/* -------------------phone number and DOB input------------------- */}
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Phone Number
              </Typography>
              <Input
                variant="outlined"
                label="Enter Phone Number"
                placeholder="Enter Phone Number"
                name="phone"
                value={values.phone}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
            </div>
            <div className="profile mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Date of Birh
              </Typography>
              <input type="date" name="dateOfBirth" value={dob} onChange={(e) => setDob(e.target.value)}
                className="p-1.5 rounded-lg !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
              />
            </div>
          </div>

          {/* -----------------------country and state input----------------------- */}
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className={`mb-8 flex flex-col w-full ${(states?.length > 0 || userInfo?.country) ? "lg:w-[47%]" : "lg:w-full"} gap-2`}>
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Country
              </Typography>
              <Select
                // as={Select}
                variant="outlined"
                label={values.country == "" ? "Select Country" : ""}
                name="country"
                value={values.country}
                labelProps={{
                  className: "hidden",
                }}
                onChange={(e) => {
                  handleChange(e);
                  formik.setFieldValue("country", e);

                  // Find the selected country object by name
                  const selectedCountry = countries.find(
                    (country) => country.name === e
                  );

                  if (selectedCountry) {
                    // Set the selected country ISO code
                    setSelectedCountry(selectedCountry.isoCode);
                  }
                }}
                className="w-full !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
              >
                {countries.map((country) => (
                  <Option key={country.isoCode} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>

            {(states?.length > 0) &&
              <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
                <Typography className="font-archivo text-sm font-bold dark:!text-textDark-100">
                  State
                </Typography>
                <Select
                  // as={Select}
                  variant="outlined"
                  name="state"
                  value={userInfo?.state ? userInfo?.state : selectedState}
                  labelProps={{
                    className: "hidden",
                  }}
                  onChange={(e) => {
                    // console.log(e)
                    setSelectedState(e)
                    // handleChange(e);
                    // formik.setFieldValue("state", e);
                  }}
                  className="w-full !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:!text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                >
                  {states?.map((state) => (
                    <Option key={state.isoCode} value={state.name}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </div>
            }
          </div>

          {/* ----------------------gender input---------------------- */}
          <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
            <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
              Gender
            </Typography>
            <Select
              // as={Select}
              variant="outlined"
              name="gender"
              value={values.gender}
              labelProps={{
                className: "hidden",
              }}
              onChange={(e) => {
                handleChange(e);
                formik.setFieldValue("gender", e);
              }}
              className="w-full !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
            >
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="others">Others</Option>
            </Select>
          </div>
        </div>

        {/* ------------------------------social media links update-------------------- */}
        <div className="flex flex-wrap flex-col justify-center mb-6">
          <Typography className="font-archivo text-2xl font-medium dark:text-textDark-100">
            Link Your Social Media{" "}
            <span className="text-[#737373] dark:text-textDark-100">(Optional)</span>
          </Typography>
          <Typography className="font-archivo text-sm font-medium mb-8 text-[#737373] dark:text-textDark-100">
            (Paste your social media link in the boxes below to link)
          </Typography>
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                LinkedIn Profile
              </Typography>
              <Input
                variant="outlined"
                label="Enter your linkedIn Profile link"
                placeholder="Enter your linkedIn Profile link"
                name="linkedIn"
                value={values?.linkedIn}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
              <Typography className="font-archivo text-xs font-bold text-error-500">
                {errors?.linkedIn || ""}
              </Typography>
            </div>
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Instagram Profile
              </Typography>
              <Input
                variant="outlined"
                label="Enter your Instagram Profile link"
                placeholder="Enter your Instagram Profile link"
                name="instagram"
                value={values?.instagram}
                onChange={(e) => handleChange(e)}
                className=" !border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
              <Typography className="font-archivo text-xs font-bold text-error-500">
                {errors?.instagram || ""}
              </Typography>
            </div>
          </div>
          <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div className="mb-8 flex flex-col w-full lg:w-[47%] gap-2">
              <Typography className="font-archivo text-sm font-bold dark:text-textDark-100">
                Twitter Profile
              </Typography>
              <Input
                variant="outlined"
                label="Enter your twitter Profile link"
                placeholder="Enter your twitter Profile link"
                name="twitter"
                value={values?.twitter}
                onChange={(e) => handleChange(e)}
                className="!border !border-[#262525] dark:!border-transparent bg-transparent dark:bg-textLight-700 text-gray-900 dark:text-textDark-100 shadow-lg shadow-gray-900/5 placeholder:text-gray-500 placeholder:opacity-100 focus:!border-[#262525] dark:focus:!border-textDark-100 focus:!border-t-[#262525] dark:focus:!border-t-textDark-100"
                labelProps={{
                  className: "hidden",
                }}
                icon={<Edit className="cursor-pointer dark:text-textDark-100" />}
              />
              <Typography className="font-archivo text-xs font-bold text-error-500">
                {errors?.twitter || ""}
              </Typography>
            </div>
          </div>
        </div>

        {/* -------------submit buttons-------------------- */}
        <div className="flex felx-wrap justify-end mb-16">
          <div className="flex felx-wrap flex-row md:flex-row sm:flex-col gap-6">
            <Button variant="outlined" className="rounded-xl normal-case" color="green">Cancel</Button>
            <Button type="submit" variant="filled" className="rounded-xl normal-case" color="green">Save</Button>
          </div>
        </div>
      </form >
    </>
  );
};

export default ProfileSettings;
