import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import setupprofile from "../../../assets/images/setupprofile1.svg";
import emo1 from "../../../assets/images/animoji (0).png";
import emo2 from "../../../assets/images/animoji (1).png";
import emo3 from "../../../assets/images/animoji (2).png";
import emo4 from "../../../assets/images/animoji (3).png";
import emo5 from "../../../assets/images/animoji (4).png";
import emo6 from "../../../assets/images/animoji (5).png";
import emo7 from "../../../assets/images/animoji (6).png";
import emo8 from "../../../assets/images/animoji (7).png";
import emo9 from "../../../assets/images/animoji (8).png";
import emo10 from "../../../assets/images/animoji (9).png";
import emo11 from "../../../assets/images/animoji (10).png";
import emo12 from "../../../assets/images/animoji (11).png";
import emo13 from "../../../assets/images/animoji (12).png";
import emo14 from "../../../assets/images/animoji (13).png";
import emo15 from "../../../assets/images/animoji (14).png";
import emo16 from "../../../assets/images/animoji (15).png";
import {
  Typography,
  Input,
  Button,
  Avatar,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  DocumentUpload,
  Sms,
  Key,
  Eye,
  EyeSlash,
  Call,
  ProfileCircle,
  Location,
  Calendar,
  People,
  Edit,
} from "iconsax-react";
import { Field, Form, Formik } from "formik";
import { Country, State } from "country-state-city";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { SETUP_PROFILE } from "../../../API/Mutations";
import toast from "react-hot-toast";
import Loader from "../../../common/components/Loader";
import { useSelector } from "react-redux";
import './setUpProfile.css';

const SetupProfilePage = () => {
  const navigate = useNavigate();
  const [proceed, setProceed] = useState(false);
  // const [imageURL, setImageURL] = useState({});
  const { userToken, userInfo } = useSelector((state) => state.authSlice);
  // console.log("asd", userToken, userInfo);
  const emojiArr = [
    emo1,
    emo2,
    emo3,
    emo4,
    emo5,
    emo6,
    emo7,
    emo8,
    emo9,
    emo10,
    emo11,
    emo12,
    emo13,
    emo14,
    emo15,
    emo16,
  ];
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [objectUrl, setObjectUrl] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [dob, setDob] = useState("")

  const [SetupUserProfile, { data, loading, error }] = useMutation(
    SETUP_PROFILE,
    {
      context: {
        headers: {
          Authorization: `${userToken.replace("Bearer ", "")}`,
        },
      },
      onError: (error) => {
        toast.error(error.graphQLErrors[0].message);
      },
    }
  );

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);


  const countries = Country.getAllCountries();

  const initialValues = {
    firstName: userInfo && userInfo.firstName ? userInfo.firstName : "",
    lastName: userInfo && userInfo.lastName ? userInfo.lastName : "",
    email: userInfo && userInfo.email ? userInfo.email : "",
    username: "",
    phoneNumber: "+91",
    country: "",
    state: "",
    dateOfBirth: dob,
    gender: "",
    staticUrl: typeof selectedAvatar === "string" ? objectUrl : "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email("Invalid email address").required(),
    username: Yup.string().required(),
    phoneNumber: Yup.string().matches(/^\+91/, 'Contact number must start with +91'),
    country: Yup.string(),
    state: Yup.string(),
    dateOfBirth: Yup.string(),
    gender: Yup.string(),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    // console.log("avatar", selectedAvatar);
    const values1 = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      phoneNumber: values.phoneNumber,
      country: values.country,
      state: values.state,
      dateOfBirth: dob,
      gender: values.gender,
      staticUrl: values.staticUrl
    };
    // console.log("body1", typeof selectedAvatar)
    // setSelectedAvatar({});
    // setObjectUrl('');

    // console.log({
    //   variables: {
    //     body: values1,
    //     file: typeof selectedAvatar === "object" ? selectedAvatar : "",
    //   },
    // });
    SetupUserProfile({ variables: { body: values1, file: typeof selectedAvatar === "object" ? selectedAvatar : "" } });
  };

  useEffect(() => {
    if (data) {
      navigate("/onboarding");
    }
  }, [data])

  useEffect(() => {
    // Convert the imported image to a Blob and then to an object URL
    if (selectedAvatar !== "object") {
      const blob = new Blob([selectedAvatar], { type: "image/png" });
      // console.log("blob1", blob)
      const url = URL.createObjectURL(blob);
      setObjectUrl(url);
      // Clean up the object URL when the component is unmounted
      return () => URL.revokeObjectURL(url);
    }
  }, [selectedAvatar]);

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-6 h-screen hidden lg:block bg-[#4C4C4C]">
          <img
            src={setupprofile}
            alt="setupprofile"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12  md:justify-self-center lg:w-full h-screen overflow-y-scroll lg:overflow-y-scroll md:overflow-visible">
          <div className="flex flex-wrap flex-col justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16  md:pr-10 sm:pr-4 py-24">
            <Typography className="font-archivo text-3xl font-bold mb-6 flex justify-between">
              Setup your profile{" "}
              {/* <span
                onClick={() => navigate("/onboarding")}
                className="text-base cursor-pointer flex gap-1 items-center"
              >
                Skip
              </span> */}
            </Typography>
            <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
              These details will be shown upfront in your profile.
            </Typography>
            {
              !proceed ? (
                <div className="flex flex-wrap w-full h-full justify-center">
                  <div className="flex flex-wrap w-full h-full">
                    <Typography className="font-archivo text-xl font-medium">
                      Select your avatar
                    </Typography>
                  </div>
                  <div className="flex flex-wrap w-full justify-center">
                    <div className="flex flex-wrap justify-center items-center mt-8 gap-6 max-w-[575px] px-[5%] my-8">
                      {emojiArr.map((x, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-wrap justify-center"
                          >
                            <img
                              src={x}
                              alt="emoji"
                              className={`cursor-pointer transition-all duration-200 ease-in-out transform hover:ring-4 hover:ring-primaryLight-500 hover:scale-110 ${selectedEmoji === index
                                ? "ring-4 ring-primaryLight-500"
                                : ""
                                }`}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                              }}
                              onClick={(e) => {
                                // console.log("ee",e)
                                setSelectedEmoji(index);
                                setSelectedAvatar(x);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex justify-center border-2 border-green-400 text-green-400 py-3 normal-case mb-6 px-4 rounded-lg">
                    <label className="cursor-pointer">
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) =>
                          // console.log(e.target.files[0]),
                          setSelectedAvatar(e.target.files[0])
                        }
                      />
                      <div className="flex flex-wrap justify-center items-center gap-4 ">
                        <DocumentUpload />
                        Upload from computer
                      </div>
                    </label>
                  </div>
                  <div className="flex flex-wrap w-full justify-center">
                    <Button
                      variant="filled"
                      size="lg"
                      color="green"
                      disabled={selectedEmoji == null && selectedAvatar == null}
                      className="py-4 normal-case text-xl w-full max-w-[490px]"
                      onClick={() => {
                        setSelectedEmoji(null);
                        // setSelectedAvatar(null);
                        setProceed(true);
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap flex-col w-full h-full justify-start">
                  {/* <div className="flex flex-wrap flex-col justify-center"> */}
                  <Typography className="font-archivo text-xl font-medium">
                    Join the Revolution!
                  </Typography>
                  <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
                    Be among the first to experience our ground breaking
                    community.
                  </Typography>
                  <div className="flex flex-wrap justify-start items-end max-w-[575px] mb-8">
                    <img
                      src={
                        typeof selectedAvatar === "object"
                          ? URL.createObjectURL(selectedAvatar)
                          : selectedAvatar
                      }
                      alt="avataar"
                      className="w-[100px] h-[100px] rounded-full"
                    />
                    <Edit
                      color="black"
                      size="24"
                      className="relative -left-[25px] bg-textDark-100/75 dark:bg-textDark-100/75 rounded-lg cursor-pointer"
                      onClick={() => (
                        setObjectUrl(''), setSelectedAvatar(null), setProceed(false)
                      )}
                    />
                  </div>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    className="w-full h-full"
                  >
                    {({
                      values,
                      handleChange,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form className="w-full h-full">
                        <div className="flex flex-wrap flex-col lg:flex-row justify-between">
                          <div
                            className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.firstName && touched.firstName
                              ? "border-error-500 animate-shake"
                              : "border-black"
                              }`}
                          >
                            <div className="min-w-[24px] min-h-[24px]">
                              <ProfileCircle
                                className={`${errors.firstName && touched.firstName
                                  ? "text-error-500"
                                  : "text-textLight-400"
                                  }`}
                              />
                            </div>
                            <Field
                              as={Input}
                              variant="standard"
                              label="Enter First Name"
                              placeholder="Enter First Name"
                              name="firstName"
                              className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                              labelProps={{
                                className: "hidden",
                              }}
                            />
                          </div>

                          <div
                            className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.lastName && touched.lastName
                              ? "border-error-500 animate-shake"
                              : "border-black"
                              }`}
                          >
                            <div className="min-w-[24px] min-h-[24px]">
                              <ProfileCircle
                                className={`${errors.lastName && touched.lastName
                                  ? "text-error-500"
                                  : "text-textLight-400"
                                  }`}
                              />
                            </div>
                            <Field
                              as={Input}
                              variant="standard"
                              label="Enter Last Name"
                              placeholder="Enter Last Name"
                              name="lastName"
                              className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                              labelProps={{
                                className: "hidden",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.email && touched.email
                            ? "border-error-500 animate-shake"
                            : "border-black"
                            }`}
                        >
                          <Sms
                            className={`${errors.email && touched.email
                              ? "text-error-500"
                              : "text-textLight-400"
                              }`}
                          />
                          <Field
                            as={Input}
                            variant="standard"
                            label="Enter email address"
                            placeholder="Enter email address"
                            name="email"
                            className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: "hidden",
                            }}
                          />
                        </div>

                        <div
                          className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.username && touched.username
                            ? "border-error-500 animate-shake"
                            : "border-black"
                            }`}
                        >
                          <Typography
                            className={`font-archivo text-xl font-bold ${errors.username && touched.username
                              ? "text-error-500"
                              : "text-textLight-400"
                              }`}
                          >
                            @
                          </Typography>
                          <Field
                            as={Input}
                            variant="standard"
                            label="Enter Username"
                            placeholder="Enter Username"
                            name="username"
                            className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: "hidden",
                            }}
                          />
                        </div>
                        <div
                          className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.phoneNumber && touched.phoneNumber
                            ? "border-error-500 animate-shake"
                            : "border-black"
                            }`}
                        >
                          <Call
                            className={`${errors.phoneNumber && touched.phoneNumber
                              ? "text-error-500"
                              : "text-textLight-400"
                              }`}
                          />
                          <Field
                            as={Input}
                            variant="standard"
                            label="Enter Phone Number"
                            placeholder="Enter Phone Number"
                            name="phoneNumber"
                            className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: "hidden",
                            }}
                            onChange={(e) => {
                              const trimmedValue = e.target.value.trim();
                              const phoneNumberRegex = /^\+91\d*$/;
                              if (!phoneNumberRegex.test(trimmedValue)) {
                                e.preventDefault();
                                return;
                              }
                              handleChange(e);
                            }}
                          />
                        </div>
                        <div className="flex flex-wrap flex-col lg:flex-row justify-between">
                          <div
                            className={`mb-8 flex items-center w-full ${states.length > 0 ? "lg:w-[47%]" : "lg:w-full"
                              } gap-6 border-b-2 ${errors.country && touched.country
                                ? "border-error-500 animate-shake"
                                : "border-black"
                              }`}
                          >
                            <div className="min-w-[24px] min-h-[24px]">
                              <Location
                                className={`${errors.country && touched.country
                                  ? "text-error-500"
                                  : "text-textLight-400"
                                  }`}
                              />
                            </div>
                            <Field
                              as={Select}
                              variant="standard"
                              label={
                                values.country == "" ? "Select Country" : ""
                              }
                              name="country"
                              value={values.country}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("country", e);

                                // Find the selected country object by name
                                const selectedCountry = countries.find(
                                  (country) => country.name === e
                                );

                                if (selectedCountry) {
                                  // Set the selected country ISO code
                                  setSelectedCountry(selectedCountry.isoCode);
                                }
                              }}
                              className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            >
                              {countries.map((country) => (
                                <Option
                                  key={country.isoCode}
                                  value={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Field>
                          </div>

                          {states?.length > 0 && (
                            <div
                              className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.state && touched.state
                                ? "border-error-500 animate-shake"
                                : "border-black"
                                }`}
                            >
                              <div className="min-w-[24px] min-h-[24px]">
                                <Location
                                  className={`${errors.state && touched.state
                                    ? "text-error-500"
                                    : "text-textLight-400"
                                    }`}
                                />
                              </div>
                              <Field
                                as={Select}
                                variant="standard"
                                label={values.state == "" ? "Select State" : ""}
                                name="state"
                                value={values.state}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue("state", e);
                                }}
                                className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:1text-gray-500 placeholder:opacity-100"
                              >
                                {states.map((state) => (
                                  <Option
                                    key={state.isoCode}
                                    value={state.name}
                                  >
                                    {state.name}
                                  </Option>
                                ))}
                              </Field>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-wrap flex-col lg:flex-row justify-between">
                          <div
                            className={`setUpProfile mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.dateOfBirth && touched.dateOfBirth
                              ? "border-error-500 animate-shake"
                              : "border-black"
                              }`}
                          >
                            <div className="min-w-[24px] min-h-[24px]">
                              <Calendar
                                className={`${errors.dateOfBirth && touched.dateOfBirth
                                  ? "text-error-500"
                                  : "text-textLight-400"
                                  }`}
                              />
                            </div>
                            <input type="date" name="dateOfBirth" value={dob} onChange={(e) => setDob(e.target.value)}
                              className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:outline-0 w-full"
                            />
                          </div>

                          <div
                            className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.gender && touched.gender
                              ? "border-error-500 animate-shake"
                              : "border-black"
                              }`}
                          >
                            <div className="min-w-[24px] min-h-[24px]">
                              <People
                                className={`${errors.gender && touched.gender
                                  ? "text-error-500"
                                  : "text-textLight-400"
                                  }`}
                              />
                            </div>
                            <Field
                              as={Select}
                              variant="standard"
                              label={values.gender == "" ? "Gender" : ""}
                              name="gender"
                              value={values.gender}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("gender", e);
                              }}
                              className="!font-archivo border-b-0 text-gray-900 ring-4 ring-transparent placeholder:1text-gray-500 placeholder:opacity-100"
                            >
                              <Option value="male">Male</Option>
                              <Option value="female">Female</Option>
                              <Option value="others">Others</Option>
                            </Field>
                          </div>
                        </div>
                        <Button
                          type="submit"
                          variant="filled"
                          size="lg"
                          color="green"
                          className="py-4 normal-case w-full"
                        >
                          Proceed
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )

              // </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupProfilePage;
