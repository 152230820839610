import { Button, Typography } from "@material-tailwind/react";
import Footer from "../Footer";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const divRef = useRef();

    useEffect(() => {
        divRef?.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <>
            <div ref={divRef} className="max-w-full min-h-screen py-3 md:py-10 bg-[#080808] flex items-center justify-center flex-col">
                <div className="w-full md:w-2/3 md:text-justify px-5 md:px-0">
                    <div className="my-6 flex flex-col-reverse md:flex-row items-start md:items-center justify-between">
                        <Typography className="font-archivo text-white text-2xl md:text-3xl font-bold">Terms and Conditions</Typography>
                        <div className="flex w-full md:w-fit justify-end">
                            <Button
                                onClick={() => navigate(-1)}
                                variant="text"
                                color="green"
                                className="font-archivo text-primaryDark-500 text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
                            >
                                <ArrowLeft size="20" /> Back
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Acceptance of Terms</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Users must agree to these terms to access and use Community.com.
                             Regular review of the terms is recommended as continued use signifies acceptance of any changes.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">User Registration and Account Security</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Users agree to provide truthful information for registration and are responsible for maintaining the security
                             of their account, including password confidentiality.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Course Access, Usage, and Restrictions</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Courses are for personal, educational use only. Users must not copy, distribute, sell, or use the content for any commercial purposes.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Intellectual Property Rights</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Community owns all rights to course materials and site content. Use of the website does not grant any ownership rights to users.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">User Conduct and Prohibitions</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Users agree to use the website responsibly and legally, refraining from any activity that could harm the site or its users.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Financial Terms</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Detailed information about course fees, payment methods, refunds, and any applicable taxes will be provided.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Privacy and Data Usage</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Community's Privacy Policy governs the collection, use, and protection of user data.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Limitations of Liability</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Community limits its liability to the extent permitted by law for any damages arising from the use of the site. </Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Amendments to Terms</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Community reserves the right to modify these terms, with continued use after changes constituting acceptance.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Governing Law and Dispute Resolution</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">These terms are governed by the laws of the specified jurisdiction, and any disputes will be resolved through the designated legal processes.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Contact Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Users can contact Community for any queries related to these terms.</Typography>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default TermsAndConditions;