import React, { useState } from 'react'

const CustomSwitch = () => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <label className='themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          className='sr-only'
        />
        <span
          className={`slider mx-4 flex h-8 w-[60px] items-center rounded-full p-1 duration-200 ${
            isChecked ? 'bg-primaryLight-900  dark:bg-primaryLight-100' : 'bg-primaryLight-100 dark:bg-primaryLight-900'
          }`}
        >
          <span
            className={`dot h-6 w-6 rounded-full duration-200 ${isChecked ? 'translate-x-[28px] bg-primaryLight-100 dark:bg-primaryLight-900' : 'bg-primaryLight-900 dark:bg-primaryLight-100'}`}
          ></span>
        </span>
      </label>
    </>
  )
}

export default CustomSwitch
