import React from "react";
import { useNavigate } from 'react-router-dom';
import {
    Navbar,
    Typography,
    Button,
    IconButton,
    Collapse,
} from "@material-tailwind/react";
import logo from '../../assets/images/rangeUnity.svg';

function StickyNavbar() {
    const [openNav, setOpenNav] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                className="p-1 font-normal text-textLight-50 font-archivo"
            >
                <a href="#" className="flex items-center">
                    About Us
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                className="p-1 font-normal  text-textLight-50 font-archivo "
            >
                <a href="#Subscription" className="flex items-center ">
                    Subscription
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                className="p-1 font-normal text-textLight-50 font-archivo"
            >
                <a href="https://ulearnix.com" target="_blank" className="flex items-center">
                    Ulearnix
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                className="p-1 font-normal  text-textLight-50 font-archivo"
            >
                <a href="https://cyberrange.bhumiitech.com/" target="_blank" className="flex items-center">
                    Rangestorm
                </a>
            </Typography>
        </ul>
    );

    return (
        <>
            <Navbar className="sticky top-0 z-50 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4 bg-[#080808] bg-opacity-100 border-0">
                <div className="flex items-center justify-around text-blue-gray-900">
                    <Typography
                        onClick={()=>navigate("/")}
                        className="mr-4 cursor-pointer py-1.5 font-medium text-primaryDark-500 font-archivo"
                    >
                         <div className="flex flex-row items-center justify-center gap-2"><img src={logo} alt="logo" className="w-[30px] h-[30px]" />Range Unity</div>
                    </Typography>
                    <div className="flex items-center gap-32">
                        <div className="mr-4 hidden lg:flex text-textLight-50">{navList}</div>
                        <div className="flex items-center gap-x-6">
                            <Button
                                variant="filled" color="green"
                                size="sm"
                                className="hidden lg:inline-block rounded-full font-archivo normal-case !bg-primaryDark-500 !tracking-wide"
                                onClick={()=>navigate("/signup")}
                            >
                                <span>Signup</span>
                            </Button>
                            <Button
                                variant="outlined"
                                color="green"
                                size="sm"
                                className="hidden lg:inline-block rounded-full focus:ring-1 font-archivo normal-case !border-primaryDark-500 !text-primaryDark-500 !tracking-wide"
                                onClick={()=>navigate('/login')}
                            >
                                <span>Login</span>
                            </Button>
                        </div>
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="#46E356"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="#46E356"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </div>
                <Collapse open={openNav}>
                    {navList}
                    <div className="flex items-center gap-x-1">
                        <Button fullWidth
                            variant="filled" color="green"
                            size="sm"
                            className="rounded-full font-archivo normal-case"
                            onClick={()=>navigate("/signup")}
                        >
                            <span>Sign up</span>
                        </Button>
                        <Button fullWidth
                            variant="outlined"
                            color="green"
                            size="sm"
                            className="rounded-full focus:ring-1 font-archivo normal-case"
                            onClick={()=>navigate('/login')}
                        >
                            <span>Login</span>
                        </Button>
                    </div>
                </Collapse>
            </Navbar>
        </>

    );
}

export default StickyNavbar;