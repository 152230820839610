import {
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import React, { useState } from "react";
import {
  SearchNormal1,
  UserOctagon,
  GalleryExport,
  CardPos,
  Subtitle,
} from "iconsax-react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const HelpAndSupport = () => {
  const [open, setOpen] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const mostSearchedData = [
    {
      name: "Creating Content",
      pic: <UserOctagon size="32" />,
    },
    {
      name: "Uploading content",
      pic: <GalleryExport size="32" />,
    },
    { name: "Subscription", pic: <CardPos size="32" /> },
    { name: "Contents", pic: <Subtitle size="32" /> },
  ];

  const qaData = [
    {
      question: "What all i can upload in this community?",
      id: 1,
      answer: `We're not always in the position that we want to be at. We're constantly
        growing. We're constantly making mistakes. We're constantly trying to express
        ourselves and actualize our dreams.`,
    },
    {
      question: "What all i can upload in this community?",
      id: 2,
      answer: `We're not always in the position that we want to be at. We're constantly
        growing. We're constantly making mistakes. We're constantly trying to express
        ourselves and actualize our dreams.`,
    },
    {
      question: "What all i can upload in this community?",
      id: 3,
      answer: `We're not always in the position that we want to be at. We're constantly
        growing. We're constantly making mistakes. We're constantly trying to express
        ourselves and actualize our dreams.`,
    },
    {
      question: "What all i can upload in this community?",
      id: 4,
      answer: `We're not always in the position that we want to be at. We're constantly
        growing. We're constantly making mistakes. We're constantly trying to express
        ourselves and actualize our dreams.`,
    },
    {
      question: "What all i can upload in this community?",
      id: 5,
      answer: `We're not always in the position that we want to be at. We're constantly
        growing. We're constantly making mistakes. We're constantly trying to express
        ourselves and actualize our dreams.`,
    },
  ];

  return (
    <div className="w-full h-full my-6">
      <Typography className="text-center font-archivo font-bold text-xl lg:text-3xl text-textLight-400 mb-6 dark:text-textDark-600">
        Hello. how can we help ?
      </Typography>
      <div className="max-w-xl mx-auto">
        <div className="relative flex items-center w-full h-12 rounded-lg shadow-2xl bg-transparent overflow-hidden dark:bg-[#171717]">
          <div className="grid place-items-center h-full w-12 text-gray-200">
            <SearchNormal1 color="#999999" />
          </div>
          <input
            className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
            type="text"
            id="search"
            placeholder="Ask your question"
            value={searchValue}
            onChange={(e) =>
              setSearchValue(
                  e.target.value
                      .replace(/^\s+|[^A-Za-z0-9\s]/g, '')
                      .slice(0, 30)
              )
          }
          />
        </div>
      </div>
      <div className="flex flex-row w-[90vw] md:w-full overflow-auto items-center md:justify-center gap-6 mt-10 mb-10">
        {mostSearchedData.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-wrap flex-col border-2 border-textLight-300 dark:border-textDark-600 rounded-lg py-4 items-center min-w-[170px] hover:cursor-pointer dark:bg-[#171717] hover:!border-primaryLight-500 hover:!text-primaryLight-500 hover:!bg-primaryLight-500/10 dark:text-textDark-600"
            onClick={()=>setSearchValue(item.name)}
            >
              <div>{item.pic}</div>
              <Typography variant="paragraph" className="text-lg font-archivo">
                {item.name}
              </Typography>
            </div>
          );
        })}
      </div>
      <Typography className="text-left font-archivo font-bold text-xl text-textLight-400 mb-6 dark:text-textDark-600">
        Most asked questions
      </Typography>
      <div className="max-w-4xl">
        {qaData.map((item, index) => {
          return (
            <div key={index}>
              <Accordion
                open={open === item.id}
                // icon={<Icon id={item.id} open={open} />}
              >
                <AccordionHeader onClick={() => handleOpen(item.id)}>
                  <Typography className="text-lg font-semibold dark:text-textDark-600">
                    {item.id}. {item.question}
                  </Typography>
                </AccordionHeader>
                <AccordionBody>
                  <Typography className="text-sm font-medium dark:text-textDark-600">
                    {item.answer}
                  </Typography>
                </AccordionBody>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HelpAndSupport;
