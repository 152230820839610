import {
  Avatar,
  Button,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import img from "../../../assets/images/3dicons.png";
import { AddCircle, ArrowDown2, Gallery } from "iconsax-react";
import { useEffect, useState } from "react";
import TextEditor from "../../../common/components/Editor";
import { EditorState } from "draft-js";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { CREATE_GROUP } from "../../../API/Mutations";
import toast from "react-hot-toast";

const CreateGroup = () => {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState("public");
  const { userInfo, userToken } = useSelector((state) => state.authSlice);
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState(
    "https://docs.material-tailwind.com/img/face-2.jpg"
  );
  const [showMembers, setShowMembers] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [length, setLength] = useState(
    editorState?.getCurrentContent().getPlainText("").length
  );
  const [followArr, setFollowArr] = useState(userInfo?.following);
  const [addedMembers, setAddedMembers] = useState([]);

  useEffect(() => {
    setLength(editorState.getCurrentContent().getPlainText("").length);
  }, [editorState]);

  const handleToggleMember = (member) => {
    if (addedMembers.includes(member)) {
      setAddedMembers(addedMembers.filter((m) => m !== member));
    } else {
      setAddedMembers([...addedMembers, member]);
    }
  };

  const [
    CreateGroup,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_GROUP, {
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
  });

  const handleCreateGroup = () => {
    const description = editorState.getCurrentContent().getPlainText("");
    const groupData = {
      name: groupName,
      groupType: groupType,
      description: description,
      members: addedMembers?.map((member) => member?._id),
    };
    CreateGroup({ variables: { input: groupData, file: image && image } });

    navigate("/home/all-messages-default");
  };

  return (
    <>
      <div className="">
        <div className="w-full flex flex-col justify-center items-center">
          <img src={img} className="w-32 h-32" />
          <Typography className="font-archivo font-bold text-2xl text-textLight-500 dark:text-white">
            Create Group
          </Typography>
        </div>

        <div className="flex flex-col gap-4 relative w-fit">
          <Typography className="font-archivo font-bold text-base text-textLight-500 dark:text-white">
            Profile Picture
          </Typography>
          <Avatar
            className="w-32 h-32 "
            src={
              imageURL || "https://docs.material-tailwind.com/img/face-2.jpg"
            }
            alt="avatar"
            size="xxl"
          />
          <label className="cursor-pointer">
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => (
                setImage(e.target.files[0]),
                setImageURL(URL.createObjectURL(e.target.files[0]))
              )}
            />
            <Gallery
              size="30"
              className="absolute bottom-3 right-2 bg-white rounded-xl p-1 shadow-black/30 shadow-md"
            />
          </label>
        </div>

        <div className="!my-6 flex gap-11 w-full">
          <div className="w-full">
            <Typography className="font-archivo font-bold text-base text-[#737373] dark:text-white/80 mb-2">
              Group Name *
            </Typography>
            <Input
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              type="text"
              placeholder="Enter Group Name"
              className="!w-full !p-5 !py-7 !border dark:!border-0 !border-[#6E6D6D] bg-white dark:bg-textLight-700 !text-base !text-[#262525] dark:!text-white !font-medium !font-archivo shadow-none ring-0 ring-transparent placeholder:text-[#262525] placeholder:opacity-100 dark:placeholder:text-white focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              labelProps={{
                className: "hidden",
              }}
              containerProps={{ className: "min-w-[100px]" }}
            />
          </div>
          <div className="w-full">
            <Typography className="font-archivo font-bold text-base text-[#737373] dark:text-white/80 mb-2">
              Group Type *
            </Typography>
            <Select
              size="lg"
              arrow={<ArrowDown2 className="!text-[#262525]" />}
              className="!w-full !p-5 !py-7 !border dark:!border-0 !border-[#6E6D6D] bg-white dark:bg-textLight-700 !text-base !text-[#262525] dark:!text-white !font-medium !font-archivo shadow-none ring-0 ring-transparent placeholder:text-[#262525] placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
              placeholder="Select Version"
              labelProps={{
                className: "hidden",
              }}
              containerProps={{ className: "min-w-[100px] h-14 " }}
              value={groupType}
              onChange={(val) => setGroupType(val)}
              menuProps={{ className: "dark:bg-textLight-800 dark:border-0" }}
            >
              <Option
                className="text-textLight-400 font-rubik font-medium text-sm dark:hover:bg-textLight-200 dark:text-white"
                value="public"
              >
                Public
              </Option>
              <Option
                className="text-textLight-400 font-rubik font-medium text-sm dark:hover:bg-textLight-200 dark:text-white"
                value="private"
              >
                Private
              </Option>
            </Select>
          </div>
        </div>
        <div className="mb-6">
          <Typography className="font-archivo font-bold text-base text-[#737373] dark:text-white/80 mb-2">
            Description *
          </Typography>
          <TextEditor
            editorState={editorState}
            setEditorState={setEditorState}
            length={length}
          />
        </div>
       
        <div className="flex gap-4 mb-6">
          <Typography className="font-archivo font-bold text-base text-[#737373] dark:text-white/80 mb-2">
            Add members{" "}
          </Typography>
          <AddCircle
            onClick={() =>{followArr && followArr?.length > 0 ? setShowMembers(!showMembers) : <>{toast.error("Grow your following list before creating a group!")}</> }}
            className="cursor-pointer text-primaryLight-500 dark:text-primaryDark-500"
          />
        </div>

        {showMembers && (
          <div className="grid gap-x-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full">
            
            {followArr?.map((item, index) => (
              <div
                key={index}
                className="flex gap-2 items-center justify-between cursor-pointer mb-6"
              >
                <div className="flex gap-2 items-center">
                  <Avatar
                    src={
                      item?.avatar
                        ? item?.avatar
                        : "https://docs.material-tailwind.com/img/face-2.jpg"
                    }
                    alt="avatar"
                    size="sm"
                  />
                  <div>
                    <Typography className="text-base font-archivo font-bold text-textLight-500 dark:text-white">
                      {item?.firstName} {item?.lastName}
                    </Typography>
                    <Typography className="text-sm font-rubik font-medium text-textLight-300 dark:text-white/60">
                      @{item?.username}
                    </Typography>
                  </div>
                </div>

                <Button
                  variant="outlined"
                  color={addedMembers.includes(item) ? "red" : "green"}
                  className={
                    !addedMembers.includes(item)
                      ? "dark:border-primaryDark-500"
                      : "dark:border-red-600"
                  }
                  size="sm"
                  onClick={() => handleToggleMember(item)}
                >
                  {addedMembers.includes(item) ? "Remove" : "Add"}
                </Button>
              </div>
            ))}
          </div>
        )}

        <div className="flex w-full items-center justify-end mb-6 gap-4">
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            color="green"
            className="dark:border-primaryDark-500 normal-case font-rubik text-sm font-medium tracking-wide !px-9 rounded-xl"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateGroup}
            variant="filled"
            color="green"
            className="dark:bg-primaryDark-500 normal-case font-rubik text-sm font-medium tracking-wide !px-9 rounded-xl"
            size="sm"
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateGroup;
