import React from 'react';

const PostPoll = ({ getPollVotes }) => {

  // Find the maximum percentage among options
  const maxPercentage =
    Math.max(
      ...Object.keys(getPollVotes)
        ?.filter(
          (x) =>
            x !== "__typename" &&
            x !== "options" &&
            getPollVotes[x] !== null
        )
        ?.map((option) => Math.round(getPollVotes[option]))
    );

  // console.log(maxPercentage, getPollVotes);

  // Determine background colors based on percentages
  const colorArr =
    Object.keys(getPollVotes)
      ?.filter(
        (x) =>
          x !== "__typename" &&
          x !== "options" &&
          getPollVotes[x] !== null
      )
      ?.map((option) =>
        Math.round(getPollVotes[option]) === maxPercentage
          ? "#EBFAEE"
          : "#FFEBEB"
      );

  const textColorArr =
    Object.keys(getPollVotes)
      ?.filter(
        (x) =>
          x !== "__typename" &&
          x !== "options" &&
          getPollVotes[x] !== null
      )
      ?.map((option) =>
        Math.round(getPollVotes[option]) === maxPercentage
          ? "#268E3E"
          : "#FF5C5C"
      );

  const optionObj = Object.keys(getPollVotes)
    .filter(key => key.startsWith("percentageOfOption") && getPollVotes[key] !== null)
    .map((key, index) => {
      const label = getPollVotes.options[`option${index + 1}`];
      const percentage = Math.round(parseFloat(getPollVotes[key]));
      return { label, percentage };
    });

  return (
    <>
      <div className="flex flex-wrap flex-col w-full my-4 rounded-xl text-base font-semibold dark:hidden">
        {optionObj?.map((option, index) => (
          <div
            className='w-full shadow-lg mb-4 rounded-xl cursor-pointer'
            key={index}
            style={{
              backgroundImage: `linear-gradient(to right, ${colorArr[index]} ${option.percentage}%, rgba(255, 255, 255, 0.1) ${option.percentage}%)`,
            }}
          >
            <div className="!break-all flex flex-wrap w-full h-full justify-between p-4 rounded-xl text-base font-semibold" style={{ color: `${textColorArr[index]}` }}>
              <div>{option.label}</div> <div>{option.percentage + "%"}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden dark:flex flex-wrap flex-col w-full my-4 rounded-xl text-base font-semibold">
        {optionObj?.map((option, index) => (
          <div
            className='w-full shadow-lg mb-4 rounded-xl cursor-pointer'
            key={index}
            style={{
              backgroundImage: `linear-gradient(to right, ${textColorArr[index]} ${option.percentage}%, rgba(255, 255, 255, 0.1) ${option.percentage}%)`,
            }}
          >
            <div className="!break-all flex flex-wrap w-full h-full justify-between p-4 rounded-xl text-base font-semibold" style={{ color: `${colorArr[index]}` }}>
              <div>{option.label}</div> <div>{option.percentage + "%"}</div>
            </div>
          </div>
        ))}
      </div>
    </>

  );
};

export default PostPoll;


