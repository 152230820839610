import logo from './logo.svg';
import './App.css';
import Router from './routes';
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginSuccess, setRegisterSuccess, setUserInfo } from './containers/Auth/authSlice';
import { useSocketContext } from './containers/ChatContextBox/ChatContext';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = useSocketContext();  
  const [trackSocketer, setTrackSocketer] = useState([]);
  const { userToken } = useSelector((state) => state?.authSlice);


  useEffect(() => {
    if (socket) {
      socket.on("connection", (tracker) => {
       console.log("connections", tracker)
        // setTrackSocketer((tracker) => [...prevTrackings, tempTracking]);
      });
      return () => {
        socket.off("connection");
      };
    }
  }, [socket, setTrackSocketer]);

  // console.log("trackSocketer", trackSocketer)

  // ------------------ custom default logout handler component (token expiry logout) ----------------------- //
  useEffect(() => {
    if (userToken?.token) {
      let decodedToken = jwtDecode(userToken);
      let currDate = new Date();
      let diff = decodedToken.exp * 1000 - currDate.getTime();
      if (diff > 0) {
        // console.log(diff)
        const timer = setTimeout(() => {
          dispatch(setLoginSuccess(false));
          dispatch(setRegisterSuccess(false));
          dispatch(setUserInfo({}));
          navigate('/login');
        }, diff);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  });
  return (
    <>
      <Router />
    </>
  );
}

export default App;
