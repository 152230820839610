import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import { InfoCircle, ArrowRight2 } from "iconsax-react";
import { IoClose } from "react-icons/io5";
import thumbsup from "../../../assets/images/thumbsup.png";
import { useMutation } from "@apollo/client";
import { REPORT_POST } from "../../../API/Mutations";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const ReportPostBox = ({ type, postId, requestTargetId, reportPostType }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openOther, setOpenOther] = useState(true);
  const [otherText, setOtherText] = useState("");
  const { userToken } = useSelector((state) => state.authSlice)
  const handleOpen = () => setOpen(true);
  const handleConfirmOpen = () => setOpenConfirm(true);
  const handleClose = () => setOpen(false);
  const handleConfirmClose = () => setOpenConfirm(false);
  const reportArray = [
    "I just don't like it",
    "It's spam",
    "Nudity or sexual activity",
    "Hate speech or symbol",
    "False information",
    "Scam or fraud",
    "Copyright Issue",
  ];

  function titleCase(string) {
    return string?.length > 0 && string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  const [reportPost, { data, loading, error }] = useMutation(REPORT_POST, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
      },
    },
  });

  const handleCloseConfirm = (item) => {
    setOpen(false);
    // setOpenConfirm(true);
    if (type === "post") {
      reportPost({
        variables: {
          body: {
            postId: postId,
            reason: item,
            requestTargetId: requestTargetId,
            requestTarget: titleCase(reportPostType)
          }
        }
      })
    }
  };

  useEffect(() => {
    if (data?.reportPost) {
      setOpenConfirm(true)
    }
  }, [data?.reportPost])

  return (
    <>
      <div className="hidden lg:block">
        <Typography
          variant="paragraph"
          className="flex items-center gap-2 text-sm font-rubik text-error-400 font-normal mt-2 text-justify tracking-wide cursor-pointer"
          onClick={handleOpen}
        >
          {/* <div className="flex flex-wrap flex-row gap-1"> */}
            <InfoCircle size="20" /> Report Post
          {/* </div> */}
        </Typography>
      </div>
      <Dialog
        open={open}
        size="md"
        handler={handleOpen}
        className="p-4 rounded-2xl"
      >
        <DialogHeader>
          <div className="flex flex-wrap flex-row items-center justify-between w-full">
            <Typography className="text-textLight-500 text-base font-bold">
              Report
            </Typography>{" "}
            <div className="text-textLight-500">
              <IoClose onClick={handleClose} className="cursor-pointer" />
            </div>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="mb-6">
            <Typography className="text-textLight-500 text-[14px] font-bold">
              Why are you reporting this {type}?
            </Typography>
            <Typography className="text-textLight-100 text-[14px] font-rubik">
              Your report is anonymous, except if you're reporting an
              intellectual property infringement. If someone is in immediate
              danger, call the local emergency services - don't wait.
            </Typography>
          </div>
          {openOther &&
            reportArray.map((item, index) => {
              return (
                <div
                  className="flex flex-wrap flex-row items-center justify-between w-full cursor-pointer bg-[#9D9D9D]/5 p-3 mb-2 hover:shadow-md rounded-xl"
                  key={index}
                  onClick={() => handleCloseConfirm(item)}
                >
                  <Typography className="text-textLight-500 text-base font-archivo font-medium">
                    {item}
                  </Typography>{" "}
                  <div className="text-textLight-500">
                    <ArrowRight2 />
                  </div>
                </div>
              );
            })}
          <div className="flex flex-wrap flex-row items-center justify-between w-full bg-transparent">
            <div
              className="flex flex-wrap flex-row items-center justify-between w-full cursor-pointer bg-[#9D9D9D]/5 p-3 mb-4 hover:shadow-md hover:rounded-xl"
              onClick={() => setOpenOther(!openOther)}
            >
              <Typography className="text-textLight-500 text-base font-archivo font-medium">
                Others
              </Typography>{" "}
              <div className="text-textLight-500">
                <ArrowRight2
                  className={`${!openOther && "rotate-90"
                    } transition-transform`}
                />
              </div>
            </div>
            <div className={`${openOther && "hidden"} w-full`}>
              <Textarea
                value={otherText}
                onChange={(e) => setOtherText(e.target.value)}
                placeholder="Type your message here..."
                variant="outlined"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                className="border bg-white dark:bg-white !border-transparent focus:!border-transparent dark:!border-textLight-400
                            dark:focus:!border-textLight-400 dark:!border text-textLight-400 placeholder:text-textLight-400 dark:text-textLight-500 dark:placeholder:text-textLight-500 font-medium"
              />
              <Button
                variant="gradient"
                onClick={() => {
                  if (type === "post") {
                    reportPost({
                      variables: {
                        body: {
                          postId: postId,
                          reason: otherText,
                          requestTargetId: requestTargetId,
                          requestTarget: titleCase(reportPostType)
                        }
                      }
                    })
                  }
                  setOpenOther(true);
                  setOpen(false);
                }}
                className="normal-case"
              >
                Submit
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={openConfirm}
        size="sm"
        handler={handleConfirmOpen}
        className="p-4 rounded-2xl"
      >
        <DialogHeader>
          <div className="flex flex-wrap flex-row items-center justify-center w-full">
            <div className="flex flex-wrap flex-row items-center justify-end w-full">
              <div className="text-primaryLight-500">
                <IoClose
                  onClick={handleConfirmClose}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <img
              src={thumbsup}
              alt="thumbs up"
              className="h-[179px] w-[179px]"
            />
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-wrap justify-center mb-6">
            <Typography className="text-primaryLight-600 text-2xl font-bold mb-4">
              Thank You
            </Typography>
            <Typography className="text-textLight-100 text-[14px] font-normal text-center">
              Thankyou for letting us know about this {type}. We will surely check
              the {type} and try to resolve your query.
            </Typography>
          </div>
          {/* <div className="flex flex-wrap justify-center">
          <Typography className="text-primaryLight-500 py-2 px-5 text-[14px] font-medium text-center cursor-pointer hover:shadow-lg"  onClick={handleConfirmClose}>
          Go back to home
            </Typography>
          </div> */}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ReportPostBox;
