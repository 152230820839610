import React, { useState } from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Chart2, DocumentText, Edit, Gallery, QuoteDownSquare } from "iconsax-react";
import Blogs from "./Blog";
import { useNavigate } from "react-router";
import Media from "./Media";
import ResearchPaper from "./ResearchPaper";
import Polls from "./Polls";
import Drafts from "./Drafts";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "./activeTabSlice";

const CreatePost = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeTab } = useSelector((state) => state.activeTabSlice);

    //displaying tab content data
    const data = [
        {
            label: "Blog",
            value: "blog",
            icon: <QuoteDownSquare size="18" />,
            el: <Blogs />
        },
        {
            label: "Media",
            value: "media",
            icon: <Gallery size="18" />,
            el: <Media />
        },
        {
            label: "Poll",
            value: "poll",
            icon: <Chart2 size="18" />,
            el: <Polls />
        },
        {
            label: "Research paper",
            value: "researchPaper",
            icon: <DocumentText size="18" />,
            el: <ResearchPaper />
        },
    ];

    const mobileData = [
        {
            label: "Blog",
            value: "blog",
            el: <Blogs />
        },
        {
            label: "Media",
            value: "media",
            el: <Media />
        },
        {
            label: "Poll",
            value: "poll",
            el: <Polls />
        },
        {
            label: "Research paper",
            value: "researchPaper",
            el: <ResearchPaper />
        },
        {
            label: "Drafts",
            value: "drafts",
            el: <Drafts />
        },
    ];
    return (
        <>
            <div className="hidden lg:block  w-full xl:w-4/5 ">
                <Tabs value={activeTab} className="w-full">
                    <TabsHeader className="bg-transparent flex justify-between gap-32" indicatorProps={{
                        className: "bg-primaryLight-500/10 shadow-none !text-primaryLight-700 dark:bg-transparent dark:border-2 dark:border-primaryDark-500",
                    }}
                    >
                        <div className="flex w-full ">
                            {data.map(({ label, value, icon }) => (
                                <Tab key={value} value={value} onClick={() => dispatch(setActiveTab(value))}>
                                    <div className={`flex items-center gap-2 font-archivo font-medium text-lg
                                ${activeTab == value ? "w-fit !text-primaryLight-700 dark:!text-primaryDark-500" : "text-textLight-400 dark:text-white"}
                            `}>
                                        {icon}{label}
                                    </div>
                                </Tab>
                            ))}
                        </div>

                        <div className="p-1" onClick={() => navigate("/home/drafts")}>
                            <Typography type="button" className="text-primaryLight-500 dark:!text-primaryDark-500 font- py-1 px-3 rounded-md hover:bg-primaryLight-500/10 focus:bg-primaryLight-500/10 cursor-pointer w-max h-fit font-archivo normal-case text-lg font-medium flex items-center gap-2">
                                <Edit size="18" />
                                Drafts
                            </Typography>
                        </div>
                    </TabsHeader>
                    <TabsBody className="w-full">
                        {data.map(({ value, el }) => (
                            <TabPanel key={value} value={value} className="px-0">
                                {el}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>

            <div className="block lg:hidden w-full">
                <Tabs value={activeTab} className="">
                    <TabsHeader className="rounded-lg bg-textLight-50 bg-opacity-100 w-[88%] md:w-[95%] fixed z-50 bottom-20 p-0 flex !gap-2" indicatorProps={{
                        className:
                            "bg-transparent shadow-none rounded-none",
                    }}
                    >
                        <div className="flex items-center justify-center w-full">
                            {mobileData?.map(({ label, value }) => (
                                <Tab key={value} value={value} onClick={() => dispatch(setActiveTab(value))} className="p-2 w-fit">
                                    <div className={`flex font-archivo font-medium text-sm p-0
                                ${activeTab == value ? "!text-textLight-500 border-b-2 border-textLight-500" : "text-textLight-200"}
                            `}>
                                        {label}
                                    </div>
                                </Tab>
                            ))}
                        </div>
                    </TabsHeader>
                    <TabsBody className="w-full">
                        {mobileData.map(({ value, el }) => (
                            <TabPanel key={value} value={value} className="px-0">
                                {el}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    );
}

export default CreatePost;