import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-tailwind/react';
import { Provider } from 'react-redux';
import { history, persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import ErrorBoundary from './ErrorBoundary';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Loader from './common/components/Loader';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { SocketProvider } from './containers/ChatContextBox/ChatContext';


const uploadLink = createUploadLink({
  uri: 'https://rangeunitybackend.bhumiitech.com/graphql', // Apollo Server is served from port 8000
  // uri:  'http://192.168.0.173:8000/graphql', // Apollo Server is served from port 8000
  // uri:  'http://192.168.0.159:8000/graphql', // Apollo Server is served from port 8000
  headers: {
    "keep-alive": "true"
  }
})

const client = new ApolloClient({
  uri: 'https://rangeunitybackend.bhumiitech.com/graphql', //graphql server url
  // uri: 'http://192.168.0.174:8000/graphql', //graphql server url
  // uri: 'http://192.168.0.159:8000/graphql', //graphql server url
  // uri: 'http://192.168.0.173:8000/graphql', //graphql server url
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: uploadLink,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ErrorBoundary>
          <Suspense fallback={<Loader minHeight="90vh" />}>
            <ThemeProvider>
              <ApolloProvider client={client}>
                <SocketProvider>
                  <App />
                </SocketProvider>
              </ApolloProvider>
            </ThemeProvider>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();