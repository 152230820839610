import { Typography, Button } from "@material-tailwind/react";
import { FaArrowRight } from "react-icons/fa6";
import  SubscriptionCard  from "../../../common/components/SubscriptionCard";
import  UpdatesCards from "../../../common/components/UpdatesCards";
import CommunityCard from "../../../common/components/CommunityCard";
import Testimonials from "../../../common/components/Testimonials";

const LandingPage = () => {
  return (
    <div className="max-w-full bg-[#080808]">
    <div className="relative max-w-full h-screen overflow-hidden">
      <iframe src='https://my.spline.design/tvatimedoor-113b958cfae4ebd45e185a09e4e386be/' className="z-0" width='150%' height='90%'></iframe>
        <div className={"absolute top-1/3 md:px-24 sm:px-4 z-10 align-middle text-textLight-50 mx-2"}>
          <Typography variant="h1" className="text-left font-archivo mb-5">
          Your <span className="text-primaryDark-500 text-6xl">GATEWAY</span> <br/>
          To Your <br/>
          <span className="text-primaryDark-500 text-6xl">FUTURE</span> Insights
          </Typography>
          <Button className="text-primaryDark-500 w-full border-2 border-primaryDark-500 text-center align-middle justify-center font-sans normal-case flex items-center gap-3">
            Join Now<FaArrowRight  />
          </Button>
        </div>
    </div>
        <div className="p-4">
        <Typography variant="h1" className="text-center font-archivo text-primaryDark-500 text-4xl" >What makes us stand out</Typography>
        <Typography variant="paragraph" className="text-center font-archivo  text-primaryDark-100" >We offer multiple benefits for our users which makes us standout in the market</Typography>
     <div className="flex item-center justify-center mt-8">
        <UpdatesCards />
     </div>
        </div>
        {/* <div className="p-4" id="Subscription">
        <Typography variant="h1" className="text-center font-archivo text-primaryDark-500 text-4xl" >Our Subscription</Typography>
        <Typography variant="paragraph" className="text-center font-archivo  text-primaryDark-100" >We offer multiple subscription model, so that user can choose according to their interest</Typography>
     <div className="flex item-center justify-center mt-8">
        <SubscriptionCard />
     </div>
        </div> */}
     <div className="mt-8">
     <Typography variant="h1" className="text-center font-archivo text-primaryDark-500 text-3xl pb-5" >Join one of the best community.</Typography>
     <div className="flex item-center justify-center pb-20">
        <CommunityCard />
     </div>
     </div>
     <div className="p-4">
        <Typography variant="h1" className="text-center font-archivo text-primaryDark-500 text-4xl" >Our Testimonials</Typography>
        <Typography variant="paragraph" className="text-center font-archivo  text-primaryDark-100" >What our valuable users say about us</Typography>
     <div className="flex item-center justify-center mt-8">
        <Testimonials />
     </div>
        </div>
    </div>
  );
};

export default LandingPage;
