import { Avatar, Typography } from "@material-tailwind/react";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { IoIosShareAlt } from "react-icons/io";
import { ArrowLeft, Dislike, Like1, MessageText } from "iconsax-react";
import { Swiper } from 'swiper/react';
import 'swiper/css';
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../containers/Pages/Chat/useOnClickOutside";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import video from '../../../assets/videos/video1.mp4';
import './index.css';
import { useMutation, useQuery } from "@apollo/client";
import { GET_REELS } from "../../../API/Queries";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import { DISLIKE_REEL, LIKE_REEL } from "../../../API/Mutations";
import toast from "react-hot-toast";

const IndividualReel = () => {
    ; const navigate = useNavigate();
    const { userToken, userInfo } = useSelector((state) => state.authSlice);
    const [showComments, setShowComments] = useState(null);
    const commentRef = useRef();
    const videoRef = useRef(null);
    const [muted, setIsMuted] = useState(true);
    const [currentReel, setCurrentReel] = useState(null)
    useOnClickOutside(commentRef, () => setShowComments(null));

    const { data, loading, error } = useQuery(GET_REELS, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message);
        },
        context: {
            headers: {
              Authorization: `${userToken?.replace("Bearer ", "")}`, //API authentication
            }
          }
    });

    const [likeReel, { data: likeData, loading: likeLoading, error: likeError }] = useMutation(
        LIKE_REEL,
        {
            context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`,
                },
            },
            onError: (error) => {
                toast.error(error.graphQLErrors[0].message);
            },
            refetchQueries: [{ query: GET_REELS }]
        }
    );

    const [dislikeReel, { data: dislikeData, loading: dislikeLoading, error: dislikeError }] = useMutation(
        DISLIKE_REEL,
        {
            context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`,
                },
            },
            onError: (error) => {
                toast.error(error.graphQLErrors[0].message);
            },
            refetchQueries: [{ query: GET_REELS }]
        }
    );

    const comments = [
        { name: "Mathews", comment: " Try platforms like Hack The Box, TryHackMe, and OverTheWire to practice and learn. read write-ups of CTF challenges to understand different approaches and techniques" },
        { name: "Zayn", comment: " Try platforms like Hack The Box, TryHackMe, and OverTheWire to practice and learn. read write-ups of CTF challenges to understand different approaches and techniques" },
        { name: "Mathews", comment: " Try platforms like Hack The Box, TryHackMe, and OverTheWire to practice and learn. read write-ups of CTF challenges to understand different approaches and techniques" },
    ]

    const handleMuted = (index) => {
        if (currentReel == index) {
            setIsMuted(false)
        } else {
            setIsMuted(true)
        }
    }

    // console.log(data?.fetchReels)

    if (loading || likeLoading || dislikeLoading) return <Loader minHeight="100vh" />;

    return (
        <>
            <div className="h-[calc(100vh-200px)] mt-6 lg:mt-0 mb-6 md:h-screen flex flex-col gap-6 pr-0 xl:pr-32">
                <Swiper
                    slidesPerView="1"
                    spaceBetween={100}
                    mousewheel={true}
                    direction="vertical"
                    modules={[Pagination, Navigation, Keyboard]}
                    keyboard={{ enabled: true }}
                    pagination={{ clickable: true }}
                    navigation={true}
                    className="mySwiper w-full h-full"
                >
                    {data?.fetchReels?.map((reel, index) => (
                        <SwiperSlide key={index} className="h-fit ">
                            <div className="flex justify-between items-center ">
                                <div className="flex gap-2 items-center">
                                    <ArrowLeft size="17" className="block md:hidden dark:text-white text-textLight-400 cursor-pointer" onClick={() => navigate(-1)} />
                                    <Avatar className="w-10 h-10"
                                        src={reel?.author?.avatar}
                                        alt="avatar"
                                    />
                                    <div className="">
                                        <Typography className="text-base md:text-2xl font-archivo font-bold dark:text-white text-textLight-400">{reel.title}</Typography>
                                        <Typography className="hidden md:block text-xs font-rubik text-textLight-200 dark:text-white font-medium">12/05/2024</Typography>
                                    </div>
                                </div>
                                <PiDotsThreeOutlineVerticalBold className="hidden md:block text-textLight-400 dark:text-white" />
                            </div>

                            <div className="flex items-end justify-center w-full gap-16 mt-6 relative">
                                <div className="w-96 h-[550px] relative">
                                    <div className="" onMouseEnter={() => (
                                        setCurrentReel(index),
                                        handleMuted(index)
                                    )}
                                    >
                                        <ReactPlayer
                                            ref={videoRef}
                                            url={reel?.videoUrl?.url}
                                            loop
                                            className="react-player"
                                            playing={true}
                                            volume={1}
                                            muted={muted}
                                            width="100%"
                                            height="100%"
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: "nofullscreen",
                                                    },
                                                },
                                            }}
                                        />
                                    </div>

                                    {/* --------------------for mobile screen----------- */}
                                    <div className="absolute bottom-[10%] right-0 mt-6 flex flex-col gap-4 pr-4 md:hidden items-center">
                                        <div className={`flex flex-col items-center gap-1 ${reel?.likes?.some((x) => x == userInfo?._id) ? "text-primaryLight-500" : "text-textLight-400"} cursor-pointer`} onClick={() => likeReel({ variables: { likeReelId: reel?._id } })}>
                                            <div className="rounded-full bg-white shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-2">
                                                <Like1 size="18" />
                                            </div>
                                        </div>
                                        <div className={`flex flex-col items-center gap-1 ${reel?.dislikes?.some((x) => x == userInfo?._id) ? "text-error-500" : "text-textLight-400"} cursor-pointer`} onClick={() => dislikeReel({ variables: { dislikeReelId: reel?._id } })}>
                                            <div className="rounded-full bg-white shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-2">
                                                <Dislike size="18" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-1 text-textLight-400 cursor-pointer" onClick={() => setShowComments(index)}>
                                            <div className="rounded-full bg-white shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-2">
                                                <MessageText size="18" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-1 text-textLight-400 cursor-pointer">
                                            <div className="rounded-full bg-white shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-2">
                                                <IoIosShareAlt size="18" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* ---------------------open reel comments------------------ */}
                                    {showComments == index &&
                                        <div ref={commentRef} className="animate-slideInUp absolute bottom-0 p-4  bg-[#F3F3F3]/95 dark:bg-[#212121]/95">
                                            <div className="flex gap-2 items-center">
                                                <Avatar
                                                    src="https://docs.material-tailwind.com/img/face-2.jpg"
                                                    alt="avatar"
                                                    size="xs"
                                                />
                                                <input
                                                    placeholder="Write your comment"
                                                    className="py-2.5 tracking-wide w-full text-sm font-archivo bg-white dark:bg-textLight-700 text-black
                                                     dark:text-white rounded-md pl-2 focus:outline-none focus:text-gray-900  placeholder:text-textLight-400 placeholder:opacity-100 dark:placeholder:text-white"
                                                />
                                            </div>
                                            <div className="mt-3 pr-4 dark:bg-transparent h-64 overflow-auto [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-track]:bg-[#F3F3F3] [&::-webkit-scrollbar-thumb]:rounded-full
                                         [&::-webkit-scrollbar-thumb]:bg-textLight-300 dark:[&::-webkit-scrollbar-track]:bg-[#212121] dark:[&::-webkit-scrollbar-thumb]:bg-[#F3F3F3]">
                                                {/* ----------------comment--------------- */}
                                                {comments?.map((comment) => (
                                                    <div className="mt-3 flex gap-2">
                                                        <Avatar
                                                            src="https://docs.material-tailwind.com/img/face-2.jpg"
                                                            alt="avatar"
                                                            size="xs"
                                                        />

                                                        <div className="">
                                                            <Typography
                                                                variant="h6"
                                                                className="font-archivo text-textLight-400 dark:text-white tracking-wide"
                                                            >
                                                                {comment.name}
                                                            </Typography>
                                                            <Typography
                                                                variant="paragraph"
                                                                className="font-rubik text-textLight-400 tracking-wide dark:text-white font-normal text-xs mt-2 text-justify"
                                                            >
                                                                {" "}
                                                                {comment.comment}
                                                            </Typography>
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex gap-4 mt-2">
                                                                    <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                                                        <Like1 size="17" />
                                                                        <Typography className="text-xs font-rubik font-medium">
                                                                            Like
                                                                        </Typography>
                                                                    </div>
                                                                    <div className="flex items-center gap-1 text-primaryLight-500 dark:text-primaryDark-500 cursor-pointer">
                                                                        <IoIosShareAlt />
                                                                        <Typography className="text-xs font-rubik font-medium">
                                                                            Reply
                                                                        </Typography>
                                                                    </div>
                                                                </div>

                                                                <div className="flex items-center gap-1 text-textLight-300 dark:text-primaryDark-500 cursor-pointer">
                                                                    <Typography className="text-sm font-rubik font-medium">
                                                                        (3 min ago)
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    }

                                    <div className={`animate-bounce absolute -bottom-16 left-40 bg-black bg-opacity-50 rounded-full p-4 flex flex-col items-center ${index == 0 ? "hidden xl:visible" : "hidden"}`}>
                                        <MdKeyboardDoubleArrowUp className="text-white" style={{ fontSize: '16px' }} />
                                        {/* <Typography className="text-white">Swipe Up</Typography> */}
                                    </div>
                                </div>

                                {/* ----------------------reel like share comment menu for tab and above screens--------------------- */}
                                <div className="mt-6 hidden md:flex  items-center justify-between">
                                    <div className="flex flex-col gap-4">
                                        <div className={`flex flex-col items-center gap-1 ${reel?.likes?.some((x) => x == userInfo?._id) ? "text-primaryLight-500" : "text-textLight-400 dark:text-primaryDark-500"}  cursor-pointer`}
                                            onClick={() => likeReel({ variables: { likeReelId: reel?._id } })}
                                        >
                                            <div className="rounded-full shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-3">
                                                <Like1 variant={reel?.likes?.some((x) => x == userInfo?._id) ? "Bold" : "Linear"} size="24" />
                                            </div>
                                            <Typography className="text-sm font-archivo font-medium">
                                                Like ({reel?.likes?.length})
                                            </Typography>
                                        </div>
                                        <div className={`flex flex-col items-center gap-1 ${reel?.dislikes?.some((x) => x == userInfo?._id) ? "text-error-500 dark:text-error-600" : "text-textLight-400 dark:text-primaryDark-500"}  cursor-pointer`}
                                            onClick={() => dislikeReel({ variables: { dislikeReelId: reel?._id } })}
                                        >
                                            <div className="rounded-full shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-3">
                                                <Dislike variant={reel?.dislikes?.some((x) => x == userInfo?._id) ? "Bold" : "Linear"} size="24" />
                                            </div>
                                            <Typography className="text-sm font-archivo font-medium">
                                                Dislike ({reel?.dislikes?.length})
                                            </Typography>
                                        </div>
                                        <div className="flex flex-col items-center gap-1 text-textLight-400 dark:text-primaryDark-500 cursor-pointer" onClick={() => setShowComments(index)}>
                                            <div className="rounded-full shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-3">
                                                <MessageText size="22" />
                                            </div>
                                            <Typography className="text-sm font-archivo font-medium">
                                                Comment
                                            </Typography>
                                        </div>
                                        <div className="flex flex-col items-center gap-1 text-textLight-400 dark:text-primaryDark-500 cursor-pointer">
                                            <div className="rounded-full shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] p-3">
                                                <IoIosShareAlt size="22" />
                                            </div>
                                            <Typography className="text-sm font-archivo font-medium">
                                                Share
                                            </Typography>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>



        </>
    )
}

export default IndividualReel;