import { Typography } from '@material-tailwind/react'
import React from 'react'

const UpdatesCards = () => {
    const dummyCardData = [
        {
            title:"Early Updates on Cyber Range",
            description: "Stay updated with our Rangestorm community. Get to know about the new trends from the experts."
        },
        {
            title:"Connections",
            description: "Connect with people around the world who are of same domain. Connect, talk and boost your learning from their experiences."
        },
        {
            title:"Early access to courses",
            description: "Get early access to trending courses on ulearnix. Also avail special discounts on your every course purchase."
        }
    ]
  return (
    <div className='flex flex-wrap justify-center gap-2'>
    {dummyCardData.map((x, index) => {
        return (
    <div key={index} className="mt-6 text-gray-700 bg-transparent shadow-md bg-clip-border rounded-xl w-96">
  <div
    className="h-56 m-4 overflow-hidden  shadow-lg bg-clip-border">
    <img
      src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=80"
      alt="card-image" />
  </div>
  <div className="p-6">
    <Typography className="mb-2 font-archivo text-2xl antialiased font-semibold leading-snug tracking-normal text-textDark-50">
    {x?.title}
    </Typography>
    <Typography className="font-archivo text-sm antialiased font-light leading-relaxed text-textDark-50">
    {x?.description}
    </Typography>
  </div>
</div>  
        )
    })}
    </div>
  )
}

export default UpdatesCards;