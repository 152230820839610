import React, { useEffect, useState } from "react";
import otpverificationimg from "../../../assets/images/otpverification1.svg";
import { ArrowLeft, Sms } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Input, Button } from "@material-tailwind/react";
import OTPInput from "react-otp-input";
import { useMutation } from "@apollo/client";
import { SUBMIT_OTP_FOR_FORGET_PASSWORD } from "../../../API/Mutations";
import toast from "react-hot-toast";
import Loader from "../../../common/components/Loader";

const OtpVerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location?.state;

  const [SubmitOtp, { data, loading, error }] = useMutation(SUBMIT_OTP_FOR_FORGET_PASSWORD, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    }
  });

  const [otp, setOtp] = useState('');

  const handleSubmit = () => {
    const body = {
      email: email,
      otp: otp
    }
    SubmitOtp({ variables: { body: body } })
  }

  useEffect(() => {
    if (data) {
      // console.log(data);
      toast.success("OTP submitted successfully");
      navigate("/change-password", { state: { email: email, otp: otp } })
    }
  }, [data])

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-6 h-screen hidden lg:block">
          <img
            src={otpverificationimg}
            alt="otpverificationimg"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 md:justify-self-center lg:w-full">
          <div
            className="flex flex-wrap flex-col justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16  md:pr-10 sm:pr-4 lg:w-full md:w-full"
            style={{ height: "100%" }}
          >
            <div className="flex w-full float-right justify-end items-center">
              <Button
                onClick={() => navigate(-1)}
                variant="text"
                color="green"
                className="font-archivo text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
              >
                <ArrowLeft size="20" /> Back
              </Button>
            </div>
            <Typography className="font-archivo text-3xl font-bold mb-8">
              OTP Verification
            </Typography>
            <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
              We've sent you a verification code on your registered email.{" "}
              <br />
              <span className="text-black">{email}</span>
            </Typography>
            {/* <div className="flex items-center gap-3 mb-4"> */}
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              // renderSeparator={<span>{"-"}</span>}
              containerStyle={"w-2/3 gap-6 mb-4"}
              renderInput={(props) => <input {...props}
                // type="number"
                className="!w-1/4 h-16 text-center text-2xl font-bold text-black bg-slate-100 border-2 border-textLight-200 hover:border-slate-200 appearance-none rounded-xl p-4 outline-none focus:bg-white focus:border-primaryLight-500 focus:ring-0"
                pattern="\d*"
                maxLength="1"
              />}
            />
            <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
              Didn't received code?{" "}
              <span className="text-supportLight-500 font-bold cursor-pointer">
                Resend Code
              </span>
            </Typography>

            <Button
              variant="filled"
              size="lg"
              color="green"
              className="py-4 normal-case max-w-[490px]"
              onClick={() => handleSubmit()}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerificationPage;
