import { gql } from "@apollo/client";

//gte domains for onboarding
export const GET_DOMAINS = gql`
  query GetDomains {
    getDomains {
      _id
      name
    }
  }
`;

//get reels list
export const GET_REELS = gql`
  query FetchReels {
    fetchReels {
      _id
      title
      description
      likes
      dislikes
      videoUrl {
        _id
        key
        url
      }
      author {
        firstName
        lastName
        avatar
        _id
        staticUrl
        username
      }
    }
  }
`;

//get blogs list
export const GET_BLOGS = gql`
  query GetBlogs {
    getBlogs {
      author
      contentImage {
        _id
        url
      }
      description
      message
      success
      title
    }
  }
`;
// Get Followers And Followings
export const GET_FOLLOWERS_AND_FOLLOWINGS = gql`
  query GetFollowersAndFollowings($userId: ID!) {
    getFollowersAndFollowings(userId: $userId) {
      followers {
        data {
          firstName
          email
          avatar
          lastName
        }
      }
    }
  }
`;

// Get Follow Requests
export const GET_FOLLOW_REQUEST = gql`
query GetFollowRequests($receiverId: String!) {
  getFollowRequests(receiverId: $receiverId) {
    sender {
      _id
      email
      avatar
      lastName
      firstName
      username
    }
  }
}
`;

// Get feed with all types of posts
export const GET_FEED = gql`
query GetAllPost {
  getAllPost {
    _id
    author {
      _id
      avatar
      firstName
      followers
      following
      lastName
      staticUrl
      username
    }
    blogId
    comments {
      createdAt
      postId
      postedBy {
        firstName
        lastName
        _id
        avatar
        username
      }
      tagged {
        username
        lastName
        firstName
        avatar
        _id
      }
      text
    }
    contentImage {
      url
      createdAt
      _id
    }
    contentPdf {
      url
      createdAt
      _id
    }
    contentVideo {
      url
      createdAt
      _id
    }
    count
    createdAt
    description
    mediaId
    options {
      option4
      option3
      option2
      option1
    }
    pollId
    question
    researchPaperId
    title
    type
    researchPaperPdf {
      url
      createdAt
      _id
    }
  }
}
`;

//get poll votes
export const GET_POLL_VOTES = gql`
query GetPollVotes($pollId: ID!) {
  getPollVotes(pollId: $pollId) {
    percentageOfOption1
    percentageOfOption2
    percentageOfOption3
    percentageOfOption4
     options {
      option4
      option3
      option2
      option1
    }
  }
}
`;

//get all posts of an individual
export const GET_ALL_POSTS_OF_INDIVIDUAL = gql`
query Query($authorId: ID) {
  getAllPostOfAnIndividual(authorId: $authorId) {
    _id
    author {
      _id
      avatar
      firstName
      followers
      following
      lastName
      staticUrl
      username
    }
    blogId
    isScheduled
    scheduledTime
    contentImage {
      _id
      createdAt
      url
    }
    contentPdf {
      _id
      createdAt
      url
    }
    contentVideo {
      _id
      createdAt
      url
    }
    count
    createdAt
    description
    mediaId
    options {
      option1
      option2
      option3
      option4
    }
    pollId
    question
    researchPaperId
    researchPaperPdf {
      _id
      createdAt
      url
    }
    type
    title
}
    }
`;

// Get all conversations for an individual

export const GET_CONVERSATIONS_OF_INDIVIDUAL = gql`
  query GetConversations {
    getConversations {
      _id
      participants {
        firstName
        lastName
        avatar
        id
        username
      }
      messages {
        message
      }
    }
  }
`;

// Get conversation with individual

export const GET_CONVERSATION_WITH_PARTICULAR_USER = gql`
  query GetConversation($getConversationId: ID!) {
    getConversation(id: $getConversationId) {
      messages {
        message
        senderId
      }
    }
  }
`;


// Get groups names of particular users
export const GET_GROUPS_OF_INDIVIDUAL = gql`
query GetGroupForMembers {
  getGroupForMembers {
    id
    name
    profileImage
    messages {
      message
    }
  }
}
`;

// Get group chat data of particular group
export const GET_GROUP_DATA_OF_INDIVIDUAL = gql`
query GetGroup($groupId: ID!) {
  getGroup(groupId: $groupId) {
    id
    profileImage
    name
    creator
    description
    groupType
    admin {
      firstName
      lastName
      avatar
      staticUrl
    }
    members {
      firstName
      lastName
      avatar
      staticUrl
      username
      id
    }
    messages {
      senderId {
        id
        firstName
        lastName
        avatar
        username
        staticUrl
      }
      message
    }
  }
}
`;

//platform search 
export const PLATFORM_SEARCH = gql`
query PlatformSearch($key: String) {
  platformSearch(key: $key) {
    group {
      _id
      groupType
      name
      profileImage
      description
      members {
        firstName
      }
    }
    people {
      firstName
      lastName
      username
      avatar
      _id
    }
    post {
      author {
        firstName
        lastName
      }
      _id
      blogId
      comments {
        _id
        createdAt
        postId
        postedBy {
          firstName
          lastName
        }
        replies {
          _id
          createdAt
          parentComment
          postedBy {
            username
            lastName
            firstName
            avatar
            _id
          }
          tagged {
            _id
            avatar
            firstName
            lastName
            username
          }
          text
        }
        tagged {
          username
          lastName
          firstName
          avatar
          _id
        }
        text
      }
      contentImage {
        url
        createdAt
        _id
      }
      contentPdf {
        url
        createdAt
        _id
      }
      contentVideo {
        url
        createdAt
        _id
      }
      count
      createdAt
      description
      mediaId
      options {
        option4
        option3
        option2
        option1
      }
      pollId
      question
      researchPaperId
      researchPaperPdf {
        url
        _id
        createdAt
      }
      title
      type
    }
  }
}
`;
// Get all chats notification for platform
export const GET_ALL_NOTIFICATION = gql`
query GetNotificationForUser {
  getNotificationForUser {
    notificationData
    read
    notificationIds
    type
  }
}
`
// Get all chats notification for platform
export const GET_ALL_NOTIFICATION_FOR_MENTIONS = gql`
query Query {
  getMentionsNotification {
    notificationData
    read
    id
    type
  }
}
`
// get mentioned posts
export const GET_MENTIONED_POSTS = gql`
query GetMentionedPost {
  getMentionedPost {
    archived
    author {
      firstName
      lastName
      username
      staticUrl
      avatar
    }
    contentImage {
      _id
      createdAt
      url
    }
    contentPdf {
      _id
      createdAt
      url
    }
    contentVideo {
      _id
      createdAt
      url
    }
    count
    createdAt
    description
    flagCount
    options {
      option4
      option3
      option2
      option1
    }
    question
    researchPaperPdf {
      url
      createdAt
      _id
    }
    title
    type
    _id
    blogId
    mediaId
    pollId
    researchPaperId
  }
}
`;

//get saved posts
export const GET_SAVED_POSTS = gql`
query Query {
  getSavedPost {
    _id
    archived
    author {
      firstName
      lastName
      staticUrl
      username
      avatar
    }
    blogId
    contentImage {
      url
      createdAt
      _id
    }
    contentPdf {
      url
      createdAt
      _id
    }
    contentVideo {
      url
      createdAt
      _id
    }
    count
    createdAt
    description
    flagCount
    mediaId
    options {
      option4
      option3
      option2
      option1
    }
    pollId
    question
    researchPaperId
    researchPaperPdf {
      url
      createdAt
      _id
    }
    title
    type
  }
}
`;

//get suggested users
export const GET_SUGGESTED_USERS = gql`
query GetSuggestedUsers {
  getSuggestedUsers {
    avatar
    firstName
    lastName
    _id
    staticUrl
    username
    followers {
      _id
    }
  }
}
`;

//get suggested groups
export const GET_SUGGESTED_GROUPS = gql`
query GetSuggestedGroups {
  getSuggestedGroups {
    _id
    members {
      _id
    }
    name
  }
}
`;

//get trending topics
export const GET_TRENDING_TOPICS = gql`
query GetTrendingTopics {
  getTrendingTopics {
    _id
    description
    image
    title
  }
}`;

// get streaks
export const USER_STREAK = gql`
query GetUserStreaks {
getUserStreaks{
    _id
    users {
     firstName
    }
    date
    activity
    count
    days
}
}

`;

// get points allocation
export const POINTS_ALLOCATION = gql`
query Query {
  pointsAllocation
}
`;