import {
  Typography,
  Stepper,
  Step,
  Button,
  Card,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { RxSewingPinFilled } from "react-icons/rx";
import level1 from "../../../assets/badges/level1.png";
import level2 from "../../../assets/badges/level2.png";
import level3 from "../../../assets/badges/level3.png";
import level4 from "../../../assets/badges/level4.png";
import level5 from "../../../assets/badges/level5.png";
import level6 from "../../../assets/badges/level6.png";
import level7 from "../../../assets/badges/level7.png";
import level8 from "../../../assets/badges/level8.png";
import level9 from "../../../assets/badges/level9.png";
import cap from "../../../assets/images/cap.png";
import tshirt from "../../../assets/images/tshirt.png";
import keychain from "../../../assets/images/keychain.png";
import bottle from "../../../assets/images/bottle.png";
import bag from "../../../assets/images/bag.png";
import pen from "../../../assets/images/pen.png";
import orange_percen from "../../../assets/images/orange%.png";
import blue_percen from "../../../assets/images/blue%.png";
import green_percen from "../../../assets/images/green%.png";
import {
  ArrowDown2,
  CardReceive,
  TicketExpired,
  WalletMinus,
} from "iconsax-react";
import "./index.css";
import { useNavigate } from "react-router";
import { USER_STREAK } from "../../../API/Queries";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

const Rewards = () => {
  const navigate = useNavigate();
  const { userToken, userInfo } = useSelector((state)=> state.authSlice);

  const [activeStep, setActiveStep] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [weekActiveStep, setWeekActiveStep] = useState(3);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  //dummy product list
  const productList = [
    {
      name: "Merch Cap",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: cap,
    },
    {
      name: "Merch Tshirt",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: tshirt,
    },
    {
      name: "Keychain",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: keychain,
    },
    {
      name: "Merch Bottle",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: bottle,
    },
    {
      name: "Merch Backpack",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: bag,
    },
    {
      name: "Merch Pen",
      ogPoints: 1500,
      points: 1000,
      description: "Get a Merchandize cap with your earned points now!",
      img: pen,
    },
  ];

  //dummy ulearnix coupon list
  const discountList = [
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
    {
      title: "20% Discount on Ulearnix ",
      description:
        "Get Ulearnix courses on discounted amount with community points.",
      points: 1500,
    },
  ];

  //colors to be reapeated on cards
  const colors = [
    { bgColor: "#FDF8E6", textColor: "#D8A600", bgImg: orange_percen },
    { bgColor: "#EBEFFC", textColor: "#3155CD", bgImg: blue_percen },
    { bgColor: "#EBFAEE", textColor: "#31B64F", bgImg: green_percen },
  ];
  // Week stepper
  const weekStepper = [
    { weekDay: "Mon"},
    { weekDay: "Tue"},
    { weekDay: "Wed"},
    { weekDay: "Thu"},
    { weekDay: "Fri"},
    { weekDay: "Sat"},
    { weekDay: "Sun"},
  ];

  const steps = [
    { label: "Level 1", points: 0, img: level1, users: [1200, 2450, 1500] },
    {
      label: "Level 2",
      points: 2500,
      img: level2,
      users: [3000, 2700, 3900, 3300],
    },
    { label: "Level 3", points: 4000, img: level3 },
  ];

    // get all mention notification for user
    const {
        data: userStreak,
        loading: userStreakLoading,
        error: userStreakError,
        refetch: refetchuserStreak, // Added refetch function
      } = useQuery(USER_STREAK, {
        context: {
          headers: {
            Authorization: `${userToken?.replace("Bearer ", "")}`,
          },
        },
        onError:(error)=> {
          console.log(error.graphQLErrors[0]?.message || "An error occurred");
        },
      });

//       useEffect(()=>{
// if (userStreak){
//     console.log("userStreak", userStreak?.getUserStreaks?.days)
// }
//       }, [userStreak])

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 is Sunday, 1 is Monday, etc.

    // Adjust weekStepper to set the current day
    const updatedWeekStepper = weekStepper.map((step, index) => ({
      ...step,
      current: index === (currentDay === 0 ? 6 : currentDay - 1), // Adjust for 0 index Sunday
    }));
    setWeekActiveStep(currentDay === 0 ? 6 : currentDay - 1); // Set active step
  }, []);

  return (
    <>
      <div className="mt-6 lg:mt-0 mb-6 min-h-screen">
        <div className="w-full flex items-center justify-center">
          <RxSewingPinFilled className="-rotate-90 text-3xl w-10 dark:text-white" />
          <Typography className="text-2xl font-archivo font-bold text-textLight-500 dark:text-white">
            Your Rewards
          </Typography>
          <RxSewingPinFilled className="rotate-90 text-3xl w-10 dark:text-white" />
        </div>

        {/* ----------------level stepper component----------------- */}
        <div className="mt-10 mb-14 lg:mb-28 w-full px-4 lg:px-6 flex items-center justify-center">
          <Stepper
            className="!w-[1100px]"
            activeStep={activeStep}
            lineClassName="bg-supportDark-50 dark:bg-supportDark-50/10 h-[3px] lg:h-2"
            activeLineClassName="bg-supportDark-300 dark:bg-supportDark-300"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                onClick={() => setActiveStep(index)}
                completedClassName="!text-textLight-500 dark:!text-white"
                className={`!bg-transparent !w-12 !h-12 md:!w-20 md:!h-20 lg:!w-fit lg:!h-fit !p-0 font-archivo font-bold text-sm lg:text-2xl lg:-ml-2 !text-textLight-400 ${
                  index == 0
                    ? "-ml-6 lg:-ml-2"
                    : index == steps.length - 1
                    ? "-mr-6 lg:-mr-8"
                    : ""
                }`}
              >
                <img
                  src={step.img}
                  className="drop-shadow-[0_4px_4px_rgba(0,0,0,0.2)]"
                />

                {index !== steps.length - 1 &&
                  step.users?.map((user, userIndex) => {
                    return (
                      <div
                        key={userIndex}
                        className="group absolute top-[23%] md:top-[35%] lg:top-[41%]"
                        style={{
                          right: `-${
                            index == 0
                              ? ((user * 1.65) / 2500) * 100
                              : (((user - 2500) * 1.45) / (4000 - 2500)) * 100
                          }%`,
                        }}
                      >
                        <div className="">
                          <div className="hidden group-hover:block absolute  -top-[68px] -left-7 rounded-lg bg-supportDark-50 px-2 lg:px-4 py-1  text-center">
                            <Typography className="text-supportDark-600 font-archivo font-bold text-xs lg:text-xl">
                              <span className="text-[4.23px] lg:text-sm font-semibold">
                                San
                              </span>{" "}
                              {user}
                            </Typography>
                            <ArrowDown2
                              variant="Bold"
                              className="hidden group-hover:block absolute -bottom-3.5 left-1/3 text-supportDark-50"
                            />
                          </div>
                          <Avatar
                            className="!w-4 !h-4 lg:!w-7 lg:!h-7 border-2 border-transparent hover:border-2 hover:border-supportDark-600"
                            src="https://docs.material-tailwind.com/img/face-2.jpg"
                            alt="avatar"
                            size="xs"
                          />
                        </div>
                      </div>
                    );
                  })}
                {index > 0 && (
                  <div className="absolute -bottom-1 lg:bottom-3 w-max text-center bg-white bg-opacity-80 border dark:!text-textLight-400 border-textLight-200 px-1 lg:px-3 rounded-lg text-xs lg:text-lg">
                    {step.points} Points
                  </div>
                )}
                <div className="absolute -bottom-7 lg:-bottom-14 w-max text-center">
                  {step.label}
                </div>
              </Step>
            ))}
          </Stepper>
        </div>

        {/* ----------------------points history component---------------- */}
        <div className="flex items-center flex-col justify-center mb-14">
          <Typography className="font-archivo font-bold text-2xl text-textLight-500 dark:text-white">
            Points History
          </Typography>
          <div className="flex flex-col lg:flex-row gap-2 bg-supportLight-50 dark:bg-supportLight-400 rounded-xl px-12 py-3 mt-5">
            <div className="lg:pr-6 lg:border-r border-dashed border-supportLight-600 dark:border-white flex flex-col items-center">
              <Typography className="font-archivo font-semibold text-supportLight-600 dark:text-white text-sm flex gap-2 items-center">
                <CardReceive size="13" /> Earned Points
              </Typography>
              <Typography className="font-archivo font-normal text-supportLight-600 dark:text-white text-3xl">
                {userInfo ? userInfo?.rewardPoints : 0}
              </Typography>
            </div>
            <div className="px-6 lg:border-r border-dashed border-supportLight-600 dark:border-white flex flex-col items-center">
              <Typography className="font-archivo font-semibold text-supportLight-600 dark:text-white text-sm flex gap-2 items-center">
                <TicketExpired size="13" /> Claimed Points
              </Typography>
              <Typography className="font-archivo font-normal text-supportLight-600 dark:text-white text-3xl">
                0
              </Typography>
            </div>
            <div className="lg:pl-6 flex flex-col items-center">
              <Typography className="font-archivo font-semibold text-supportLight-600 dark:text-white text-sm flex gap-2 items-center">
                <WalletMinus size="13" /> Balance Points
              </Typography>
              <Typography className="font-archivo font-normal text-supportLight-600 dark:text-white text-3xl">
              {userInfo ? userInfo?.rewardPoints : 0}
              </Typography>
            </div>
          </div>
        </div>

        {/* ----------------------points history component---------------- */}

        <div className="flex items-center flex-col justify-center mb-14">
          <Typography className="font-archivo font-bold text-2xl text-textLight-500 dark:text-white">
            Weekly Streaks
          </Typography>
          <div className="w-1/2 min-w-[300px] pt-16 pb-4">
            <Stepper
              activeStep={weekActiveStep}
              lineClassName="bg-[#E9E9E9] pb-[3px]"
              activeLineClassName="bg-[#E9E9E9] pb-[3px]"
            >
              {weekStepper?.map((x, index) => (
                <Step
                  key={index}
                  className={`h-5 w-5 !bg-[#E9E9E9] text-white cursor-pointer`}
                  activeClassName="ring-0 !bg-primaryLight-600 text-[#E9E9E9]"
                  completedClassName={`${userStreak?.getUserStreaks?.days.includes(x?.weekDay) ? "!bg-primaryLight-700" : "!bg-error-500"} text-white`}
                >
                  <div className="absolute -top-[2.3rem] w-max text-center text-xs">
                    <Typography
                      color="inherit"
                      className="font-archivo text-base text-textLight-200 font-semibold"
                    >
                      {x.weekDay}
                    </Typography>
                  </div>
                </Step>
              ))}
            </Stepper>
          </div>
          <Typography className="font-archivo font-bold text-2xl text-textLight-500 dark:text-white">
            Streaks point earned -{" "}
            <span className="text-primaryLight-500">32 Points</span>
          </Typography>
        </div>

        {/* ------------------------merchandise product list----------------- */}
        <div className="flex items-center flex-col justify-center mb-14">
          <Typography className="font-archivo font-bold text-2xl text-textLight-500 dark:text-white">
            Redeem Points
          </Typography>
          <div className="flex w-[90vw] md:w-full overflow-auto md:grid grid-cols-3 gap-7 mt-5">
            {productList?.map((product, index) => (
              <Card
                key={index}
                className="min-w-[70vw] md:min-w-fit p-3 drop-shadow-xl  bg-transparent dark:bg-textLight-700"
              >
                <img src={product.img} />
                <div className="flex justify-between items-center mt-1">
                  <Typography className="font-archivo font-medium text-base  text-textLight-500 dark:text-textDark-100">
                    {product.name}
                  </Typography>
                  <Typography className="font-archivo font-medium text-base  text-textLight-500 dark:text-textDark-100 flex gap-1 items-center">
                    <span className="font-rubik text-[10px] font-normal text-textLight-200 line-through">
                      {product.ogPoints} Pts
                    </span>
                    {product.points} Pts
                  </Typography>
                </div>
                <Typography className="font-rubik text-xs font-normal text-textLight-300 mt-2 dark:text-textDark-100">
                  {product.description}
                </Typography>
                <Button
                  size="sm"
                  color="green"
                  className="normal-case w-fit mt-2 font-archivo font-medium text-sm tracking-wide rounded-[10px] !py-2 !px-4"
                  onClick={() => navigate("/home/rewards-checkout")}
                >
                  Claim Now!
                </Button>
              </Card>
            ))}
          </div>
        </div>

        {/* --------------------------ulearnix coupons---------------------- */}
        <div className="max-w-full lg:max-w-[75vw] lg:px-12 lg:pl-24 flex items-center flex-col justify-center mb-14">
          <Typography className="font-archivo font-bold text-lg text-textLight-400 dark:text-white">
            Exclusive Discounts on Ulearnix
          </Typography>

          <div className="flex w-full mt-4 gap-7 pb-4 px-2 overflow-auto [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121] dark:[&::-webkit-scrollbar-thumb]:bg-white">
            {discountList?.map((discount, index) => (
              <Card
                key={index}
                className="relative flex-shrink-0 w-[272px] p-3 shadow-lg"
                style={{ backgroundColor: `${colors[index % 3].bgColor}` }}
              >
                <img
                  src={colors[index % 3].bgImg}
                  className="absolute right-0"
                />
                <div
                  className="py-4 before:h-4 before:w-2 before:absolute before:top-[54%] before:left-1 before:rounded-[0_60px_60px_0] before:-translate-x-1 before:translate-y-1/2 before:bg-white dark:before:bg-[#212121] before:shadow-inner after:shadow-inner
                                after:h-4 after:w-2 after:absolute after:top-[54%] after:-right-1 after:rounded-[60px_0px_0px_60px] after:-translate-x-1 after:translate-y-1/2 after:bg-white dark:after:bg-[#212121]"
                  style={{
                    borderBottom: `1px dashed ${colors[index % 3].textColor}`,
                  }}
                >
                  <Typography
                    className="font-archivo text-lg font-bold"
                    style={{ color: `${colors[index % 3].textColor}` }}
                  >
                    {discount.title}
                  </Typography>
                  <Typography
                    className="text-[10px] font-rubik font-normal"
                    style={{ color: `${colors[index % 3].textColor}` }}
                  >
                    {discount.description}
                  </Typography>
                </div>
                <div className="flex items-center justify-between pt-2">
                  <Typography
                    className="font-archivo font-medium text-lg text-textLight-500"
                    style={{ color: `${colors[index % 3].textColor}` }}
                  >
                    {discount.points} <span className="text-[10px]">pts</span>
                  </Typography>
                  <Button
                    size="sm"
                    color="green"
                    style={{
                      backgroundColor: `${colors[index % 3].textColor}`,
                    }}
                    className="normal-case w-fit font-archivo font-medium text-sm tracking-wide rounded-[10px] !py-2 !px-4 hover:shadow-none"
                  >
                    {" "}
                    Claim Now!
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
