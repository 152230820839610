import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import { AddCircle } from "iconsax-react";
import { MdAddCircleOutline } from "react-icons/md";

const SuggestedUsersCard = () => {
  const users = [
    { name: "Aniket Singh", followers: "145k" },
    { name: "Deepak", followers: "15k" },
    { name: "Mahesh", followers: "120k" },
    { name: "Abhishek Singh", followers: "190k" },
  ];

  return (
    <>
      <div className="flex w-full overflow-auto gap-4 pb-4">
        {users?.map((item, index) => (
          <Card
            key={index}
            className="min-w-32 w-full h-fit flex flex-col p-4 items-center font-archivo text-xl shadow-md text-textLight-500 dark:text-white dark:bg-textLight-700 font-bold cursor-pointer "
          >
            <Avatar
              className="!rounded-md"
              src="https://docs.material-tailwind.com/img/face-2.jpg"
              alt="avatar"
              size="md"
            />

            <div className="pt-2 flex items-center flex-col">
              <Typography className="text-sm font-archivo text-textLight-500 dark:text-white font-bold text-nowrap">
                {item?.name}
              </Typography>
              <Typography className="text-xs font-rubik text-textLight-800 dark:text-white font-normal">
                {item?.followers} Followers
              </Typography>
              <Button
                variant="filled"
                size="sm"
                color="green"
                className="text-xs mt-2 flex items-center normal-case font-semibold tracking-wide gap-2"
              >
                Follow <AddCircle size="16" />
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default SuggestedUsersCard;
