import { Button, Typography } from "@material-tailwind/react";
import Footer from "../Footer";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const divRef = useRef();

    useEffect(() => {
        divRef?.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <>
            <div ref={divRef} className="max-w-full min-h-screen py-3 md:py-10 bg-[#080808] flex items-center justify-center flex-col">
                <div className="w-full md:w-2/3 md:text-justify px-5 md:px-0">
                    <div className="my-6 flex flex-col-reverse md:flex-row items-start md:items-center justify-between">
                        <Typography className="font-archivo text-white text-2xl md:text-3xl font-bold">Privacy Policy</Typography>
                        <div className="flex w-full md:w-fit justify-end">
                            <Button
                                onClick={() => navigate(-1)}
                                variant="text"
                                color="green"
                                className="font-archivo text-primaryDark-500 text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
                            >
                                <ArrowLeft size="20" /> Back
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">Effective Date: 01 Jan 2024</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">This Privacy Policy governs the manner in which Bhumi Itech Private Limited collects, uses, maintains, and discloses information collected from
                            users (hereinafter referred to as 'Users') of the www.bhumiitech.com website (hereinafter referred to as the 'Website').
                            This Privacy Policy applies to the Website and all products and services offered by Bhumi Itech Private Limited.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">1. Personal Identification Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">We may collect personal identification information from Users in a variety of ways, including but not limited to, when Users visit our Website, register on the Website, place an order, subscribe to the newsletter, respond to a survey, fill out a form
                            , and in connection with other activities, services, features, or resources we make available on our Website. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and other relevant information. Users may, however, visit our Website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us.
                            Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain website-related activities.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">2. Non-Personal Identification Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">We may collect non-personal identification information about Users whenever they interact with our Website. Non-personal identification information may include the browser name, the type of computer, and technical information about Users'
                            means of connection to our Website, such as the operating system and the Internet service providers utilized and other similar information.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">3. Web Browser Cookies</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Our Website may use 'cookies' to enhance User experience. The User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent.
                            If they do so, note that some parts of the Website may not function properly.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">4. How We Use Collected Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Bhumi Itech Private Limited may collect and use Users' personal information for the following purposes:<br />
                            <li className="ml-6">To improve customer service: Information provided by Users helps us respond to customer service requests and support needs more efficiently.</li>
                            <li className="ml-6">To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.</li>
                            <li className="ml-6">To improve our Website: We continually strive to improve our Website offerings based on the information and feedback we receive from Users.</li>
                            <li className="ml-6">To process payments: We may use the information Users provide when placing an order only to provide service to that order.
                                We do not share this information with outside parties except to the extent necessary to provide the service.</li>
                            <li className="ml-6">To send periodic emails: We may use the email address to send Users information and updates pertaining to their order or to respond to their inquiries,
                                questions, or other requests. If Users decide to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails,
                                we include detailed unsubscribe instructions at the bottom of each email or the User may contact us via our Website.</li>
                        </Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">5. How We Protect Your Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration,
                            disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Website.</Typography>
                    </div>

                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">6. Sharing Your Personal Information</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors
                            and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">7. Third-Party Websites</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Users may find advertising or other content on our Website that link to the sites and services of our partners, suppliers, advertisers, sponsors,
                            licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible
                            for the practices employed by websites linked to or from our Website. In addition, these sites or services, including their content and links,
                            may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website,
                            including websites that have a link to our Website, is subject to that website's own terms and policies.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">8. Changes to This Privacy Policy</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">Bhumi Itech Private Limited has the discretion to update this Privacy Policy at any time.
                            When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect
                            the personal information we collect. You acknowledge and agree that it is your
                            responsibility to review this Privacy Policy periodically and become aware of modifications.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">9. Your Acceptance of These Terms</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">By using this Website, you signify your acceptance of this Privacy Policy.
                            If you do not agree to this Privacy Policy, please do not use our Website. Your continued use of the Website following the posting of changes to this Privacy
                            Policy will be deemed your acceptance of those changes.</Typography>
                    </div>


                    <div className="flex flex-col gap-1 mb-6">
                        <Typography className="text-white font-semibold font-archivo text-xl">10. Contact Us</Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal">
                            If you have any questions or require further information about our Cancellation & Refund Policy, please contact us at:
                        </Typography>
                        <Typography className="text-textLight-50 font-rubik text-base font-normal mt-2">
                            Bhumi Itech Private Limited Address: #521, 5th Floor, City Centre,<br />
                            Sector-12, Dwarka, New Delhi-110075, India<br />
                            Email: info@bhumiitech.com<br />
                            This Policy was last updated on 01 January 2024.
                        </Typography>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PrivacyPolicy;