import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import { AddCircle } from "iconsax-react";
import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router";
import { setAddMember, setGroupAdmin } from "./frequentlyUsedEmojiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_GROUPS_OF_INDIVIDUAL } from "../../../API/Queries";
import { useEffect } from "react";
import toast from "react-hot-toast";

const GroupCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, userInfo } = useSelector((state) => state.authSlice);

  const groups = [
    {
      name: "Gang",
      message: "Ayush - HAHAHAHA",
      date: "21/09/23",
      number: 3,
    },
    {
      name: "Notorious",
      message: "Anmol - Maybe",
      date: "21/09/23",
      number: 3,
    },
    {
      name: "Rajeev Lumari",
      message: "Hi, How are you?",
      date: "21/09/23",
      number: 3,
    },
  ];

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
    refetch: refetchgroupdataRequests, // Added refetch function
  } = useQuery(GET_GROUPS_OF_INDIVIDUAL, {
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      toast.error(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  useEffect(() => {
    refetchgroupdataRequests();
  }, [groupData]);

  return (
    <>
      <Card className="dark:bg-textLight-700 pb-3">
        <div className="p-6 pb-0">
          <div className="flex justify-between">
            <Typography
              variant="h4"
              className="font-archivo text-xl text-textLight-500 dark:text-white font-bold mb-4"
            >
              Groups ({groupData && groupData?.getGroupForMembers?.length})
            </Typography>
            <AddCircle
              onClick={() => navigate("/home/create-group")}
              className="cursor-pointer text-primaryLight-500 dark:text-primaryDark-500"
            />
          </div>

          <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
            <span className="absolute inset-y-0 left-0 flex items-center pl-1">
              <div className="p-1">
                <LuSearch className="text-xl" />
              </div>
            </span>
            <input
              type="search"
              name="q"
              className="py-2.5 w-full text-sm font-archivo dark:bg-textLight-500 dark:text-white text-black rounded-md pl-10 focus:outline-none
                         focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
              placeholder="Search"
            />
          </div>
        </div>
        <div
          className="p-2 h-44 overflow-auto flex flex-col [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full
                [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121] dark:[&::-webkit-scrollbar-thumb]:bg-white "
        >
          {groupData?.getGroupForMembers?.map((item, index) => (
            <div
              onClick={() => {
                const userData1 = {
                  groupId: item?.id,
                  userId: userInfo?._id,
                  groupName: item?.name,
                  avatar: item?.profileImage
                    ? item?.profileImage
                    : "https://docs.material-tailwind.com/img/face-2.jpg",
                  type: "group",
                };
                navigate(`/home/chat/${index}`, {
                  state: { type: "group", userData: userData1 },
                });
                dispatch(setGroupAdmin(true));
                dispatch(setAddMember(false));
              }}
              key={index}
              className="px-4 py-3 cursor-pointer flex items-center justify-between hover:bg-primaryLight-500 hover:bg-opacity-15"
            >
              <div className="flex gap-2 items-center">
                <Avatar
                  src={
                    item?.profileImage
                      ? item?.profileImage
                      : "https://docs.material-tailwind.com/img/face-2.jpg"
                  }
                  alt="avatar"
                  size="sm"
                />
                <div>
                <div className="max-w-[90px]">
                  <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white truncate">
                    {item?.name}
                  </Typography>
                  </div>
                  <div className="max-w-[90px]">
                  <Typography className="text-xs font-normal font-rubik text-textLight-400 dark:text-white truncate">
                    {item?.messages && item?.messages?.length > 0
                      ? item?.messages[item?.messages?.length - 1]?.message
                      : "Star your conversation..."}
                  </Typography>
                </div>
                </div>
              </div>
              <div className="flex gap-1 flex-col items-center">
                <Typography className="text-sm font-archivo font-normal text-supportLight-500 dark:text-supportDark-100">
                  {item?.date ? item?.date : "21/09/23"}
                </Typography>
                <Typography className="text-xs font-rubik text-center text-white font-bold bg-supportLight-500 px-1 rounded-full">
                  {item?.messages?.length > 0 && item?.messages?.length}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default GroupCard;
