import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginimg from "../../../assets/images/login1.svg";
import gIcon from "../../../assets/images/googleIcon.png";
import { Sms, Key, Eye, EyeSlash, ArrowLeft } from "iconsax-react";
import { Typography, Input, Button, Alert } from "@material-tailwind/react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { LOGIN } from "../../../API/Mutations";
import Loader from "../../../common/components/Loader";
import { setLoginSuccess, setUserInfo, setUserToken } from "../authSlice";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { POINTS_ALLOCATION } from "../../../API/Queries";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [Login, { data, loading, error }] = useMutation(LOGIN, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    }
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required(),
    password: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    Login({ variables: { body: values } });

  };

  useEffect(() => {
    if (data) {
      if (data?.Login?.data?.role !== "admin") {
        dispatch(setUserInfo(data?.Login?.data));
        dispatch(setUserToken(data?.Login?.token));
        dispatch(setLoginSuccess(true));
        toast.success("User logged in successfully")
        // navigate("/home");
      } else {
        toast.error("You are not authorized to access this page");
      }
    }
  }, [data])
  
  const {
    data: pointsAllocation,
    loading: pointsAllocationLoading,
    error: pointsAllocationError,
    refetch: refetchpointsAllocation, // Added refetch function
  } = useQuery(POINTS_ALLOCATION, {
    context: {
      headers: {
        Authorization: `${data?.Login?.token && data?.Login?.token?.replace("Bearer ", "")}`,
      },
    },
    onError:(error)=> {
      console.log(error.graphQLErrors[0]?.message || "An error occurred");
    },
    skip: !data && !data?.Login?.token
  });
  
  useEffect(()=>{
    if(pointsAllocation) {
      navigate("/home");
    }

  }, [pointsAllocation])


  if (loading || pointsAllocationLoading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-7 h-screen hidden lg:block">
          <img
            src={loginimg}
            alt="loginImg"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-span-12 lg:col-span-5 sm:col-span-12 md:col-span-12 md:justify-self-center lg:w-full">
          <div
            className="flex  h-full items-center flex-col justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16  md:pr-10 sm:pr-4 lg:w-full md:w-full"
            style={{ height: "100%" }}
          >
            <div className="flex w-full float-right justify-end items-center">
              <Button onClick={() => navigate("/")} variant="text" color="green" className="font-archivo text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"><ArrowLeft size="20" /> Back</Button>
            </div>
            <div className="w-full">
              <Typography className="font-archivo text-4xl font-bold mb-8">
                Welcome Back
              </Typography>
              <Typography className="font-archivo text-xl font-medium">
                We missed you a lot
              </Typography>
              <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
                Login to your account using your Email ID and Password
              </Typography>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-full h-full"
            >
              {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Form className="w-full">
                  <div className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.email && touched.email ? "border-error-500 animate-shake" : "border-black"}`}>
                    <Sms className={`${errors.email && touched.email ? "text-error-500" : 'text-textLight-400'}`} />
                    <Field
                      as={Input}
                      variant="standard"
                      label="Enter email address"
                      placeholder="Enter email address"
                      name="email"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                  <div className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.password && touched.password ? "border-error-500 animate-shake" : "border-black"}`}>
                    <Key className={`${errors.password && touched.password ? "text-error-500" : 'text-textLight-400'}`} />
                    <Field
                      as={Input}
                      variant="standard"
                      type={passwordVisible ? "text" : "password"}
                      label="Enter the password"
                      placeholder="Enter the password"
                      name="password"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                      icon={
                        passwordVisible ? (
                          <EyeSlash size="20"
                            className="text-black cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <Eye size="20"
                            className="text-black cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        )
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="filled"
                    size="lg"
                    color="green"
                    className="py-4 normal-case w-full"
                  >
                    Get Started
                  </Button>
                </Form>
              )}
            </Formik>
            {/* <div className="mb-8 relative flex items-center gap-6 border-b-2 border-black">
              <Sms color="#4D4D4D" />
              <Input
                variant="standard"
                label="Enter email address"
                placeholder="Enter email address"
                className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: "hidden",
                }}
              />
            </div>
            <div className="mb-8 relative flex items-center gap-6 border-b-2 border-black">
              <Key color="#4D4D4D" />
              <Input
                variant="standard"
                type="password"
                label="Enter the password"
                placeholder="Enter the password"
                icon={<Eye className="text-black cursor-pointer" />}
                className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: "hidden",
                }}
              />
            </div> */}
            <div className="flex items-end justify-end w-full mt-1">
              <Typography
                className="font-archivo text-base font-bold text-supportLight-500 text-right"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forget-password")}
              >
                Forgot Password?
              </Typography>
            </div>
            {/* <Button
              variant="filled"
              size="lg"
              color="green"
              className="py-4 normal-case"
            >
              Get Started
            </Button> */}
            <Typography className="font-archivo text-base font-normal mb-8 text-center mt-2">
              or
            </Typography>
            <div className="flex flex-wrap items-center justify-center mb-6">
              <div
                className="flex flex-wrap flex-row gap-2 py-1 px-5 items-center justify-center rounded-lg border"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={gIcon}
                  alt="google-Icon"
                  style={{ width: "24px", height: "24px" }}
                />
                <Typography className="font-rubik font-normal text-xs">
                  Sign in with Google
                </Typography>
              </div>
            </div>
            <Typography className="font-archivo text-sm font-semibold text-textLight-300 text-center">
              Don't have an account?{" "}
              <span onClick={() => navigate("/signup")}
                className="font-archivo font-bold text-supportLight-500"
                style={{ cursor: "pointer" }}
              >
                Signup
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
