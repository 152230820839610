import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import {
  InfoCircle,
  ArrowRight2,
  Messages2,
  Whatsapp,
  ExportSquare,
} from "iconsax-react";
import { LiaTelegram } from "react-icons/lia";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import thumbsup from "../../assets/images/thumbsup.png";

import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const SharePostBox = () => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openOther, setOpenOther] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleConfirmOpen = () => setOpenConfirm(true);
  const handleClose = () => setOpen(false);
  const handleConfirmClose = () => setOpenConfirm(false);
  const handleCloseConfirm = () => {
    setOpen(false);
    setOpenConfirm(true);
  };
  const shareUrl = "http://github.com";

  return (
    <>
      <div
        className="flex flex-col md:flex-row items-center gap-1 text-textLight-200 md:text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer"
        onClick={handleOpen}
      >
        <ExportSquare size="20" />
        <Typography className="text-sm font-archivo font-medium">
          Share
        </Typography>
      </div>
      <Dialog
        open={open}
        size="md"
        handler={handleOpen}
        className="p-4 rounded-2xl dark:bg-textLight-500"
      >
        <DialogHeader>
          <div className="flex flex-wrap flex-row items-center justify-between w-full">
            <Typography className="text-textLight-500 text-base font-bold dark:text-white">
              Share with
            </Typography>{" "}
            <div className="text-textLight-500">
              <IoClose onClick={handleClose} className="cursor-pointer dark:text-white" />
            </div>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-wrap flex-row justify-center sm:justify-between">
            {/* -----------chat share----------- */}
            <div className="group flex flex-col items-center cursor-pointer gap-1">
              <div className="text-textLight-500 rounded-full p-3 group-hover:bg-primaryLight-50 group-hover:dark:bg-primaryDark-50 dark:bg-[#9D9D9D] dark:bg-opacity-5">
                <Messages2 size={30} className="text-textLight-300 dark:text-white group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500" />
              </div>
              <Typography className="text-sm font-rubik font-normal dark:text-white text-textLight-300 group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500">Chat</Typography>
            </div>

            {/* ----------telegram share-------------- */}
            <TelegramShareButton url={shareUrl} className="group flex flex-col items-center justify-center gap-1">
              <div className="text-textLight-500 rounded-full p-3 group-hover:bg-primaryLight-50 group-hover:dark:bg-primaryDark-50 dark:bg-[#9D9D9D] dark:bg-opacity-5">
                <LiaTelegram size={30} className="text-textLight-300 dark:text-white group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500" />
              </div>
              <Typography className="text-sm font-rubik font-normal dark:text-white text-textLight-300 group-hover:text-primaryLight-400">Telegram</Typography>
            </TelegramShareButton>

            {/* -------------twitter share------------- */}
            <TwitterShareButton url={shareUrl} className="group flex flex-col items-center justify-center gap-1">
              <div className="text-textLight-500 rounded-full p-3 group-hover:bg-primaryLight-50 group-hover:dark:bg-primaryDark-50 dark:bg-[#9D9D9D] dark:bg-opacity-5">
                <FaXTwitter size={30} className="text-textLight-300 dark:text-white group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500" />
              </div>
              <Typography className="text-sm font-rubik font-normal dark:text-white text-textLight-300 group-hover:text-primaryLight-400">Twitter</Typography>
            </TwitterShareButton>

            {/* -------------whatsapp share------------ */}
            <WhatsappShareButton url={shareUrl} className="group flex flex-col items-center justify-center gap-1">
              <div className="text-textLight-500 rounded-full p-3 group-hover:bg-primaryLight-50 group-hover:dark:bg-primaryDark-50 dark:bg-[#9D9D9D] dark:bg-opacity-5">
                <Whatsapp size={30} className="text-textLight-300 dark:text-white group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500" />
              </div>
              <Typography className="text-sm font-rubik font-normal dark:text-white text-textLight-300 group-hover:text-primaryLight-400">Whatsapp</Typography>
            </WhatsappShareButton>

            {/* -----------------email share-------------- */}
            <EmailShareButton url={shareUrl} className="group flex flex-col items-center justify-center gap-1">
              <div className="text-textLight-500 rounded-full p-3 group-hover:bg-primaryLight-50 group-hover:dark:bg-primaryDark-50 dark:bg-[#9D9D9D] dark:bg-opacity-5">
                <MdOutlineAlternateEmail size={30} className="text-textLight-300 dark:text-white group-hover:text-primaryLight-400 group-hover:dark:text-primaryDark-500" />
              </div>
              <Typography className="text-sm font-rubik font-normal dark:text-white text-textLight-300 group-hover:text-primaryLight-400">Email</Typography>
            </EmailShareButton>
          </div>
          <Typography className="text-textLight-100 text-center text-sm font-medium my-6 font-archivo">
            Or share with link
          </Typography>
          <div className="grid grid-cols-12 grid-rows-1 px-3 gap-6">
            <div className="col-span-11 overflow-hidden">
              <Typography className="noWrap text-textLight-100 text-center text-[14px]">
                {" "}
                https://www.figma.com/file/NlfVhYygR9mAQasassdsada/Share...https://www.figma.com/file/NlfVhYygR9mAQasassdsada/Share...https://www.figma.com/file/NlfVhYygR9mAQasassdsada/Share...
              </Typography>
            </div>
            <div onClick={() =>  navigator.clipboard.writeText(shareUrl)}>
              <FaRegCopy
                size="24"
                className="text-primaryLight-500 cursor-pointer"
                onClick={() => console.log("copied!")}
              />
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default SharePostBox;
