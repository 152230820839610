

import { Moon, Sun1 } from 'iconsax-react';
import './mobiletoggle.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setMode } from './themeToggleSlice';

const MobileToggle = () => {

    const dispatch = useDispatch();
    const { mode } = useSelector((state) => state.themeToggleSlice)

    useEffect(() => {
        document.body.classList.toggle("dark", mode);
    }, [mode])

    return (
        <>
            <input type="checkbox" checked={mode} onChange={(e) => dispatch(setMode(e.target.checked))} id="checkboxInput" />
            <label htmlFor="checkboxInput" className="toggleSwitch dark:bg-textLight-700">
                <div className="speaker dark:bg-textLight-700">
                    <Sun1 size="25" />
                </div>
                <div className="mute-speaker">
                    <Moon size="25" />
                </div>
            </label>






        </>
    )
}

export default MobileToggle;