
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";
import { setActiveScreen } from "../Sidebar/sidebarSlice";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Avatar,
} from "@material-tailwind/react";
import avatar from '../../../assets/images/dummy_avatar.png';
import ThemeToggle from "../ThemeModeToggle";
import { TbLogout2 } from "react-icons/tb";
import { ImSun } from "react-icons/im";
import { Cup, Home, Messages2, ProfileCircle, Setting4 } from "iconsax-react";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { setLoginSuccess, setRegisterSuccess, setUserInfo, setUserToken } from "../../../containers/Auth/authSlice";
import { setIsEditPost } from '../../../containers/Pages/CreatePost/activeTabSlice';

const TabletSidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { mode } = useSelector((state) => state.themeToggleSlice);
    const { activeScreen } = useSelector((state) => state.sidebarSlice);
    const classNames = "font-medium font-archivo text-base text-[#2A2A2E] dark:text-white focus:text-primaryLight-700 dark:focus:text-primaryDark-500 focus:bg-transparent border-0 border-transparent dark:hover:bg-black dark:focus:bg-black"

    const listItem = [
        { icon: <Home size="20" />, text: "Home", nav: "/home" },
        { icon: <ProfileCircle size="20" />, text: "Profile", nav: "/home/profile" },
        { icon: <Messages2 size="20" />, text: "Messages", nav: "/home/all-messages" },
        { icon: <Setting4 size="20" />, text: "Settings", nav: "/home/settings" },
        { icon: <Cup size="20" />, text: "Rewards", nav: "/home/rewards" }
    ]

    useEffect(() => {
        switch (location?.pathname) {
            case '/home':
                dispatch((setActiveScreen(0)));
                break;
            case '/home/profile':
                dispatch((setActiveScreen(1)));
                break;
            case '/home/all-messages-default':
                dispatch((setActiveScreen(2)));
                break;
            case "/home/settings":
                dispatch((setActiveScreen(3)));
                break;
            case "/home/rewards":
                dispatch((setActiveScreen(4)));
                break;
        }
    }, [location, activeScreen])

    return (
        <>
            <Card className="sticky top-0 z-50 h-screen w-full max-w-[92px] shadow-xl shadow-blue-gray-900/5 border-r-2 dark:!border-0 rounded-none dark:bg-black">
                <div className="flex flex-col items-center justify-center gap-4 p-6">
                    <img src={avatar} />
                    <Avatar className="!w-5 !h-5"
                        src="https://docs.material-tailwind.com/img/face-2.jpg"
                        alt="avatar"
                        size="sm"
                    />
                </div>
                <div className="flex flex-col items-center justify-between h-full">
                    <div className="flex flex-col items-center w-full justify-center gap-10">
                        {listItem?.map((item, index) => (
                            <div onClick={() => (
                                navigate(item.nav),
                                item.text === "Home" && dispatch(setActiveSection("default")),
                                dispatch((setActiveScreen(index)))
                            )}
                                key={index} id={`list-item-${index}`}
                                className={`${classNames} 
                              ${activeScreen == index && "bg-transparent bg-opacity-25 border-0 text-primaryLight-700 dark:bg-transparent dark:!text-primaryDark-500"}`}
                            >
                                {item?.icon}
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col items-center justify-center gap-8 py-4">
                        <div className="-p-1 my-1 font-medium font-archivo text-base text-[#2A2A2E] dark:text-white  dark:hover:bg-black hover:bg-transparent hover:bg-opacity-25 hover:border-2 hover:border-white focus:bg-transparent dark:focus:bg-transparent focus:bg-opacity-25 focus:border-l-4 focus:rounded-tl-none focus:rounded-bl-none focus:border-transparent border-2 border-white dark:border-transparent">
                            <ThemeToggle />
                        </div>
                        <div className={classNames} onClick={() =>
                            dispatch(setLoginSuccess(false), dispatch(setIsEditPost(false)),
                                dispatch(setRegisterSuccess(false), dispatch(setUserInfo({})), dispatch(setUserToken(""))
                                ))}>
                            <TbLogout2 />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default TabletSidebar;