import { Outlet, useLocation } from "react-router"
import StickyNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Toaster } from "react-hot-toast";

const LandingLayout = () => {
    const location = useLocation();
    return (
        <>
            {(location?.pathname === '/signup' ||
                location?.pathname === '/login' ||
                location?.pathname === '/onboarding' ||
                location?.pathname === '/setup-profile' ||
                location?.pathname === '/forget-password' ||
                location?.pathname === '/change-password' ||
                location?.pathname === '/otp-verification'
            ) ?
                null : (<StickyNavbar />)}
            <Outlet />
            {(
                // location?.pathname === "/home/all-messages-default" ||
                location?.pathname === "/login" ||
                location?.pathname === "/forget-password" ||
                location?.pathname === "/otp-verification" ||
                location?.pathname === "/setup-profile" ||
                location?.pathname === "/onboarding" ||
                location?.pathname === "/signup"
            ) ? null : <Footer />}

            <Toaster
                position="top-center"
                toastOptions={{
                    success: {
                        style: {
                            borderBottom: '4px solid green',
                            color: "green",
                            borderRadius: "5px"
                        },
                    },
                    error: {
                        style: {
                            borderBottom: '4px solid red',
                            color: "red",
                            borderRadius: "5px"
                        },
                        icon : "❌",
                        iconTheme: {
                            primary: 'red',
                            secondary: 'red',
                        },
                    },
                }}

            />

        </>
    )
}

export default LandingLayout;