

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeScreen: 0
};

const sidebarSlice = createSlice({
    name: 'sidebarSlice',
    initialState,
    reducers: {
        setActiveScreen: (state, { payload }) => {
            state.activeScreen = payload;
        }
    }
});

export const { setActiveScreen } = sidebarSlice.actions;
export default sidebarSlice.reducer;
