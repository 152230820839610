import { Avatar, Typography } from "@material-tailwind/react";
import dot from "../../../assets/images/dot.svg";
import green_dot from "../../../assets/images/green_dot.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const MessageBubble = ({ messages, type, userData }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.authSlice);

  return (
    <>
      {(messages && type && userInfo && userInfo?._id)
        ? messages?.map((message, index) =>{ return(
          <div key={index}>
          { userInfo?._id && type && message &&  message?.senderId  &&

            <div
              className={`flex flex-col gap-2 ${
                userInfo?._id !==
                (type == "individual"
                  ? message?.senderId
                  : message?.senderId?.id)
                  ? "items-start"
                  : "items-end justify-end"
              }`}
            >
              <div
                className={`w-fit flex flex-col mb-3  ${
                  userInfo?._id !==
                  (type == "individual"
                    ? message?.senderId
                    : message?.senderId?.id)
                    ? "items-start"
                    : "items-end justify-end"
                } `}
              >
                <div
                  className={`min-w-[215px] max-w-[500px] rounded-2xl py-2 px-4  ${
                    userInfo?._id !==
                    (type == "individual"
                      ? message?.senderId
                      : message?.senderId?.id)
                      ? " bg-white text-textLight-400"
                      : "text-white bg-primaryLight-600"
                  }`}
                >
                  {type == "group" && (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Avatar
                          className="!w-3 !h-3"
                          src={
                            message?.senderId?.avatar
                              ? message?.senderId?.avatar
                              : "https://docs.material-tailwind.com/img/face-2.jpg"
                          }
                          alt="avatar"
                          size="xs"
                        />
                        {message?.senderId && (
                          <Typography
                            className="text-xs font-normal font-rubik"
                          >
                            {message?.senderId?.firstName +
                              " " +
                              message?.senderId?.lastName}
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}
                  {message?.message && (
                    <Typography className="w-fit text-sm font-medium font-archivo mb-1 lg:mb-0 break-all">
                      {message?.message && message?.message}
                    </Typography>
                  )}
                </div>
                <img
                  src={
                    userInfo?._id !==
                    (type == "individual"
                      ? message?.senderId
                      : message?.senderId?.id)
                      ? dot
                      : green_dot
                  }
                  style={{ width: "5px", height: "5px" }}
                />
                {/* {type == "individual" && ( */}
                <Typography className="text-xs text-white font-rubik ml-3">
                  Today, 8:00 p.m.
                </Typography>
                {/* )} */}
              </div>
            </div>
            }
            </div>
          )}
        )
        : navigate("/home/all-messages-default")}
    </>
  );
};

export default MessageBubble;
