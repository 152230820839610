import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Card,
    Typography,
} from "@material-tailwind/react";
import { Calendar, CalendarEdit, Clock } from "iconsax-react";
import { CgClose } from "react-icons/cg";
import './index.css';
import moment from "moment/moment";
import { CREATE_BLOG, CREATE_MEDIA, CREATE_POLLS, CREATE_RESEARCH_PAPER, EDIT_SCHEDULE_TIME } from "../../../API/Mutations";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_POSTS_OF_INDIVIDUAL } from "../../../API/Queries";
import toast from "react-hot-toast";
import { setActiveScreen } from "../Sidebar/sidebarSlice";
import { setIsEditPost } from "../../../containers/Pages/CreatePost/activeTabSlice";
import { useNavigate } from "react-router";


const ScheduleCard = ({ handleOpen, open, setOpen, data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editDate = data?.scheduledTime && new Date(data?.scheduledTime).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' })
    const { isEdit } = useSelector((state) => state.activeTabSlice);
    const [date, setDate] = useState(isEdit ? moment(data?.scheduledTime).format("YYYY-MM-DD") : "");
    const [time, setTime] = useState(isEdit ? editDate : "");
    const { userToken, userInfo } = useSelector((state) => state.authSlice);
    const isoTimestamp = `${date}T${time}:00`;

    // console.log(isEdit, date, time)
    // console.log(editDate)

    // schedule blog query
    const [createBlog, { data: createData, loading, error }] = useMutation(CREATE_BLOG, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //schedule media query
    const [createMedia, { data: mediaData, loading: mediaLoading, error: mediaError }] = useMutation(CREATE_MEDIA, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //schedule polls query
    const [createPoll, { data: pollData, loading: pollLoading, error: pollError }] = useMutation(CREATE_POLLS, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //schedule research paper
    const [createResearchPaper, { data: researchPaperData, loading: researchPaperLoading, error: researchPaperError }] = useMutation(CREATE_RESEARCH_PAPER, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //edit schedule time
    const [editScheduledTime, { data: scheduleData }] = useMutation(EDIT_SCHEDULE_TIME, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    })

    const handleSubmit = () => {
        if (!isEdit) {
            if (data?.type == "blog") {
                let body = {
                    author: userInfo?._id,
                    description: data?.text,
                    title: data?.heading,
                    isScheduled: true,
                    scheduledTime: isoTimestamp,
                    mentions: [],
                    type: "blog"
                }
                createBlog({ variables: { body: body, file: [data?.file, data?.image] } })
            } else if (data?.type == "media") {
                let body = {
                    author: userInfo?._id,
                    description: data?.text,
                    isScheduled: true,
                    scheduledTime: isoTimestamp,
                    mentions: [],
                    type: "media"
                }
                createMedia({ variables: { body: body, file: data?.fileArr } })
            } else if (data?.type == "poll") {
                let body = {
                    author: userInfo?._id,
                    type: "poll",
                    question: data?.question,
                    options: data?.options[0],
                    isScheduled: true,
                    scheduledTime: isoTimestamp,
                    mentions: [],
                }
                createPoll({ variables: { body: body } })
            } else if (data?.type == "research_paper") {
                let body = {
                    author: userInfo?._id,
                    type: "researchPaper",
                    description: data?.text,
                    isScheduled: true,
                    scheduledTime: isoTimestamp,
                    mentions: [],
                }
                createResearchPaper({ variables: { body: body, file: data?.pdf } })
            }
        } else {
            editScheduledTime({
                variables: {
                    body: {
                        postId: data?._id,
                        scheduledTime: isoTimestamp
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (createData || mediaData || pollData || researchPaperData || scheduleData) {
            setOpen(false)
            toast.success("Post scheduled successfully!")
            navigate("/home/profile")
            dispatch(setActiveScreen(0));
            dispatch(setIsEditPost(false))
        }
    }, [createData, mediaData, pollData, researchPaperData, scheduleData])

    return (
        <>
            <Dialog size="xs"
                className="bg-white dark:bg-textLight-500 py-4"
                open={open}
                // handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
            >
                <div className=" w-fit float-end mt-6 mr-4 lg:mr-6 cursor-pointer">
                    <CgClose onClick={() => setOpen(false)} className="text-textLight-500 dark:text-white hover:text-error-500 dark:hover:text-error-500" />
                </div>
                <DialogHeader className="flex text-textLight-500 dark:text-white gap-2 items-center justify-center font-archivo font-medium text-xl ">
                    <CalendarEdit size="20" /> Schedule Post
                </DialogHeader>
                <DialogBody className="lg:px-14 flex flex-col gap-6">
                    <Card className="flex p-4 flex-row gap-4 shadow-gray-700/30 dark:bg-textLight-700 dark:shadow-none dark:border dark:border-white">
                        <div className="border-r-2 border-textLight-300 dark:border-white w-fit pr-4 flex items-center justify-center">
                            <Calendar className="text-textLight-300 dark:text-white" />
                        </div>
                        <div className="schedule px-4 w-full">
                            <Typography className="font-rubik font-medium text-sm text-textLight-300 dark:text-white mb-1">Set Date</Typography>
                            <input type="date" value={date} onChange={(e) => setDate(e.target.value)}
                                className="border-0 focus:outline-0 w-full font-archivo font-semibold text-textLight-200 dark:bg-textLight-700 dark:text-white"
                            />
                        </div>
                    </Card>
                    <Card className="flex p-4 flex-row gap-4 shadow-gray-700/30 dark:bg-textLight-700 dark:shadow-none dark:border dark:border-white">
                        <div className="border-r-2 border-textLight-300 dark:border-white w-fit pr-4 flex items-center justify-center">
                            <Clock className="text-textLight-300 dark:text-white" />
                        </div>
                        <div className="schedule px-4 w-full">
                            <Typography className="font-rubik font-medium text-sm text-textLight-300 dark:text-white mb-1">Time</Typography>
                            <input type="time" value={time} onChange={(e) => setTime(e.target.value)}
                                className="border-0 focus:outline-0 w-full font-archivo font-semibold text-textLight-200 dark:bg-textLight-700 dark:text-white"
                            />
                        </div>
                    </Card>
                </DialogBody>
                <DialogFooter className="flex gap-4">
                    <Button onClick={() => setOpen(false)} color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-lg font-archivo dark:text-primaryDark-500 dark:border-primaryDark-500 text-sm font-medium px-6 py-2">Cancel</Button>
                    <Button onClick={handleSubmit} color="green" size="sm" variant="filled" className="rounded-lg font-archivo text-sm font-medium px-6 py-2 dark:bg-primaryDark-500 normal-case">Save</Button>
                </DialogFooter>


            </Dialog>
        </>
    );
}

export default ScheduleCard;