import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { Typography } from '@material-tailwind/react';
import './index.css';
import { useSelector } from 'react-redux';

const TextEditor = ({ editorState, setEditorState, length }) => {
    // console.log((draftToMarkdown(convertToRaw(editorState.getCurrentContent()))))
    const { mode } = useSelector((state) => state.themeToggleSlice);

    const tagSuggest = [
        { text: 'APPLE', value: 'apple', url: 'apple' },
        { text: 'BANANA', value: 'banana', url: 'banana' },
        { text: 'CHERRY', value: 'cherry', url: 'cherry' },
        { text: 'DURIAN', value: 'durian', url: 'durian' },
        { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
        { text: 'FIG', value: 'fig', url: 'fig' },
        { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
        { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
    ]

    useEffect(() => {
        if (mode) {
            const options = Array.from(document.querySelectorAll(".rdw-option-wrapper"));
            options.map((option) => (
                option.classList.add("dark-bg")
            ))
        } else {
            const options = Array.from(document.querySelectorAll(".rdw-option-wrapper"));
            options.map((option) => (
                option.classList.remove("dark-bg")
            ))
        }
    }, [mode])

    return (
        <>
            <div className='border border-textLight-50 dark:border-transparent dark:bg-textLight-700 rounded-xl min-h-32 lg:min-h-52'>
                <Editor
                    editorState={editorState}
                    toolbar={{
                        link: { className: "!hidden" },
                        image: { className: "!hidden" },
                        remove: { className: "!hidden" },
                        history: { className: "!hidden" },
                        embedded: { className: "!hidden" },
                    }}
                    placeholder='What do you want to talk about?'
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class !min-h-32 lg:!min-h-40 px-4 dark:text-white"
                    toolbarClassName="!border-transparent !border-b-textLight-50 !bg-transparent"
                    onEditorStateChange={setEditorState}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: tagSuggest,
                    }}
                    hashtag={{
                        separator: ' ',
                        trigger: '#',
                    }}
                />
                <div className='flex w-full justify-end px-2 pb-2'>
                    <Typography className='text-xs font-rubik font-medium'>
                        {length}/{length}
                    </Typography>
                </div>
            </div>
        </>
    )
}

export default TextEditor;