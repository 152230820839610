

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeTab: "blog",
    isEdit: false,
};

const activeTabSlice = createSlice({
    name: 'activeTabSlice',
    initialState,
    reducers: {
        setActiveTab: (state, { payload }) => {
            state.activeTab = payload;
        },
        setIsEditPost: (state, { payload }) => {
            state.isEdit = payload;
        }
    }
});

export const { setActiveTab, setIsEditPost } = activeTabSlice.actions;
export default activeTabSlice.reducer;
