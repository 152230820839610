import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";
import { setActiveScreen } from "../Sidebar/sidebarSlice";
import { AddCircle, Home, Messages2, ProfileCircle, SearchNormal, Setting4 } from "iconsax-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { setMobileActiveScreen } from "./mobileNavSlice";
import { setActiveTab } from "../../../containers/Pages/CreatePost/activeTabSlice";

const BottomNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.split("/")[2]
    const listItem = [
        { icon: <Home size="20" />, text: "Home", nav: "/home" },
        { icon: <Messages2 size="20" />, text: "Messages", nav: "/home/all-messages" },
        { icon: <AddCircle size="20" />, text: "Post", nav: "/home/create-post" },
        { icon: <Setting4 size="20" />, text: "Settings", nav: "/home/settings" },
        { icon: <ProfileCircle size="20" />, text: "Profile", nav: "/home/profile" },
    ]
    const { mode } = useSelector((state) => state.themeToggleSlice);
    const { mobileActiveScreen } = useSelector((state) => state.mobileNavSlice);
    const classNames = "font-medium font-archivo text-base text-[#2A2A2E] dark:text-white focus:text-primaryLight-700 dark:focus:text-primaryDark-500 focus:bg-transparent border-2 border-transparent dark:hover:bg-black dark:focus:bg-black"

    useEffect(() => {
        switch (location?.pathname) {
            case '/home':
                dispatch((setMobileActiveScreen(0)));
                break;
            case '/home/all-messages':
                dispatch((setMobileActiveScreen(1)));
                break;
            case '/home/create-post':
                dispatch((setMobileActiveScreen(2)));
                break;
            case "/home/settings":
                dispatch((setMobileActiveScreen(3)));
                break;
            case "/home/profile":
                dispatch((setMobileActiveScreen(4)));
                break;
        }
    }, [location, mobileActiveScreen])

    return (
        <>
            <div className="fixed z-50 -bottom-1 w-screen">
                <List className="!flex !flex-row items-center bg-white dark:bg-black p-1 !shadow-[rgba(18,18,18,0.25)_10px_5px_4px_10px]">
                    {listItem?.map((item, index) => (
                        <ListItem onClick={() => (
                            navigate(item.nav),
                            item.text === "Home" && dispatch(setActiveSection("default")),
                            item.text === "Post" && dispatch(setActiveTab("blog")), 
                            dispatch((setMobileActiveScreen(index)))
                        )}
                            key={index} id={`list-item-${index}`}
                            className={`${classNames} transition-all flex flex-col !items-center p-2 text-xl gap-1
                            ${mobileActiveScreen == index && "hover:text-primaryLight-700 hover:bg-transparent bg-transparent text-primaryLight-700 dark:text-primaryDark-500 dark:border-l-4 dark:bg-transparent"}
                            `}
                        >
                            {item?.icon}
                            <Typography className="text-xs font-rubik font-medium">
                                {item?.text}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    )
}

export default BottomNav;