import { Avatar, Card, Typography } from "@material-tailwind/react";
import { RiShareBoxLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";

const MobileTopicsCard = () => {
  const dispatch = useDispatch();
  const topics = [
    {
      title: "Cloud is Also Potentially Vulnerable",
      img: "https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      title: "Artificial Intelligence (AI) Integration",
      img: "https://images.unsplash.com/photo-1696429175928-793a1cdef1d3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGFpfGVufDB8fDB8fHww",
    },
    {
      title: "Serverless Architectures",
      img: "https://images.unsplash.com/photo-1560732488-6b0df240254a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c2VydmVyfGVufDB8fDB8fHww",
    },
    {
      title: "Cloud is Also Potentially Vulnerable",
      img: "https://plus.unsplash.com/premium_photo-1683141114059-aaeaf635dc05?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      title: "Artificial Intelligence (AI) Integration",
      img: "https://images.unsplash.com/photo-1616531770192-6eaea74c2456?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGNsb3VkJTIwc2VydmVyfGVufDB8fDB8fHww",
    },
    {
      title: "Serverless Architectures",
      img: "https://images.unsplash.com/photo-1550751827-4bd374c3f58b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHNlcnZlcnxlbnwwfHwwfHx8MA%3D%3D",
    },
    {
      title: "Cloud is Also Potentially Vulnerable",
      img: "https://plus.unsplash.com/premium_photo-1683288125490-871195ac4b99?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fGNsb3VkJTIwY29tcHV0aW5nfGVufDB8fDB8fHww",
    },
    {
      title: "Artificial Intelligence (AI) Integration",
      img: "https://images.unsplash.com/photo-1680783954745-3249be59e527?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzJ8fGFpfGVufDB8fDB8fHww",
    },
    {
      title: "Serverless Architectures",
      img: "https://images.unsplash.com/photo-1605918321412-d6504db4748e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGNsb3VkJTIwc2VydmVyfGVufDB8fDB8fHww",
    },
  ];

  return (
    <>
      <div className="flex w-full overflow-auto gap-4 pb-4">
        {topics?.map((item, index) => (
          <Card onClick={() => dispatch(setActiveSection("topics"))}
            key={index}
            className="min-w-44 w-full h-32 rounded-none !rounded-tr-xl !rounded-bl-md flex flex-col justify-between relative overflow-hidden hover:underline underline-offset-2 text-white cursor-pointer "
          >
            <div
              className="absolute w-full h-full top-0 rounded-none !rounded-tr-xl !rounded-bl-md transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-125"
              style={{
                backgroundImage: `url(${item?.img})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="p-2 z-10 flex flex-col items-end w-full">
              <div className="flex items-center -space-x-2">
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 1"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                />
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 2"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
                />
                <Avatar
                  size="xs"
                  variant="circular"
                  alt="user 3"
                  className="border-2 border-white hover:z-10 focus:z-10"
                  src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
                />
              </div>
            </div>
            <div className="z-10 p-2 flex w-full items-center justify-between">
              <Typography className="text-xs font-archivo text-white font-bold">
                {item?.title}
              </Typography>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default MobileTopicsCard;
