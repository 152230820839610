import { Avatar, Button, Card, Carousel, IconButton, Typography } from "@material-tailwind/react";
import { Dislike, DocumentText1, ExportSquare, InfoCircle, Like1, Message2, Profile2User, Save2 } from "iconsax-react";
import { useLocation, useNavigate } from "react-router"
import PDFViewer from "../../../common/components/PdfViewer";
import './post.css';
import Loader from "../../../common/components/Loader";
import { useMutation } from "@apollo/client";
import { CREATE_BLOG, CREATE_MEDIA, CREATE_POLLS, CREATE_RESEARCH_PAPER, EDIT_BLOG, EDIT_MEDIA, EDIT_POLL, EDIT_RESEARCH_PAPER } from "../../../API/Mutations";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setActiveScreen } from "../../../common/components/Sidebar/sidebarSlice";
import { useEffect } from "react";
import { GET_ALL_POSTS_OF_INDIVIDUAL } from "../../../API/Queries";
import { setIsEditPost } from "./activeTabSlice";

const Preview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const state = location?.state;
    // console.log(state)

    const { userInfo, userToken } = useSelector((state) => state.authSlice);
    const { isEdit } = useSelector((state) => state.activeTabSlice);

    const getFileNameFromUrl = (url) => {
        // Use the URL object to parse the URL and extract the pathname
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        // Split the pathname to get the filename
        const fileName = pathname.split("/").pop();
        const parts = fileName.split("-");
        parts.shift(); // Remove the first part
        // Join the remaining parts back together with dashes
        return parts.join("-");
    };

    // create blog query
    const [createBlog, { data, loading, error }] = useMutation(CREATE_BLOG, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    // edit blog query
    const [editBlog, { data: editBlogData, loading: editLoadingBlog, error: editErrorBlog }] = useMutation(EDIT_BLOG, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //create media query
    const [createMedia, { data: mediaData, loading: mediaLoading, error: mediaError }] = useMutation(CREATE_MEDIA, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //edit media query
    const [editMedia, { data: editMediaData, loading: editMediaLoading, error: editMediaError }] = useMutation(EDIT_MEDIA, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //create polls query
    const [createPoll, { data: pollData, loading: pollLoading, error: pollError }] = useMutation(CREATE_POLLS, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //edit polls query
    const [editPoll, { data: editPollData, loading: editPollLoading, error: editPollError }] = useMutation(EDIT_POLL, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //create research paper
    const [createResearchPaper, { data: researchPaperData, loading: researchPaperLoading, error: researchPaperError }] = useMutation(CREATE_RESEARCH_PAPER, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    //edit research paper
    const [editResearchPaper, { data: editResearchPaperData, loading: editResearchPaperLoading, error: editResearchPaperError }] = useMutation(EDIT_RESEARCH_PAPER, {
        onError: (error) => {
            // console.log(error.graphQLErrors[0].message)
            toast.error(error.graphQLErrors[0].message)
        },
        context: {
            headers: {
                Authorization: `${userToken.replace("Bearer ", "")}`,
            },
        },
        refetchQueries: [{
            query: GET_ALL_POSTS_OF_INDIVIDUAL, variables: { authorId: userInfo?._id }, context: {
                headers: {
                    Authorization: `${userToken?.replace("Bearer ", "")}`, // API authentication
                },
            },
        }]
    });

    const handleCreatePost = () => {
        if (state?.type == "blog") {
            let body = {
                author: userInfo?._id,
                description: state?.text,
                title: state?.heading,
                type: "blog"
            }

            let editBody = {
                description: state?.text,
                title: state?.heading,
                blogId: state?.blogId,
                postId: state?.postId
            }
            if (!isEdit) {
                createBlog({ variables: { body: body, file: [state?.file, state?.image] } })
            } else {
                editBlog({ variables: { body: editBody, file: [state?.file, state?.image] } })
            }

        } else if (state?.type == "media") {
            let body = {
                author: userInfo?._id,
                description: state?.text,
                type: "media"
            }

            let editBody = {
                description: state?.text,
                mediaId: state?.mediaId,
                postId: state?.postId
            }
            if (!isEdit) {
                createMedia({ variables: { body: body, file: state?.fileArr } })
            } else {
                editMedia({ variables: { body: editBody, file: state?.fileArr } })
            }
        } else if (state?.type == "poll") {
            let body = {
                author: userInfo?._id,
                type: "poll",
                question: state?.question,
                options: state?.options[0]
            }
            let editBody = {
                question: state?.question,
                options: state?.options[0],
                pollId: state?.pollId,
                postId: state?.postId
            }
            if (!isEdit) {
                createPoll({ variables: { body: body } })
            } else {
                // console.log("editBody", editBody)
                editPoll({ variables: { body: editBody } })
            }
        } else if (state?.type == "research_paper") {
            let body = {
                author: userInfo?._id,
                type: "researchPaper",
                description: state?.text
            }

            let editBody = {
                description: state?.text,
                paperId: state?.paperId,
                postId: state?.postId,
            }

            if (!isEdit) {
                createResearchPaper({ variables: { body: body, file: state?.pdf } })
            } else {
                editResearchPaper({ variables: { body: editBody, file: state?.pdf } })
            }
        }
    }

    useEffect(() => {
        if (data || mediaData || pollData || researchPaperData || editPollData || editResearchPaperData || editBlogData || editMediaData) {
            toast.success("Post created successfully!")
            navigate("/home/profile")
            dispatch(setActiveScreen(0));
            dispatch(setIsEditPost(false))
        }
    }, [data, mediaData, pollData, researchPaperData, editPollData, editResearchPaperData, editBlogData, editMediaData])

    if (loading || mediaLoading || pollLoading || researchPaperLoading
        || editPollLoading || editResearchPaperLoading || editLoadingBlog || editMediaLoading) return <Loader minHeight="100vh" />;

    return (
        <>
            <div className="w-full lg:w-2/3 py-6 lg:py-0">
                <div className="flex gap-4">
                    <Avatar className="hidden lg:block !w-12 !h-12"
                        src="https://docs.material-tailwind.com/img/face-2.jpg"
                        alt="avatar"
                        size="md"
                    />
                    <div className="w-full">
                        <div className="flex gap-2 items-center">
                            <Avatar className="block lg:hidden w-9 h-9"
                                src="https://docs.material-tailwind.com/img/face-2.jpg"
                                alt="avatar"
                                size="md"
                            />
                            <div className="">
                                <Typography className="font-archivo font-bold text-lg text-textLight-400 dark:text-white">Alex Mathew</Typography>
                                <Typography className="font-rubik font-medium text-sm text-textLight-400 dark:text-white">1 min ago</Typography>
                            </div>
                        </div>

                        {/* -------------------------preview for blog---------------- */}
                        {state?.type == "blog" &&
                            <div className="w-full mt-4 flex flex-col gap-4">
                                <Typography variant="paragraph" className="break-all font-bold font-archivo text-base text-textLight-400 dark:text-white">{state?.heading}</Typography>
                                <Typography variant="paragraph" className="break-all text-justify font-normal font-rubik text-sm text-textLight-300 dark:text-white">{state?.text}</Typography>
                                <div className="h-72 w-full lg:w-[80%] relative">
                                    <img src={state?.imageURL} className="object-cover w-full h-full" />
                                    <div className="absolute bottom-0 m-2 bg-primaryLight-50 p-1 rounded-full">
                                        <Profile2User size="20" color="#292D32" />
                                    </div>
                                </div>
                                <div className="w-fit flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                                    <DocumentText1 size="18" /> {isEdit && state?.fileURL ? decodeURIComponent(getFileNameFromUrl(state?.fileURL)) : state?.file?.name}
                                </div>
                            </div>
                        }

                        {/* -------------------------preview for poll----------------- */}
                        {state?.type == "poll" &&
                            <div className="mt-4 flex flex-col gap-4">
                                <Typography className="font-medium font-archivo text-lg text-textLight-400 dark:text-white">{state?.question}</Typography>

                                {Object?.keys(state?.options[0])?.map((option, index) => ( //mapping the option[0] object to show the option input field
                                    <div key={index} className="flex gap-2 w-full items-center justify-between">
                                        <Card className="w-[70%] dark:bg-textLight-700 drop-shadow-[0_0px_3px_rgba(0,0,0,0.25)] dark:drop-shadow-none px-4 py-3 mx-1">
                                            <Typography className="text-textLight-200 dark:text-white font-archivo font-semibold text-base">
                                                {state?.options[0][option]}
                                            </Typography>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        }

                        {/* -------------------------preview for research_paper----------------- */}
                        {state?.type == "research_paper" &&
                            <div className="mt-4 flex flex-col gap-4">
                                <Typography className="font-medium font-archivo text-lg text-textLight-400 dark:text-white">{state?.text}</Typography>

                                <div className="flex flex-wrap justify-center px-4 md:px-0 w-full mt-4">
                                    <PDFViewer pdfURL={state?.pdf && state?.pdf} uploadedFileUrl={(isEdit && !state?.pdf) && state?.file} />
                                </div>
                            </div>
                        }

                        {/* -------------------------preview for media----------------- */}
                        {state?.type == "media" &&
                            <div className="mt-4 flex flex-col gap-4">
                                <Typography className="font-medium font-archivo text-lg text-textLight-400 dark:text-white">{state?.text}</Typography>

                                <div className="w-full">
                                    {state.imageArr?.length > 1 ?
                                        <Carousel transition={{ duration: 1 }}
                                            className="rounded-xl !h-96"
                                            prevArrow={({ handlePrev }) => (
                                                <IconButton
                                                    variant="text"
                                                    color="white"
                                                    size="lg"
                                                    onClick={handlePrev}
                                                    className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={3}
                                                        stroke="currentColor"
                                                        className="h-6 w-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                                        />
                                                    </svg>
                                                </IconButton>
                                            )}

                                            nextArrow={({ handleNext }) => (
                                                <IconButton
                                                    variant="text"
                                                    color="white"
                                                    size="lg"
                                                    onClick={handleNext}
                                                    className="!absolute top-2/4 !right-4 -translate-y-2/4 rounded-full bg-black/50 hover:bg-black"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={3}
                                                        stroke="currentColor"
                                                        className="h-6 w-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                                        />
                                                    </svg>
                                                </IconButton>
                                            )}
                                            navigation={({ setActiveIndex, activeIndex, length }) => (
                                                <div className="absolute bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                                    {new Array(length).fill("").map((_, i) => (
                                                        <span
                                                            key={i}
                                                            className={`block h-1.5 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-primaryLight-500" : "w-1.5 bg-primaryLight-100"
                                                                }`}
                                                            onClick={() => setActiveIndex(i)}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {state?.imageArr?.map((media) => (
                                                <>
                                                    {media?.type && (media?.type.split("/")[0] == "image" ?
                                                        <img src={media?.url} className={` object-contain w-full h-[95%] rounded-xl`} /> :
                                                        <video src={media?.url} muted autoPlay loop className={`object-cover w-full h-[95%] rounded-xl`} />
                                                    )}
                                                    {isEdit && media?.__typename && (media?.__typename == "imageContent" ?
                                                        <img src={media?.url} className={` object-contain w-full h-[95%] rounded-xl`} /> :
                                                        <video src={media?.url} muted autoPlay loop className={`object-cover w-full h-[95%] rounded-xl`} />
                                                    )}
                                                    {/* {media?.type ?
                                                        (media?.type.split("/")[0] == "image" ?
                                                            <img src={media?.url} className={` object-contain w-full h-[95%] rounded-xl`} /> :
                                                            <video src={media?.url} muted autoPlay loop className={`object-cover w-full h-[95%] rounded-xl`} />

                                                        ) :
                                                        <>
                                                            <img src={media?.url} className={` object-contain w-full h-[95%] rounded-xl`} /> :
                                                            <video src={media?.url} muted autoPlay loop className={`object-cover w-full h-[95%] rounded-xl`} /></>
                                                    } */}
                                                    <div className="absolute bottom-4 m-2 bg-primaryLight-50 p-1 rounded-full">
                                                        <Profile2User size="20" color="#292D32" />
                                                    </div>
                                                </>
                                            ))}
                                        </Carousel>
                                        :
                                        <div className="rounded-xl !h-96">
                                            {state?.imageArr[0].type?.split("/")[0] == "image" ?
                                                <img src={state?.imageArr[0]?.url} className={` object-contain w-full h-[95%] rounded-xl`} /> :
                                                <video src={state?.imageArr[0]?.url} muted autoPlay loop className={` object-cover w-full h-[95%] rounded-xl`} />

                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }


                        <div className="mt-2 md:mt-6 flex items-center justify-between border-t md:border-0 px-4 md:pl-0 xl:px-0 py-2 md:py-0 gap-4">
                            <div className="flex gap-4 w-full md:w-fit items-center justify-between px-4 md:pl-0 xl:px-0">
                                <div className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                    <Like1 size="22" />
                                    <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                        Like <span className="hidden md:flex">(230)</span>
                                    </Typography>
                                </div>
                                <div className="flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                    <Dislike size="22" />
                                    <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                        Dislike <span className="hidden md:flex">(30)</span>
                                    </Typography>
                                </div>
                                <div className="flex lg:hidden flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                    <Message2 size="22" />
                                    <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                        Comment <span className="hidden md:flex">(30)</span>
                                    </Typography>
                                </div>
                                <div className="hidden lg:flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                    <Save2 size="22" />
                                    <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                        Save <span className="hidden md:flex">(12)</span>
                                    </Typography>
                                </div>
                                <div className="flex lg:hidden flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                    <ExportSquare size="22" />
                                    <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                        Share <span className="hidden md:flex">(12)</span>
                                    </Typography>
                                </div>
                            </div>
                            <div className="hidden lg:flex flex-col md:flex-row items-center gap-1 text-primaryLight-500 md:dark:text-primaryDark-500 cursor-pointer">
                                <InfoCircle size="22" />
                                <Typography className="flex gap-1 text-sm font-rubik md:font-archivo font-medium">
                                    Report Post
                                </Typography>
                            </div>
                        </div>

                        <input placeholder="Write your comment" className="hidden md:block mt-5 py-2.5 tracking-wide w-full text-sm font-archivo bg-[#F3F3F3] dark:bg-textLight-700 text-black dark:text-white rounded-md pl-2 focus:outline-none focus:text-gray-900 
                         placeholder:text-textLight-400 placeholder:opacity-100 dark:placeholder:text-white"
                        />
                    </div>

                </div>

                <div className="my-6 flex items-end w-full justify-end gap-4">
                    <Button variant="outlined" size="sm" color="green" className="ring-0 focus:ring-0 normal-case tracking-wide rounded-xl font-archivo text-sm !px-6" onClick={() => navigate(-1)}>Cancel</Button>
                    <button className="btn normal-case tracking-wide rounded-xl font-archivo text-sm !px-6" onClick={handleCreatePost}>
                        <div className="svg-wrapper-1">
                            <div className="svg-wrapper">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        fill="currentColor"
                                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <span>Post</span>
                    </button>
                </div>


            </div>
        </>
    )
}

export default Preview