import { useState } from "react";
import PostCard from "../../../common/components/PostCard";
import ScrollView from "../Profile/ScrollView";
import { Typography } from "@material-tailwind/react";


const PostSearch = ({ data }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [scrollView, setScrollView] = useState(false);

    return (
        <>
            {data?.length > 0 ?
                <div className="flex flex-wrap flex-row gap-5 justify-between mb-24 lg:mb-0">
                    {scrollView ? (
                        <ScrollView
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            setScrollView={setScrollView}
                            mention={false}
                            saved={false}
                        />
                    ) : (
                        <>
                            {data?.map((x, i) => {
                                return (
                                    <PostCard
                                        data={x}
                                        isEdit={isEdit}
                                        setIsEdit={setIsEdit}
                                        setScrollView={setScrollView}
                                        key={i}
                                        mention={false}
                                        saved={false}
                                        isScheduled={false}
                                        type={x?.type}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
                :
                <Typography className="mx-10 my-6 font-archivo font-bold text-base min-h-28 text-textLight-400 dark:text-white">
                    No posts found
                </Typography>
            }
        </>
    )
}

export default PostSearch;