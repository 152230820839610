import { Avatar, Button, Chip, Input, Tooltip, Typography } from "@material-tailwind/react";
import TextEditor from "../../../common/components/Editor";
import { Clock, DocumentText1, DocumentUpload, Gallery, SearchNormal1, TagUser, Trash } from "iconsax-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ScheduleCard from "../../../common/components/ScheduleCard";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToMarkdown from 'draftjs-to-markdown';
import { useSelector } from "react-redux";

const Blogs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const { isEdit } = useSelector((state) => state.activeTabSlice);

    const getFileNameFromUrl = (url) => {
        // Use the URL object to parse the URL and extract the pathname
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        // Split the pathname to get the filename
        const fileName = pathname.split("/").pop();
        const parts = fileName.split("-");
        parts.shift(); // Remove the first part
        // Join the remaining parts back together with dashes
        return parts.join("-");
    };

    const [heading, setHeading] = useState(isEdit && data?.type == "blog" ? data?.title : "");
    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState();
    const [file, setFile] = useState();
    const [fileURL, setFileURL] = useState();
    const [showTagOption, setShowTagOption] = useState(false);
    const [open, setOpen] = useState(false);
    const [editorState, setEditorState] = useState(() => isEdit && data?.type == "blog" ?
        EditorState.createWithContent(ContentState.createFromText(data?.description))
        : EditorState.createEmpty());

    const [length, setLength] = useState(editorState?.getCurrentContent().getPlainText('').length)
    // console.log((draftToMarkdown(convertToRaw(editorState.getCurrentContent()))))
    useEffect(() => {
        setLength(editorState.getCurrentContent().getPlainText('').length)
    }, [editorState])

    const handleOpen = () => setOpen(!open);

    const state = {
        file: file,
        image: image,
        imageURL: imageURL,
        heading: heading,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        type: "blog"
    }

    const editState = {
        file: file,
        fileURL: data?.contentPdf[0]?.url,
        image: image,
        imageURL: image ? imageURL : data?.contentImage[0]?.url,
        heading: heading,
        text: draftToMarkdown(convertToRaw(editorState?.getCurrentContent())),
        type: "blog",
        blogId: data?.blogId,
        postId: data?._id
    }

    return (
        <>
            <div className="">

                <div className="flex lg:hidden justify-between items-center gap-3">
                    <div className="">
                        <Typography className="text-textLight-500 font-bold text-base font-archivo dark:text-white">Add new blog</Typography>
                        <Typography variant="paragraph" className="text-textLight-200 dark:text-textLight-50 font-normal text-xs font-rubik">Enter the blog field given below to share it on the platform</Typography>
                    </div>
                    <div onClick={handleOpen} className="w-fit cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                        <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                    </div>
                    <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} />
                </div>
                <div className="mb-4">
                    <Input
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        variant="standard"
                        label="Enter Heading"
                        placeholder="Enter Heading"
                        className="border-b-0 text-textLight-500 dark:text-white !text-lg ring-4 !font-archivo ring-transparent !font-medium placeholder:!font-medium placeholder:text-textLight-200 placeholder:opacity-100"
                        labelProps={{
                            className: "hidden",
                        }}
                    />
                </div>

                <TextEditor editorState={editorState} setEditorState={setEditorState} length={length} />
                <div className="my-6 flex gap-5 w-full items-end justify-between">
                    <div className="">
                        <div className="flex gap-5">

                            {/* --------------upload image button--------------- */}
                            <div className="cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white hover:bg-primaryLight-50 dark:hover:border-primaryDark-500 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                                <Tooltip content="Upload Image" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                    <label className="cursor-pointer">
                                        <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => (
                                            setImage(e.target.files[0]), setImageURL(URL.createObjectURL(e.target.files[0])))}
                                        />
                                        <Gallery className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                                    </label>
                                </Tooltip>
                            </div>

                            {/* -----------------upload docs button------------ */}
                            <div className="cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white hover:bg-primaryLight-50  dark:hover:border-primaryDark-500 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                                <Tooltip content="Upload Document" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                    <label className="cursor-pointer">
                                        <input type="file" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={(e) => (
                                            setFile(e.target.files[0]), setFileURL(URL.createObjectURL(e.target.files[0])))}
                                        />
                                        <DocumentUpload className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                                    </label>
                                </Tooltip>
                            </div>

                            {/* ---------------------tag user button------------------ */}
                            <div onClick={() => setShowTagOption(!showTagOption)}
                                className="cursor-pointer bg-white dark:bg-textLight-500 dark:border dark:border-white hover:bg-primaryLight-50 dark:hover:border-primaryDark-500 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                                <Tooltip content="Tag User" className="bg-gray-600 px-2 py-1 text-xs font-rubik">
                                    <TagUser className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                                </Tooltip>
                            </div>
                        </div>

                        {/* ----------------preview image if available--------------- */}
                        {image && imageURL && (
                            <div className="mt-6">
                                <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload media</Typography>
                                <div className=" mt-4 !relative w-fit">
                                    <Avatar
                                        src={imageURL}
                                        className="w-40 h-40 !rounded-xl"
                                    />
                                    <div className="absolute top-0 right-0 p-1 bg-gradient-to-r from-white to-[rgba(238,238,238,0.88)] rounded-tr-[11px] rounded-bl-sm">
                                        <Trash size="20" className="text-error-500" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {isEdit && data?.type == "blog" && !image && !imageURL && (
                            <div className="mt-6">
                                <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload media</Typography>
                                <div className=" mt-4 !relative w-fit">
                                    <Avatar
                                        src={data?.contentImage[0]?.url}
                                        className="w-40 h-40 !rounded-xl"
                                    />
                                    <div className="absolute top-0 right-0 p-1 bg-gradient-to-r from-white to-[rgba(238,238,238,0.88)] rounded-tr-[11px] rounded-bl-sm">
                                        <Trash size="20" className="text-error-500" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* --------------------preview file name if available------------- */}
                        {file && fileURL && (
                            <div className="mt-6">
                                <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Upload documents</Typography>
                                <div className="mt-4 flex gap-4 items-center w-fit">
                                    <div className="flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                                        <DocumentText1 size="18" /> {file.name}
                                    </div>
                                    <Trash size="20" className="text-error-500 cursor-pointer" />
                                </div>
                            </div>
                        )}

                        {isEdit && data?.type == "blog" && !file && !fileURL && data?.contentPdf?.length > 0 && (
                            <div className="mt-6">
                                <div className="mt-4 flex gap-4 items-center w-fit">
                                    <div className="flex items-center justify-center gap-2 px-3 py-2 bg-gradient-to-t from-white to-[rgba(238,238,238,0.88)] rounded-xl  dark:from-black border border-textLight-50 text-textLight-400 dark:text-white font-archivo font-semibold text-sm">
                                        <DocumentText1 size="18" /> {decodeURIComponent(getFileNameFromUrl(data?.contentPdf[0]?.url))}
                                    </div>
                                    <Trash size="20" className="text-error-500 cursor-pointer" />
                                </div>
                            </div>
                        )}

                        {/* --------------------show tag option------------- */}
                        {showTagOption && (
                            <>
                                <div className="mt-6 w-96">
                                    <Typography className="font-archivo font-bold text-textLight-400 dark:text-white text-lg">Tag people</Typography>
                                    <div className="mt-4 flex gap-4 items-center w-full">
                                        <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <SearchNormal1 size="18" />
                                            </span>
                                            <input
                                                type="search"
                                                name="q"
                                                className="py-2.5 w-full text-sm font-archivo dark:bg-textLight-700 dark:text-white text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-gray-500 placeholder:opacity-100"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </div>
                                    <Chip size="sm" value="@vivek09_76" className="w-fit normal-case rounded-xl bg-primaryLight-50 text-textLight-400 dark:text-white dark:bg-primaryDark-800 font-archivo" />
                                </div>
                            </>
                        )}
                    </div>

                    {/* ----------------section action buttons-------------- */}
                    <div className="gap-5 hidden lg:flex">
                        <div onClick={handleOpen} className="cursor-pointer bg-white dark:bg-textLight-500 hover:bg-primaryLight-50 shadow-[inset_0px_4px_8px_0px_rgba(110,110,110,0.15)] hover:shadow-[inset_0px_4px_8px_0px_rgba(235,250,238,0.15)] dark:shadow-none p-2 rounded-full">
                            <Clock className="text-textLight-400 dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500" />
                        </div>
                        <ScheduleCard open={open} handleOpen={handleOpen} setOpen={setOpen} data={isEdit ? data : state} />
                        <Button
                            // disabled={editorState.getCurrentContent().getPlainText('').length === 0 || !imageURL || !file || heading == ""}
                            color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                        <Button
                            // disabled={editorState.getCurrentContent().getPlainText('').length === 0 || !imageURL || !file || heading == ""}
                            color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2 normal-case" onClick={() =>
                                navigate("/home/preview", {
                                    state: !isEdit ? state : editState
                                })}
                        >Preview</Button>
                    </div>
                </div>
                <div className="gap-5 flex items-end justify-end lg:hidden mb-10">
                    <Button
                        // disabled={editorState.getCurrentContent().getPlainText('').length === 0 || !imageURL || !file || heading == ""}
                        color="green" size="sm" variant="outlined" className="ring-0 focus:ring-0 normal-case rounded-xl font-archivo text-sm font-medium px-6 py-2" onClick={() => navigate("/home/drafts")}>Save Draft</Button>
                    <Button
                        // disabled={editorState.getCurrentContent().getPlainText('').length === 0 || !imageURL || !file || heading == ""}
                        color="green" size="sm" variant="filled" className="rounded-xl font-archivo text-sm font-medium px-6 py-2 normal-case" onClick={() =>
                            navigate("/home/preview", {
                                state: !isEdit ? state : editState
                            })}
                    >Preview</Button>
                </div>

            </div>
        </>
    )
}

export default Blogs;