import {
  Avatar,
  Button,
  Card,
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { AddCircle, SearchNormal1, MinusCirlce } from "iconsax-react";
import chatBg from "../../../assets/images/chat-bg.jpg";
import { LuSearch } from "react-icons/lu";
import { FiVideo } from "react-icons/fi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { TbPhoto } from "react-icons/tb";
import { TiAttachment } from "react-icons/ti";
import smiley from "../../../assets/images/smileys.svg";
import darkSmiley from "../../../assets/images/dark-smileys.svg";
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import {
  setAddMember,
  setEmojiArray,
  setGroupAdmin,
} from "./frequentlyUsedEmojiSlice";
import MessageBubble from "./MessageBubble";
import "./chat.css";
import useOnClickOutside from "./useOnClickOutside";
import { useNavigate } from "react-router";
import { setActiveScreen } from "../../../common/components/Sidebar/sidebarSlice";
import {
  GET_CONVERSATIONS_OF_INDIVIDUAL,
  GET_CONVERSATION_WITH_PARTICULAR_USER,
  GET_GROUP_DATA_OF_INDIVIDUAL,
} from "../../../API/Queries";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_MEMBERS_FROM_GROUP,
  REMOVE_MEMBERS_FROM_GROUP,
  SEND_CHAT_GROUP,
  SEND_CHAT_INDIVIDUAL,
} from "../../../API/Mutations";
import { useSocketContext } from "../../ChatContextBox/ChatContext";
import ReportPostBox from "../../../common/components/ReportPostCard";

const ChatBox = ({ type, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocketContext();
  const { mode } = useSelector((state) => state.themeToggleSlice);
  const [individualChat, setIndividualChat] = useState([]);
  const [individualGroupChat, setIndividualGroupChat] = useState([]);
  const { userInfo, userToken } = useSelector((state) => state.authSlice);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [individualMessage, setIndividualMessage] = useState("");
  const [individualGroupMessage, setIndividualGroupMessage] = useState("");
  const { groupAdmin } = useSelector((state) => state.frequentlyUsedEmojiSlice);
  const { addMember } = useSelector((state) => state.frequentlyUsedEmojiSlice);
  const [frequentUsed, setFrequentUsed] = useState([]);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);
  const { emojiArray } = useSelector((state) => state.frequentlyUsedEmojiSlice);
  useEffect(() => {
    dispatch(setEmojiArray(frequentUsed));
  }, [frequentUsed]);
  const chatRef = useRef();
  const searchRef = useRef();
  useOnClickOutside(searchRef, () => setIsInputVisible(false));

  // ------------------- send individual chat ----------------- //

  const {
    data: conversationData,
    loading: conversationLoading,
    error: conversationError,
    refetch: refetchconversationRequests, // Added refetch function
  } = useQuery(GET_CONVERSATION_WITH_PARTICULAR_USER, {
    variables: {
      getConversationId: type == "individual" && userData ? userData?.id : "",
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "testId")}`,
      },
    },
    onError: (error) => {
      console.log(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  // ------------------- send group chat ----------------- //

  const {
    data: groupConversationData,
    loading: groupConversationLoading,
    error: groupConversationError,
    refetch: refetchgroupconversationRequests, // Added refetch function
  } = useQuery(GET_GROUP_DATA_OF_INDIVIDUAL, {
    variables: {
      groupId:
        type == "group" && userData
          ? userData?.groupId
          : "667134f1c1112ad5d3fff8c",
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      console.log(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  // --------------send individual chat -------------------- //
  const [
    SendMessage,
    {
      data: individualChatData,
      loading: individualChatLoading,
      error: individualChatError,
    },
  ] = useMutation(SEND_CHAT_INDIVIDUAL, {
    variables: {
      input: {
        receiverId: userData && userData?.userId,
        message: individualMessage && individualMessage,
      },
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      console.log(error.graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    if (individualChatData) {
      setIndividualChat((prevMessages) => [
        ...prevMessages,
        individualChatData?.sendMessage,
      ]);
    }
  }, [individualChatData]);

  // --------------send individual group chat -------------------- //
  const [
    SendGroupMessages,
    { data: groupChatData, loading: groupChatLoading, error: groupChatError },
  ] = useMutation(SEND_CHAT_GROUP, {
    variables: {
      input: {
        groupId: userData && userData?.groupId,
        message: individualGroupMessage && individualGroupMessage,
      },
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      console.log(error.graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    if (groupChatData) { 
        setIndividualGroupChat((prevMessages) => [
          ...prevMessages,
          groupChatData?.sendGroupMessages,
        ]);
    }
  }, [groupChatData]);

  // set individual user chat
  useEffect(() => {
    if (type == "individual") {
      if (conversationData && conversationData?.getConversation?.messages) {
        setIndividualChat(conversationData?.getConversation?.messages);
      }
    }
  }, [conversationData]);

  // set individual group chat
  useEffect(() => {
    if (type == "group") {
      if (groupConversationData && groupConversationData?.getGroup?.messages) {
        setIndividualGroupChat(groupConversationData?.getGroup?.messages);
      }
    }
  }, [groupConversationData]);

  //scroll the chat div to bottom
  useEffect(() => {
    {
      groupAdmin && chatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [individualChat, individualGroupChat]);

  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", (newMessage) => {
        setIndividualChat((prevMessages) => [...prevMessages, newMessage]);
      });
      socket.on("groupMessage", (newMessage) => {
        const tempMessage={
          message: newMessage?.message?.message,
          senderId:{
            avatar: newMessage?.message?.senderId?.avatar,
            firstName: newMessage?.message?.senderId?.firstName,
            id: newMessage?.message?.senderId?._id,
            lastName: newMessage?.message?.senderId?.lastName,
            staticUrl: "",
            username: newMessage?.message?.senderId?.property,
            __typename: "User"
          },
          __typename:"GroupMessage"
        }
        setIndividualGroupChat((prevMessages) => [
          ...prevMessages,
          tempMessage,
        ]);
      });

      return () => {
        socket.off("receiveMessage");
        socket.off("groupMessage");
      };
    }
  }, [socket, setIndividualChat, setIndividualGroupChat]);

  const groupMemberIds = new Set(
    groupConversationData?.getGroup?.members?.map((v) => v?.id)
  );

  // ----------------------remove user api----------------------//
  const [
    RemoveMember,
    {
      data: removeUserData,
      loading: removeUserLoading,
      error: removeUserError,
    },
  ] = useMutation(REMOVE_MEMBERS_FROM_GROUP, {
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
  });

  // ----------------------remove user handler----------------------//
  const removeUserHandle = (userId, groupId) => {
    RemoveMember({
      variables: { input: { groupId: groupId, memberId: userId } },
    });
  };

  // ----------------------add user api----------------------//
  const [
    AddMember,
    { data: addUserData, loading: addUserLoading, error: addUserError },
  ] = useMutation(ADD_MEMBERS_FROM_GROUP, {
    onError: (error) => {
      toast.error(error.graphQLErrors[0].message);
    },
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
  });

  // ----------------------add user handler----------------------//
  const addUserHandle = (userId, groupId) => {
    AddMember({
      variables: { input: { groupId: groupId, memberId: userId } },
    });
  };

  useEffect(() => {
    if (type == "individual") {
      refetchconversationRequests();
    }
  }, [userData, type == "individual"]);
  useEffect(() => {
    if (type == "group") {
      refetchgroupconversationRequests();
    }
  }, [userData, type == "group", removeUserData, addUserData]);
  // console.log("groupConversationData?.getGroup", groupConversationData?.getGroup?.creator)

  return (
    <>
      <Card
        className="w-full h-full rounded-2xl !border-0 flex flex-col justify-between bg-white dark:bg-textLight-600"
        style={{
          backgroundImage: !groupAdmin ? "none" : `url(${chatBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div>
          {/* --------------------chat header---------------- */}
          <div
            className={`${
              groupAdmin == false && `sticky -top-2`
            } bg-white dark:bg-textLight-600 w-full h-fit p-4 rounded-tl-2xl rounded-tr-2xl ${
              groupAdmin == true &&
              `border border-b-0 dark:border-0 dark:border-b border-textLight-100`
            }`}
          >
            <div className="flex flex-wrap gap-2 items-center justify-between">
              <div
                className="cursor-pointer flex gap-2 items-center"
                onClick={() =>
                  type == "group"
                    ? dispatch(setGroupAdmin(!groupAdmin))
                    : // dispatch(setActiveScreen(0)),
                      navigate("/home/user-profile")
                }
              >
                <Avatar
                  src={
                    userData?.avatar
                      ? userData?.avatar
                      : "https://docs.material-tailwind.com/img/face-2.jpg"
                  }
                  alt="avatar"
                  size="md"
                  className={type == "group" && "w-[99px] h-[99px]"}
                />
                <div>
                  <Typography className="text-sm font-archivo font-semibold text-textLight-500 dark:text-white">
                    {type == "group"
                      ? userData?.groupName
                      : userData?.firstName + " " + userData?.lastName}
                  </Typography>
                  <Typography className="text-xs font-archivo mt-1 text-textLight-400 dark:text-white">
                    Online - Last seen, 2.02pm
                  </Typography>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                {isInputVisible && (
                  <div
                    ref={searchRef}
                    className="animate-slideInRight relative rounded-2xl w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                      <div className="p-1">
                        <LuSearch className="text-xl" />
                      </div>
                    </span>
                    <input
                      type="search"
                      name="q"
                      className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900  placeholder:text-textLight-300 placeholder:opacity-100"
                      placeholder="Search message here"
                    />
                  </div>
                )}
                {!isInputVisible && (
                  <LuSearch
                    onClick={() => setIsInputVisible(true)}
                    className="text-textLight-300 dark:text-white text-xl cursor-pointer"
                  />
                )}
                <FiVideo className="text-textLight-300 dark:text-white text-xl" />

                <Menu placement="left-start">
                  <MenuHandler>
                    <IconButton variant="text" className="dark:text-white">
                      {" "}
                      <PiDotsThreeOutlineVerticalBold className="text-textLight-300 dark:text-white text-xl" />
                    </IconButton>
                  </MenuHandler>
                  <MenuList className="flex flex-wrap max-w-[10px] justify-center gap-2 py-0">
                    <ReportPostBox type="chat" />
                    {groupConversationData?.getGroup?.creator ==
                      userInfo?._id && (
                      <MenuItem
                        className="flex px-0 justify-center"
                        onClick={() =>
                          navigate("/home/edit-group", {
                            state: { data: groupConversationData?.getGroup },
                          })
                        }
                      >
                        Edit Group
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </div>
            </div>

            {/* ------------------------User List--------------------- */}

            {!groupAdmin && (
              <div className="pt-6">
                <div className="flex flex-wrap justify-between">
                  <Typography className="font-archivo text-lg font-bold text-textLight-500 dark:text-white">
                    Group Members{" "}
                    <span className="text-textLight-400 dark:text-white">
                      ({groupConversationData?.getGroup?.members.length - 1})
                    </span>
                  </Typography>
                  {groupConversationData?.getGroup?.creator == userInfo?._id &&
                    (!addMember ? (
                      <Button
                        variant="filled"
                        color="green"
                        size="sm"
                        className="flex flex-wrap gap-2 items-center normal-case"
                        onClick={() => dispatch(setAddMember(true))}
                      >
                        <AddCircle />
                        Add Member
                      </Button>
                    ) : (
                      <div className="flex justify-end gap-2">
                        <Button
                          variant="outlined"
                          color="green"
                          size="sm"
                          className="flex flex-wrap gap-2 items-center normal-case"
                          onClick={() => dispatch(setAddMember(false))}
                        >
                          <MinusCirlce />
                          Remove Member
                        </Button>
                        {/* <Button
                        variant="filled"
                        color="green"
                        size="sm"
                        className="flex flex-wrap gap-2 items-center normal-case"
                        onClick={() => dispatch(setAddMember(false))}
                      >
                        Save
                      </Button> */}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {/* ------------------------joined member list component--------------------- */}
            {!groupAdmin && !addMember && (
              <div className="w-full px-1 mt-4">
                <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                  <div className="grid place-items-center h-full w-12 text-gray-200">
                    <SearchNormal1 color="#999999" />
                  </div>
                  <input
                    className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                    type="text"
                    id="search"
                    placeholder="Search Members"
                  />
                </div>
              </div>
            )}
            {!groupAdmin && !addMember && (
              <div className="overflow-y-auto h-[70vh]">
                {!groupAdmin && !addMember && (
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 place-items-stretch w-full mt-6 justify-center">
                    {groupConversationData &&
                      groupConversationData?.getGroup?.members
                        ?.filter((v) => v?.id && v?.id !== userInfo?._id)
                        ?.map((v, index) => (
                          <div
                            className="flex items-center justify-between gap-4 mb-6 w-full max-w-[350px]"
                            key={index}
                          >
                            <div className="flex gap-2 items-center justify-center cursor-pointer">
                              <Avatar
                                src={
                                  v?.avatar
                                    ? v?.avatar
                                    : "https://docs.material-tailwind.com/img/face-2.jpg"
                                }
                                alt="avatar"
                                size="sm"
                              />
                              <div>
                                <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                                  {v?.firstName && v?.firstName}{" "}
                                  {v?.lastName && v?.lastName}
                                </Typography>
                                <Typography className="text-base font-rubik text-textLight-300 dark:text-white">
                                  @{v?.username ? v?.username : "sumidev"}
                                </Typography>
                              </div>
                            </div>
                            <div>
                              {groupConversationData?.getGroup?.creator ==
                              userInfo?._id ? (
                                <Button
                                  variant="outlined"
                                  color="green"
                                  size="sm"
                                  onClick={() =>
                                    removeUserHandle(
                                      v?.id,
                                      groupConversationData?.getGroup?.id
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Chip
                                  color="green"
                                  variant="ghost"
                                  value="Admin"
                                  className="rounded-full text-primaryLight-500 dark:text-text-primaryLight-500 normal-case"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                )}
              </div>
            )}

            {/* ------------------------add member component--------------------- */}
            {addMember && !groupAdmin && (
              <div className="w-full px-1 mt-4">
                <div className="relative flex items-center w-full h-12 rounded-lg shadow-lg bg-transparent overflow-hidden dark:bg-[#171717]">
                  <div className="grid place-items-center h-full w-12 text-gray-200">
                    <SearchNormal1 color="#999999" />
                  </div>
                  <input
                    className="peer h-full w-full outline-none text-sm text-gray-700 dark:text-textDark-600 pr-2 bg-transparent"
                    type="text"
                    id="search"
                    placeholder="Search Following"
                  />
                </div>
              </div>
            )}
            {addMember && !groupAdmin && (
              <div className="overflow-y-auto h-[70vh]">
                {addMember && !groupAdmin && (
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 place-items-stretch w-full mt-6 justify-center">
                    {userInfo?.following
                      ?.filter((v) => !groupMemberIds?.has(v?._id))
                      .map((v, index) => (
                        <div
                          className="flex items-center justify-between gap-4 mb-6 w-full max-w-[350px]"
                          key={index}
                        >
                          <div className="flex gap-2 items-center justify-center cursor-pointer">
                            <Avatar
                              src={
                                v?.avatar
                                  ? v?.avatar
                                  : "https://docs.material-tailwind.com/img/face-2.jpg"
                              }
                              alt="avatar"
                              size="sm"
                            />
                            <div>
                              <Typography className="text-lg font-archivo font-semibold text-textLight-500 dark:text-white">
                                {v?.firstName && v?.firstName}{" "}
                                {v?.lastName && v?.lastName}
                              </Typography>
                              <Typography className="text-base font-rubik text-textLight-300 dark:text-white">
                                @{v?.username && v?.username}
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Button
                              variant="outlined"
                              color="green"
                              size="sm"
                              onClick={() =>
                                addUserHandle(
                                  v?._id,
                                  groupConversationData?.getGroup?.id
                                )
                              }
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* -----------------messages div-------------------- */}
          {groupAdmin && (
            <div ref={chatRef} className="p-8 max-h-[70vh] overflow-y-auto">
              <MessageBubble
                messages={
                  type == "group" ? individualGroupChat : individualChat
                }
                userData={userData}
                type={type}
              />
            </div>
          )}
        </div>

        {/* ------------------------chat footer with chat input--------------------- */}
        {groupAdmin && (
          <div className="sticky -bottom-5 px-2 pt-2 !bg-black !bg-opacity-35 rounded-bl-2xl rounded-br-2xl">
            <div className="w-full">
              <Textarea
                placeholder="Type your message here..."
                variant="outlined"
                value={
                  type == "individual"
                    ? individualMessage
                    : individualGroupMessage
                }
                onChange={(e) => {
                  type == "individual"
                    ? setIndividualMessage(e.target.value)
                    : setIndividualGroupMessage(e.target.value);
                }}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                className="border bg-white dark:bg-textLight-700 !border-transparent focus:!border-transparent dark:!border-primaryDark-500
                             dark:focus:!border-primaryDark-500 dark:!border text-textLight-400 placeholder:text-textLight-400 dark:text-white dark:placeholder:text-white font-medium break-all"
              />
            </div>
            <div className="rounded-lg bg-white dark:bg-textLight-700 mb-2 px-4 py-2 flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <Tooltip
                  content="Image"
                  className="bg-gray-600 px-2 py-1 text-xs font-rubik"
                >
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <TbPhoto className="text-xl text-textLight-400 dark:text-white" />
                  </label>
                </Tooltip>
                <Tooltip
                  content="Document"
                  className="bg-gray-600 px-2 py-1 text-xs font-rubik"
                >
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <TiAttachment className="text-2xl text-textLight-400 dark:text-white" />
                  </label>
                </Tooltip>
                <img
                  src={!mode ? smiley : darkSmiley}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  onClick={() => setEmojiOpen(!emojiOpen)}
                />
                <div className="flex">
                  {emojiArray &&
                    emojiArray?.map((emoji, index) => (
                      <p key={index}>{emoji}</p>
                    ))}
                </div>
                <Typography className="text-xs font-rubik font-normal">
                  {image && image.name}
                </Typography>
                <Typography className="text-xs font-rubik font-normal">
                  {file && file.name}
                </Typography>
              </div>
              <Button
                color="green"
                size="sm"
                className="normal-case text-sm"
                onClick={() => {
                  {
                    type == "individual" ? SendMessage() : SendGroupMessages();
                  }
                  setIndividualMessage("");
                  setIndividualGroupMessage("");
                }}
              >
                Send
              </Button>
            </div>
            {emojiOpen && (
              <div className="pb-2">
                <EmojiPicker
                  open={emojiOpen}
                  width="100%"
                  previewConfig={{ showPreview: false }}
                  height={225}
                  theme={!mode ? "light" : "dark"}
                  searchDisabled={true}
                  onEmojiClick={(data) =>
                    setFrequentUsed((prev) => [...prev, data.emoji])
                  }
                />
              </div>
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default ChatBox;
