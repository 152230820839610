import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "./pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFViewer = ({ pdfURL, uploadedFileUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    if (pdfURL) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(pdfURL);
      reader.onloadend = () => {
        const pdfArray = new Uint8Array(reader.result);
        const pdfBlob = new Blob([pdfArray], { type: "application/pdf" });
        setPdfBlob(pdfBlob);
      };
    }
  }, [pdfURL]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      {pdfBlob && (
        <div style={{ height: "70vh", width: "100%", overflowY: "auto" }}>
          <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={1000}
              />
            ))}
          </Document>
        </div>
      )}
      {uploadedFileUrl && (
        <div style={{ height: "500px", width: "100%" }}>
          <iframe
            src={`https://docs.google.com/gview?url=${uploadedFileUrl}&embedded=true#page=1`}
            style={{ width: "100%", height: "100%", border: "none" }}
          />
        </div>
      )}
    </>
  );
};

export default PDFViewer;
