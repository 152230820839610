import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { useState } from "react";
import CustomSwitch from "../../../common/components/CustomSwitch";
import HelpAndSupport from "./Help&Support";
import ProfileSettings from "./ProfileSettings";
import ThemeToggle from "../../../common/components/ThemeModeToggle";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("Profile Settings");

  const data = [
    {
      label: "Profile Settings",
      value: "Profile Settings"
    },
    {
      label: "Notifications",
      value: "Notifications"
    },
    {
      label: "Help & Support",
      value: "Help & Support"
    }
  ];


  return (
    <div className="w-full dark:bg-[#212121]">
      <div className="flex lg:hidden bg-[#F3F3F3]/30 dark:bg-transparent dark:text-white items-center justify-between p-2 my-4 rounded-md">
        <Typography className="font-rubik font-medium text-base">Theme</Typography>
        <ThemeToggle />
      </div>
      <Tabs value="Profile Settings" className="w-full flex flex-wrap justify-center md:justify-start">
        <TabsHeader
          className="bg-transparent font-archivo text-lg"
          indicatorProps={{
            className: "bg-primaryLight-700/25 font-archivo shadow-none !text-primaryLight-700 dark:bg-transparent dark:border-2 dark:border-primaryLight-500"
          }}
        >
          <div className="flex flex-row w-[90vw] md:w-full overflow-auto">
            {data.map(({ label, value }) => (
              <div key={value}>
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={activeTab === value ? "text-primaryLight-700 text-lg font-archivo w-[10rem] md:w-[12rem]  dark:text-primaryLight-500" : "text-gray-900 text-lg font-archivo w-[10rem] md:w-[12rem]  dark:text-textDark-600"}
                >
                  {label}
                </Tab>
              </div>
            ))}
          </div>
        </TabsHeader>
        <TabsBody className="w-full">
          <TabPanel value="Notifications" className="p-0 w-full">
            <Typography variant="paragraph" className="text-left font-archivo text-lg text-gray-900 dark:text-textDark-600 font-bold my-6">
              Notification Settings
            </Typography>

            <div className="flex flex-col gap-6">
              <div className="flex gap-2 items-center justify-between my-[4px]">
                <div>
                  <Typography variant="paragraph" className="text-left font-archivo font-semibold text-gray-900 dark:text-textDark-600">
                    Notifications for Posts
                  </Typography>
                </div>
                <CustomSwitch />
              </div>
              <div className="flex gap-2 items-center justify-between my-[4px]">
                <div>
                  <Typography variant="paragraph" className="text-left font-archivo font-semibold text-gray-900 dark:text-textDark-600">
                  Notifications for Messages
                  </Typography>
                </div>
                <CustomSwitch />
              </div>
              <div className="flex gap-2 items-center justify-between my-[4px]">
                <div>
                  <Typography variant="paragraph" className="text-left font-archivo font-semibold text-gray-900 dark:text-textDark-600">
                  Notifications for Interested Domains
                  </Typography>
                </div>
                <CustomSwitch />
              </div>
              <div className="flex gap-2 items-center justify-between my-[4px]">
                <div>
                  <Typography variant="paragraph" className="text-left font-archivo font-semibold text-gray-900 dark:text-textDark-600">
                  Notifications for Trending Topics
                  </Typography>
                </div>
                <CustomSwitch />
              </div>
              <div className="flex gap-2 items-center justify-between my-[4px]">
                <div>
                  <Typography variant="paragraph" className="text-left font-archivo font-semibold text-gray-900 dark:text-textDark-600">
                    Reminders
                  </Typography>
                </div>
                <CustomSwitch />
              </div>
            </div>
          </TabPanel>
          <TabPanel className="p-0" value="Profile Settings">
            <ProfileSettings />
          </TabPanel>
          <TabPanel className="p-0" value="Help & Support">
            <HelpAndSupport />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default Settings;