import React, { useState } from 'react';
import { Carousel, IconButton, Typography, Rating } from "@material-tailwind/react";

const Testimonials = () => {
  const [nav, setNav] = useState(false);
    const dummyCardData = [
        {
            userName:"Hannah Schmitt",
            userField:"Cyber Security",
            userDescription:"Amazing platform to study cyber security. Every course is well organized and study material is up to the mark. Haven’t faced any problem browsing through the platform.",
            rating:3,
            createdAt:"24 hrs. ago"
        },
        {
            userName:"Han mitt",
            userField:"Cyber Security",
            userDescription:"Amazing platform to study cyber security. Every course is well organized and study material is up to the mark. Haven’t faced any problem browsing through the platform.",
            rating:3,
            createdAt:"24 hrs. ago"
        },
        {
            userName:"Hah Schm",
            userField:"Cyber Security",
            userDescription:"Amazing platform to study cyber security. Every course is well organized and study material is up to the mark. Haven’t faced any problem browsing through the platform.",
            rating:3,
            createdAt:"24 hrs. ago"
        }
    ]
  return (
    <Carousel
      className="rounded-xl md:w-2/3 sm:max-w-full"
      autoplay={true}
      autoplayDelay={5000}
      navigation={()=>setNav(nav)}
      loop={true}
      prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4 hover:bg-transparent rounded-full"
        >
          <svg className="h-12 w-12 fill-[#46e356]" strokeWidth={2} strokeLinejoin="round" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z"></path>
          </svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 !right-4 -translate-y-2/4 hover:bg-transparent rounded-full"
        >
        <svg className="h-12 w-12 fill-[#46e356]" strokeWidth={2} strokeLinejoin="round" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path 
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"></path>
        </svg>
        </IconButton>
      )}
    >
    {dummyCardData.map((item, index) => {
        return (
     <div key={index} className="h-full w-full mt-[51px] flex justify-center">
     <div className='min-h-[341px] w-2/3 bg-textLight-600 border-2 border-textLight-300 rounded-xl mb-12'>
        <div className='flex flex-wrap justify-center w-full mb-4'>
     <img
      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80"
      alt="avatar"
      className="h-[102px] w-[102px] -mt-[51px] !rounded-full  object-cover object-center border-[0.5px] border-textLight-300" />
        </div>
        <div className='flex flex-wrap justify-center w-full mb-2'>
            <Typography className="font-archivo text-2xl text-textLight-50 font-semibold">{item.userName}</Typography>
        </div>
        <div className='flex flex-wrap justify-center w-full mb-6'>
            <Typography className="font-archivo text-lg text-textLight-50 font-light">{item.userField}</Typography>
        </div>
        <div className='flex flex-wrap justify-center w-full mb-5'>
            <Typography className="font-archivo text-lg text-textLight-50 font-normal px-6 text-center">“{item.userDescription}” </Typography>
        </div>
        <div className='flex flex-wrap justify-center w-full mb-2'>
        <div className="inline-flex items-center">
            <Rating value={item?.rating} />
    </div>
         </div>
        <div className='flex flex-wrap justify-center w-full mb-3'>
            <Typography className="font-archivo text-sm text-textLight-50 font-normal">{item.createdAt}</Typography>
        </div>
     </div>
     </div>
        )
    })}
     
    </Carousel>
  )
}

export default Testimonials;