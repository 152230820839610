
import './loader.css';

const Loader = ({ minHeight }) => {
    return (
        <>
            <div className="spinnerContainer" style={{minHeight: minHeight}}>
                <div className="spinner !border-r-primaryLight-500 dark:!border-r-primaryDark-500"></div>
                <div className="loader !text-2xl !font-archivo">
                    <p className='text-textLight-700 dark:text-white font-bold'>Loading</p>
                    <div className="words">
                        <span className="word text-primaryLight-500 dark:text-primaryDark-500 font-semibold">posts</span>
                        <span className="word text-primaryLight-500 dark:text-primaryDark-500 font-semibold">images</span>
                        <span className="word text-primaryLight-500 dark:text-primaryDark-500 font-semibold">followers</span>
                        <span className="word text-primaryLight-500 dark:text-primaryDark-500 font-semibold">hashtags</span>
                        <span className="word text-primaryLight-500 dark:text-primaryDark-500 font-semibold">posts</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader;