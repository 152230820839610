import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import signupimg from "../../../assets/images/signup1.svg";
import gIcon from "../../../assets/images/googleIcon.png";
import {
  Sms,
  Key,
  Eye,
  EyeSlash,
  Call,
  ProfileCircle,
  Location,
  ArrowLeft,
} from "iconsax-react";
import { Country, State } from "country-state-city";
import {
  Typography,
  Input,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import { useMutation } from "@apollo/client";
import Loader from "../../../common/components/Loader";
import { SIGNUP } from "../../../API/Mutations";
import { useDispatch } from "react-redux";
import { setRegisterSuccess, setUserInfo, setUserToken } from "../authSlice";
import toast from "react-hot-toast";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [signUp, { data, loading, error }] = useMutation(SIGNUP, {
    onError: (error) => {
      // console.log(error.graphQLErrors[0].message)
      toast.error(error.graphQLErrors[0].message)
    }
  });

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const countries = Country.getAllCountries();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email("Invalid email address").required(),
    password: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    signUp({ variables: { body: values } });

  };

  useEffect(() => {
    if (data) {
      dispatch(setUserInfo(data?.signUp?.data));
      dispatch(setUserToken(data?.signUp?.token));
      dispatch(setRegisterSuccess(true));
      toast.success("User registered successfully")
      navigate("/setup-profile");
    }
  }, [data])

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <div className="grid grid-cols-12 grid-rows-1 gap-0 h-screen justify-evenly">
        <div className="lg:col-span-6 h-screen hidden lg:block">
          <img
            src={signupimg}
            alt="signupimg"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 md:justify-self-center lg:w-full h-screen overflow-y-auto lg:overflow-y-auto md:overflow-visible">
          <div className="h-full flex flex-col items-center justify-center pl-4 pr-4 lg:pl-10 md:pl-5 sm:pl-4 lg:pr-16 md:pr-10 sm:pr-4 ">
            <div className="flex w-full float-right justify-end items-center">
              <Button
                onClick={() => navigate("/")}
                variant="text"
                color="green"
                className="font-archivo text-lg normal-case !p-0 flex items-center gap-1 hover:bg-transparent"
              >
                <ArrowLeft size="20" /> Back
              </Button>
            </div>
            <div className="w-full">
              <Typography className="font-archivo text-3xl font-bold mb-8">
                Rangestorm Community
              </Typography>
              <Typography className="font-archivo text-xl font-medium">
                Join the Revolution!
              </Typography>
              <Typography className="font-archivo text-base font-normal text-textLight-300 mb-8">
                Be among the first to experience our ground breaking community.
              </Typography>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              className="w-full h-full"
            >
              {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Form className="w-full">
                  <div className="flex flex-wrap flex-col lg:flex-row justify-between">
                    <div className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.firstName && touched.firstName ? "border-error-500 animate-shake" : "border-black"}`}>
                      <div className="min-w-[24px] min-h-[24px]">
                        <ProfileCircle className={`${errors.firstName && touched.firstName ? "text-error-500" : 'text-textLight-400'}`} />
                      </div>
                      <Field
                        as={Input}
                        variant="standard"
                        label="Enter First Name"
                        placeholder="Enter First Name"
                        name="firstName"
                        className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: "hidden",
                        }}
                      />
                    </div>

                    <div className={`mb-8 flex items-center w-full lg:w-[47%] gap-6 border-b-2 ${errors.lastName && touched.lastName ? "border-error-500 animate-shake" : "border-black"}`}>
                      <div className="min-w-[24px] min-h-[24px]">
                        <ProfileCircle className={`${errors.lastName && touched.lastName ? "text-error-500" : 'text-textLight-400'}`} />
                      </div>
                      <Field
                        as={Input}
                        variant="standard"
                        label="Enter Last Name"
                        placeholder="Enter Last Name"
                        name="lastName"
                        className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: "hidden",
                        }}
                      />
                    </div>

                  </div>

                  <div className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.email && touched.email ? "border-error-500 animate-shake" : "border-black"}`}>
                    <Sms className={`${errors.email && touched.email ? "text-error-500" : 'text-textLight-400'}`} />
                    <Field
                      as={Input}
                      variant="standard"
                      label="Enter email address"
                      placeholder="Enter email address"
                      name="email"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                  <div className={`mb-8 relative flex items-center gap-6 border-b-2 ${errors.password && touched.password ? "border-error-500 animate-shake" : "border-black"}`}>
                    <Key className={`${errors.password && touched.password ? "text-error-500" : 'text-textLight-400'}`} />
                    <Field
                      as={Input}
                      variant="standard"
                      type={passwordVisible ? "text" : "password"}
                      label="Enter the password"
                      placeholder="Enter the password"
                      name="password"
                      className="border-b-0 text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: "hidden",
                      }}
                      icon={
                        passwordVisible ? (
                          <EyeSlash size="20"
                            className="text-black cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <Eye size="20"
                            className="text-black cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        )
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="filled"
                    size="lg"
                    color="green"
                    className="py-4 normal-case w-full"
                  >
                    Get Started
                  </Button>
                </Form>
              )}
            </Formik>
            <Typography className="font-archivo text-base font-normal mb-3 text-center mt-2">
              or
            </Typography>
            <div className="flex flex-wrap items-center justify-center mb-6">
              <div
                className="flex flex-wrap flex-row gap-2 py-1 px-5 items-center justify-center rounded-lg border"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={gIcon}
                  alt="google-Icon"
                  style={{ width: "24px", height: "24px" }}
                />
                <Typography className="font-rubik font-normal text-xs">
                  Sign in with Google
                </Typography>
              </div>
            </div>
            <Typography className="font-archivo text-sm font-semibold text-textLight-300 text-center">
              Already have an account?{" "}
              <span onClick={() => navigate("/login")}
                className="font-archivo font-bold text-supportLight-500"
                style={{ cursor: "pointer" }}
              >
                Login
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
