

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: false
};

const themeToggleSlice = createSlice({
    name: 'themeToggleSlice',
    initialState,
    reducers: {
        setMode: (state, { payload }) => {
            state.mode = payload;
        }
    }
});

export const { setMode } = themeToggleSlice.actions;
export default themeToggleSlice.reducer;
