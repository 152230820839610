import mobileNavSlice from "../common/components/BottomNav/mobileNavSlice";
import sidebarSlice from "../common/components/Sidebar/sidebarSlice";
import themeToggleSlice from "../common/components/ThemeModeToggle/themeToggleSlice";
import authSlice from "../containers/Auth/authSlice";
import frequentlyUsedEmojiSlice from "../containers/Pages/Chat/frequentlyUsedEmojiSlice";
import activeTabSlice from "../containers/Pages/CreatePost/activeTabSlice";
import homeSectionSlice from "../containers/Pages/HomePage/homeSectionSlice";

const rootReducer = {
    themeToggleSlice,
    sidebarSlice,
    homeSectionSlice,
    frequentlyUsedEmojiSlice,
    mobileNavSlice,
    authSlice,
    activeTabSlice
}

export default rootReducer;