

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mobileActiveScreen: 0
};

const mobileNavSlice = createSlice({
    name: 'mobileNavSlice',
    initialState,
    reducers: {
        setMobileActiveScreen: (state, { payload }) => {
            state.mobileActiveScreen = payload;
        }
    }
});

export const { setMobileActiveScreen } = mobileNavSlice.actions;
export default mobileNavSlice.reducer;
