import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import { LuSearch } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setActiveSection } from "../HomePage/homeSectionSlice";
import { setAddMember, setGroupAdmin } from "./frequentlyUsedEmojiSlice";
import { GET_CONVERSATIONS_OF_INDIVIDUAL } from "../../../API/Queries";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

const MessageCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [individualUsers, setIndividualUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const { userInfo, userToken } = useSelector((state) => state.authSlice);
  // const individualUsers = userInfo?.followers.concat(userInfo?.following);

  const {
    data: conversationData,
    loading: conversationLoading,
    error: conversationError,
    refetch: refetchconversationRequests, // Added refetch function
  } = useQuery(GET_CONVERSATIONS_OF_INDIVIDUAL, {
    context: {
      headers: {
        Authorization: `${userToken.replace("Bearer ", "")}`,
      },
    },
    onError: (error) => {
      toast.error(error.graphQLErrors[0]?.message || "An error occurred");
    },
  });

  useEffect(() => {
    refetchconversationRequests();
  }, [conversationData]);

  useEffect(() => {
    if (conversationData) {
      const users = conversationData?.getConversations?.slice()?.reverse();
      setIndividualUsers(users);
    }
  }, [conversationData]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = individualUsers.filter((user) => {
        const userName =
          user?.participants[0]?.firstName +
          " " +
          user?.participants[0]?.lastName;
        if (userName?.toLowerCase().includes(searchQuery.toLowerCase())) {
          return userName;
        }
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchQuery, individualUsers]);

  return (
    <>
      <Card className="dark:bg-textLight-700 h-2/4 pb-3">
        <div className="p-6 pb-0">
          <Typography
            variant="h4"
            className="font-archivo text-xl text-textLight-500 dark:text-white font-bold mb-4"
          >
            Messages (
            {conversationData?.getConversations
              ? conversationData?.getConversations?.length
              : 0}
            )
          </Typography>

          <div className="relative rounded-xl mb-4 w-full text-gray-600 focus-within:text-gray-400 drop-shadow-lg">
            <span className="absolute inset-y-0 left-0 flex items-center pl-1">
              <div className="p-1">
                <LuSearch className="text-xl" />
              </div>
            </span>
            <input
              type="search"
              name="q"
              className="py-2.5 w-full text-sm font-archivo text-black rounded-md pl-10 focus:outline-none
                         focus:bg-white focus:text-gray-900 dark:bg-textLight-500 dark:text-white placeholder:text-gray-500 placeholder:opacity-100"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <div className="absolute top-full left-0 w-full bg-white dark:bg-textLight-500 border border-gray-200 dark:border-gray-700 rounded-md z-10">
                {filteredUsers.length > 0 ? (
                  filteredUsers.map((user, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSearchQuery("");
                      }}
                      className="px-4 py-2 flex items-center cursor-pointer bg-textLight-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <Avatar
                        src={
                          user?.participants[0]?.avatar
                            ? user?.participants[0]?.avatar
                            : "https://docs.material-tailwind.com/img/face-2.jpg"
                        }
                        alt="avatar"
                        size="sm"
                      />
                      <Typography className="ml-2 text-sm font-archivo text-gray-700 dark:text-gray-200">
                        {user?.participants[0]?.firstName +
                          "  " +
                          user?.participants[0]?.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200">
                    No users found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className="h-full p-2 pt-0 overflow-auto flex flex-col [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:rounded-full
                [&::-webkit-scrollbar-thumb]:bg-[#333333] dark:[&::-webkit-scrollbar-track]:bg-[#212121] dark:[&::-webkit-scrollbar-thumb]:bg-white "
        >
          {conversationData?.getConversations
            ?.slice()
            .reverse()
            .map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  const userData1 = {
                    id: item?._id,
                    userId: item?.participants[0]?.id,
                    firstName: item?.participants[0]?.firstName,
                    lastName: item?.participants[0]?.lastName,
                    avatar: item?.participants[0]?.avatar
                      ? item?.participants[0]?.avatar
                      : "https://docs.material-tailwind.com/img/face-2.jpg",
                    userName: item?.participants[0]?.username,
                    type: "individual",
                  };
                  dispatch(setActiveSection("messages"));
                  navigate(`/home/chat/${index}`, {
                    state: { type: "individual", userData: userData1 },
                  });
                  dispatch(setGroupAdmin(true));
                  dispatch(setAddMember(false));
                }}
                className="px-4 py-3 flex items-center justify-between cursor-pointer hover:bg-primaryLight-500 hover:bg-opacity-15"
              >
                <div className="flex gap-2 items-center">
                  <Avatar
                    src={
                      item?.participants[0]?.avatar
                        ? item?.participants[0]?.avatar
                        : "https://docs.material-tailwind.com/img/face-2.jpg"
                    }
                    alt="avatar"
                    size="sm"
                  />
                  <div>
                    <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white">
                      {item?.participants[0]?.firstName
                        ? item?.participants[0]?.firstName +
                          " " +
                          item?.participants[0]?.lastName
                        : "sumit Sachdeva"}
                    </Typography>
                    <Typography className="text-xs font-normal font-rubik text-textLight-400 dark:text-white">
                      {item?.messages && item?.messages?.length > 0
                        ? item?.messages[item?.messages?.length - 1]?.message
                        : "Star your conversation..."}
                    </Typography>
                  </div>
                </div>
                <div className="flex gap-1 flex-col items-center">
                  <Typography className="text-sm font-archivo font-normal text-supportLight-500 dark:text-supportDark-100">
                    21/09/23
                  </Typography>
                  <Typography className="text-xs font-rubik text-center text-white font-bold bg-supportLight-500 px-1 rounded-full">
                    {item?.messages?.length > 0 && item?.messages?.length}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      </Card>
    </>
  );
};

export default MessageCard;
