import {
    Navigate,
    useLocation,
    useNavigate,
    useParams,
    useRoutes
} from 'react-router-dom';
import LandingLayout from '../common/layout/LandingLayout';
import LandingPage from '../containers/Pages/LandingPage/LandingPage';
import HomeLayout from '../common/layout/HomeLayout';
import HomePage from '../containers/Pages/HomePage';
import Login from '../containers/Auth/Login';
import SignUp from '../containers/Auth/SignUp';
import ForgetPasswordPage from '../containers/Auth/ForgetPassword';
import OtpVerificationPage from '../containers/Auth/ForgetPassword/OtpVerification';
import SetupProfilePage from '../containers/Auth/SignUp/SetupProfilePage';
import OnboardingPage from '../containers/Auth/OnBoarding';
import Settings from '../containers/Pages/Settings';
import Chat from '../containers/Pages/Chat';
import Profile from '../containers/Pages/Profile';
import AllMessagesMobile from '../containers/Pages/Chat/AllMessagesMobile';
import DesktopDefault from '../containers/Pages/Chat/DesktopDefault';
import CreatePost from '../containers/Pages/CreatePost';
import Drafts from '../containers/Pages/CreatePost/Drafts';
import Preview from '../containers/Pages/CreatePost/Preview';
import CreateGroup from '../containers/Pages/Chat/CreateGroup';
import Search from '../containers/Pages/Search';
import MyProfile from '../containers/Pages/MyProfile';
import UserViewProfile from '../containers/Pages/UserViewProfile';
import SuggestedAll from '../containers/Pages/SuggestedAll';
import Rewards from '../containers/Pages/Rewards';
import RewardsCheckout from '../containers/Pages/Rewards/RewardsCheckout';
import { useSelector } from 'react-redux';
import ChangePassword from '../containers/Auth/ForgetPassword/ChangePassword';
import RefundPolicy from '../common/components/Policies/RefundPolicy';
import PrivacyPolicy from '../common/components/Policies/PrivacyPolicy';
import CookiePolicy from '../common/components/Policies/CookiePolicy';
import TermsAndConditions from '../common/components/Policies/TermsAndConditions';
import EditGroup from '../containers/Pages/Chat/EditGroup';

const Router = () => {
    const navigate = useNavigate();
    const loggedin = false;

    const { loginSuccess, registerSuccess } = useSelector((state) => state.authSlice);

    return useRoutes([
        {
            path: '/',
            element: !loginSuccess || !registerSuccess ? <LandingLayout /> : <Navigate to="/home" />,
            children: [
                { path: '', element: !loginSuccess || !registerSuccess ? <LandingPage /> : <Navigate to="/home" /> },
                { path: "login", element: <Login /> },
                { path: "forget-password", element: <ForgetPasswordPage /> },
                { path: "otp-verification", element: <OtpVerificationPage /> },
                { path: "change-password", element: <ChangePassword /> },
                { path: "setup-profile", element: <SetupProfilePage /> },
                { path: "onboarding", element: <OnboardingPage /> },
                { path: "signup", element: <SignUp /> }
            ]
        },
        {
            path: 'home',
            element: loginSuccess || registerSuccess ? <HomeLayout /> : <Navigate to="/" />,
            children: [
                { path: "", element: <HomePage /> },
                { path: "settings", element: <Settings /> },
                { path: "chat/:id", element: <Chat /> },
                { path: "profile", element: <MyProfile /> },
                { path: "user-profile", element: <UserViewProfile /> },
                { path: "all-messages", element: <AllMessagesMobile /> },
                { path: "all-messages-default", element: <DesktopDefault /> },
                { path: "create-post", element: <CreatePost /> },
                { path: "drafts", element: <Drafts /> },
                { path: "preview", element: <Preview /> },
                { path: "create-group", element: <CreateGroup /> },
                { path: "edit-group", element: <EditGroup /> },
                { path: "search", element: <Search /> },
                { path: "suggested-all", element: <SuggestedAll /> },
                { path: "rewards", element: <Rewards /> },
                { path: "rewards-checkout", element: <RewardsCheckout /> }
            ]
        },
        { path: "/refund-policy", element: <RefundPolicy /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/cookie-policy", element: <CookiePolicy /> },
        { path: "/terms-and-conditions", element: <TermsAndConditions /> },
    ])

};

export default Router;
