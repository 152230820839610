import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const { userInfo } = useSelector((state) => state.authSlice);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (userInfo?._id) {
      // const newSocket = io("http://192.168.0.173:8000", {
      const newSocket = io("https://rangeunitybackend.bhumiitech.com", {
        query: { userId: userInfo._id },
      });
      setSocket(newSocket);
      //   console.log("socket", newSocket);

      return () => {
        newSocket.close();
      };
    }
  }, [userInfo]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext);
};
