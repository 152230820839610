import React, { useEffect, useState } from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Drawer,
} from "@material-tailwind/react";
import avatar from '../../../assets/images/dummy_avatar.png';
import { TbLogout2 } from "react-icons/tb";
import bg from '../../../assets/images/bg-light.jpg';
import darkBg from '../../../assets/images/bg-dark.jpg';
import ThemeToggle from "../ThemeModeToggle";
import { ImSun } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { setActiveScreen, setCurrentPath } from "./sidebarSlice";
import { setActiveSection } from "../../../containers/Pages/HomePage/homeSectionSlice";
import { useLocation, useNavigate } from "react-router";
import { Home, LogoutCurve, Messages2, ProfileCircle, Setting4, Sun, Edit, Cup } from "iconsax-react";
import { setLoginSuccess, setRegisterSuccess, setUserInfo, setUserToken } from "../../../containers/Auth/authSlice";
import toast from "react-hot-toast";
import { setIsEditPost } from "../../../containers/Pages/CreatePost/activeTabSlice";
import logo from '../../../assets/images/rangeUnity.svg';

function SidebarWithLogo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.split("/")[2]

    const listItem = [
        { icon: <Home size="20" />, text: "Home", nav: "/home" },
        { icon: <ProfileCircle size="20" />, text: "Profile", nav: "/home/profile" },
        { icon: <Messages2 size="20" />, text: "Messages", nav: "/home/all-messages-default" },
        { icon: <Setting4 size="20" />, text: "Settings", nav: "/home/settings" },
        { icon: <Cup size="20" />, text: "Rewards", nav: "/home/rewards" }
    ]
    const { mode } = useSelector((state) => state.themeToggleSlice);
    const { activeScreen, currentPath } = useSelector((state) => state.sidebarSlice);
    const { userInfo } = useSelector((state) => state.authSlice);
    const classNames = "my-1 font-medium font-archivo text-base text-[#2A2A2E] dark:text-white hover:text-primaryLight-700 dark:hover:text-primaryDark-500 focus:text-primaryLight-700 dark:focus:text-primaryDark-500  hover:bg-primaryLight-500 hover:bg-opacity-25 hover:border-2 hover:border-primaryLight-500 dark:hover:border-2 dark:hover:border-primaryLight-500 focus:bg-primaryLight-300 focus:bg-opacity-25 focus:border-l-4 dark:focus:border-l-4 focus:rounded-tl-none focus:rounded-bl-none focus:border-primaryLight-300 dark:focus:border-primaryDark-500 border-2 border-transparent dark:hover:bg-black dark:focus:bg-black"

    useEffect(() => {
        switch (location?.pathname) {
            case '/home':
                dispatch((setActiveScreen(0)));
                break;
            case '/home/profile':
                dispatch((setActiveScreen(1)));
                break;
            case '/home/all-messages-default':
                dispatch((setActiveScreen(2)));
                break;
            case "/home/settings":
                dispatch((setActiveScreen(3)));
                break;
            case "/home/rewards":
                dispatch((setActiveScreen(4)));
                break;
        }
    }, [location, activeScreen])

    return (
        <>
            <Card className="sticky top-0 z-50 min-h-screen h-fit w-full max-w-[17rem] shadow-xl shadow-blue-gray-900/5 border-r-2 dark:border-0 rounded-none dark:bg-black">
                <div className="mb-2 flex flex-row items-center justify-center gap-2 p-6">
                    <img src={logo} alt="logo" className="w-[30px] h-[30px]" />
                    <Typography variant="h5" className="text-textLight-400 text-md">
                        Range Unity
                    </Typography>
                </div>
                <div className="mb-2 flex flex-col items-center justify-center gap-4 p-4 bg-center" style={{ backgroundImage: `url(${!mode ? bg : darkBg})`, backgroundRepeat: 'no-repeat' }}>
                    <div onClick={() => navigate("/home/profile")} className="cursor-pointer relative -right-[25px] flex flex-wrap items-end">
                        <img src={userInfo?.avatar || userInfo?.staticUrl || avatar} alt="brand" className="h-16 w-16 ring-2 ring-primaryLight-500 rounded-full" />
                        <Typography className="relative bg-primaryLight-500 py-[2px] px-3 text-textDark-100 rounded-full text-sm -left-[40px] -bottom-[10px]">80%</Typography>
                    </div>
                    <Typography className="text-textLight-500 dark:text-white font-archivo text-base font-bold">{userInfo?.firstName} {userInfo?.lastName}</Typography>
                    <div className="flex gap-6">
                        <div className="flex flex-col items-center justify-center">
                            <Typography className="font-archivo font-bold text-sm text-textLight-500 dark:text-white">{userInfo?.followers?.length}</Typography>
                            <Typography className="font-rubik text-xs text-textLight-300 dark:text-white">Followers</Typography>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <Typography className="font-archivo font-bold text-sm text-textLight-500 dark:text-white">{userInfo?.following?.length}</Typography>
                            <Typography className="font-rubik text-xs text-textLight-300 dark:text-white">Following</Typography>
                        </div>
                    </div>
                </div>
                <div className="h-[66vh] flex flex-col gap-0 justify-between px-4">
                    <List className="!gap-0 !py-0">
                        {listItem?.map((item, index) => (
                            <ListItem onClick={() => (
                                navigate(item.nav),
                                item.text == "Home" && dispatch(setActiveSection("default")),
                                dispatch((setActiveScreen(index))),
                                navigate(item?.nav)
                            )}
                                key={index} id={`list-item-${index}`}
                                className={`${classNames} 
                              ${activeScreen == index && "bg-primaryLight-300 bg-opacity-25 border-l-4 text-primaryLight-700 rounded-tl-none rounded-bl-none border-primaryLight-300 dark:text-primaryDark-500 dark:border-l-4 dark:bg-black dark:border-primaryDark-500"}`}
                            >
                                <ListItemPrefix>
                                    {item?.icon}
                                </ListItemPrefix>
                                {item?.text}
                            </ListItem>
                        ))}
                    </List>
                    <List className="!gap-0">
                        <ListItem className="my-0 font-medium font-archivo text-base text-[#2A2A2E] dark:text-white  dark:hover:bg-black hover:bg-transparent hover:bg-opacity-25 hover:border-2 hover:border-white focus:bg-transparent dark:focus:bg-transparent focus:bg-opacity-25 focus:border-l-4 focus:rounded-tl-none focus:rounded-bl-none focus:border-transparent border-2 border-white dark:border-transparent">
                            <ListItemPrefix>
                                <Sun size="20" />
                            </ListItemPrefix>
                            Interface
                            <ListItemSuffix><ThemeToggle /></ListItemSuffix>
                        </ListItem>
                        <ListItem className={classNames} onClick={() =>
                            dispatch(setLoginSuccess(false), dispatch(setIsEditPost(false)),
                                dispatch(setRegisterSuccess(false), dispatch(setUserInfo({})), dispatch(setUserToken(""))
                                    // toast.success("User logged out successfully")
                                ))}>
                            <ListItemPrefix>
                                <LogoutCurve size="20" />
                            </ListItemPrefix>
                            Logout
                        </ListItem>
                    </List>
                </div>
            </Card>

        </>
    );
}

export default SidebarWithLogo;