import React, { useState } from 'react'
import Profile from '../Profile'
import { useLocation, useParams } from 'react-router';

const UserViewProfile = () => {
  // const params = useParams();
  const location = useLocation();
    const [isEdit, setIsEdit] = useState(false);
    const userData = location?.state?.values;
    // console.log("userData", userData)
  return (
    <>
    <Profile isEdit={isEdit} setIsEdit={setIsEdit} />
    </>
  )
}

export default UserViewProfile;