

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    emojiArray: [],
    groupAdmin:true,
    addMember: false

};

const frequentlyUsedEmojiSlice = createSlice({
    name: 'frequentlyUsedEmojiSlice',
    initialState,
    reducers: {
        setEmojiArray: (state, { payload }) => {
            state.emojiArray = payload;
        },
        setGroupAdmin:(state, { payload }) => {
            state.groupAdmin = payload;
        },
        setAddMember:(state, { payload }) => {
            state.addMember = payload;
        }
    }
});

export const { setEmojiArray, setGroupAdmin, setAddMember } = frequentlyUsedEmojiSlice.actions;
export default frequentlyUsedEmojiSlice.reducer;
