import { useQuery } from "@apollo/client";
import { Avatar, Button, Card, Typography } from "@material-tailwind/react";
import { MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router";
import { GET_SUGGESTED_USERS } from "../../../API/Queries";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../../../common/components/Loader";

const SuggestedUsers = () => {
  const navigate = useNavigate();
  const [peopleData, setPeopleData] = useState([]);
  const { userToken, userInfo } = useSelector((state) => state.authSlice);

  const { data: suggestedUsers, loading } = useQuery(GET_SUGGESTED_USERS, {
    context: {
      headers: {
        Authorization: `${userToken?.replace("Bearer ", "")}`,
      },
    },
  });

  useEffect(() => {
    const temp = suggestedUsers?.getSuggestedUsers?.filter((x) =>
      userInfo?.following?.some((y) => x?._id !== y?._id)
    );
    setPeopleData(temp);
  }, [suggestedUsers?.getSuggestedUsers]);

  if (loading) return <Loader minHeight="100vh" />;

  return (
    <>
      <Card className="h-fit p-6 dark:bg-textLight-700">
        <Typography
          variant="h4"
          className="font-archivo text-xl text-textLight-500 dark:text-white font-bold mb-4"
        >
          Suggested for you
        </Typography>
        <div className="flex flex-col gap-2">
          {peopleData?.slice(0, 4)?.map((item, index) => (
            <div
              onClick={() => navigate("/home/user-profile",{state:{ values: item }})}
              key={index}
              className="cursor-pointer flex items-center justify-between pb-2 "
            >
              <div className="flex gap-2 items-center">
                <Avatar
                  className="!rounded-md"
                  src={item?.avatar ? item?.avatar : "https://docs.material-tailwind.com/img/face-2.jpg"}
                  alt="avatar"
                  size="md"
                />
                <div>
                  <Typography className="text-sm font-archivo font-semibold text-textLight-400 dark:text-white">
                    {item?.firstName} {item?.lastName}
                  </Typography>
                  <Typography className="text-xs font-rubik text-textLight-400 dark:text-white">
                    {item?.followers?.length}{" "}
                    {item?.followers?.length < 9 ? "Follower" : "Followers"}
                  </Typography>
                </div>
              </div>
              <div className="flex gap-2 items-center text-2xl text-primaryLight-500 dark:text-primaryDark-500">
                <MdAddCircleOutline />
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <Button
            variant="text"
            color="green"
            size="sm"
            className="w-1/2 mt-4 normal-case text-md font-archivo"
            onClick={() => navigate("/home/suggested-all")}
          >
            See All
          </Button>
        </div>
      </Card>
    </>
  );
};

export default SuggestedUsers;
